export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 186.9,
    "out:Total Energy Use Post PV": 186.9,
    "out:Primary Energy": 135.39,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 63.66,
    "out:Total CO2/m2": 63.66,
    "out:Total CO2 (tons)": 217,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 43898,
    "out:Running Cost over RSP/MSEK": 12.702,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 4,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 187,
    "out:Energy use kWh/m2": 187,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 181.9,
    "out:Primary Energy": 131.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 61.86,
    "out:Total CO2/m2": 61.86,
    "out:Total CO2 (tons)": 210.87,
    "out:Klimatpaverkan": -6.13,
    "out:Initial Cost/MSEK": 0.085225,
    "out:Running cost/(Apt SEK y)": 42687,
    "out:Running Cost over RSP/MSEK": 12.352,
    "out:LCP/MSEK": 0.265,
    "out:ROI% old": 58.77,
    "out:Payback discounted": 2,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 2.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 53,
    "out:Return/kSEK/y": 45,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 17045,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 15511,
    "out:DH kr savings": 15511,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 180.9,
    "out:Primary Energy": 132.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.5,
    "out:CO2 Operational/m2": 61.5,
    "out:Total CO2/m2": 61.99,
    "out:Total CO2 (tons)": 211.33,
    "out:Klimatpaverkan": -5.67,
    "out:Initial Cost/MSEK": 0.204912,
    "out:Running cost/(Apt SEK y)": 42445,
    "out:Running Cost over RSP/MSEK": 12.282,
    "out:LCP/MSEK": 0.216,
    "out:ROI% old": 29.33,
    "out:Payback discounted": 4,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 3.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 26,
    "out:Return/kSEK/y": 54,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 20454,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 18613,
    "out:DH kr savings": 18613,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 174.9,
    "out:Primary Energy": 128.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.5,
    "out:CO2 Operational/m2": 59.34,
    "out:Total CO2/m2": 59.83,
    "out:Total CO2 (tons)": 203.97,
    "out:Klimatpaverkan": -13.03,
    "out:Initial Cost/MSEK": 0.290137,
    "out:Running cost/(Apt SEK y)": 40992,
    "out:Running Cost over RSP/MSEK": 11.861,
    "out:LCP/MSEK": 0.551,
    "out:ROI% old": 41.43,
    "out:Payback discounted": 3,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 6.9,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 37,
    "out:Return/kSEK/y": 108,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 40908,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 37226,
    "out:DH kr savings": 37226,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 186.9,
    "out:Total Energy Use Post PV": 181.73,
    "out:Primary Energy": 126.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 32.66,
    "out:Total CO2/m2": 45.01,
    "out:Total CO2 (tons)": 153.45,
    "out:Klimatpaverkan": -63.55,
    "out:Initial Cost/MSEK": 0.709109,
    "out:Running cost/(Apt SEK y)": 42382,
    "out:Running Cost over RSP/MSEK": 12.275,
    "out:LCP/MSEK": -0.282,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 2.7,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 56,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56074,
    "out:DH kr savings": 0,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 176.73,
    "out:Primary Energy": 122.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 30.86,
    "out:Total CO2/m2": 43.21,
    "out:Total CO2 (tons)": 147.32,
    "out:Klimatpaverkan": -69.68,
    "out:Initial Cost/MSEK": 0.794334,
    "out:Running cost/(Apt SEK y)": 41172,
    "out:Running Cost over RSP/MSEK": 11.925,
    "out:LCP/MSEK": -0.017,
    "out:ROI% old": 13.98,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 5.6,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 101,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 17045,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71585,
    "out:DH kr savings": 15511,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 175.73,
    "out:Primary Energy": 123.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 43.35,
    "out:Total CO2 (tons)": 147.78,
    "out:Klimatpaverkan": -69.22,
    "out:Initial Cost/MSEK": 0.914021,
    "out:Running cost/(Apt SEK y)": 40930,
    "out:Running Cost over RSP/MSEK": 11.855,
    "out:LCP/MSEK": -0.067,
    "out:ROI% old": 13.25,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 110,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 20454,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74687,
    "out:DH kr savings": 18613,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 169.73,
    "out:Primary Energy": 119.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 28.34,
    "out:Total CO2/m2": 41.19,
    "out:Total CO2 (tons)": 140.42,
    "out:Klimatpaverkan": -76.58,
    "out:Initial Cost/MSEK": 0.999246,
    "out:Running cost/(Apt SEK y)": 39477,
    "out:Running Cost over RSP/MSEK": 11.434,
    "out:LCP/MSEK": 0.269,
    "out:ROI% old": 18.13,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 164,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 40908,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93300,
    "out:DH kr savings": 37226,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 186.9,
    "out:Total Energy Use Post PV": 180.88,
    "out:Primary Energy": 124.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -20.02,
    "out:Total CO2/m2": 4.69,
    "out:Total CO2 (tons)": 15.98,
    "out:Klimatpaverkan": -201.02,
    "out:Initial Cost/MSEK": 1.41825,
    "out:Running cost/(Apt SEK y)": 41036,
    "out:Running Cost over RSP/MSEK": 11.897,
    "out:LCP/MSEK": -0.613,
    "out:ROI% old": 8.11,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 3.3,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 106,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105885,
    "out:DH kr savings": 0,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 181.9,
    "out:Total Energy Use Post PV": 175.88,
    "out:Primary Energy": 120.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -21.82,
    "out:Total CO2/m2": 2.89,
    "out:Total CO2 (tons)": 9.85,
    "out:Klimatpaverkan": -207.15,
    "out:Initial Cost/MSEK": 1.5035,
    "out:Running cost/(Apt SEK y)": 39825,
    "out:Running Cost over RSP/MSEK": 11.546,
    "out:LCP/MSEK": -0.347,
    "out:ROI% old": 10.98,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 151,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 17045,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121396,
    "out:DH kr savings": 15511,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 180.9,
    "out:Total Energy Use Post PV": 174.88,
    "out:Primary Energy": 121.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.2,
    "out:CO2 Operational/m2": -22.18,
    "out:Total CO2/m2": 3.02,
    "out:Total CO2 (tons)": 10.31,
    "out:Klimatpaverkan": -206.69,
    "out:Initial Cost/MSEK": 1.623125,
    "out:Running cost/(Apt SEK y)": 39583,
    "out:Running Cost over RSP/MSEK": 11.476,
    "out:LCP/MSEK": -0.397,
    "out:ROI% old": 10.79,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 6.9,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 165,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 160,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 20454,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124498,
    "out:DH kr savings": 18613,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 174.9,
    "out:Total Energy Use Post PV": 168.88,
    "out:Primary Energy": 118.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.2,
    "out:CO2 Operational/m2": -24.34,
    "out:Total CO2/m2": 0.86,
    "out:Total CO2 (tons)": 2.95,
    "out:Klimatpaverkan": -214.05,
    "out:Initial Cost/MSEK": 1.708375,
    "out:Running cost/(Apt SEK y)": 38130,
    "out:Running Cost over RSP/MSEK": 11.055,
    "out:LCP/MSEK": -0.061,
    "out:ROI% old": 13.77,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 213,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 40908,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143111,
    "out:DH kr savings": 37226,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 177.9,
    "out:Total Energy Use Post PV": 177.9,
    "out:Primary Energy": 131.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 60.42,
    "out:Total CO2/m2": 60.42,
    "out:Total CO2 (tons)": 205.96,
    "out:Klimatpaverkan": -11.04,
    "out:Initial Cost/MSEK": 0.097655,
    "out:Running cost/(Apt SEK y)": 41719,
    "out:Running Cost over RSP/MSEK": 12.071,
    "out:LCP/MSEK": 0.533,
    "out:ROI% old": 92.31,
    "out:Payback discounted": 2,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 5.1,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 83,
    "out:Return/kSEK/y": 81,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 29,
    "out:Payback Time": 3.448276,
    "out:DH kWh savings": 30681,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 27920,
    "out:DH kr savings": 27920,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 172.9,
    "out:Primary Energy": 128.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 58.62,
    "out:Total CO2/m2": 58.62,
    "out:Total CO2 (tons)": 199.82,
    "out:Klimatpaverkan": -17.18,
    "out:Initial Cost/MSEK": 0.18288,
    "out:Running cost/(Apt SEK y)": 40508,
    "out:Running Cost over RSP/MSEK": 11.721,
    "out:LCP/MSEK": 0.799,
    "out:ROI% old": 76.68,
    "out:Payback discounted": 2,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 69,
    "out:Return/kSEK/y": 125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 24,
    "out:Payback Time": 4.166667,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 43431,
    "out:DH kr savings": 43431,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 172.9,
    "out:Primary Energy": 129.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.5,
    "out:CO2 Operational/m2": 58.62,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 201.52,
    "out:Klimatpaverkan": -15.48,
    "out:Initial Cost/MSEK": 0.302567,
    "out:Running cost/(Apt SEK y)": 40508,
    "out:Running Cost over RSP/MSEK": 11.721,
    "out:LCP/MSEK": 0.679,
    "out:ROI% old": 46.35,
    "out:Payback discounted": 3,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 41,
    "out:Return/kSEK/y": 125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 43431,
    "out:DH kr savings": 43431,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 125.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.5,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 57.31,
    "out:Total CO2 (tons)": 195.38,
    "out:Klimatpaverkan": -21.62,
    "out:Initial Cost/MSEK": 0.387792,
    "out:Running cost/(Apt SEK y)": 39297,
    "out:Running Cost over RSP/MSEK": 11.37,
    "out:LCP/MSEK": 0.944,
    "out:ROI% old": 49.08,
    "out:Payback discounted": 3,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 44,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 64771,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 58942,
    "out:DH kr savings": 58942,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 177.9,
    "out:Total Energy Use Post PV": 172.73,
    "out:Primary Energy": 122.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 29.42,
    "out:Total CO2/m2": 41.77,
    "out:Total CO2 (tons)": 142.41,
    "out:Klimatpaverkan": -74.59,
    "out:Initial Cost/MSEK": 0.806764,
    "out:Running cost/(Apt SEK y)": 40203,
    "out:Running Cost over RSP/MSEK": 11.644,
    "out:LCP/MSEK": 0.251,
    "out:ROI% old": 18.73,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 137,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 30681,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83994,
    "out:DH kr savings": 27920,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 167.73,
    "out:Primary Energy": 118.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.35,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 39.97,
    "out:Total CO2 (tons)": 136.27,
    "out:Klimatpaverkan": -80.73,
    "out:Initial Cost/MSEK": 0.891989,
    "out:Running cost/(Apt SEK y)": 38992,
    "out:Running Cost over RSP/MSEK": 11.294,
    "out:LCP/MSEK": 0.516,
    "out:ROI% old": 22.56,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 182,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99505,
    "out:DH kr savings": 43431,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 167.73,
    "out:Primary Energy": 120.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 40.47,
    "out:Total CO2 (tons)": 137.97,
    "out:Klimatpaverkan": -79.03,
    "out:Initial Cost/MSEK": 1.011676,
    "out:Running cost/(Apt SEK y)": 38992,
    "out:Running Cost over RSP/MSEK": 11.294,
    "out:LCP/MSEK": 0.397,
    "out:ROI% old": 19.89,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 182,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99505,
    "out:DH kr savings": 43431,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 116.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.85,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 38.67,
    "out:Total CO2 (tons)": 131.83,
    "out:Klimatpaverkan": -85.17,
    "out:Initial Cost/MSEK": 1.096901,
    "out:Running cost/(Apt SEK y)": 37782,
    "out:Running Cost over RSP/MSEK": 10.943,
    "out:LCP/MSEK": 0.662,
    "out:ROI% old": 22.91,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 64771,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115016,
    "out:DH kr savings": 58942,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 177.9,
    "out:Total Energy Use Post PV": 171.88,
    "out:Primary Energy": 121.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -23.26,
    "out:Total CO2/m2": 1.45,
    "out:Total CO2 (tons)": 4.94,
    "out:Klimatpaverkan": -212.06,
    "out:Initial Cost/MSEK": 1.515875,
    "out:Running cost/(Apt SEK y)": 38857,
    "out:Running Cost over RSP/MSEK": 11.266,
    "out:LCP/MSEK": -0.079,
    "out:ROI% old": 13.54,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 8.7,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 30681,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133805,
    "out:DH kr savings": 27920,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 166.88,
    "out:Primary Energy": 117.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.71,
    "out:CO2 Operational/m2": -25.06,
    "out:Total CO2/m2": -0.35,
    "out:Total CO2 (tons)": -1.2,
    "out:Klimatpaverkan": -218.2,
    "out:Initial Cost/MSEK": 1.601125,
    "out:Running cost/(Apt SEK y)": 37646,
    "out:Running Cost over RSP/MSEK": 10.915,
    "out:LCP/MSEK": 0.186,
    "out:ROI% old": 15.95,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149316,
    "out:DH kr savings": 43431,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 166.88,
    "out:Primary Energy": 118.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.2,
    "out:CO2 Operational/m2": -25.06,
    "out:Total CO2/m2": 0.14,
    "out:Total CO2 (tons)": 0.49,
    "out:Klimatpaverkan": -216.51,
    "out:Initial Cost/MSEK": 1.72075,
    "out:Running cost/(Apt SEK y)": 37646,
    "out:Running Cost over RSP/MSEK": 10.915,
    "out:LCP/MSEK": 0.066,
    "out:ROI% old": 14.84,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149316,
    "out:DH kr savings": 43431,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 434756,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.53,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 115.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.2,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": -1.66,
    "out:Total CO2 (tons)": -5.64,
    "out:Klimatpaverkan": -222.64,
    "out:Initial Cost/MSEK": 1.806,
    "out:Running cost/(Apt SEK y)": 36435,
    "out:Running Cost over RSP/MSEK": 10.565,
    "out:LCP/MSEK": 0.332,
    "out:ROI% old": 16.91,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 64771,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164827,
    "out:DH kr savings": 58942,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 415924,
    "out:% savings (space heating)": 4.33,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 122.01,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.65,
    "out:Electricity (inc PV)": 41.89,
    "out:Total Energy Use Pre PV": 130.65,
    "out:Total Energy Use Post PV": 128.89,
    "out:Primary Energy": 127.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.51,
    "out:CO2 Operational/m2": 37.08,
    "out:Total CO2/m2": 40.59,
    "out:Total CO2 (tons)": 138.36,
    "out:Klimatpaverkan": -78.64,
    "out:Initial Cost/MSEK": 2.846,
    "out:Running cost/(Apt SEK y)": 27903,
    "out:Running Cost over RSP/MSEK": 8.056,
    "out:LCP/MSEK": 1.801,
    "out:ROI% old": 23.32,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 3055.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": -88585,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109989,
    "out:DH kr savings": 260584,
    "out:El kr savings": -150595,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 296778,
    "out:% savings (space heating)": 31.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 74.37,
    "out:Etvv": 0,
    "out:Ef": 41.89,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.59,
    "out:Electricity (inc PV)": 41.79,
    "out:Total Energy Use Pre PV": 125.59,
    "out:Total Energy Use Post PV": 123.79,
    "out:Primary Energy": 123.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.51,
    "out:CO2 Operational/m2": 35.27,
    "out:Total CO2/m2": 38.78,
    "out:Total CO2 (tons)": 132.2,
    "out:Klimatpaverkan": -84.8,
    "out:Initial Cost/MSEK": 2.93125,
    "out:Running cost/(Apt SEK y)": 26684,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": 2.068,
    "out:ROI% old": 24.37,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 3051.4,
    "out:Return %": 22,
    "out:Return/kSEK/y": 637,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303401,
    "out:EL kWh savings": -88254,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126063,
    "out:DH kr savings": 276095,
    "out:El kr savings": -150032,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278561,
    "out:% savings (space heating)": 35.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 69.16,
    "out:Etvv": 0,
    "out:Ef": 41.79,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 41.49,
    "out:Total Energy Use Pre PV": 125.06,
    "out:Total Energy Use Post PV": 123.49,
    "out:Primary Energy": 125.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4,
    "out:CO2 Operational/m2": 35.2,
    "out:Total CO2/m2": 39.2,
    "out:Total CO2 (tons)": 133.65,
    "out:Klimatpaverkan": -83.35,
    "out:Initial Cost/MSEK": 3.051,
    "out:Running cost/(Apt SEK y)": 26601,
    "out:Running Cost over RSP/MSEK": 7.679,
    "out:LCP/MSEK": 1.973,
    "out:ROI% old": 23.52,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 3014.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303401,
    "out:EL kWh savings": -87233,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127799,
    "out:DH kr savings": 276095,
    "out:El kr savings": -148296,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 281132,
    "out:% savings (space heating)": 35.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 71.93,
    "out:Etvv": 0,
    "out:Ef": 41.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.99,
    "out:Electricity (inc PV)": 41.38,
    "out:Total Energy Use Pre PV": 119.99,
    "out:Total Energy Use Post PV": 118.38,
    "out:Primary Energy": 121.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4,
    "out:CO2 Operational/m2": 33.39,
    "out:Total CO2/m2": 37.4,
    "out:Total CO2 (tons)": 127.48,
    "out:Klimatpaverkan": -89.52,
    "out:Initial Cost/MSEK": 3.136125,
    "out:Running cost/(Apt SEK y)": 25380,
    "out:Running Cost over RSP/MSEK": 7.325,
    "out:LCP/MSEK": 2.241,
    "out:ROI% old": 24.49,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 3009.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -86866,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143933,
    "out:DH kr savings": 291606,
    "out:El kr savings": -147672,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 263046,
    "out:% savings (space heating)": 39.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 66.76,
    "out:Etvv": 0,
    "out:Ef": 41.38,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.65,
    "out:Electricity (inc PV)": 32.5,
    "out:Total Energy Use Pre PV": 130.65,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 110.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.86,
    "out:CO2 Operational/m2": 27.97,
    "out:Total CO2/m2": 43.83,
    "out:Total CO2 (tons)": 149.41,
    "out:Klimatpaverkan": -67.59,
    "out:Initial Cost/MSEK": 3.555125,
    "out:Running cost/(Apt SEK y)": 26216,
    "out:Running Cost over RSP/MSEK": 7.58,
    "out:LCP/MSEK": 1.567,
    "out:ROI% old": 20.58,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 2411.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5492.78,
    "out:PV (% sold (El))": 14.42,
    "out:PV (kWh self-consumed)": 32610.11,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": -56588,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171388,
    "out:DH kr savings": 260584,
    "out:El kr savings": -96199,
    "out:El kr sold": 7003,
    "out:El kr saved": 55437,
    "out:El kr return": 62440,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 296778,
    "out:% savings (space heating)": 31.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 74.37,
    "out:Etvv": 0,
    "out:Ef": 32.5,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.59,
    "out:Electricity (inc PV)": 32.41,
    "out:Total Energy Use Pre PV": 125.59,
    "out:Total Energy Use Post PV": 114.41,
    "out:Primary Energy": 106.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.86,
    "out:CO2 Operational/m2": 26.14,
    "out:Total CO2/m2": 41.99,
    "out:Total CO2 (tons)": 143.16,
    "out:Klimatpaverkan": -73.84,
    "out:Initial Cost/MSEK": 3.640375,
    "out:Running cost/(Apt SEK y)": 24997,
    "out:Running Cost over RSP/MSEK": 7.227,
    "out:LCP/MSEK": 1.835,
    "out:ROI% old": 21.48,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 2407.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 699,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5511.37,
    "out:PV (% sold (El))": 14.46,
    "out:PV (kWh self-consumed)": 32591.52,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303401,
    "out:EL kWh savings": -56292,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 187426,
    "out:DH kr savings": 276095,
    "out:El kr savings": -95696,
    "out:El kr sold": 7027,
    "out:El kr saved": 55406,
    "out:El kr return": 62433,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278561,
    "out:% savings (space heating)": 35.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 69.16,
    "out:Etvv": 0,
    "out:Ef": 32.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 32.14,
    "out:Total Energy Use Pre PV": 125.06,
    "out:Total Energy Use Post PV": 114.14,
    "out:Primary Energy": 108.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.35,
    "out:CO2 Operational/m2": 25.8,
    "out:Total CO2/m2": 42.16,
    "out:Total CO2 (tons)": 143.72,
    "out:Klimatpaverkan": -73.28,
    "out:Initial Cost/MSEK": 3.76,
    "out:Running cost/(Apt SEK y)": 24915,
    "out:Running Cost over RSP/MSEK": 7.204,
    "out:LCP/MSEK": 1.738,
    "out:ROI% old": 20.89,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 2373.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 702,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5693.38,
    "out:PV (% sold (El))": 14.94,
    "out:PV (kWh self-consumed)": 32409.51,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303401,
    "out:EL kWh savings": -55379,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189210,
    "out:DH kr savings": 276095,
    "out:El kr savings": -94144,
    "out:El kr sold": 7259,
    "out:El kr saved": 55096,
    "out:El kr return": 62355,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 281132,
    "out:% savings (space heating)": 35.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 71.93,
    "out:Etvv": 0,
    "out:Ef": 32.14,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.99,
    "out:Electricity (inc PV)": 32.05,
    "out:Total Energy Use Pre PV": 119.99,
    "out:Total Energy Use Post PV": 109.05,
    "out:Primary Energy": 104.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.35,
    "out:CO2 Operational/m2": 23.96,
    "out:Total CO2/m2": 40.32,
    "out:Total CO2 (tons)": 137.44,
    "out:Klimatpaverkan": -79.56,
    "out:Initial Cost/MSEK": 3.84525,
    "out:Running cost/(Apt SEK y)": 23695,
    "out:Running Cost over RSP/MSEK": 6.851,
    "out:LCP/MSEK": 2.006,
    "out:ROI% old": 21.74,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2369.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5716.3,
    "out:PV (% sold (El))": 15,
    "out:PV (kWh self-consumed)": 32386.59,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -55051,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205308,
    "out:DH kr savings": 291606,
    "out:El kr savings": -93586,
    "out:El kr sold": 7288,
    "out:El kr saved": 55057,
    "out:El kr return": 62345,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 263046,
    "out:% savings (space heating)": 39.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 66.76,
    "out:Etvv": 0,
    "out:Ef": 32.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.65,
    "out:Electricity (inc PV)": 29.37,
    "out:Total Energy Use Pre PV": 130.65,
    "out:Total Energy Use Post PV": 116.37,
    "out:Primary Energy": 104.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.21,
    "out:CO2 Operational/m2": -12.34,
    "out:Total CO2/m2": 15.87,
    "out:Total CO2 (tons)": 54.1,
    "out:Klimatpaverkan": -162.9,
    "out:Initial Cost/MSEK": 4.26425,
    "out:Running cost/(Apt SEK y)": 24774,
    "out:Running Cost over RSP/MSEK": 7.174,
    "out:LCP/MSEK": 1.264,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 2176.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.45,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32379.44,
    "out:PV (% sold (El))": 42.49,
    "out:PV (kWh self-consumed)": 43826.35,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": -45932,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223783,
    "out:DH kr savings": 260584,
    "out:El kr savings": -78085,
    "out:El kr sold": 41284,
    "out:El kr saved": 74505,
    "out:El kr return": 115789,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 296778,
    "out:% savings (space heating)": 31.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 74.37,
    "out:Etvv": 0,
    "out:Ef": 29.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.59,
    "out:Electricity (inc PV)": 29.3,
    "out:Total Energy Use Pre PV": 125.59,
    "out:Total Energy Use Post PV": 111.3,
    "out:Primary Energy": 101.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.21,
    "out:CO2 Operational/m2": -14.2,
    "out:Total CO2/m2": 14.01,
    "out:Total CO2 (tons)": 47.76,
    "out:Klimatpaverkan": -169.24,
    "out:Initial Cost/MSEK": 4.3495,
    "out:Running cost/(Apt SEK y)": 23555,
    "out:Running Cost over RSP/MSEK": 6.821,
    "out:LCP/MSEK": 1.531,
    "out:ROI% old": 19.32,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 2173.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32415.45,
    "out:PV (% sold (El))": 42.54,
    "out:PV (kWh self-consumed)": 43790.33,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 303401,
    "out:EL kWh savings": -45667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239791,
    "out:DH kr savings": 276095,
    "out:El kr savings": -77633,
    "out:El kr sold": 41330,
    "out:El kr saved": 74444,
    "out:El kr return": 115773,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278561,
    "out:% savings (space heating)": 35.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 69.16,
    "out:Etvv": 0,
    "out:Ef": 29.3,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.06,
    "out:Electricity (inc PV)": 29.06,
    "out:Total Energy Use Pre PV": 125.06,
    "out:Total Energy Use Post PV": 111.06,
    "out:Primary Energy": 102.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.71,
    "out:CO2 Operational/m2": -14.79,
    "out:Total CO2/m2": 13.92,
    "out:Total CO2 (tons)": 47.45,
    "out:Klimatpaverkan": -169.55,
    "out:Initial Cost/MSEK": 4.469125,
    "out:Running cost/(Apt SEK y)": 23476,
    "out:Running Cost over RSP/MSEK": 6.799,
    "out:LCP/MSEK": 1.435,
    "out:ROI% old": 18.87,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 2143.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 756,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32767.19,
    "out:PV (% sold (El))": 43,
    "out:PV (kWh self-consumed)": 43438.59,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303401,
    "out:EL kWh savings": -44848,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241631,
    "out:DH kr savings": 276095,
    "out:El kr savings": -76242,
    "out:El kr sold": 41778,
    "out:El kr saved": 73846,
    "out:El kr return": 115624,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 281132,
    "out:% savings (space heating)": 35.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 71.93,
    "out:Etvv": 0,
    "out:Ef": 29.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.99,
    "out:Electricity (inc PV)": 28.97,
    "out:Total Energy Use Pre PV": 119.99,
    "out:Total Energy Use Post PV": 105.97,
    "out:Primary Energy": 98.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.71,
    "out:CO2 Operational/m2": -16.66,
    "out:Total CO2/m2": 12.05,
    "out:Total CO2 (tons)": 41.06,
    "out:Klimatpaverkan": -175.94,
    "out:Initial Cost/MSEK": 4.554375,
    "out:Running cost/(Apt SEK y)": 22255,
    "out:Running Cost over RSP/MSEK": 6.445,
    "out:LCP/MSEK": 1.703,
    "out:ROI% old": 19.63,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2139.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 801,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32811.29,
    "out:PV (% sold (El))": 43.06,
    "out:PV (kWh self-consumed)": 43394.5,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -44554,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257698,
    "out:DH kr savings": 291606,
    "out:El kr savings": -75742,
    "out:El kr sold": 41834,
    "out:El kr saved": 73771,
    "out:El kr return": 115605,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 263046,
    "out:% savings (space heating)": 39.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 66.76,
    "out:Etvv": 0,
    "out:Ef": 28.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.86,
    "out:Electricity (inc PV)": 41.35,
    "out:Total Energy Use Pre PV": 123.86,
    "out:Total Energy Use Post PV": 122.35,
    "out:Primary Energy": 124.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.51,
    "out:CO2 Operational/m2": 34.82,
    "out:Total CO2/m2": 38.32,
    "out:Total CO2 (tons)": 130.64,
    "out:Klimatpaverkan": -86.36,
    "out:Initial Cost/MSEK": 2.943625,
    "out:Running cost/(Apt SEK y)": 26327,
    "out:Running Cost over RSP/MSEK": 7.599,
    "out:LCP/MSEK": 2.159,
    "out:ROI% old": 24.76,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 3000,
    "out:Return %": 22,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 306810,
    "out:EL kWh savings": -86772,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131684,
    "out:DH kr savings": 279197,
    "out:El kr savings": -147513,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 276003,
    "out:% savings (space heating)": 36.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 71.12,
    "out:Etvv": 0,
    "out:Ef": 41.35,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.79,
    "out:Electricity (inc PV)": 41.24,
    "out:Total Energy Use Pre PV": 118.79,
    "out:Total Energy Use Post PV": 117.24,
    "out:Primary Energy": 120.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.51,
    "out:CO2 Operational/m2": 33.01,
    "out:Total CO2/m2": 36.51,
    "out:Total CO2 (tons)": 124.47,
    "out:Klimatpaverkan": -92.53,
    "out:Initial Cost/MSEK": 3.028875,
    "out:Running cost/(Apt SEK y)": 25105,
    "out:Running Cost over RSP/MSEK": 7.246,
    "out:LCP/MSEK": 2.428,
    "out:ROI% old": 25.74,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 2995,
    "out:Return %": 23,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 323855,
    "out:EL kWh savings": -86393,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 147840,
    "out:DH kr savings": 294708,
    "out:El kr savings": -146868,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 257970,
    "out:% savings (space heating)": 40.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 65.97,
    "out:Etvv": 0,
    "out:Ef": 41.24,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.35,
    "out:Electricity (inc PV)": 41.02,
    "out:Total Energy Use Pre PV": 119.35,
    "out:Total Energy Use Post PV": 118.02,
    "out:Primary Energy": 122.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4,
    "out:CO2 Operational/m2": 33.31,
    "out:Total CO2/m2": 37.31,
    "out:Total CO2 (tons)": 127.19,
    "out:Klimatpaverkan": -89.81,
    "out:Initial Cost/MSEK": 3.148625,
    "out:Running cost/(Apt SEK y)": 25279,
    "out:Running Cost over RSP/MSEK": 7.296,
    "out:LCP/MSEK": 2.257,
    "out:ROI% old": 24.53,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2964.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -85646,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146007,
    "out:DH kr savings": 291606,
    "out:El kr savings": -145599,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 263902,
    "out:% savings (space heating)": 39.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 69.21,
    "out:Etvv": 0,
    "out:Ef": 41.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.27,
    "out:Electricity (inc PV)": 40.9,
    "out:Total Energy Use Pre PV": 114.27,
    "out:Total Energy Use Post PV": 112.9,
    "out:Primary Energy": 118.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4,
    "out:CO2 Operational/m2": 31.5,
    "out:Total CO2/m2": 35.5,
    "out:Total CO2 (tons)": 121.02,
    "out:Klimatpaverkan": -95.98,
    "out:Initial Cost/MSEK": 3.233875,
    "out:Running cost/(Apt SEK y)": 24055,
    "out:Running Cost over RSP/MSEK": 6.942,
    "out:LCP/MSEK": 2.526,
    "out:ROI% old": 25.45,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2958.7,
    "out:Return %": 23,
    "out:Return/kSEK/y": 734,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -85238,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162211,
    "out:DH kr savings": 307117,
    "out:El kr savings": -144905,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 246014,
    "out:% savings (space heating)": 43.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 64.08,
    "out:Etvv": 0,
    "out:Ef": 40.9,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.86,
    "out:Electricity (inc PV)": 32.02,
    "out:Total Energy Use Pre PV": 123.86,
    "out:Total Energy Use Post PV": 113.02,
    "out:Primary Energy": 107.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.86,
    "out:CO2 Operational/m2": 25.31,
    "out:Total CO2/m2": 41.17,
    "out:Total CO2 (tons)": 140.36,
    "out:Klimatpaverkan": -76.64,
    "out:Initial Cost/MSEK": 3.65275,
    "out:Running cost/(Apt SEK y)": 24642,
    "out:Running Cost over RSP/MSEK": 7.125,
    "out:LCP/MSEK": 1.924,
    "out:ROI% old": 21.81,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 2360.7,
    "out:Return %": 20,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5763.73,
    "out:PV (% sold (El))": 15.13,
    "out:PV (kWh self-consumed)": 32339.17,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 306810,
    "out:EL kWh savings": -54967,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 193102,
    "out:DH kr savings": 279197,
    "out:El kr savings": -93444,
    "out:El kr sold": 7349,
    "out:El kr saved": 54977,
    "out:El kr return": 62325,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 276003,
    "out:% savings (space heating)": 36.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 71.12,
    "out:Etvv": 0,
    "out:Ef": 32.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.79,
    "out:Electricity (inc PV)": 31.92,
    "out:Total Energy Use Pre PV": 118.79,
    "out:Total Energy Use Post PV": 107.92,
    "out:Primary Energy": 103.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.86,
    "out:CO2 Operational/m2": 23.47,
    "out:Total CO2/m2": 39.33,
    "out:Total CO2 (tons)": 134.07,
    "out:Klimatpaverkan": -82.93,
    "out:Initial Cost/MSEK": 3.738,
    "out:Running cost/(Apt SEK y)": 23421,
    "out:Running Cost over RSP/MSEK": 6.771,
    "out:LCP/MSEK": 2.193,
    "out:ROI% old": 22.67,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 2356.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 758,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5788.39,
    "out:PV (% sold (El))": 15.19,
    "out:PV (kWh self-consumed)": 32314.51,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 323855,
    "out:EL kWh savings": -54628,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209220,
    "out:DH kr savings": 294708,
    "out:El kr savings": -92868,
    "out:El kr sold": 7380,
    "out:El kr saved": 54935,
    "out:El kr return": 62315,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 257970,
    "out:% savings (space heating)": 40.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 65.97,
    "out:Etvv": 0,
    "out:Ef": 31.92,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.35,
    "out:Electricity (inc PV)": 31.73,
    "out:Total Energy Use Pre PV": 119.35,
    "out:Total Energy Use Post PV": 108.73,
    "out:Primary Energy": 105.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.35,
    "out:CO2 Operational/m2": 23.55,
    "out:Total CO2/m2": 39.91,
    "out:Total CO2 (tons)": 136.04,
    "out:Klimatpaverkan": -80.96,
    "out:Initial Cost/MSEK": 3.85775,
    "out:Running cost/(Apt SEK y)": 23596,
    "out:Running Cost over RSP/MSEK": 6.823,
    "out:LCP/MSEK": 2.022,
    "out:ROI% old": 21.77,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2328.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5940.59,
    "out:PV (% sold (El))": 15.59,
    "out:PV (kWh self-consumed)": 32162.3,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -53962,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207444,
    "out:DH kr savings": 291606,
    "out:El kr savings": -91736,
    "out:El kr sold": 7574,
    "out:El kr saved": 54676,
    "out:El kr return": 62250,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 263902,
    "out:% savings (space heating)": 39.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 69.21,
    "out:Etvv": 0,
    "out:Ef": 31.73,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.27,
    "out:Electricity (inc PV)": 31.62,
    "out:Total Energy Use Pre PV": 114.27,
    "out:Total Energy Use Post PV": 103.62,
    "out:Primary Energy": 101.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.35,
    "out:CO2 Operational/m2": 21.7,
    "out:Total CO2/m2": 38.05,
    "out:Total CO2 (tons)": 129.72,
    "out:Klimatpaverkan": -87.28,
    "out:Initial Cost/MSEK": 3.942875,
    "out:Running cost/(Apt SEK y)": 22373,
    "out:Running Cost over RSP/MSEK": 6.468,
    "out:LCP/MSEK": 2.291,
    "out:ROI% old": 22.59,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2323.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 796,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5970.22,
    "out:PV (% sold (El))": 15.67,
    "out:PV (kWh self-consumed)": 32132.67,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -53599,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223611,
    "out:DH kr savings": 307117,
    "out:El kr savings": -91118,
    "out:El kr sold": 7612,
    "out:El kr saved": 54626,
    "out:El kr return": 62238,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 246014,
    "out:% savings (space heating)": 43.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 64.08,
    "out:Etvv": 0,
    "out:Ef": 31.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.86,
    "out:Electricity (inc PV)": 28.95,
    "out:Total Energy Use Pre PV": 123.86,
    "out:Total Energy Use Post PV": 109.95,
    "out:Primary Energy": 101.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.21,
    "out:CO2 Operational/m2": -15.37,
    "out:Total CO2/m2": 12.84,
    "out:Total CO2 (tons)": 43.76,
    "out:Klimatpaverkan": -173.24,
    "out:Initial Cost/MSEK": 4.361875,
    "out:Running cost/(Apt SEK y)": 23203,
    "out:Running Cost over RSP/MSEK": 6.72,
    "out:LCP/MSEK": 1.621,
    "out:ROI% old": 19.59,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 2131.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 766,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32902.35,
    "out:PV (% sold (El))": 43.18,
    "out:PV (kWh self-consumed)": 43303.44,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 306810,
    "out:EL kWh savings": -44479,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245533,
    "out:DH kr savings": 279197,
    "out:El kr savings": -75615,
    "out:El kr sold": 41950,
    "out:El kr saved": 73616,
    "out:El kr return": 115566,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 276003,
    "out:% savings (space heating)": 36.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 71.12,
    "out:Etvv": 0,
    "out:Ef": 28.95,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.79,
    "out:Electricity (inc PV)": 28.86,
    "out:Total Energy Use Pre PV": 118.79,
    "out:Total Energy Use Post PV": 104.86,
    "out:Primary Energy": 98.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.21,
    "out:CO2 Operational/m2": -17.25,
    "out:Total CO2/m2": 10.96,
    "out:Total CO2 (tons)": 37.36,
    "out:Klimatpaverkan": -179.64,
    "out:Initial Cost/MSEK": 4.447125,
    "out:Running cost/(Apt SEK y)": 21982,
    "out:Running Cost over RSP/MSEK": 6.366,
    "out:LCP/MSEK": 1.889,
    "out:ROI% old": 20.35,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 2127.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 811,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32949.69,
    "out:PV (% sold (El))": 43.24,
    "out:PV (kWh self-consumed)": 43256.09,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 323855,
    "out:EL kWh savings": -44176,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261621,
    "out:DH kr savings": 294708,
    "out:El kr savings": -75098,
    "out:El kr sold": 42011,
    "out:El kr saved": 73535,
    "out:El kr return": 115546,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 257970,
    "out:% savings (space heating)": 40.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 65.97,
    "out:Etvv": 0,
    "out:Ef": 28.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.35,
    "out:Electricity (inc PV)": 28.68,
    "out:Total Energy Use Pre PV": 119.35,
    "out:Total Energy Use Post PV": 105.68,
    "out:Primary Energy": 100.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.71,
    "out:CO2 Operational/m2": -17.38,
    "out:Total CO2/m2": 11.33,
    "out:Total CO2 (tons)": 38.63,
    "out:Klimatpaverkan": -178.37,
    "out:Initial Cost/MSEK": 4.566875,
    "out:Running cost/(Apt SEK y)": 22159,
    "out:Running Cost over RSP/MSEK": 6.418,
    "out:LCP/MSEK": 1.718,
    "out:ROI% old": 19.66,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2102.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 804,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33240.62,
    "out:PV (% sold (El))": 43.62,
    "out:PV (kWh self-consumed)": 42965.17,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -43578,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259905,
    "out:DH kr savings": 291606,
    "out:El kr savings": -74083,
    "out:El kr sold": 42382,
    "out:El kr saved": 73041,
    "out:El kr return": 115423,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 263902,
    "out:% savings (space heating)": 39.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 69.21,
    "out:Etvv": 0,
    "out:Ef": 28.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.27,
    "out:Electricity (inc PV)": 28.59,
    "out:Total Energy Use Pre PV": 114.27,
    "out:Total Energy Use Post PV": 100.59,
    "out:Primary Energy": 96.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.71,
    "out:CO2 Operational/m2": -19.27,
    "out:Total CO2/m2": 9.44,
    "out:Total CO2 (tons)": 32.17,
    "out:Klimatpaverkan": -184.83,
    "out:Initial Cost/MSEK": 4.652,
    "out:Running cost/(Apt SEK y)": 20936,
    "out:Running Cost over RSP/MSEK": 6.064,
    "out:LCP/MSEK": 1.987,
    "out:ROI% old": 20.39,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2098.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 850,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 24.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33296.84,
    "out:PV (% sold (El))": 43.69,
    "out:PV (kWh self-consumed)": 42908.95,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -43252,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276042,
    "out:DH kr savings": 307117,
    "out:El kr savings": -73528,
    "out:El kr sold": 42453,
    "out:El kr saved": 72945,
    "out:El kr return": 115399,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 246014,
    "out:% savings (space heating)": 43.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 64.08,
    "out:Etvv": 0,
    "out:Ef": 28.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.95,
    "out:Electricity (inc PV)": 58.47,
    "out:Total Energy Use Pre PV": 88.95,
    "out:Total Energy Use Post PV": 85.47,
    "out:Primary Energy": 118.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.1,
    "out:CO2 Operational/m2": 17.9,
    "out:Total CO2/m2": 22,
    "out:Total CO2 (tons)": 74.98,
    "out:Klimatpaverkan": -142.02,
    "out:Initial Cost/MSEK": 4.70225,
    "out:Running cost/(Apt SEK y)": 16242,
    "out:Running Cost over RSP/MSEK": 4.669,
    "out:LCP/MSEK": 3.331,
    "out:ROI% old": 24.41,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 4336.7,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1023,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 490896,
    "out:EL kWh savings": -145136,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199985,
    "out:DH kr savings": 446715,
    "out:El kr savings": -246731,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 90831,
    "out:% savings (space heating)": 79.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 19.62,
    "out:Etvv": 0,
    "out:Ef": 58.47,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.47,
    "out:Electricity (inc PV)": 57.87,
    "out:Total Energy Use Pre PV": 84.47,
    "out:Total Energy Use Post PV": 80.87,
    "out:Primary Energy": 115.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.1,
    "out:CO2 Operational/m2": 16.39,
    "out:Total CO2/m2": 20.49,
    "out:Total CO2 (tons)": 69.86,
    "out:Klimatpaverkan": -147.14,
    "out:Initial Cost/MSEK": 4.7875,
    "out:Running cost/(Apt SEK y)": 15197,
    "out:Running Cost over RSP/MSEK": 4.367,
    "out:LCP/MSEK": 3.548,
    "out:ROI% old": 24.87,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 4302.7,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1062,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 504532,
    "out:EL kWh savings": -143065,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215914,
    "out:DH kr savings": 459124,
    "out:El kr savings": -243210,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 77469,
    "out:% savings (space heating)": 82.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 16.11,
    "out:Etvv": 0,
    "out:Ef": 57.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.76,
    "out:Electricity (inc PV)": 57.73,
    "out:Total Energy Use Pre PV": 84.76,
    "out:Total Energy Use Post PV": 81.73,
    "out:Primary Energy": 116.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.59,
    "out:CO2 Operational/m2": 16.66,
    "out:Total CO2/m2": 21.25,
    "out:Total CO2 (tons)": 72.46,
    "out:Klimatpaverkan": -144.54,
    "out:Initial Cost/MSEK": 4.90725,
    "out:Running cost/(Apt SEK y)": 15328,
    "out:Running Cost over RSP/MSEK": 4.405,
    "out:LCP/MSEK": 3.39,
    "out:ROI% old": 24.15,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 4253.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1057,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 501123,
    "out:EL kWh savings": -142609,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213586,
    "out:DH kr savings": 456022,
    "out:El kr savings": -242435,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 82031,
    "out:% savings (space heating)": 81.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 18.32,
    "out:Etvv": 0,
    "out:Ef": 57.73,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.23,
    "out:Electricity (inc PV)": 57.1,
    "out:Total Energy Use Pre PV": 80.23,
    "out:Total Energy Use Post PV": 77.1,
    "out:Primary Energy": 113.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.59,
    "out:CO2 Operational/m2": 15.15,
    "out:Total CO2/m2": 19.75,
    "out:Total CO2 (tons)": 67.31,
    "out:Klimatpaverkan": -149.69,
    "out:Initial Cost/MSEK": 4.992375,
    "out:Running cost/(Apt SEK y)": 14277,
    "out:Running Cost over RSP/MSEK": 4.101,
    "out:LCP/MSEK": 3.609,
    "out:ROI% old": 24.61,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 4216.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1096,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 514759,
    "out:EL kWh savings": -140442,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229679,
    "out:DH kr savings": 468431,
    "out:El kr savings": -238752,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 69104,
    "out:% savings (space heating)": 84.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 14.89,
    "out:Etvv": 0,
    "out:Ef": 57.1,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.95,
    "out:Electricity (inc PV)": 47.83,
    "out:Total Energy Use Pre PV": 88.95,
    "out:Total Energy Use Post PV": 74.83,
    "out:Primary Energy": 99.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.45,
    "out:CO2 Operational/m2": 14.8,
    "out:Total CO2/m2": 31.25,
    "out:Total CO2 (tons)": 106.52,
    "out:Klimatpaverkan": -110.48,
    "out:Initial Cost/MSEK": 5.411375,
    "out:Running cost/(Apt SEK y)": 14506,
    "out:Running Cost over RSP/MSEK": 4.18,
    "out:LCP/MSEK": 3.111,
    "out:ROI% old": 22.5,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 3620.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1088,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1267.06,
    "out:PV (% sold (El))": 3.33,
    "out:PV (kWh self-consumed)": 36835.83,
    "out:PV (ratio sold/self-consumed)": 0.03,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 490896,
    "out:EL kWh savings": -108842,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263300,
    "out:DH kr savings": 446715,
    "out:El kr savings": -185031,
    "out:El kr sold": 1616,
    "out:El kr saved": 62621,
    "out:El kr return": 64236,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 90831,
    "out:% savings (space heating)": 79.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 19.62,
    "out:Etvv": 0,
    "out:Ef": 47.83,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.47,
    "out:Electricity (inc PV)": 47.25,
    "out:Total Energy Use Pre PV": 84.47,
    "out:Total Energy Use Post PV": 70.25,
    "out:Primary Energy": 96.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.45,
    "out:CO2 Operational/m2": 13.2,
    "out:Total CO2/m2": 29.65,
    "out:Total CO2 (tons)": 101.07,
    "out:Klimatpaverkan": -115.93,
    "out:Initial Cost/MSEK": 5.496625,
    "out:Running cost/(Apt SEK y)": 13462,
    "out:Running Cost over RSP/MSEK": 3.878,
    "out:LCP/MSEK": 3.327,
    "out:ROI% old": 22.93,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3587.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1126,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1335.95,
    "out:PV (% sold (El))": 3.51,
    "out:PV (kWh self-consumed)": 36766.94,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 504532,
    "out:EL kWh savings": -106876,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279138,
    "out:DH kr savings": 459124,
    "out:El kr savings": -181689,
    "out:El kr sold": 1703,
    "out:El kr saved": 62504,
    "out:El kr return": 64207,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 77469,
    "out:% savings (space heating)": 82.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 16.11,
    "out:Etvv": 0,
    "out:Ef": 47.25,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.76,
    "out:Electricity (inc PV)": 47.12,
    "out:Total Energy Use Pre PV": 84.76,
    "out:Total Energy Use Post PV": 71.12,
    "out:Primary Energy": 97.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.95,
    "out:CO2 Operational/m2": 13.32,
    "out:Total CO2/m2": 30.27,
    "out:Total CO2 (tons)": 103.18,
    "out:Klimatpaverkan": -113.82,
    "out:Initial Cost/MSEK": 5.61625,
    "out:Running cost/(Apt SEK y)": 13594,
    "out:Running Cost over RSP/MSEK": 3.917,
    "out:LCP/MSEK": 3.169,
    "out:ROI% old": 22.35,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 163.4,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 3539.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1121,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1440.4,
    "out:PV (% sold (El))": 3.78,
    "out:PV (kWh self-consumed)": 36662.49,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 501123,
    "out:EL kWh savings": -106444,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276904,
    "out:DH kr savings": 456022,
    "out:El kr savings": -180955,
    "out:El kr sold": 1837,
    "out:El kr saved": 62326,
    "out:El kr return": 64163,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 82031,
    "out:% savings (space heating)": 81.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 18.32,
    "out:Etvv": 0,
    "out:Ef": 47.12,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.23,
    "out:Electricity (inc PV)": 46.52,
    "out:Total Energy Use Pre PV": 80.23,
    "out:Total Energy Use Post PV": 66.52,
    "out:Primary Energy": 94.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.95,
    "out:CO2 Operational/m2": 11.7,
    "out:Total CO2/m2": 28.65,
    "out:Total CO2 (tons)": 97.65,
    "out:Klimatpaverkan": -119.35,
    "out:Initial Cost/MSEK": 5.7015,
    "out:Running cost/(Apt SEK y)": 12544,
    "out:Running Cost over RSP/MSEK": 3.613,
    "out:LCP/MSEK": 3.388,
    "out:ROI% old": 22.77,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3503.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1160,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1521.35,
    "out:PV (% sold (El))": 3.99,
    "out:PV (kWh self-consumed)": 36581.55,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 514759,
    "out:EL kWh savings": -104392,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292904,
    "out:DH kr savings": 468431,
    "out:El kr savings": -177466,
    "out:El kr sold": 1940,
    "out:El kr saved": 62189,
    "out:El kr return": 64128,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 69104,
    "out:% savings (space heating)": 84.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 14.89,
    "out:Etvv": 0,
    "out:Ef": 46.52,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 26.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.95,
    "out:Electricity (inc PV)": 43.02,
    "out:Total Energy Use Pre PV": 88.95,
    "out:Total Energy Use Post PV": 70.02,
    "out:Primary Energy": 91.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.8,
    "out:CO2 Operational/m2": -15.86,
    "out:Total CO2/m2": 12.94,
    "out:Total CO2 (tons)": 44.12,
    "out:Klimatpaverkan": -172.88,
    "out:Initial Cost/MSEK": 6.1205,
    "out:Running cost/(Apt SEK y)": 12990,
    "out:Running Cost over RSP/MSEK": 3.753,
    "out:LCP/MSEK": 2.829,
    "out:ROI% old": 20.89,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 27,
    "out:El bought/kWh/m2": 3249.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 21688.92,
    "out:PV (% sold (El))": 28.46,
    "out:PV (kWh self-consumed)": 54516.87,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 490896,
    "out:EL kWh savings": -92464,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 317180,
    "out:DH kr savings": 446715,
    "out:El kr savings": -157189,
    "out:El kr sold": 27653,
    "out:El kr saved": 92679,
    "out:El kr return": 120332,
    "out:% solar/total": 101,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 90831,
    "out:% savings (space heating)": 79.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 19.62,
    "out:Etvv": 0,
    "out:Ef": 43.02,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 61.47,
    "out:Electricity (inc PV)": 42.51,
    "out:Total Energy Use Pre PV": 84.47,
    "out:Total Energy Use Post PV": 65.51,
    "out:Primary Energy": 87.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.8,
    "out:CO2 Operational/m2": -17.73,
    "out:Total CO2/m2": 11.07,
    "out:Total CO2 (tons)": 37.74,
    "out:Klimatpaverkan": -179.26,
    "out:Initial Cost/MSEK": 6.20575,
    "out:Running cost/(Apt SEK y)": 11948,
    "out:Running Cost over RSP/MSEK": 3.451,
    "out:LCP/MSEK": 3.045,
    "out:ROI% old": 21.3,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3220.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1182,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 43.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 21941,
    "out:PV (% sold (El))": 28.79,
    "out:PV (kWh self-consumed)": 54264.78,
    "out:PV (ratio sold/self-consumed)": 0.4,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 504532,
    "out:EL kWh savings": -90725,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 332866,
    "out:DH kr savings": 459124,
    "out:El kr savings": -154233,
    "out:El kr sold": 27975,
    "out:El kr saved": 92250,
    "out:El kr return": 120225,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 77469,
    "out:% savings (space heating)": 82.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 16.11,
    "out:Etvv": 0,
    "out:Ef": 42.51,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 24.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.76,
    "out:Electricity (inc PV)": 42.4,
    "out:Total Energy Use Pre PV": 84.76,
    "out:Total Energy Use Post PV": 66.4,
    "out:Primary Energy": 89.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.3,
    "out:CO2 Operational/m2": -18.01,
    "out:Total CO2/m2": 11.29,
    "out:Total CO2 (tons)": 38.49,
    "out:Klimatpaverkan": -178.51,
    "out:Initial Cost/MSEK": 6.325375,
    "out:Running cost/(Apt SEK y)": 12083,
    "out:Running Cost over RSP/MSEK": 3.491,
    "out:LCP/MSEK": 2.886,
    "out:ROI% old": 20.8,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 5,
    "out:DH+ DHW/kWh/m2": 24,
    "out:El bought/kWh/m2": 3177.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1177,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22312.28,
    "out:PV (% sold (El))": 29.28,
    "out:PV (kWh self-consumed)": 53893.5,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 501123,
    "out:EL kWh savings": -90343,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330887,
    "out:DH kr savings": 456022,
    "out:El kr savings": -153583,
    "out:El kr sold": 28448,
    "out:El kr saved": 91619,
    "out:El kr return": 120067,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 82031,
    "out:% savings (space heating)": 81.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 18.32,
    "out:Etvv": 0,
    "out:Ef": 42.4,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 20.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.23,
    "out:Electricity (inc PV)": 41.87,
    "out:Total Energy Use Pre PV": 80.23,
    "out:Total Energy Use Post PV": 61.87,
    "out:Primary Energy": 85.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.3,
    "out:CO2 Operational/m2": -19.92,
    "out:Total CO2/m2": 9.38,
    "out:Total CO2 (tons)": 31.96,
    "out:Klimatpaverkan": -185.04,
    "out:Initial Cost/MSEK": 6.410625,
    "out:Running cost/(Apt SEK y)": 11035,
    "out:Running Cost over RSP/MSEK": 3.188,
    "out:LCP/MSEK": 3.104,
    "out:ROI% old": 21.2,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3146.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1216,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 42.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22589.4,
    "out:PV (% sold (El))": 29.64,
    "out:PV (kWh self-consumed)": 53616.39,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 514759,
    "out:EL kWh savings": -88526,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346739,
    "out:DH kr savings": 468431,
    "out:El kr savings": -150493,
    "out:El kr sold": 28801,
    "out:El kr saved": 91148,
    "out:El kr return": 119949,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 69104,
    "out:% savings (space heating)": 84.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 14.89,
    "out:Etvv": 0,
    "out:Ef": 41.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.35,
    "out:Electricity (inc PV)": 57.48,
    "out:Total Energy Use Pre PV": 83.35,
    "out:Total Energy Use Post PV": 80.48,
    "out:Primary Energy": 116,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.1,
    "out:CO2 Operational/m2": 16.25,
    "out:Total CO2/m2": 20.34,
    "out:Total CO2 (tons)": 69.35,
    "out:Klimatpaverkan": -147.65,
    "out:Initial Cost/MSEK": 4.799875,
    "out:Running cost/(Apt SEK y)": 15022,
    "out:Running Cost over RSP/MSEK": 4.317,
    "out:LCP/MSEK": 3.586,
    "out:ROI% old": 24.96,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 4224.7,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1068,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 504532,
    "out:EL kWh savings": -141757,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218138,
    "out:DH kr savings": 459124,
    "out:El kr savings": -240987,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 79180,
    "out:% savings (space heating)": 81.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 17.9,
    "out:Etvv": 0,
    "out:Ef": 57.48,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.81,
    "out:Electricity (inc PV)": 56.84,
    "out:Total Energy Use Pre PV": 78.81,
    "out:Total Energy Use Post PV": 75.84,
    "out:Primary Energy": 112.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.1,
    "out:CO2 Operational/m2": 14.74,
    "out:Total CO2/m2": 18.83,
    "out:Total CO2 (tons)": 64.2,
    "out:Klimatpaverkan": -152.8,
    "out:Initial Cost/MSEK": 4.885125,
    "out:Running cost/(Apt SEK y)": 13969,
    "out:Running Cost over RSP/MSEK": 4.012,
    "out:LCP/MSEK": 3.805,
    "out:ROI% old": 25.41,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 4187,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1107,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 518168,
    "out:EL kWh savings": -139557,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234286,
    "out:DH kr savings": 471533,
    "out:El kr savings": -237247,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 66410,
    "out:% savings (space heating)": 84.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 14.5,
    "out:Etvv": 0,
    "out:Ef": 56.84,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.36,
    "out:Electricity (inc PV)": 56.88,
    "out:Total Energy Use Pre PV": 80.36,
    "out:Total Energy Use Post PV": 77.88,
    "out:Primary Energy": 114.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.59,
    "out:CO2 Operational/m2": 15.4,
    "out:Total CO2/m2": 19.99,
    "out:Total CO2 (tons)": 68.15,
    "out:Klimatpaverkan": -148.85,
    "out:Initial Cost/MSEK": 5.004875,
    "out:Running cost/(Apt SEK y)": 14383,
    "out:Running Cost over RSP/MSEK": 4.132,
    "out:LCP/MSEK": 3.565,
    "out:ROI% old": 24.46,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 4155.4,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1092,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 511350,
    "out:EL kWh savings": -139692,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227853,
    "out:DH kr savings": 465329,
    "out:El kr savings": -237476,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 72523,
    "out:% savings (space heating)": 83.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 16.89,
    "out:Etvv": 0,
    "out:Ef": 56.88,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.79,
    "out:Electricity (inc PV)": 56.21,
    "out:Total Energy Use Pre PV": 76.79,
    "out:Total Energy Use Post PV": 74.21,
    "out:Primary Energy": 110.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.59,
    "out:CO2 Operational/m2": 14.24,
    "out:Total CO2/m2": 18.83,
    "out:Total CO2 (tons)": 64.21,
    "out:Klimatpaverkan": -152.79,
    "out:Initial Cost/MSEK": 5.090125,
    "out:Running cost/(Apt SEK y)": 13567,
    "out:Running Cost over RSP/MSEK": 3.896,
    "out:LCP/MSEK": 3.716,
    "out:ROI% old": 24.72,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 4115.2,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1122,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 521577,
    "out:EL kWh savings": -137409,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241040,
    "out:DH kr savings": 474635,
    "out:El kr savings": -233595,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 60151,
    "out:% savings (space heating)": 86.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 13.58,
    "out:Etvv": 0,
    "out:Ef": 56.21,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.35,
    "out:Electricity (inc PV)": 46.89,
    "out:Total Energy Use Pre PV": 83.35,
    "out:Total Energy Use Post PV": 69.89,
    "out:Primary Energy": 96.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.45,
    "out:CO2 Operational/m2": 12.82,
    "out:Total CO2/m2": 29.27,
    "out:Total CO2 (tons)": 99.78,
    "out:Klimatpaverkan": -117.22,
    "out:Initial Cost/MSEK": 5.509,
    "out:Running cost/(Apt SEK y)": 13289,
    "out:Running Cost over RSP/MSEK": 3.829,
    "out:LCP/MSEK": 3.364,
    "out:ROI% old": 23.01,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3511.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1133,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1502.77,
    "out:PV (% sold (El))": 3.94,
    "out:PV (kWh self-consumed)": 36600.13,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 504532,
    "out:EL kWh savings": -105636,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281458,
    "out:DH kr savings": 459124,
    "out:El kr savings": -179582,
    "out:El kr sold": 1916,
    "out:El kr saved": 62220,
    "out:El kr return": 64136,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 79180,
    "out:% savings (space heating)": 81.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 17.9,
    "out:Etvv": 0,
    "out:Ef": 46.89,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.81,
    "out:Electricity (inc PV)": 46.28,
    "out:Total Energy Use Pre PV": 78.81,
    "out:Total Energy Use Post PV": 65.28,
    "out:Primary Energy": 93.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.45,
    "out:CO2 Operational/m2": 11.19,
    "out:Total CO2/m2": 27.64,
    "out:Total CO2 (tons)": 94.23,
    "out:Klimatpaverkan": -122.77,
    "out:Initial Cost/MSEK": 5.59425,
    "out:Running cost/(Apt SEK y)": 12237,
    "out:Running Cost over RSP/MSEK": 3.524,
    "out:LCP/MSEK": 3.584,
    "out:ROI% old": 23.44,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3475.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1587.71,
    "out:PV (% sold (El))": 4.17,
    "out:PV (kWh self-consumed)": 36515.18,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 518168,
    "out:EL kWh savings": -103555,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297514,
    "out:DH kr savings": 471533,
    "out:El kr savings": -176043,
    "out:El kr sold": 2024,
    "out:El kr saved": 62076,
    "out:El kr return": 64100,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 66410,
    "out:% savings (space heating)": 84.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 14.5,
    "out:Etvv": 0,
    "out:Ef": 46.28,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.36,
    "out:Electricity (inc PV)": 46.31,
    "out:Total Energy Use Pre PV": 80.36,
    "out:Total Energy Use Post PV": 67.31,
    "out:Primary Energy": 95.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.95,
    "out:CO2 Operational/m2": 11.75,
    "out:Total CO2/m2": 28.7,
    "out:Total CO2 (tons)": 97.82,
    "out:Klimatpaverkan": -119.18,
    "out:Initial Cost/MSEK": 5.714,
    "out:Running cost/(Apt SEK y)": 12651,
    "out:Running Cost over RSP/MSEK": 3.645,
    "out:LCP/MSEK": 3.344,
    "out:ROI% old": 22.65,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 3444.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1156,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1660.94,
    "out:PV (% sold (El))": 4.36,
    "out:PV (kWh self-consumed)": 36441.96,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 511350,
    "out:EL kWh savings": -103682,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 291187,
    "out:DH kr savings": 465329,
    "out:El kr savings": -176259,
    "out:El kr sold": 2118,
    "out:El kr saved": 61951,
    "out:El kr return": 64069,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 72523,
    "out:% savings (space heating)": 83.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 16.89,
    "out:Etvv": 0,
    "out:Ef": 46.31,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.79,
    "out:Electricity (inc PV)": 45.68,
    "out:Total Energy Use Pre PV": 76.79,
    "out:Total Energy Use Post PV": 63.68,
    "out:Primary Energy": 91.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.95,
    "out:CO2 Operational/m2": 10.46,
    "out:Total CO2/m2": 27.41,
    "out:Total CO2 (tons)": 93.43,
    "out:Klimatpaverkan": -123.57,
    "out:Initial Cost/MSEK": 5.799125,
    "out:Running cost/(Apt SEK y)": 11836,
    "out:Running Cost over RSP/MSEK": 3.409,
    "out:LCP/MSEK": 3.494,
    "out:ROI% old": 22.89,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3406.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1186,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1756.09,
    "out:PV (% sold (El))": 4.61,
    "out:PV (kWh self-consumed)": 36346.8,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 521577,
    "out:EL kWh savings": -101526,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304280,
    "out:DH kr savings": 474635,
    "out:El kr savings": -172594,
    "out:El kr sold": 2239,
    "out:El kr saved": 61790,
    "out:El kr return": 64029,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 60151,
    "out:% savings (space heating)": 86.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 13.58,
    "out:Etvv": 0,
    "out:Ef": 45.68,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 23.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.35,
    "out:Electricity (inc PV)": 42.19,
    "out:Total Energy Use Pre PV": 83.35,
    "out:Total Energy Use Post PV": 65.19,
    "out:Primary Energy": 88.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.8,
    "out:CO2 Operational/m2": -18.74,
    "out:Total CO2/m2": 10.07,
    "out:Total CO2 (tons)": 34.32,
    "out:Klimatpaverkan": -182.68,
    "out:Initial Cost/MSEK": 6.218125,
    "out:Running cost/(Apt SEK y)": 11780,
    "out:Running Cost over RSP/MSEK": 3.403,
    "out:LCP/MSEK": 3.081,
    "out:ROI% old": 21.36,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 23,
    "out:El bought/kWh/m2": 3153.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1188,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 42.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22526.36,
    "out:PV (% sold (El))": 29.56,
    "out:PV (kWh self-consumed)": 53679.42,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 504532,
    "out:EL kWh savings": -89628,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 335478,
    "out:DH kr savings": 459124,
    "out:El kr savings": -152367,
    "out:El kr sold": 28721,
    "out:El kr saved": 91255,
    "out:El kr return": 119976,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 79180,
    "out:% savings (space heating)": 81.79,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 17.9,
    "out:Etvv": 0,
    "out:Ef": 42.19,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.81,
    "out:Electricity (inc PV)": 41.65,
    "out:Total Energy Use Pre PV": 78.81,
    "out:Total Energy Use Post PV": 60.65,
    "out:Primary Energy": 85.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.8,
    "out:CO2 Operational/m2": -20.67,
    "out:Total CO2/m2": 8.14,
    "out:Total CO2 (tons)": 27.74,
    "out:Klimatpaverkan": -189.26,
    "out:Initial Cost/MSEK": 6.303375,
    "out:Running cost/(Apt SEK y)": 10730,
    "out:Running Cost over RSP/MSEK": 3.1,
    "out:LCP/MSEK": 3.299,
    "out:ROI% old": 21.76,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3121.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1227,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 41.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22811.85,
    "out:PV (% sold (El))": 29.93,
    "out:PV (kWh self-consumed)": 53393.93,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 518168,
    "out:EL kWh savings": -87784,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351386,
    "out:DH kr savings": 471533,
    "out:El kr savings": -149232,
    "out:El kr sold": 29085,
    "out:El kr saved": 90770,
    "out:El kr return": 119855,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 66410,
    "out:% savings (space heating)": 84.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 14.5,
    "out:Etvv": 0,
    "out:Ef": 41.65,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 21.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.36,
    "out:Electricity (inc PV)": 41.68,
    "out:Total Energy Use Pre PV": 80.36,
    "out:Total Energy Use Post PV": 62.68,
    "out:Primary Energy": 86.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.3,
    "out:CO2 Operational/m2": -20.36,
    "out:Total CO2/m2": 8.94,
    "out:Total CO2 (tons)": 30.49,
    "out:Klimatpaverkan": -186.51,
    "out:Initial Cost/MSEK": 6.423125,
    "out:Running cost/(Apt SEK y)": 11147,
    "out:Running Cost over RSP/MSEK": 3.22,
    "out:LCP/MSEK": 3.059,
    "out:ROI% old": 21.09,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 21,
    "out:El bought/kWh/m2": 3094.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1212,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 41.16,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23053.22,
    "out:PV (% sold (El))": 30.25,
    "out:PV (kWh self-consumed)": 53152.57,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 511350,
    "out:EL kWh savings": -87897,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 345297,
    "out:DH kr savings": 465329,
    "out:El kr savings": -149424,
    "out:El kr sold": 29393,
    "out:El kr saved": 90359,
    "out:El kr return": 119752,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 72523,
    "out:% savings (space heating)": 83.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 16.89,
    "out:Etvv": 0,
    "out:Ef": 41.68,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.79,
    "out:Electricity (inc PV)": 41.12,
    "out:Total Energy Use Pre PV": 76.79,
    "out:Total Energy Use Post PV": 59.12,
    "out:Primary Energy": 83.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.3,
    "out:CO2 Operational/m2": -21.97,
    "out:Total CO2/m2": 7.33,
    "out:Total CO2 (tons)": 25,
    "out:Klimatpaverkan": -192,
    "out:Initial Cost/MSEK": 6.50825,
    "out:Running cost/(Apt SEK y)": 10334,
    "out:Running Cost over RSP/MSEK": 2.985,
    "out:LCP/MSEK": 3.209,
    "out:ROI% old": 21.33,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 18,
    "out:El bought/kWh/m2": 3060,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1242,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 40.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23363.83,
    "out:PV (% sold (El))": 30.66,
    "out:PV (kWh self-consumed)": 52841.95,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 521577,
    "out:EL kWh savings": -85986,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 358248,
    "out:DH kr savings": 474635,
    "out:El kr savings": -146176,
    "out:El kr sold": 29789,
    "out:El kr saved": 89831,
    "out:El kr return": 119620,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 60151,
    "out:% savings (space heating)": 86.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 13.58,
    "out:Etvv": 0,
    "out:Ef": 41.12,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 175.9,
    "out:Total Energy Use Post PV": 175.9,
    "out:Primary Energy": 127.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.18,
    "out:CO2 Operational/m2": 59.7,
    "out:Total CO2/m2": 61.88,
    "out:Total CO2 (tons)": 210.94,
    "out:Klimatpaverkan": -6.06,
    "out:Initial Cost/MSEK": 0.543603,
    "out:Running cost/(Apt SEK y)": 41234,
    "out:Running Cost over RSP/MSEK": 11.931,
    "out:LCP/MSEK": 0.228,
    "out:ROI% old": 20.27,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 6.2,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 18,
    "out:Return/kSEK/y": 99,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 37499,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 34124,
    "out:DH kr savings": 34124,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 170.9,
    "out:Primary Energy": 124.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.18,
    "out:CO2 Operational/m2": 57.9,
    "out:Total CO2/m2": 60.08,
    "out:Total CO2 (tons)": 204.8,
    "out:Klimatpaverkan": -12.2,
    "out:Initial Cost/MSEK": 0.628828,
    "out:Running cost/(Apt SEK y)": 40024,
    "out:Running Cost over RSP/MSEK": 11.58,
    "out:LCP/MSEK": 0.493,
    "out:ROI% old": 25.49,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 9.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 23,
    "out:Return/kSEK/y": 143,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 54544,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 49635,
    "out:DH kr savings": 49635,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 169.9,
    "out:Primary Energy": 125.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.68,
    "out:CO2 Operational/m2": 57.54,
    "out:Total CO2/m2": 60.21,
    "out:Total CO2 (tons)": 205.27,
    "out:Klimatpaverkan": -11.73,
    "out:Initial Cost/MSEK": 0.748515,
    "out:Running cost/(Apt SEK y)": 39781,
    "out:Running Cost over RSP/MSEK": 11.51,
    "out:LCP/MSEK": 0.443,
    "out:ROI% old": 22.75,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 20,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 57953,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52737,
    "out:DH kr savings": 52737,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 164.9,
    "out:Primary Energy": 121.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.68,
    "out:CO2 Operational/m2": 55.74,
    "out:Total CO2/m2": 58.41,
    "out:Total CO2 (tons)": 199.13,
    "out:Klimatpaverkan": -17.87,
    "out:Initial Cost/MSEK": 0.83374,
    "out:Running cost/(Apt SEK y)": 38571,
    "out:Running Cost over RSP/MSEK": 11.16,
    "out:LCP/MSEK": 0.709,
    "out:ROI% old": 26.43,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 24,
    "out:Return/kSEK/y": 197,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74998,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 68248,
    "out:DH kr savings": 68248,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 175.9,
    "out:Total Energy Use Post PV": 170.73,
    "out:Primary Energy": 118.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.53,
    "out:CO2 Operational/m2": 28.7,
    "out:Total CO2/m2": 43.23,
    "out:Total CO2 (tons)": 147.39,
    "out:Klimatpaverkan": -69.61,
    "out:Initial Cost/MSEK": 1.25275,
    "out:Running cost/(Apt SEK y)": 39719,
    "out:Running Cost over RSP/MSEK": 11.504,
    "out:LCP/MSEK": -0.055,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 9.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 155,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 37499,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90198,
    "out:DH kr savings": 34124,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 165.73,
    "out:Primary Energy": 114.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.53,
    "out:CO2 Operational/m2": 26.9,
    "out:Total CO2/m2": 41.44,
    "out:Total CO2 (tons)": 141.25,
    "out:Klimatpaverkan": -75.75,
    "out:Initial Cost/MSEK": 1.337875,
    "out:Running cost/(Apt SEK y)": 38508,
    "out:Running Cost over RSP/MSEK": 11.154,
    "out:LCP/MSEK": 0.211,
    "out:ROI% old": 16.54,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 12.7,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 199,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 54544,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105709,
    "out:DH kr savings": 49635,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 164.73,
    "out:Primary Energy": 115.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.03,
    "out:CO2 Operational/m2": 26.54,
    "out:Total CO2/m2": 41.57,
    "out:Total CO2 (tons)": 141.72,
    "out:Klimatpaverkan": -75.28,
    "out:Initial Cost/MSEK": 1.457625,
    "out:Running cost/(Apt SEK y)": 38266,
    "out:Running Cost over RSP/MSEK": 11.083,
    "out:LCP/MSEK": 0.161,
    "out:ROI% old": 15.86,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 208,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 57953,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108811,
    "out:DH kr savings": 52737,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 159.73,
    "out:Primary Energy": 112.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.03,
    "out:CO2 Operational/m2": 24.74,
    "out:Total CO2/m2": 39.77,
    "out:Total CO2 (tons)": 135.58,
    "out:Klimatpaverkan": -81.42,
    "out:Initial Cost/MSEK": 1.542875,
    "out:Running cost/(Apt SEK y)": 37055,
    "out:Running Cost over RSP/MSEK": 10.733,
    "out:LCP/MSEK": 0.426,
    "out:ROI% old": 18.23,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74998,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124322,
    "out:DH kr savings": 68248,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 175.9,
    "out:Total Energy Use Post PV": 169.88,
    "out:Primary Energy": 116.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.89,
    "out:CO2 Operational/m2": -23.98,
    "out:Total CO2/m2": 2.91,
    "out:Total CO2 (tons)": 9.92,
    "out:Klimatpaverkan": -207.08,
    "out:Initial Cost/MSEK": 1.961875,
    "out:Running cost/(Apt SEK y)": 38373,
    "out:Running Cost over RSP/MSEK": 11.125,
    "out:LCP/MSEK": -0.385,
    "out:ROI% old": 11.48,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 10,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 204,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 37499,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140009,
    "out:DH kr savings": 34124,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 170.9,
    "out:Total Energy Use Post PV": 164.88,
    "out:Primary Energy": 113.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.89,
    "out:CO2 Operational/m2": -25.78,
    "out:Total CO2/m2": 1.11,
    "out:Total CO2 (tons)": 3.78,
    "out:Klimatpaverkan": -213.22,
    "out:Initial Cost/MSEK": 2.047125,
    "out:Running cost/(Apt SEK y)": 37162,
    "out:Running Cost over RSP/MSEK": 10.775,
    "out:LCP/MSEK": -0.12,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 13.3,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 54544,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155520,
    "out:DH kr savings": 49635,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 169.9,
    "out:Total Energy Use Post PV": 163.88,
    "out:Primary Energy": 114.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.38,
    "out:CO2 Operational/m2": -26.14,
    "out:Total CO2/m2": 1.24,
    "out:Total CO2 (tons)": 4.24,
    "out:Klimatpaverkan": -212.76,
    "out:Initial Cost/MSEK": 2.16675,
    "out:Running cost/(Apt SEK y)": 36920,
    "out:Running Cost over RSP/MSEK": 10.705,
    "out:LCP/MSEK": -0.169,
    "out:ROI% old": 13.17,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 258,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 57953,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158622,
    "out:DH kr savings": 52737,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 164.9,
    "out:Total Energy Use Post PV": 158.88,
    "out:Primary Energy": 110.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.38,
    "out:CO2 Operational/m2": -27.94,
    "out:Total CO2/m2": -0.55,
    "out:Total CO2 (tons)": -1.89,
    "out:Klimatpaverkan": -218.89,
    "out:Initial Cost/MSEK": 2.252,
    "out:Running cost/(Apt SEK y)": 35709,
    "out:Running Cost over RSP/MSEK": 10.354,
    "out:LCP/MSEK": 0.096,
    "out:ROI% old": 14.9,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74998,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174133,
    "out:DH kr savings": 68248,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 166.9,
    "out:Primary Energy": 124.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.18,
    "out:CO2 Operational/m2": 56.46,
    "out:Total CO2/m2": 58.64,
    "out:Total CO2 (tons)": 199.89,
    "out:Klimatpaverkan": -17.11,
    "out:Initial Cost/MSEK": 0.641257,
    "out:Running cost/(Apt SEK y)": 39055,
    "out:Running Cost over RSP/MSEK": 11.3,
    "out:LCP/MSEK": 0.761,
    "out:ROI% old": 31.24,
    "out:Payback discounted": 4,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 28,
    "out:Return/kSEK/y": 179,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 68180,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62044,
    "out:DH kr savings": 62044,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 120.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.18,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 56.84,
    "out:Total CO2 (tons)": 193.76,
    "out:Klimatpaverkan": -23.24,
    "out:Initial Cost/MSEK": 0.726482,
    "out:Running cost/(Apt SEK y)": 37844,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": 1.026,
    "out:ROI% old": 34.47,
    "out:Payback discounted": 4,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 31,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77555,
    "out:DH kr savings": 77555,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 122.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.68,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 57.33,
    "out:Total CO2 (tons)": 195.45,
    "out:Klimatpaverkan": -21.55,
    "out:Initial Cost/MSEK": 0.846169,
    "out:Running cost/(Apt SEK y)": 37844,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": 0.907,
    "out:ROI% old": 29.59,
    "out:Payback discounted": 4,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 26,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77555,
    "out:DH kr savings": 77555,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 118.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.68,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 189.31,
    "out:Klimatpaverkan": -27.69,
    "out:Initial Cost/MSEK": 0.931394,
    "out:Running cost/(Apt SEK y)": 36634,
    "out:Running Cost over RSP/MSEK": 10.599,
    "out:LCP/MSEK": 1.172,
    "out:ROI% old": 32.26,
    "out:Payback discounted": 4,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 29,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93066,
    "out:DH kr savings": 93066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 161.73,
    "out:Primary Energy": 115,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.53,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 40,
    "out:Total CO2 (tons)": 136.34,
    "out:Klimatpaverkan": -80.66,
    "out:Initial Cost/MSEK": 1.350375,
    "out:Running cost/(Apt SEK y)": 37539,
    "out:Running Cost over RSP/MSEK": 10.873,
    "out:LCP/MSEK": 0.479,
    "out:ROI% old": 19.35,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 68180,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118118,
    "out:DH kr savings": 62044,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 111.48,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.53,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 38.2,
    "out:Total CO2 (tons)": 130.21,
    "out:Klimatpaverkan": -86.79,
    "out:Initial Cost/MSEK": 1.435625,
    "out:Running cost/(Apt SEK y)": 36329,
    "out:Running Cost over RSP/MSEK": 10.523,
    "out:LCP/MSEK": 0.744,
    "out:ROI% old": 21.69,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 280,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133629,
    "out:DH kr savings": 77555,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 112.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.03,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 38.69,
    "out:Total CO2 (tons)": 131.9,
    "out:Klimatpaverkan": -85.1,
    "out:Initial Cost/MSEK": 1.55525,
    "out:Running cost/(Apt SEK y)": 36329,
    "out:Running Cost over RSP/MSEK": 10.523,
    "out:LCP/MSEK": 0.624,
    "out:ROI% old": 20.02,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 280,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133629,
    "out:DH kr savings": 77555,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 109.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.03,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 36.89,
    "out:Total CO2 (tons)": 125.76,
    "out:Klimatpaverkan": -91.24,
    "out:Initial Cost/MSEK": 1.6405,
    "out:Running cost/(Apt SEK y)": 35118,
    "out:Running Cost over RSP/MSEK": 10.172,
    "out:LCP/MSEK": 0.89,
    "out:ROI% old": 22.03,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 20,
    "out:Return/kSEK/y": 325,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149140,
    "out:DH kr savings": 93066,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 160.88,
    "out:Primary Energy": 113.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.89,
    "out:CO2 Operational/m2": -27.22,
    "out:Total CO2/m2": -0.33,
    "out:Total CO2 (tons)": -1.13,
    "out:Klimatpaverkan": -218.13,
    "out:Initial Cost/MSEK": 2.0595,
    "out:Running cost/(Apt SEK y)": 36193,
    "out:Running Cost over RSP/MSEK": 10.494,
    "out:LCP/MSEK": 0.148,
    "out:ROI% old": 15.31,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 68180,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167929,
    "out:DH kr savings": 62044,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 109.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.89,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": -2.13,
    "out:Total CO2 (tons)": -7.27,
    "out:Klimatpaverkan": -224.27,
    "out:Initial Cost/MSEK": 2.14475,
    "out:Running cost/(Apt SEK y)": 34982,
    "out:Running Cost over RSP/MSEK": 10.144,
    "out:LCP/MSEK": 0.414,
    "out:ROI% old": 17.04,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 330,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183440,
    "out:DH kr savings": 77555,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.7,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 111.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.38,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": -1.63,
    "out:Total CO2 (tons)": -5.57,
    "out:Klimatpaverkan": -222.57,
    "out:Initial Cost/MSEK": 2.264375,
    "out:Running cost/(Apt SEK y)": 34982,
    "out:Running Cost over RSP/MSEK": 10.144,
    "out:LCP/MSEK": 0.294,
    "out:ROI% old": 16.14,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 330,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183440,
    "out:DH kr savings": 77555,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 397830,
    "out:% savings (space heating)": 8.49,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 116.7,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 107.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.38,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": -3.43,
    "out:Total CO2 (tons)": -11.71,
    "out:Klimatpaverkan": -228.71,
    "out:Initial Cost/MSEK": 2.349625,
    "out:Running cost/(Apt SEK y)": 33772,
    "out:Running Cost over RSP/MSEK": 9.793,
    "out:LCP/MSEK": 0.559,
    "out:ROI% old": 17.69,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198951,
    "out:DH kr savings": 93066,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 380676,
    "out:% savings (space heating)": 12.44,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 111.67,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.49,
    "out:Electricity (inc PV)": 41.6,
    "out:Total Energy Use Pre PV": 120.49,
    "out:Total Energy Use Post PV": 118.6,
    "out:Primary Energy": 120.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.69,
    "out:CO2 Operational/m2": 33.46,
    "out:Total CO2/m2": 39.15,
    "out:Total CO2 (tons)": 133.45,
    "out:Klimatpaverkan": -83.55,
    "out:Initial Cost/MSEK": 3.389625,
    "out:Running cost/(Apt SEK y)": 25457,
    "out:Running Cost over RSP/MSEK": 7.347,
    "out:LCP/MSEK": 1.965,
    "out:ROI% old": 22.57,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 3044.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -87608,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142672,
    "out:DH kr savings": 291606,
    "out:El kr savings": -148933,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 261606,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 64.48,
    "out:Etvv": 0,
    "out:Ef": 41.6,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.43,
    "out:Electricity (inc PV)": 41.49,
    "out:Total Energy Use Pre PV": 115.43,
    "out:Total Energy Use Post PV": 113.49,
    "out:Primary Energy": 116.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.69,
    "out:CO2 Operational/m2": 31.65,
    "out:Total CO2/m2": 37.34,
    "out:Total CO2 (tons)": 127.28,
    "out:Klimatpaverkan": -89.72,
    "out:Initial Cost/MSEK": 3.474875,
    "out:Running cost/(Apt SEK y)": 24237,
    "out:Running Cost over RSP/MSEK": 6.994,
    "out:LCP/MSEK": 2.233,
    "out:ROI% old": 23.47,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 3040.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 727,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -87252,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158789,
    "out:DH kr savings": 307117,
    "out:El kr savings": -148328,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 245128,
    "out:% savings (space heating)": 43.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.79,
    "out:Etvv": 0,
    "out:Ef": 41.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.86,
    "out:Electricity (inc PV)": 41.18,
    "out:Total Energy Use Pre PV": 114.86,
    "out:Total Energy Use Post PV": 113.18,
    "out:Primary Energy": 117.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.18,
    "out:CO2 Operational/m2": 31.58,
    "out:Total CO2/m2": 37.76,
    "out:Total CO2 (tons)": 128.72,
    "out:Klimatpaverkan": -88.28,
    "out:Initial Cost/MSEK": 3.5945,
    "out:Running cost/(Apt SEK y)": 24148,
    "out:Running Cost over RSP/MSEK": 6.969,
    "out:LCP/MSEK": 2.139,
    "out:ROI% old": 22.79,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 3000.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -86162,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160641,
    "out:DH kr savings": 307117,
    "out:El kr savings": -146476,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 246444,
    "out:% savings (space heating)": 43.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 62.13,
    "out:Etvv": 0,
    "out:Ef": 41.18,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.79,
    "out:Electricity (inc PV)": 41.06,
    "out:Total Energy Use Pre PV": 110.79,
    "out:Total Energy Use Post PV": 109.06,
    "out:Primary Energy": 114.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.18,
    "out:CO2 Operational/m2": 30.13,
    "out:Total CO2/m2": 36.31,
    "out:Total CO2 (tons)": 123.78,
    "out:Klimatpaverkan": -93.22,
    "out:Initial Cost/MSEK": 3.67975,
    "out:Running cost/(Apt SEK y)": 23168,
    "out:Running Cost over RSP/MSEK": 6.685,
    "out:LCP/MSEK": 2.338,
    "out:ROI% old": 23.36,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2995.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 767,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 351127,
    "out:EL kWh savings": -85771,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173716,
    "out:DH kr savings": 319526,
    "out:El kr savings": -145810,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 230110,
    "out:% savings (space heating)": 47.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 57.48,
    "out:Etvv": 0,
    "out:Ef": 41.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.49,
    "out:Electricity (inc PV)": 32.24,
    "out:Total Energy Use Pre PV": 120.49,
    "out:Total Energy Use Post PV": 109.24,
    "out:Primary Energy": 103.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.04,
    "out:CO2 Operational/m2": 24.27,
    "out:Total CO2/m2": 42.31,
    "out:Total CO2 (tons)": 144.24,
    "out:Klimatpaverkan": -72.76,
    "out:Initial Cost/MSEK": 4.09875,
    "out:Running cost/(Apt SEK y)": 23770,
    "out:Running Cost over RSP/MSEK": 6.872,
    "out:LCP/MSEK": 1.731,
    "out:ROI% old": 20.32,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2401.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 745,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5545.89,
    "out:PV (% sold (El))": 14.56,
    "out:PV (kWh self-consumed)": 32557,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -55714,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203964,
    "out:DH kr savings": 291606,
    "out:El kr savings": -94713,
    "out:El kr sold": 7071,
    "out:El kr saved": 55347,
    "out:El kr return": 62418,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 261606,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 64.48,
    "out:Etvv": 0,
    "out:Ef": 32.24,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.43,
    "out:Electricity (inc PV)": 32.15,
    "out:Total Energy Use Pre PV": 115.43,
    "out:Total Energy Use Post PV": 104.15,
    "out:Primary Energy": 99.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.04,
    "out:CO2 Operational/m2": 22.44,
    "out:Total CO2/m2": 40.47,
    "out:Total CO2 (tons)": 137.98,
    "out:Klimatpaverkan": -79.02,
    "out:Initial Cost/MSEK": 4.184,
    "out:Running cost/(Apt SEK y)": 22551,
    "out:Running Cost over RSP/MSEK": 6.519,
    "out:LCP/MSEK": 1.999,
    "out:ROI% old": 21.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2397.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 790,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5566.35,
    "out:PV (% sold (El))": 14.61,
    "out:PV (kWh self-consumed)": 32536.54,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -55395,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220042,
    "out:DH kr savings": 307117,
    "out:El kr savings": -94172,
    "out:El kr sold": 7097,
    "out:El kr saved": 55312,
    "out:El kr return": 62409,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 245128,
    "out:% savings (space heating)": 43.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.79,
    "out:Etvv": 0,
    "out:Ef": 32.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.86,
    "out:Electricity (inc PV)": 31.86,
    "out:Total Energy Use Pre PV": 114.86,
    "out:Total Energy Use Post PV": 103.86,
    "out:Primary Energy": 100.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.54,
    "out:CO2 Operational/m2": 22.08,
    "out:Total CO2/m2": 40.61,
    "out:Total CO2 (tons)": 138.45,
    "out:Klimatpaverkan": -78.55,
    "out:Initial Cost/MSEK": 4.303625,
    "out:Running cost/(Apt SEK y)": 22464,
    "out:Running Cost over RSP/MSEK": 6.494,
    "out:LCP/MSEK": 1.904,
    "out:ROI% old": 20.61,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2361,
    "out:Return %": 18,
    "out:Return/kSEK/y": 793,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5762.27,
    "out:PV (% sold (El))": 15.12,
    "out:PV (kWh self-consumed)": 32340.62,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -54423,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221945,
    "out:DH kr savings": 307117,
    "out:El kr savings": -92519,
    "out:El kr sold": 7347,
    "out:El kr saved": 54979,
    "out:El kr return": 62326,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 246444,
    "out:% savings (space heating)": 43.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 62.13,
    "out:Etvv": 0,
    "out:Ef": 31.86,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.79,
    "out:Electricity (inc PV)": 31.76,
    "out:Total Energy Use Pre PV": 110.79,
    "out:Total Energy Use Post PV": 99.76,
    "out:Primary Energy": 97.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.54,
    "out:CO2 Operational/m2": 20.59,
    "out:Total CO2/m2": 39.13,
    "out:Total CO2 (tons)": 133.38,
    "out:Klimatpaverkan": -83.62,
    "out:Initial Cost/MSEK": 4.388875,
    "out:Running cost/(Apt SEK y)": 21484,
    "out:Running Cost over RSP/MSEK": 6.211,
    "out:LCP/MSEK": 2.103,
    "out:ROI% old": 21.13,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2356.4,
    "out:Return %": 19,
    "out:Return/kSEK/y": 829,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5787.54,
    "out:PV (% sold (El))": 15.19,
    "out:PV (kWh self-consumed)": 32315.35,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 351127,
    "out:EL kWh savings": -54073,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234981,
    "out:DH kr savings": 319526,
    "out:El kr savings": -91924,
    "out:El kr sold": 7379,
    "out:El kr saved": 54936,
    "out:El kr return": 62315,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 230110,
    "out:% savings (space heating)": 47.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 57.48,
    "out:Etvv": 0,
    "out:Ef": 31.76,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.49,
    "out:Electricity (inc PV)": 29.14,
    "out:Total Energy Use Pre PV": 120.49,
    "out:Total Energy Use Post PV": 106.14,
    "out:Primary Energy": 97.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.39,
    "out:CO2 Operational/m2": -16.11,
    "out:Total CO2/m2": 14.28,
    "out:Total CO2 (tons)": 48.67,
    "out:Klimatpaverkan": -168.33,
    "out:Initial Cost/MSEK": 4.807875,
    "out:Running cost/(Apt SEK y)": 22329,
    "out:Running Cost over RSP/MSEK": 6.466,
    "out:LCP/MSEK": 1.428,
    "out:ROI% old": 18.53,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 2167.7,
    "out:Return %": 17,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32482.26,
    "out:PV (% sold (El))": 42.62,
    "out:PV (kWh self-consumed)": 43723.52,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 320446,
    "out:EL kWh savings": -45149,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256268,
    "out:DH kr savings": 291606,
    "out:El kr savings": -76753,
    "out:El kr sold": 41415,
    "out:El kr saved": 74330,
    "out:El kr return": 115745,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 261606,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 64.48,
    "out:Etvv": 0,
    "out:Ef": 29.14,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.43,
    "out:Electricity (inc PV)": 29.06,
    "out:Total Energy Use Pre PV": 115.43,
    "out:Total Energy Use Post PV": 101.06,
    "out:Primary Energy": 94.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.39,
    "out:CO2 Operational/m2": -17.98,
    "out:Total CO2/m2": 12.41,
    "out:Total CO2 (tons)": 42.31,
    "out:Klimatpaverkan": -174.69,
    "out:Initial Cost/MSEK": 4.893125,
    "out:Running cost/(Apt SEK y)": 21109,
    "out:Running Cost over RSP/MSEK": 6.113,
    "out:LCP/MSEK": 1.696,
    "out:ROI% old": 19.24,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2164.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32521.85,
    "out:PV (% sold (El))": 42.68,
    "out:PV (kWh self-consumed)": 43683.94,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -44863,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272314,
    "out:DH kr savings": 307117,
    "out:El kr savings": -76268,
    "out:El kr sold": 41465,
    "out:El kr saved": 74263,
    "out:El kr return": 115728,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 245128,
    "out:% savings (space heating)": 43.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.79,
    "out:Etvv": 0,
    "out:Ef": 29.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.86,
    "out:Electricity (inc PV)": 28.8,
    "out:Total Energy Use Pre PV": 114.86,
    "out:Total Energy Use Post PV": 100.8,
    "out:Primary Energy": 95.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.89,
    "out:CO2 Operational/m2": -18.61,
    "out:Total CO2/m2": 12.28,
    "out:Total CO2 (tons)": 41.86,
    "out:Klimatpaverkan": -175.14,
    "out:Initial Cost/MSEK": 5.01275,
    "out:Running cost/(Apt SEK y)": 21025,
    "out:Running Cost over RSP/MSEK": 6.089,
    "out:LCP/MSEK": 1.601,
    "out:ROI% old": 18.85,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 2131.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.66,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32899.55,
    "out:PV (% sold (El))": 43.17,
    "out:PV (kWh self-consumed)": 43306.24,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 337491,
    "out:EL kWh savings": -43991,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274279,
    "out:DH kr savings": 307117,
    "out:El kr savings": -74785,
    "out:El kr sold": 41947,
    "out:El kr saved": 73621,
    "out:El kr return": 115568,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 246444,
    "out:% savings (space heating)": 43.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 62.13,
    "out:Etvv": 0,
    "out:Ef": 28.8,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.79,
    "out:Electricity (inc PV)": 28.71,
    "out:Total Energy Use Pre PV": 110.79,
    "out:Total Energy Use Post PV": 96.71,
    "out:Primary Energy": 91.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.89,
    "out:CO2 Operational/m2": -20.13,
    "out:Total CO2/m2": 10.76,
    "out:Total CO2 (tons)": 36.68,
    "out:Klimatpaverkan": -180.32,
    "out:Initial Cost/MSEK": 5.098,
    "out:Running cost/(Apt SEK y)": 20045,
    "out:Running Cost over RSP/MSEK": 5.805,
    "out:LCP/MSEK": 1.799,
    "out:ROI% old": 19.33,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2127.7,
    "out:Return %": 17,
    "out:Return/kSEK/y": 883,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.59,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32948.07,
    "out:PV (% sold (El))": 43.24,
    "out:PV (kWh self-consumed)": 43257.72,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 351127,
    "out:EL kWh savings": -43677,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 287283,
    "out:DH kr savings": 319526,
    "out:El kr savings": -74251,
    "out:El kr sold": 42009,
    "out:El kr saved": 73538,
    "out:El kr return": 115547,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 230110,
    "out:% savings (space heating)": 47.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 57.48,
    "out:Etvv": 0,
    "out:Ef": 28.71,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.64,
    "out:Electricity (inc PV)": 41.03,
    "out:Total Energy Use Pre PV": 113.64,
    "out:Total Energy Use Post PV": 112.03,
    "out:Primary Energy": 116.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.69,
    "out:CO2 Operational/m2": 31.19,
    "out:Total CO2/m2": 36.87,
    "out:Total CO2 (tons)": 125.7,
    "out:Klimatpaverkan": -91.3,
    "out:Initial Cost/MSEK": 3.48725,
    "out:Running cost/(Apt SEK y)": 23872,
    "out:Running Cost over RSP/MSEK": 6.889,
    "out:LCP/MSEK": 2.326,
    "out:ROI% old": 23.82,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2985,
    "out:Return %": 21,
    "out:Return/kSEK/y": 741,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 340900,
    "out:EL kWh savings": -85671,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164579,
    "out:DH kr savings": 310219,
    "out:El kr savings": -145640,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 241484,
    "out:% savings (space heating)": 44.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 61.36,
    "out:Etvv": 0,
    "out:Ef": 41.03,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 40.91,
    "out:Total Energy Use Pre PV": 108.57,
    "out:Total Energy Use Post PV": 106.91,
    "out:Primary Energy": 113.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 5.69,
    "out:CO2 Operational/m2": 29.38,
    "out:Total CO2/m2": 35.06,
    "out:Total CO2 (tons)": 119.53,
    "out:Klimatpaverkan": -97.47,
    "out:Initial Cost/MSEK": 3.5725,
    "out:Running cost/(Apt SEK y)": 22649,
    "out:Running Cost over RSP/MSEK": 6.535,
    "out:LCP/MSEK": 2.595,
    "out:ROI% old": 24.66,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2979.6,
    "out:Return %": 22,
    "out:Return/kSEK/y": 786,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 357945,
    "out:EL kWh savings": -85267,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180776,
    "out:DH kr savings": 325730,
    "out:El kr savings": -144954,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 225202,
    "out:% savings (space heating)": 48.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.72,
    "out:Etvv": 0,
    "out:Ef": 40.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.1,
    "out:Electricity (inc PV)": 40.68,
    "out:Total Energy Use Pre PV": 109.1,
    "out:Total Energy Use Post PV": 107.68,
    "out:Primary Energy": 114.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.18,
    "out:CO2 Operational/m2": 29.68,
    "out:Total CO2/m2": 35.86,
    "out:Total CO2 (tons)": 122.24,
    "out:Klimatpaverkan": -94.76,
    "out:Initial Cost/MSEK": 3.69225,
    "out:Running cost/(Apt SEK y)": 22818,
    "out:Running Cost over RSP/MSEK": 6.584,
    "out:LCP/MSEK": 2.426,
    "out:ROI% old": 23.67,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2947,
    "out:Return %": 21,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 354536,
    "out:EL kWh savings": -84471,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179027,
    "out:DH kr savings": 322628,
    "out:El kr savings": -143600,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 229797,
    "out:% savings (space heating)": 47.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.52,
    "out:Etvv": 0,
    "out:Ef": 40.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.01,
    "out:Electricity (inc PV)": 40.55,
    "out:Total Energy Use Pre PV": 105.01,
    "out:Total Energy Use Post PV": 103.55,
    "out:Primary Energy": 111.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.18,
    "out:CO2 Operational/m2": 28.22,
    "out:Total CO2/m2": 34.41,
    "out:Total CO2 (tons)": 117.29,
    "out:Klimatpaverkan": -99.71,
    "out:Initial Cost/MSEK": 3.777375,
    "out:Running cost/(Apt SEK y)": 21835,
    "out:Running Cost over RSP/MSEK": 6.299,
    "out:LCP/MSEK": 2.626,
    "out:ROI% old": 24.22,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2940.5,
    "out:Return %": 22,
    "out:Return/kSEK/y": 816,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -84038,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192172,
    "out:DH kr savings": 335037,
    "out:El kr savings": -142865,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 213688,
    "out:% savings (space heating)": 50.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.91,
    "out:Etvv": 0,
    "out:Ef": 40.55,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.64,
    "out:Electricity (inc PV)": 31.74,
    "out:Total Energy Use Pre PV": 113.64,
    "out:Total Energy Use Post PV": 102.74,
    "out:Primary Energy": 100.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.04,
    "out:CO2 Operational/m2": 21.58,
    "out:Total CO2/m2": 39.62,
    "out:Total CO2 (tons)": 135.06,
    "out:Klimatpaverkan": -81.94,
    "out:Initial Cost/MSEK": 4.196375,
    "out:Running cost/(Apt SEK y)": 22188,
    "out:Running Cost over RSP/MSEK": 6.415,
    "out:LCP/MSEK": 2.091,
    "out:ROI% old": 21.41,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2347.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 803,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5838.15,
    "out:PV (% sold (El))": 15.32,
    "out:PV (kWh self-consumed)": 32264.74,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 340900,
    "out:EL kWh savings": -53984,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225890,
    "out:DH kr savings": 310219,
    "out:El kr savings": -91773,
    "out:El kr sold": 7444,
    "out:El kr saved": 54850,
    "out:El kr return": 62294,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 241484,
    "out:% savings (space heating)": 44.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 61.36,
    "out:Etvv": 0,
    "out:Ef": 31.74,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 31.63,
    "out:Total Energy Use Pre PV": 108.57,
    "out:Total Energy Use Post PV": 97.63,
    "out:Primary Energy": 96.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.04,
    "out:CO2 Operational/m2": 19.73,
    "out:Total CO2/m2": 37.77,
    "out:Total CO2 (tons)": 128.75,
    "out:Klimatpaverkan": -88.25,
    "out:Initial Cost/MSEK": 4.281625,
    "out:Running cost/(Apt SEK y)": 20966,
    "out:Running Cost over RSP/MSEK": 6.061,
    "out:LCP/MSEK": 2.36,
    "out:ROI% old": 22.16,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2342.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 849,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5865.2,
    "out:PV (% sold (El))": 15.39,
    "out:PV (kWh self-consumed)": 32237.69,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 357945,
    "out:EL kWh savings": -53624,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242048,
    "out:DH kr savings": 325730,
    "out:El kr savings": -91161,
    "out:El kr sold": 7478,
    "out:El kr saved": 54804,
    "out:El kr return": 62282,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 225202,
    "out:% savings (space heating)": 48.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.72,
    "out:Etvv": 0,
    "out:Ef": 31.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.1,
    "out:Electricity (inc PV)": 31.42,
    "out:Total Energy Use Pre PV": 109.1,
    "out:Total Energy Use Post PV": 98.42,
    "out:Primary Energy": 98.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.54,
    "out:CO2 Operational/m2": 19.79,
    "out:Total CO2/m2": 38.32,
    "out:Total CO2 (tons)": 130.65,
    "out:Klimatpaverkan": -86.35,
    "out:Initial Cost/MSEK": 4.40125,
    "out:Running cost/(Apt SEK y)": 21137,
    "out:Running Cost over RSP/MSEK": 6.11,
    "out:LCP/MSEK": 2.191,
    "out:ROI% old": 21.4,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2312.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6029.34,
    "out:PV (% sold (El))": 15.82,
    "out:PV (kWh self-consumed)": 32073.55,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 354536,
    "out:EL kWh savings": -52915,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240360,
    "out:DH kr savings": 322628,
    "out:El kr savings": -89955,
    "out:El kr sold": 7687,
    "out:El kr saved": 54525,
    "out:El kr return": 62212,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 229797,
    "out:% savings (space heating)": 47.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.52,
    "out:Etvv": 0,
    "out:Ef": 31.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.01,
    "out:Electricity (inc PV)": 31.31,
    "out:Total Energy Use Pre PV": 105.01,
    "out:Total Energy Use Post PV": 94.31,
    "out:Primary Energy": 94.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.54,
    "out:CO2 Operational/m2": 18.29,
    "out:Total CO2/m2": 36.82,
    "out:Total CO2 (tons)": 125.53,
    "out:Klimatpaverkan": -91.47,
    "out:Initial Cost/MSEK": 4.4865,
    "out:Running cost/(Apt SEK y)": 20154,
    "out:Running Cost over RSP/MSEK": 5.826,
    "out:LCP/MSEK": 2.39,
    "out:ROI% old": 21.9,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2306.7,
    "out:Return %": 20,
    "out:Return/kSEK/y": 879,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6062.31,
    "out:PV (% sold (El))": 15.91,
    "out:PV (kWh self-consumed)": 32040.58,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -52530,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253465,
    "out:DH kr savings": 335037,
    "out:El kr savings": -89301,
    "out:El kr sold": 7729,
    "out:El kr saved": 54469,
    "out:El kr return": 62198,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 213688,
    "out:% savings (space heating)": 50.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.91,
    "out:Etvv": 0,
    "out:Ef": 31.31,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.64,
    "out:Electricity (inc PV)": 28.69,
    "out:Total Energy Use Pre PV": 113.64,
    "out:Total Energy Use Post PV": 99.69,
    "out:Primary Energy": 94.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.39,
    "out:CO2 Operational/m2": -19.21,
    "out:Total CO2/m2": 11.18,
    "out:Total CO2 (tons)": 38.12,
    "out:Klimatpaverkan": -178.88,
    "out:Initial Cost/MSEK": 4.9055,
    "out:Running cost/(Apt SEK y)": 20750,
    "out:Running Cost over RSP/MSEK": 6.01,
    "out:LCP/MSEK": 1.787,
    "out:ROI% old": 19.49,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 2119.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33045.09,
    "out:PV (% sold (El))": 43.36,
    "out:PV (kWh self-consumed)": 43160.7,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 340900,
    "out:EL kWh savings": -43598,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278236,
    "out:DH kr savings": 310219,
    "out:El kr savings": -74116,
    "out:El kr sold": 42132,
    "out:El kr saved": 73373,
    "out:El kr return": 115506,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 241484,
    "out:% savings (space heating)": 44.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 61.36,
    "out:Etvv": 0,
    "out:Ef": 28.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 28.59,
    "out:Total Energy Use Pre PV": 108.57,
    "out:Total Energy Use Post PV": 94.59,
    "out:Primary Energy": 91.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.39,
    "out:CO2 Operational/m2": -21.1,
    "out:Total CO2/m2": 9.29,
    "out:Total CO2 (tons)": 31.69,
    "out:Klimatpaverkan": -185.31,
    "out:Initial Cost/MSEK": 4.99075,
    "out:Running cost/(Apt SEK y)": 19528,
    "out:Running Cost over RSP/MSEK": 5.656,
    "out:LCP/MSEK": 2.056,
    "out:ROI% old": 20.17,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 2115.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33096.85,
    "out:PV (% sold (El))": 43.43,
    "out:PV (kWh self-consumed)": 43108.93,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 357945,
    "out:EL kWh savings": -43274,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294362,
    "out:DH kr savings": 325730,
    "out:El kr savings": -73566,
    "out:El kr sold": 42198,
    "out:El kr saved": 73285,
    "out:El kr return": 115484,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 225202,
    "out:% savings (space heating)": 48.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.72,
    "out:Etvv": 0,
    "out:Ef": 28.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.1,
    "out:Electricity (inc PV)": 28.41,
    "out:Total Energy Use Pre PV": 109.1,
    "out:Total Energy Use Post PV": 95.41,
    "out:Primary Energy": 92.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.89,
    "out:CO2 Operational/m2": -21.26,
    "out:Total CO2/m2": 9.63,
    "out:Total CO2 (tons)": 32.83,
    "out:Klimatpaverkan": -184.17,
    "out:Initial Cost/MSEK": 5.110375,
    "out:Running cost/(Apt SEK y)": 19701,
    "out:Running Cost over RSP/MSEK": 5.706,
    "out:LCP/MSEK": 1.886,
    "out:ROI% old": 19.56,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 2088.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 895,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33408.87,
    "out:PV (% sold (El))": 43.84,
    "out:PV (kWh self-consumed)": 42796.92,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 354536,
    "out:EL kWh savings": -42638,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292739,
    "out:DH kr savings": 322628,
    "out:El kr savings": -72485,
    "out:El kr sold": 42596,
    "out:El kr saved": 72755,
    "out:El kr return": 115351,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 229797,
    "out:% savings (space heating)": 47.14,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.52,
    "out:Etvv": 0,
    "out:Ef": 28.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.01,
    "out:Electricity (inc PV)": 28.31,
    "out:Total Energy Use Pre PV": 105.01,
    "out:Total Energy Use Post PV": 91.31,
    "out:Primary Energy": 89.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.89,
    "out:CO2 Operational/m2": -22.8,
    "out:Total CO2/m2": 8.09,
    "out:Total CO2 (tons)": 27.57,
    "out:Klimatpaverkan": -189.43,
    "out:Initial Cost/MSEK": 5.195625,
    "out:Running cost/(Apt SEK y)": 18718,
    "out:Running Cost over RSP/MSEK": 5.421,
    "out:LCP/MSEK": 2.085,
    "out:ROI% old": 20.02,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2083.4,
    "out:Return %": 18,
    "out:Return/kSEK/y": 932,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33471.31,
    "out:PV (% sold (El))": 43.92,
    "out:PV (kWh self-consumed)": 42734.47,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -42292,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 305816,
    "out:DH kr savings": 335037,
    "out:El kr savings": -71897,
    "out:El kr sold": 42676,
    "out:El kr saved": 72649,
    "out:El kr return": 115325,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 213688,
    "out:% savings (space heating)": 50.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.91,
    "out:Etvv": 0,
    "out:Ef": 28.31,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.83,
    "out:Electricity (inc PV)": 57.1,
    "out:Total Energy Use Pre PV": 80.83,
    "out:Total Energy Use Post PV": 77.1,
    "out:Primary Energy": 112.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.28,
    "out:CO2 Operational/m2": 15.23,
    "out:Total CO2/m2": 21.51,
    "out:Total CO2 (tons)": 73.32,
    "out:Klimatpaverkan": -143.68,
    "out:Initial Cost/MSEK": 5.245875,
    "out:Running cost/(Apt SEK y)": 14372,
    "out:Running Cost over RSP/MSEK": 4.128,
    "out:LCP/MSEK": 3.328,
    "out:ROI% old": 23.35,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 4258.4,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1092,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 514759,
    "out:EL kWh savings": -140460,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229650,
    "out:DH kr savings": 468431,
    "out:El kr savings": -238781,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 66484,
    "out:% savings (space heating)": 84.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 13.32,
    "out:Etvv": 0,
    "out:Ef": 57.1,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.31,
    "out:Electricity (inc PV)": 56.43,
    "out:Total Energy Use Pre PV": 76.31,
    "out:Total Energy Use Post PV": 72.43,
    "out:Primary Energy": 108.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.28,
    "out:CO2 Operational/m2": 13.72,
    "out:Total CO2/m2": 20,
    "out:Total CO2 (tons)": 68.18,
    "out:Klimatpaverkan": -148.82,
    "out:Initial Cost/MSEK": 5.331125,
    "out:Running cost/(Apt SEK y)": 13321,
    "out:Running Cost over RSP/MSEK": 3.824,
    "out:LCP/MSEK": 3.547,
    "out:ROI% old": 23.79,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 4221.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1131,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 528395,
    "out:EL kWh savings": -138165,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245959,
    "out:DH kr savings": 480839,
    "out:El kr savings": -234880,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 55198,
    "out:% savings (space heating)": 87.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.51,
    "out:Etvv": 0,
    "out:Ef": 56.43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.56,
    "out:Electricity (inc PV)": 56.3,
    "out:Total Energy Use Pre PV": 76.56,
    "out:Total Energy Use Post PV": 73.3,
    "out:Primary Energy": 109.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.77,
    "out:CO2 Operational/m2": 13.98,
    "out:Total CO2/m2": 20.76,
    "out:Total CO2 (tons)": 70.76,
    "out:Klimatpaverkan": -146.24,
    "out:Initial Cost/MSEK": 5.45075,
    "out:Running cost/(Apt SEK y)": 13445,
    "out:Running Cost over RSP/MSEK": 3.86,
    "out:LCP/MSEK": 3.391,
    "out:ROI% old": 23.17,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 4169.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1127,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 524986,
    "out:EL kWh savings": -137723,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243608,
    "out:DH kr savings": 477737,
    "out:El kr savings": -234129,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 58602,
    "out:% savings (space heating)": 86.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 12.22,
    "out:Etvv": 0,
    "out:Ef": 56.3,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.99,
    "out:Electricity (inc PV)": 55.59,
    "out:Total Energy Use Pre PV": 72.99,
    "out:Total Energy Use Post PV": 69.59,
    "out:Primary Energy": 106.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.77,
    "out:CO2 Operational/m2": 12.83,
    "out:Total CO2/m2": 19.6,
    "out:Total CO2 (tons)": 66.82,
    "out:Klimatpaverkan": -150.18,
    "out:Initial Cost/MSEK": 5.536,
    "out:Running cost/(Apt SEK y)": 12630,
    "out:Running Cost over RSP/MSEK": 3.624,
    "out:LCP/MSEK": 3.542,
    "out:ROI% old": 23.43,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4129.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1157,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -135314,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257010,
    "out:DH kr savings": 487044,
    "out:El kr savings": -230034,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 47855,
    "out:% savings (space heating)": 88.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.52,
    "out:Etvv": 0,
    "out:Ef": 55.59,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.83,
    "out:Electricity (inc PV)": 46.53,
    "out:Total Energy Use Pre PV": 80.83,
    "out:Total Energy Use Post PV": 66.53,
    "out:Primary Energy": 93.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.63,
    "out:CO2 Operational/m2": 11.9,
    "out:Total CO2/m2": 30.54,
    "out:Total CO2 (tons)": 104.1,
    "out:Klimatpaverkan": -112.9,
    "out:Initial Cost/MSEK": 5.955,
    "out:Running cost/(Apt SEK y)": 12637,
    "out:Running Cost over RSP/MSEK": 3.64,
    "out:LCP/MSEK": 3.107,
    "out:ROI% old": 21.74,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3544.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1157,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1428.83,
    "out:PV (% sold (El))": 3.75,
    "out:PV (kWh self-consumed)": 36674.07,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 514759,
    "out:EL kWh savings": -104408,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292758,
    "out:DH kr savings": 468431,
    "out:El kr savings": -177494,
    "out:El kr sold": 1822,
    "out:El kr saved": 62346,
    "out:El kr return": 64168,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 66484,
    "out:% savings (space heating)": 84.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 13.32,
    "out:Etvv": 0,
    "out:Ef": 46.53,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.31,
    "out:Electricity (inc PV)": 45.89,
    "out:Total Energy Use Pre PV": 76.31,
    "out:Total Energy Use Post PV": 61.89,
    "out:Primary Energy": 89.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.63,
    "out:CO2 Operational/m2": 10.29,
    "out:Total CO2/m2": 28.92,
    "out:Total CO2 (tons)": 98.58,
    "out:Klimatpaverkan": -118.42,
    "out:Initial Cost/MSEK": 6.04025,
    "out:Running cost/(Apt SEK y)": 11588,
    "out:Running Cost over RSP/MSEK": 3.336,
    "out:LCP/MSEK": 3.326,
    "out:ROI% old": 22.15,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3509,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1195,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1508.83,
    "out:PV (% sold (El))": 3.96,
    "out:PV (kWh self-consumed)": 36594.06,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 528395,
    "out:EL kWh savings": -102239,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 308957,
    "out:DH kr savings": 480839,
    "out:El kr savings": -173807,
    "out:El kr sold": 1924,
    "out:El kr saved": 62210,
    "out:El kr return": 64134,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 55198,
    "out:% savings (space heating)": 87.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.51,
    "out:Etvv": 0,
    "out:Ef": 45.89,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.56,
    "out:Electricity (inc PV)": 45.77,
    "out:Total Energy Use Pre PV": 76.56,
    "out:Total Energy Use Post PV": 62.77,
    "out:Primary Energy": 90.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.13,
    "out:CO2 Operational/m2": 10.38,
    "out:Total CO2/m2": 29.51,
    "out:Total CO2 (tons)": 100.59,
    "out:Klimatpaverkan": -116.41,
    "out:Initial Cost/MSEK": 6.159875,
    "out:Running cost/(Apt SEK y)": 11713,
    "out:Running Cost over RSP/MSEK": 3.373,
    "out:LCP/MSEK": 3.169,
    "out:ROI% old": 21.64,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3458,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1191,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1628.97,
    "out:PV (% sold (El))": 4.28,
    "out:PV (kWh self-consumed)": 36473.93,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 524986,
    "out:EL kWh savings": -101822,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 306716,
    "out:DH kr savings": 477737,
    "out:El kr savings": -173098,
    "out:El kr sold": 2077,
    "out:El kr saved": 62006,
    "out:El kr return": 64083,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 58602,
    "out:% savings (space heating)": 86.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 12.22,
    "out:Etvv": 0,
    "out:Ef": 45.77,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.99,
    "out:Electricity (inc PV)": 45.1,
    "out:Total Energy Use Pre PV": 72.99,
    "out:Total Energy Use Post PV": 59.1,
    "out:Primary Energy": 87.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.13,
    "out:CO2 Operational/m2": 9.09,
    "out:Total CO2/m2": 28.22,
    "out:Total CO2 (tons)": 96.2,
    "out:Klimatpaverkan": -120.8,
    "out:Initial Cost/MSEK": 6.245125,
    "out:Running cost/(Apt SEK y)": 10899,
    "out:Running Cost over RSP/MSEK": 3.137,
    "out:LCP/MSEK": 3.32,
    "out:ROI% old": 21.88,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3419.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1221,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1722.67,
    "out:PV (% sold (El))": 4.52,
    "out:PV (kWh self-consumed)": 36380.22,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -99551,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320004,
    "out:DH kr savings": 487044,
    "out:El kr savings": -169236,
    "out:El kr sold": 2196,
    "out:El kr saved": 61846,
    "out:El kr return": 64043,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 47855,
    "out:% savings (space heating)": 88.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.52,
    "out:Etvv": 0,
    "out:Ef": 45.1,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.83,
    "out:Electricity (inc PV)": 41.87,
    "out:Total Energy Use Pre PV": 80.83,
    "out:Total Energy Use Post PV": 61.87,
    "out:Primary Energy": 84.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.98,
    "out:CO2 Operational/m2": -19.38,
    "out:Total CO2/m2": 11.6,
    "out:Total CO2 (tons)": 39.56,
    "out:Klimatpaverkan": -177.44,
    "out:Initial Cost/MSEK": 6.664125,
    "out:Running cost/(Apt SEK y)": 11126,
    "out:Running Cost over RSP/MSEK": 3.214,
    "out:LCP/MSEK": 2.824,
    "out:ROI% old": 20.34,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3182.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1213,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22271.81,
    "out:PV (% sold (El))": 29.23,
    "out:PV (kWh self-consumed)": 53933.97,
    "out:PV (ratio sold/self-consumed)": 0.41,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 514759,
    "out:EL kWh savings": -88540,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346309,
    "out:DH kr savings": 468431,
    "out:El kr savings": -150518,
    "out:El kr sold": 28397,
    "out:El kr saved": 91688,
    "out:El kr return": 120084,
    "out:% solar/total": 100,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 66484,
    "out:% savings (space heating)": 84.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 13.32,
    "out:Etvv": 0,
    "out:Ef": 41.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.31,
    "out:Electricity (inc PV)": 41.31,
    "out:Total Energy Use Pre PV": 76.31,
    "out:Total Energy Use Post PV": 57.31,
    "out:Primary Energy": 81.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.98,
    "out:CO2 Operational/m2": -21.29,
    "out:Total CO2/m2": 9.69,
    "out:Total CO2 (tons)": 33.04,
    "out:Klimatpaverkan": -183.96,
    "out:Initial Cost/MSEK": 6.749375,
    "out:Running cost/(Apt SEK y)": 10079,
    "out:Running Cost over RSP/MSEK": 2.911,
    "out:LCP/MSEK": 3.042,
    "out:ROI% old": 20.72,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3151.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1251,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.11,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22546.96,
    "out:PV (% sold (El))": 29.59,
    "out:PV (kWh self-consumed)": 53658.82,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 528395,
    "out:EL kWh savings": -86618,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 362336,
    "out:DH kr savings": 480839,
    "out:El kr savings": -147251,
    "out:El kr sold": 28747,
    "out:El kr saved": 91220,
    "out:El kr return": 119967,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 55198,
    "out:% savings (space heating)": 87.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.51,
    "out:Etvv": 0,
    "out:Ef": 41.31,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.56,
    "out:Electricity (inc PV)": 41.2,
    "out:Total Energy Use Pre PV": 76.56,
    "out:Total Energy Use Post PV": 58.2,
    "out:Primary Energy": 82.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.48,
    "out:CO2 Operational/m2": -21.62,
    "out:Total CO2/m2": 9.86,
    "out:Total CO2 (tons)": 33.62,
    "out:Klimatpaverkan": -183.38,
    "out:Initial Cost/MSEK": 6.869,
    "out:Running cost/(Apt SEK y)": 10207,
    "out:Running Cost over RSP/MSEK": 2.948,
    "out:LCP/MSEK": 2.885,
    "out:ROI% old": 20.29,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 222.4,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3105.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1247,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22948.07,
    "out:PV (% sold (El))": 30.11,
    "out:PV (kWh self-consumed)": 53257.71,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 524986,
    "out:EL kWh savings": -86248,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 360374,
    "out:DH kr savings": 477737,
    "out:El kr savings": -146622,
    "out:El kr sold": 29259,
    "out:El kr saved": 90538,
    "out:El kr return": 119797,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 58602,
    "out:% savings (space heating)": 86.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 12.22,
    "out:Etvv": 0,
    "out:Ef": 41.2,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.99,
    "out:Electricity (inc PV)": 40.61,
    "out:Total Energy Use Pre PV": 72.99,
    "out:Total Energy Use Post PV": 54.61,
    "out:Primary Energy": 79.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.48,
    "out:CO2 Operational/m2": -23.22,
    "out:Total CO2/m2": 8.26,
    "out:Total CO2 (tons)": 28.16,
    "out:Klimatpaverkan": -188.84,
    "out:Initial Cost/MSEK": 6.95425,
    "out:Running cost/(Apt SEK y)": 9395,
    "out:Running Cost over RSP/MSEK": 2.713,
    "out:LCP/MSEK": 3.035,
    "out:ROI% old": 20.52,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3072,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1277,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23254.99,
    "out:PV (% sold (El))": 30.52,
    "out:PV (kWh self-consumed)": 52950.79,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -84234,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 373496,
    "out:DH kr savings": 487044,
    "out:El kr savings": -143198,
    "out:El kr sold": 29650,
    "out:El kr saved": 90016,
    "out:El kr return": 119666,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 47855,
    "out:% savings (space heating)": 88.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.52,
    "out:Etvv": 0,
    "out:Ef": 40.61,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.12,
    "out:Electricity (inc PV)": 56.03,
    "out:Total Energy Use Pre PV": 75.12,
    "out:Total Energy Use Post PV": 72.03,
    "out:Primary Energy": 109.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.28,
    "out:CO2 Operational/m2": 13.56,
    "out:Total CO2/m2": 19.84,
    "out:Total CO2 (tons)": 67.64,
    "out:Klimatpaverkan": -149.36,
    "out:Initial Cost/MSEK": 5.3435,
    "out:Running cost/(Apt SEK y)": 13135,
    "out:Running Cost over RSP/MSEK": 3.771,
    "out:LCP/MSEK": 3.588,
    "out:ROI% old": 23.88,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 4138.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1138,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 528395,
    "out:EL kWh savings": -136800,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248279,
    "out:DH kr savings": 480839,
    "out:El kr savings": -232561,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 56077,
    "out:% savings (space heating)": 87.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.86,
    "out:Etvv": 0,
    "out:Ef": 56.03,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.54,
    "out:Electricity (inc PV)": 55.31,
    "out:Total Energy Use Pre PV": 71.54,
    "out:Total Energy Use Post PV": 68.31,
    "out:Primary Energy": 106,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.28,
    "out:CO2 Operational/m2": 12.41,
    "out:Total CO2/m2": 18.69,
    "out:Total CO2 (tons)": 63.7,
    "out:Klimatpaverkan": -153.3,
    "out:Initial Cost/MSEK": 5.42875,
    "out:Running cost/(Apt SEK y)": 12317,
    "out:Running Cost over RSP/MSEK": 3.534,
    "out:LCP/MSEK": 3.739,
    "out:ROI% old": 24.13,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 175,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4097.6,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1169,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 538622,
    "out:EL kWh savings": -134351,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261749,
    "out:DH kr savings": 490146,
    "out:El kr savings": -228397,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 45528,
    "out:% savings (space heating)": 89.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.2,
    "out:Etvv": 0,
    "out:Ef": 55.31,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.06,
    "out:Electricity (inc PV)": 55.37,
    "out:Total Energy Use Pre PV": 73.06,
    "out:Total Energy Use Post PV": 70.37,
    "out:Primary Energy": 107.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.77,
    "out:CO2 Operational/m2": 13.06,
    "out:Total CO2/m2": 19.84,
    "out:Total CO2 (tons)": 67.63,
    "out:Klimatpaverkan": -149.37,
    "out:Initial Cost/MSEK": 5.5485,
    "out:Running cost/(Apt SEK y)": 12726,
    "out:Running Cost over RSP/MSEK": 3.653,
    "out:LCP/MSEK": 3.501,
    "out:ROI% old": 23.3,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4064.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1153,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 531804,
    "out:EL kWh savings": -134562,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 255186,
    "out:DH kr savings": 483942,
    "out:El kr savings": -228756,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 50245,
    "out:% savings (space heating)": 88.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.03,
    "out:Etvv": 0,
    "out:Ef": 55.37,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.43,
    "out:Electricity (inc PV)": 54.63,
    "out:Total Energy Use Pre PV": 69.43,
    "out:Total Energy Use Post PV": 66.63,
    "out:Primary Energy": 104.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 6.77,
    "out:CO2 Operational/m2": 11.9,
    "out:Total CO2/m2": 18.68,
    "out:Total CO2 (tons)": 63.66,
    "out:Klimatpaverkan": -153.34,
    "out:Initial Cost/MSEK": 5.633625,
    "out:Running cost/(Apt SEK y)": 11901,
    "out:Running Cost over RSP/MSEK": 3.414,
    "out:LCP/MSEK": 3.654,
    "out:ROI% old": 23.55,
    "out:Payback discounted": 5,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4019.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1184,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -132015,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 268822,
    "out:DH kr savings": 493248,
    "out:El kr savings": -224426,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 40209,
    "out:% savings (space heating)": 90.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.46,
    "out:Etvv": 0,
    "out:Ef": 54.63,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.12,
    "out:Electricity (inc PV)": 45.51,
    "out:Total Energy Use Pre PV": 75.12,
    "out:Total Energy Use Post PV": 61.51,
    "out:Primary Energy": 90.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.63,
    "out:CO2 Operational/m2": 9.86,
    "out:Total CO2/m2": 28.49,
    "out:Total CO2 (tons)": 97.13,
    "out:Klimatpaverkan": -119.87,
    "out:Initial Cost/MSEK": 6.052625,
    "out:Running cost/(Apt SEK y)": 11404,
    "out:Running Cost over RSP/MSEK": 3.284,
    "out:LCP/MSEK": 3.366,
    "out:ROI% old": 22.23,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3428.7,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1202,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1700.29,
    "out:PV (% sold (El))": 4.46,
    "out:PV (kWh self-consumed)": 36402.6,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 528395,
    "out:EL kWh savings": -100951,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 311390,
    "out:DH kr savings": 480839,
    "out:El kr savings": -171618,
    "out:El kr sold": 2168,
    "out:El kr saved": 61884,
    "out:El kr return": 64052,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 56077,
    "out:% savings (space heating)": 87.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.86,
    "out:Etvv": 0,
    "out:Ef": 45.51,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.54,
    "out:Electricity (inc PV)": 44.84,
    "out:Total Energy Use Pre PV": 71.54,
    "out:Total Energy Use Post PV": 57.84,
    "out:Primary Energy": 87.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.63,
    "out:CO2 Operational/m2": 8.57,
    "out:Total CO2/m2": 27.2,
    "out:Total CO2 (tons)": 92.71,
    "out:Klimatpaverkan": -124.29,
    "out:Initial Cost/MSEK": 6.137875,
    "out:Running cost/(Apt SEK y)": 10587,
    "out:Running Cost over RSP/MSEK": 3.047,
    "out:LCP/MSEK": 3.517,
    "out:ROI% old": 22.47,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 222.4,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3389.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1233,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1798.53,
    "out:PV (% sold (El))": 4.72,
    "out:PV (kWh self-consumed)": 36304.37,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 538622,
    "out:EL kWh savings": -98644,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324744,
    "out:DH kr savings": 490146,
    "out:El kr savings": -167695,
    "out:El kr sold": 2293,
    "out:El kr saved": 61717,
    "out:El kr return": 64011,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 45528,
    "out:% savings (space heating)": 89.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.2,
    "out:Etvv": 0,
    "out:Ef": 44.84,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.06,
    "out:Electricity (inc PV)": 44.89,
    "out:Total Energy Use Pre PV": 73.06,
    "out:Total Energy Use Post PV": 59.89,
    "out:Primary Energy": 88.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.13,
    "out:CO2 Operational/m2": 9.11,
    "out:Total CO2/m2": 28.23,
    "out:Total CO2 (tons)": 96.25,
    "out:Klimatpaverkan": -120.75,
    "out:Initial Cost/MSEK": 6.2575,
    "out:Running cost/(Apt SEK y)": 10997,
    "out:Running Cost over RSP/MSEK": 3.166,
    "out:LCP/MSEK": 3.278,
    "out:ROI% old": 21.77,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3357.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1217,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1880.37,
    "out:PV (% sold (El))": 4.93,
    "out:PV (kWh self-consumed)": 36222.52,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 531804,
    "out:EL kWh savings": -98843,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318306,
    "out:DH kr savings": 483942,
    "out:El kr savings": -168033,
    "out:El kr sold": 2397,
    "out:El kr saved": 61578,
    "out:El kr return": 63976,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 50245,
    "out:% savings (space heating)": 88.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.03,
    "out:Etvv": 0,
    "out:Ef": 44.89,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.43,
    "out:Electricity (inc PV)": 44.19,
    "out:Total Energy Use Pre PV": 69.43,
    "out:Total Energy Use Post PV": 56.19,
    "out:Primary Energy": 85.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.13,
    "out:CO2 Operational/m2": 7.78,
    "out:Total CO2/m2": 26.91,
    "out:Total CO2 (tons)": 91.74,
    "out:Klimatpaverkan": -125.26,
    "out:Initial Cost/MSEK": 6.34275,
    "out:Running cost/(Apt SEK y)": 10173,
    "out:Running Cost over RSP/MSEK": 2.928,
    "out:LCP/MSEK": 3.432,
    "out:ROI% old": 22.01,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3314.7,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1248,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1992.6,
    "out:PV (% sold (El))": 5.23,
    "out:PV (kWh self-consumed)": 36110.29,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -96448,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331827,
    "out:DH kr savings": 493248,
    "out:El kr savings": -163962,
    "out:El kr sold": 2541,
    "out:El kr saved": 61387,
    "out:El kr return": 63928,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 40209,
    "out:% savings (space heating)": 90.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.46,
    "out:Etvv": 0,
    "out:Ef": 44.19,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.12,
    "out:Electricity (inc PV)": 40.97,
    "out:Total Energy Use Pre PV": 75.12,
    "out:Total Energy Use Post PV": 56.97,
    "out:Primary Energy": 82.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.98,
    "out:CO2 Operational/m2": -22.38,
    "out:Total CO2/m2": 8.61,
    "out:Total CO2 (tons)": 29.34,
    "out:Klimatpaverkan": -187.66,
    "out:Initial Cost/MSEK": 6.76175,
    "out:Running cost/(Apt SEK y)": 9900,
    "out:Running Cost over RSP/MSEK": 2.859,
    "out:LCP/MSEK": 3.081,
    "out:ROI% old": 20.8,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3080,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1258,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23182.01,
    "out:PV (% sold (El))": 30.42,
    "out:PV (kWh self-consumed)": 53023.78,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 528395,
    "out:EL kWh savings": -85477,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 365086,
    "out:DH kr savings": 480839,
    "out:El kr savings": -145310,
    "out:El kr sold": 29557,
    "out:El kr saved": 90140,
    "out:El kr return": 119697,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 56077,
    "out:% savings (space heating)": 87.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.86,
    "out:Etvv": 0,
    "out:Ef": 40.97,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 13.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.54,
    "out:Electricity (inc PV)": 40.37,
    "out:Total Energy Use Pre PV": 71.54,
    "out:Total Energy Use Post PV": 53.37,
    "out:Primary Energy": 79.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.98,
    "out:CO2 Operational/m2": -24,
    "out:Total CO2/m2": 6.99,
    "out:Total CO2 (tons)": 23.82,
    "out:Klimatpaverkan": -193.18,
    "out:Initial Cost/MSEK": 6.847,
    "out:Running cost/(Apt SEK y)": 9085,
    "out:Running Cost over RSP/MSEK": 2.624,
    "out:LCP/MSEK": 3.231,
    "out:ROI% old": 21.03,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3045,
    "out:Return %": 19,
    "out:Return/kSEK/y": 1288,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23500.35,
    "out:PV (% sold (El))": 30.84,
    "out:PV (kWh self-consumed)": 52705.44,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 538622,
    "out:EL kWh savings": -83430,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378278,
    "out:DH kr savings": 490146,
    "out:El kr savings": -141831,
    "out:El kr sold": 29963,
    "out:El kr saved": 89599,
    "out:El kr return": 119562,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 45528,
    "out:% savings (space heating)": 89.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.2,
    "out:Etvv": 0,
    "out:Ef": 40.37,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.06,
    "out:Electricity (inc PV)": 40.43,
    "out:Total Energy Use Pre PV": 73.06,
    "out:Total Energy Use Post PV": 55.43,
    "out:Primary Energy": 80.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.48,
    "out:CO2 Operational/m2": -23.72,
    "out:Total CO2/m2": 7.76,
    "out:Total CO2 (tons)": 26.45,
    "out:Klimatpaverkan": -190.55,
    "out:Initial Cost/MSEK": 6.966625,
    "out:Running cost/(Apt SEK y)": 9498,
    "out:Running Cost over RSP/MSEK": 2.744,
    "out:LCP/MSEK": 2.992,
    "out:ROI% old": 20.42,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3016.7,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1273,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23760.03,
    "out:PV (% sold (El))": 31.18,
    "out:PV (kWh self-consumed)": 52445.75,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 531804,
    "out:EL kWh savings": -83607,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 372105,
    "out:DH kr savings": 483942,
    "out:El kr savings": -142131,
    "out:El kr sold": 30294,
    "out:El kr saved": 89158,
    "out:El kr return": 119452,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 50245,
    "out:% savings (space heating)": 88.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.03,
    "out:Etvv": 0,
    "out:Ef": 40.43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.43,
    "out:Electricity (inc PV)": 39.8,
    "out:Total Energy Use Pre PV": 69.43,
    "out:Total Energy Use Post PV": 51.8,
    "out:Primary Energy": 77.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.48,
    "out:CO2 Operational/m2": -25.39,
    "out:Total CO2/m2": 6.09,
    "out:Total CO2 (tons)": 20.75,
    "out:Klimatpaverkan": -196.25,
    "out:Initial Cost/MSEK": 7.051875,
    "out:Running cost/(Apt SEK y)": 8676,
    "out:Running Cost over RSP/MSEK": 2.506,
    "out:LCP/MSEK": 3.144,
    "out:ROI% old": 20.66,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 2979.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1303,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24107.28,
    "out:PV (% sold (El))": 31.63,
    "out:PV (kWh self-consumed)": 52098.5,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -81481,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385467,
    "out:DH kr savings": 493248,
    "out:El kr savings": -138518,
    "out:El kr sold": 30737,
    "out:El kr saved": 88567,
    "out:El kr return": 119304,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 40209,
    "out:% savings (space heating)": 90.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.46,
    "out:Etvv": 0,
    "out:Ef": 39.8,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 172.9,
    "out:Primary Energy": 125.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.97,
    "out:CO2 Operational/m2": 58.62,
    "out:Total CO2/m2": 66.59,
    "out:Total CO2 (tons)": 226.99,
    "out:Klimatpaverkan": 9.99,
    "out:Initial Cost/MSEK": 1.874625,
    "out:Running cost/(Apt SEK y)": 40508,
    "out:Running Cost over RSP/MSEK": 11.721,
    "out:LCP/MSEK": -0.893,
    "out:ROI% old": 7.48,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 8.1,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 125,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 43431,
    "out:DH kr savings": 43431,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 167.9,
    "out:Primary Energy": 122.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.97,
    "out:CO2 Operational/m2": 56.82,
    "out:Total CO2/m2": 64.79,
    "out:Total CO2 (tons)": 220.85,
    "out:Klimatpaverkan": 3.85,
    "out:Initial Cost/MSEK": 1.959875,
    "out:Running cost/(Apt SEK y)": 39297,
    "out:Running Cost over RSP/MSEK": 11.37,
    "out:LCP/MSEK": -0.628,
    "out:ROI% old": 9.71,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 170,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 64771,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 58942,
    "out:DH kr savings": 58942,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 166.9,
    "out:Primary Energy": 122.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.47,
    "out:CO2 Operational/m2": 56.46,
    "out:Total CO2/m2": 64.92,
    "out:Total CO2 (tons)": 221.32,
    "out:Klimatpaverkan": 4.32,
    "out:Initial Cost/MSEK": 2.0795,
    "out:Running cost/(Apt SEK y)": 39055,
    "out:Running Cost over RSP/MSEK": 11.3,
    "out:LCP/MSEK": -0.677,
    "out:ROI% old": 9.63,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 179,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 68180,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62044,
    "out:DH kr savings": 62044,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 119.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.47,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 63.12,
    "out:Total CO2 (tons)": 215.18,
    "out:Klimatpaverkan": -1.82,
    "out:Initial Cost/MSEK": 2.16475,
    "out:Running cost/(Apt SEK y)": 37844,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": -0.412,
    "out:ROI% old": 11.57,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77555,
    "out:DH kr savings": 77555,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 167.73,
    "out:Primary Energy": 116.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.32,
    "out:CO2 Operational/m2": 27.62,
    "out:Total CO2/m2": 47.94,
    "out:Total CO2 (tons)": 163.44,
    "out:Klimatpaverkan": -53.56,
    "out:Initial Cost/MSEK": 2.58375,
    "out:Running cost/(Apt SEK y)": 38992,
    "out:Running Cost over RSP/MSEK": 11.294,
    "out:LCP/MSEK": -1.175,
    "out:ROI% old": 7.79,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 11.3,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 182,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99505,
    "out:DH kr savings": 43431,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 162.73,
    "out:Primary Energy": 112.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.32,
    "out:CO2 Operational/m2": 25.82,
    "out:Total CO2/m2": 46.14,
    "out:Total CO2 (tons)": 157.3,
    "out:Klimatpaverkan": -59.7,
    "out:Initial Cost/MSEK": 2.669,
    "out:Running cost/(Apt SEK y)": 37782,
    "out:Running Cost over RSP/MSEK": 10.943,
    "out:LCP/MSEK": -0.91,
    "out:ROI% old": 9.41,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 226,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 64771,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115016,
    "out:DH kr savings": 58942,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 161.73,
    "out:Primary Energy": 113.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.82,
    "out:CO2 Operational/m2": 25.46,
    "out:Total CO2/m2": 46.28,
    "out:Total CO2 (tons)": 157.77,
    "out:Klimatpaverkan": -59.23,
    "out:Initial Cost/MSEK": 2.788625,
    "out:Running cost/(Apt SEK y)": 37539,
    "out:Running Cost over RSP/MSEK": 10.873,
    "out:LCP/MSEK": -0.96,
    "out:ROI% old": 9.37,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68180,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118118,
    "out:DH kr savings": 62044,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 110.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.82,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 44.48,
    "out:Total CO2 (tons)": 151.63,
    "out:Klimatpaverkan": -65.37,
    "out:Initial Cost/MSEK": 2.873875,
    "out:Running cost/(Apt SEK y)": 36329,
    "out:Running Cost over RSP/MSEK": 10.523,
    "out:LCP/MSEK": -0.694,
    "out:ROI% old": 10.83,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 280,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133629,
    "out:DH kr savings": 77555,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 172.9,
    "out:Total Energy Use Post PV": 166.88,
    "out:Primary Energy": 114.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.68,
    "out:CO2 Operational/m2": -25.06,
    "out:Total CO2/m2": 7.62,
    "out:Total CO2 (tons)": 25.97,
    "out:Klimatpaverkan": -191.03,
    "out:Initial Cost/MSEK": 3.292875,
    "out:Running cost/(Apt SEK y)": 37646,
    "out:Running Cost over RSP/MSEK": 10.915,
    "out:LCP/MSEK": -1.506,
    "out:ROI% old": 7.75,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 12,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 231,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 47726,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149316,
    "out:DH kr savings": 43431,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 167.9,
    "out:Total Energy Use Post PV": 161.88,
    "out:Primary Energy": 111.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.68,
    "out:CO2 Operational/m2": -26.86,
    "out:Total CO2/m2": 5.82,
    "out:Total CO2 (tons)": 19.83,
    "out:Klimatpaverkan": -197.17,
    "out:Initial Cost/MSEK": 3.378125,
    "out:Running cost/(Apt SEK y)": 36435,
    "out:Running Cost over RSP/MSEK": 10.565,
    "out:LCP/MSEK": -1.24,
    "out:ROI% old": 9.04,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 276,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 64771,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164827,
    "out:DH kr savings": 58942,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 166.9,
    "out:Total Energy Use Post PV": 160.88,
    "out:Primary Energy": 112.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.17,
    "out:CO2 Operational/m2": -27.22,
    "out:Total CO2/m2": 5.95,
    "out:Total CO2 (tons)": 20.3,
    "out:Klimatpaverkan": -196.7,
    "out:Initial Cost/MSEK": 3.49775,
    "out:Running cost/(Apt SEK y)": 36193,
    "out:Running Cost over RSP/MSEK": 10.494,
    "out:LCP/MSEK": -1.29,
    "out:ROI% old": 9.02,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 285,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 68180,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167929,
    "out:DH kr savings": 62044,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 108.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.17,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": 4.15,
    "out:Total CO2 (tons)": 14.16,
    "out:Klimatpaverkan": -202.84,
    "out:Initial Cost/MSEK": 3.583,
    "out:Running cost/(Apt SEK y)": 34982,
    "out:Running Cost over RSP/MSEK": 10.144,
    "out:LCP/MSEK": -1.025,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 330,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183440,
    "out:DH kr savings": 77555,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 163.9,
    "out:Primary Energy": 122.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.97,
    "out:CO2 Operational/m2": 55.38,
    "out:Total CO2/m2": 63.35,
    "out:Total CO2 (tons)": 215.94,
    "out:Klimatpaverkan": -1.06,
    "out:Initial Cost/MSEK": 1.97225,
    "out:Running cost/(Apt SEK y)": 38329,
    "out:Running Cost over RSP/MSEK": 11.09,
    "out:LCP/MSEK": -0.36,
    "out:ROI% old": 11.68,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 78407,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 71350,
    "out:DH kr savings": 71350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 158.9,
    "out:Primary Energy": 118.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 7.97,
    "out:CO2 Operational/m2": 53.58,
    "out:Total CO2/m2": 61.55,
    "out:Total CO2 (tons)": 209.81,
    "out:Klimatpaverkan": -7.19,
    "out:Initial Cost/MSEK": 2.0575,
    "out:Running cost/(Apt SEK y)": 37118,
    "out:Running Cost over RSP/MSEK": 10.739,
    "out:LCP/MSEK": -0.094,
    "out:ROI% old": 13.63,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95452,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 86861,
    "out:DH kr savings": 86861,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 158.9,
    "out:Primary Energy": 119.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.47,
    "out:CO2 Operational/m2": 53.58,
    "out:Total CO2/m2": 62.04,
    "out:Total CO2 (tons)": 211.5,
    "out:Klimatpaverkan": -5.5,
    "out:Initial Cost/MSEK": 2.17725,
    "out:Running cost/(Apt SEK y)": 37118,
    "out:Running Cost over RSP/MSEK": 10.739,
    "out:LCP/MSEK": -0.214,
    "out:ROI% old": 12.88,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 251,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 95452,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86861,
    "out:DH kr savings": 86861,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 153.9,
    "out:Primary Energy": 116.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 8.47,
    "out:CO2 Operational/m2": 51.78,
    "out:Total CO2/m2": 60.24,
    "out:Total CO2 (tons)": 205.37,
    "out:Klimatpaverkan": -11.63,
    "out:Initial Cost/MSEK": 2.262375,
    "out:Running cost/(Apt SEK y)": 35907,
    "out:Running Cost over RSP/MSEK": 10.388,
    "out:LCP/MSEK": 0.051,
    "out:ROI% old": 14.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 13,
    "out:Return/kSEK/y": 296,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102372,
    "out:DH kr savings": 102372,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 158.73,
    "out:Primary Energy": 112.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.32,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 44.7,
    "out:Total CO2 (tons)": 152.39,
    "out:Klimatpaverkan": -64.61,
    "out:Initial Cost/MSEK": 2.681375,
    "out:Running cost/(Apt SEK y)": 36813,
    "out:Running Cost over RSP/MSEK": 10.663,
    "out:LCP/MSEK": -0.642,
    "out:ROI% old": 10.87,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 78407,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127424,
    "out:DH kr savings": 71350,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 153.73,
    "out:Primary Energy": 109.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.32,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 42.9,
    "out:Total CO2 (tons)": 146.26,
    "out:Klimatpaverkan": -70.74,
    "out:Initial Cost/MSEK": 2.766625,
    "out:Running cost/(Apt SEK y)": 35602,
    "out:Running Cost over RSP/MSEK": 10.312,
    "out:LCP/MSEK": -0.377,
    "out:ROI% old": 12.34,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 95452,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142935,
    "out:DH kr savings": 86861,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 153.73,
    "out:Primary Energy": 110.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.82,
    "out:CO2 Operational/m2": 22.58,
    "out:Total CO2/m2": 43.4,
    "out:Total CO2 (tons)": 147.95,
    "out:Klimatpaverkan": -69.05,
    "out:Initial Cost/MSEK": 2.886375,
    "out:Running cost/(Apt SEK y)": 35602,
    "out:Running Cost over RSP/MSEK": 10.312,
    "out:LCP/MSEK": -0.496,
    "out:ROI% old": 11.83,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 95452,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142935,
    "out:DH kr savings": 86861,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 148.73,
    "out:Primary Energy": 107.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.82,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 41.6,
    "out:Total CO2 (tons)": 141.82,
    "out:Klimatpaverkan": -75.18,
    "out:Initial Cost/MSEK": 2.9715,
    "out:Running cost/(Apt SEK y)": 34392,
    "out:Running Cost over RSP/MSEK": 9.962,
    "out:LCP/MSEK": -0.231,
    "out:ROI% old": 13.18,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158446,
    "out:DH kr savings": 102372,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 157.88,
    "out:Primary Energy": 111.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.68,
    "out:CO2 Operational/m2": -28.3,
    "out:Total CO2/m2": 4.38,
    "out:Total CO2 (tons)": 14.92,
    "out:Klimatpaverkan": -202.08,
    "out:Initial Cost/MSEK": 3.3905,
    "out:Running cost/(Apt SEK y)": 35467,
    "out:Running Cost over RSP/MSEK": 10.284,
    "out:LCP/MSEK": -0.972,
    "out:ROI% old": 10.19,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 78407,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177235,
    "out:DH kr savings": 71350,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 152.88,
    "out:Primary Energy": 107.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.68,
    "out:CO2 Operational/m2": -30.1,
    "out:Total CO2/m2": 2.58,
    "out:Total CO2 (tons)": 8.79,
    "out:Klimatpaverkan": -208.21,
    "out:Initial Cost/MSEK": 3.47575,
    "out:Running cost/(Apt SEK y)": 34256,
    "out:Running Cost over RSP/MSEK": 9.933,
    "out:LCP/MSEK": -0.707,
    "out:ROI% old": 11.38,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 95452,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192746,
    "out:DH kr savings": 86861,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.53,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 158.9,
    "out:Total Energy Use Post PV": 152.88,
    "out:Primary Energy": 109.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.17,
    "out:CO2 Operational/m2": -30.1,
    "out:Total CO2/m2": 3.07,
    "out:Total CO2 (tons)": 10.48,
    "out:Klimatpaverkan": -206.52,
    "out:Initial Cost/MSEK": 3.5955,
    "out:Running cost/(Apt SEK y)": 34256,
    "out:Running Cost over RSP/MSEK": 9.933,
    "out:LCP/MSEK": -0.827,
    "out:ROI% old": 11,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 357,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 95452,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192746,
    "out:DH kr savings": 86861,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 387016,
    "out:% savings (space heating)": 10.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 113.53,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 108.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 147.88,
    "out:Primary Energy": 105.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 33.17,
    "out:CO2 Operational/m2": -31.9,
    "out:Total CO2/m2": 1.27,
    "out:Total CO2 (tons)": 4.34,
    "out:Klimatpaverkan": -212.66,
    "out:Initial Cost/MSEK": 3.680625,
    "out:Running cost/(Apt SEK y)": 33045,
    "out:Running Cost over RSP/MSEK": 9.583,
    "out:LCP/MSEK": -0.561,
    "out:ROI% old": 12.11,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 402,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208257,
    "out:DH kr savings": 102372,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 370354,
    "out:% savings (space heating)": 14.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 108.64,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z00000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.49,
    "out:Electricity (inc PV)": 41.57,
    "out:Total Energy Use Pre PV": 117.49,
    "out:Total Energy Use Post PV": 115.57,
    "out:Primary Energy": 117.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.47,
    "out:CO2 Operational/m2": 32.38,
    "out:Total CO2/m2": 43.85,
    "out:Total CO2 (tons)": 149.5,
    "out:Klimatpaverkan": -67.5,
    "out:Initial Cost/MSEK": 4.720625,
    "out:Running cost/(Apt SEK y)": 24730,
    "out:Running Cost over RSP/MSEK": 7.137,
    "out:LCP/MSEK": 0.845,
    "out:ROI% old": 16.84,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 3044,
    "out:Return %": 15,
    "out:Return/kSEK/y": 709,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 330673,
    "out:EL kWh savings": -87520,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152128,
    "out:DH kr savings": 300912,
    "out:El kr savings": -148785,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 250848,
    "out:% savings (space heating)": 42.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 61.39,
    "out:Etvv": 0,
    "out:Ef": 41.57,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 41.47,
    "out:Total Energy Use Pre PV": 112.42,
    "out:Total Energy Use Post PV": 110.47,
    "out:Primary Energy": 114.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.47,
    "out:CO2 Operational/m2": 30.57,
    "out:Total CO2/m2": 42.05,
    "out:Total CO2 (tons)": 143.33,
    "out:Klimatpaverkan": -73.67,
    "out:Initial Cost/MSEK": 4.805875,
    "out:Running cost/(Apt SEK y)": 23510,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": 1.113,
    "out:ROI% old": 17.59,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 3039.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 754,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -87155,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168260,
    "out:DH kr savings": 316423,
    "out:El kr savings": -148164,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 234878,
    "out:% savings (space heating)": 45.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.86,
    "out:Etvv": 0,
    "out:Ef": 41.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.85,
    "out:Electricity (inc PV)": 41.14,
    "out:Total Energy Use Pre PV": 111.85,
    "out:Total Energy Use Post PV": 110.14,
    "out:Primary Energy": 115.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.97,
    "out:CO2 Operational/m2": 30.5,
    "out:Total CO2/m2": 42.47,
    "out:Total CO2 (tons)": 144.77,
    "out:Klimatpaverkan": -72.23,
    "out:Initial Cost/MSEK": 4.925625,
    "out:Running cost/(Apt SEK y)": 23420,
    "out:Running Cost over RSP/MSEK": 6.758,
    "out:LCP/MSEK": 1.019,
    "out:ROI% old": 17.24,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2999.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 758,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -86057,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170126,
    "out:DH kr savings": 316423,
    "out:El kr savings": -146297,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 235752,
    "out:% savings (space heating)": 45.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.06,
    "out:Etvv": 0,
    "out:Ef": 41.14,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.78,
    "out:Electricity (inc PV)": 41.03,
    "out:Total Energy Use Pre PV": 107.78,
    "out:Total Energy Use Post PV": 106.03,
    "out:Primary Energy": 112.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.97,
    "out:CO2 Operational/m2": 29.05,
    "out:Total CO2/m2": 41.02,
    "out:Total CO2 (tons)": 139.82,
    "out:Klimatpaverkan": -77.18,
    "out:Initial Cost/MSEK": 5.01075,
    "out:Running cost/(Apt SEK y)": 22440,
    "out:Running Cost over RSP/MSEK": 6.474,
    "out:LCP/MSEK": 1.217,
    "out:ROI% old": 17.76,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2994.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -85656,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183218,
    "out:DH kr savings": 328832,
    "out:El kr savings": -145615,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219929,
    "out:% savings (space heating)": 49.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.56,
    "out:Etvv": 0,
    "out:Ef": 41.03,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.49,
    "out:Electricity (inc PV)": 32.22,
    "out:Total Energy Use Pre PV": 117.49,
    "out:Total Energy Use Post PV": 106.22,
    "out:Primary Energy": 100.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.83,
    "out:CO2 Operational/m2": 23.19,
    "out:Total CO2/m2": 47.02,
    "out:Total CO2 (tons)": 160.28,
    "out:Klimatpaverkan": -56.72,
    "out:Initial Cost/MSEK": 5.42975,
    "out:Running cost/(Apt SEK y)": 23043,
    "out:Running Cost over RSP/MSEK": 6.662,
    "out:LCP/MSEK": 0.611,
    "out:ROI% old": 15.89,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2400.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 772,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5547.59,
    "out:PV (% sold (El))": 14.56,
    "out:PV (kWh self-consumed)": 32555.31,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 330673,
    "out:EL kWh savings": -55635,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213405,
    "out:DH kr savings": 300912,
    "out:El kr savings": -94580,
    "out:El kr sold": 7073,
    "out:El kr saved": 55344,
    "out:El kr return": 62417,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 250848,
    "out:% savings (space heating)": 42.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 61.39,
    "out:Etvv": 0,
    "out:Ef": 32.22,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 32.12,
    "out:Total Energy Use Pre PV": 112.42,
    "out:Total Energy Use Post PV": 101.12,
    "out:Primary Energy": 97.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.83,
    "out:CO2 Operational/m2": 21.35,
    "out:Total CO2/m2": 45.18,
    "out:Total CO2 (tons)": 154.02,
    "out:Klimatpaverkan": -62.98,
    "out:Initial Cost/MSEK": 5.515,
    "out:Running cost/(Apt SEK y)": 21823,
    "out:Running Cost over RSP/MSEK": 6.308,
    "out:LCP/MSEK": 0.879,
    "out:ROI% old": 16.56,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2396.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5568.55,
    "out:PV (% sold (El))": 14.61,
    "out:PV (kWh self-consumed)": 32534.34,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -55309,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229498,
    "out:DH kr savings": 316423,
    "out:El kr savings": -94025,
    "out:El kr sold": 7100,
    "out:El kr saved": 55308,
    "out:El kr return": 62408,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 234878,
    "out:% savings (space heating)": 45.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.86,
    "out:Etvv": 0,
    "out:Ef": 32.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.85,
    "out:Electricity (inc PV)": 31.84,
    "out:Total Energy Use Pre PV": 111.85,
    "out:Total Energy Use Post PV": 100.84,
    "out:Primary Energy": 98.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.32,
    "out:CO2 Operational/m2": 20.99,
    "out:Total CO2/m2": 45.31,
    "out:Total CO2 (tons)": 154.48,
    "out:Klimatpaverkan": -62.52,
    "out:Initial Cost/MSEK": 5.634625,
    "out:Running cost/(Apt SEK y)": 21735,
    "out:Running Cost over RSP/MSEK": 6.283,
    "out:LCP/MSEK": 0.784,
    "out:ROI% old": 16.27,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2360.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 820,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5766,
    "out:PV (% sold (El))": 15.13,
    "out:PV (kWh self-consumed)": 32336.89,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -54329,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231416,
    "out:DH kr savings": 316423,
    "out:El kr savings": -92359,
    "out:El kr sold": 7352,
    "out:El kr saved": 54973,
    "out:El kr return": 62324,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 235752,
    "out:% savings (space heating)": 45.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.06,
    "out:Etvv": 0,
    "out:Ef": 31.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.78,
    "out:Electricity (inc PV)": 31.73,
    "out:Total Energy Use Pre PV": 107.78,
    "out:Total Energy Use Post PV": 96.73,
    "out:Primary Energy": 95.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.32,
    "out:CO2 Operational/m2": 19.5,
    "out:Total CO2/m2": 43.83,
    "out:Total CO2 (tons)": 149.41,
    "out:Klimatpaverkan": -67.59,
    "out:Initial Cost/MSEK": 5.719875,
    "out:Running cost/(Apt SEK y)": 20755,
    "out:Running Cost over RSP/MSEK": 6,
    "out:LCP/MSEK": 0.983,
    "out:ROI% old": 16.74,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2355.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5791.91,
    "out:PV (% sold (El))": 15.2,
    "out:PV (kWh self-consumed)": 32310.98,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -53970,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 244467,
    "out:DH kr savings": 328832,
    "out:El kr savings": -91750,
    "out:El kr sold": 7385,
    "out:El kr saved": 54929,
    "out:El kr return": 62313,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219929,
    "out:% savings (space heating)": 49.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.56,
    "out:Etvv": 0,
    "out:Ef": 31.73,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.49,
    "out:Electricity (inc PV)": 29.12,
    "out:Total Energy Use Pre PV": 117.49,
    "out:Total Energy Use Post PV": 103.12,
    "out:Primary Energy": 95.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.18,
    "out:CO2 Operational/m2": -17.2,
    "out:Total CO2/m2": 18.98,
    "out:Total CO2 (tons)": 64.71,
    "out:Klimatpaverkan": -152.29,
    "out:Initial Cost/MSEK": 6.138875,
    "out:Running cost/(Apt SEK y)": 21602,
    "out:Running Cost over RSP/MSEK": 6.256,
    "out:LCP/MSEK": 0.308,
    "out:ROI% old": 15,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 2167.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32485.54,
    "out:PV (% sold (El))": 42.63,
    "out:PV (kWh self-consumed)": 43720.25,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 330673,
    "out:EL kWh savings": -45079,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265698,
    "out:DH kr savings": 300912,
    "out:El kr savings": -76634,
    "out:El kr sold": 41419,
    "out:El kr saved": 74324,
    "out:El kr return": 115743,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 250848,
    "out:% savings (space heating)": 42.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 61.39,
    "out:Etvv": 0,
    "out:Ef": 29.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 29.04,
    "out:Total Energy Use Pre PV": 112.42,
    "out:Total Energy Use Post PV": 98.04,
    "out:Primary Energy": 92.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.18,
    "out:CO2 Operational/m2": -19.07,
    "out:Total CO2/m2": 17.11,
    "out:Total CO2 (tons)": 58.34,
    "out:Klimatpaverkan": -158.66,
    "out:Initial Cost/MSEK": 6.224125,
    "out:Running cost/(Apt SEK y)": 20382,
    "out:Running Cost over RSP/MSEK": 5.903,
    "out:LCP/MSEK": 0.576,
    "out:ROI% old": 15.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2163.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32526.1,
    "out:PV (% sold (El))": 42.68,
    "out:PV (kWh self-consumed)": 43679.68,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -44786,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281758,
    "out:DH kr savings": 316423,
    "out:El kr savings": -76136,
    "out:El kr sold": 41471,
    "out:El kr saved": 74255,
    "out:El kr return": 115726,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 234878,
    "out:% savings (space heating)": 45.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.86,
    "out:Etvv": 0,
    "out:Ef": 29.04,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.85,
    "out:Electricity (inc PV)": 28.78,
    "out:Total Energy Use Pre PV": 111.85,
    "out:Total Energy Use Post PV": 97.78,
    "out:Primary Energy": 93.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.68,
    "out:CO2 Operational/m2": -19.7,
    "out:Total CO2/m2": 16.98,
    "out:Total CO2 (tons)": 57.87,
    "out:Klimatpaverkan": -159.13,
    "out:Initial Cost/MSEK": 6.343875,
    "out:Running cost/(Apt SEK y)": 20297,
    "out:Running Cost over RSP/MSEK": 5.878,
    "out:LCP/MSEK": 0.48,
    "out:ROI% old": 15.37,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2131.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 873,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32906.72,
    "out:PV (% sold (El))": 43.18,
    "out:PV (kWh self-consumed)": 43299.06,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -43907,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 283738,
    "out:DH kr savings": 316423,
    "out:El kr savings": -74641,
    "out:El kr sold": 41956,
    "out:El kr saved": 73608,
    "out:El kr return": 115564,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 235752,
    "out:% savings (space heating)": 45.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 59.06,
    "out:Etvv": 0,
    "out:Ef": 28.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.78,
    "out:Electricity (inc PV)": 28.69,
    "out:Total Energy Use Pre PV": 107.78,
    "out:Total Energy Use Post PV": 93.69,
    "out:Primary Energy": 89.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.68,
    "out:CO2 Operational/m2": -21.22,
    "out:Total CO2/m2": 15.45,
    "out:Total CO2 (tons)": 52.68,
    "out:Klimatpaverkan": -164.32,
    "out:Initial Cost/MSEK": 6.429,
    "out:Running cost/(Apt SEK y)": 19317,
    "out:Running Cost over RSP/MSEK": 5.594,
    "out:LCP/MSEK": 0.679,
    "out:ROI% old": 15.79,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2127,
    "out:Return %": 14,
    "out:Return/kSEK/y": 910,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.58,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32956.45,
    "out:PV (% sold (El))": 43.25,
    "out:PV (kWh self-consumed)": 43249.33,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -43585,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 296756,
    "out:DH kr savings": 328832,
    "out:El kr savings": -74095,
    "out:El kr sold": 42019,
    "out:El kr saved": 73524,
    "out:El kr return": 115543,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219929,
    "out:% savings (space heating)": 49.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.56,
    "out:Etvv": 0,
    "out:Ef": 28.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.63,
    "out:Electricity (inc PV)": 41,
    "out:Total Energy Use Pre PV": 110.63,
    "out:Total Energy Use Post PV": 109,
    "out:Primary Energy": 114.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.47,
    "out:CO2 Operational/m2": 30.11,
    "out:Total CO2/m2": 41.58,
    "out:Total CO2 (tons)": 141.75,
    "out:Klimatpaverkan": -75.25,
    "out:Initial Cost/MSEK": 4.818375,
    "out:Running cost/(Apt SEK y)": 23143,
    "out:Running Cost over RSP/MSEK": 6.678,
    "out:LCP/MSEK": 1.206,
    "out:ROI% old": 17.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2984.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 768,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 351127,
    "out:EL kWh savings": -85559,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174075,
    "out:DH kr savings": 319526,
    "out:El kr savings": -145451,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 230817,
    "out:% savings (space heating)": 46.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 58.29,
    "out:Etvv": 0,
    "out:Ef": 41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.55,
    "out:Electricity (inc PV)": 40.88,
    "out:Total Energy Use Pre PV": 105.55,
    "out:Total Energy Use Post PV": 103.88,
    "out:Primary Energy": 111.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.47,
    "out:CO2 Operational/m2": 28.3,
    "out:Total CO2/m2": 39.77,
    "out:Total CO2 (tons)": 135.58,
    "out:Klimatpaverkan": -81.42,
    "out:Initial Cost/MSEK": 4.9035,
    "out:Running cost/(Apt SEK y)": 21920,
    "out:Running Cost over RSP/MSEK": 6.324,
    "out:LCP/MSEK": 1.475,
    "out:ROI% old": 18.58,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2978.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 813,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -85146,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190288,
    "out:DH kr savings": 335037,
    "out:El kr savings": -144749,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 215053,
    "out:% savings (space heating)": 50.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 53.8,
    "out:Etvv": 0,
    "out:Ef": 40.88,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.08,
    "out:Electricity (inc PV)": 40.64,
    "out:Total Energy Use Pre PV": 106.08,
    "out:Total Energy Use Post PV": 104.64,
    "out:Primary Energy": 112.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.97,
    "out:CO2 Operational/m2": 28.59,
    "out:Total CO2/m2": 40.56,
    "out:Total CO2 (tons)": 138.28,
    "out:Klimatpaverkan": -78.72,
    "out:Initial Cost/MSEK": 5.02325,
    "out:Running cost/(Apt SEK y)": 22088,
    "out:Running Cost over RSP/MSEK": 6.373,
    "out:LCP/MSEK": 1.306,
    "out:ROI% old": 18,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2945.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364763,
    "out:EL kWh savings": -84345,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188547,
    "out:DH kr savings": 331934,
    "out:El kr savings": -143387,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219211,
    "out:% savings (space heating)": 49.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.47,
    "out:Etvv": 0,
    "out:Ef": 40.64,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.98,
    "out:Electricity (inc PV)": 40.51,
    "out:Total Energy Use Pre PV": 101.98,
    "out:Total Energy Use Post PV": 100.51,
    "out:Primary Energy": 109.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 11.97,
    "out:CO2 Operational/m2": 27.14,
    "out:Total CO2/m2": 39.11,
    "out:Total CO2 (tons)": 133.33,
    "out:Klimatpaverkan": -83.67,
    "out:Initial Cost/MSEK": 5.1085,
    "out:Running cost/(Apt SEK y)": 21105,
    "out:Running Cost over RSP/MSEK": 6.088,
    "out:LCP/MSEK": 1.506,
    "out:ROI% old": 18.5,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2938.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -83903,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201708,
    "out:DH kr savings": 344343,
    "out:El kr savings": -142635,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 203620,
    "out:% savings (space heating)": 53.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.01,
    "out:Etvv": 0,
    "out:Ef": 40.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.63,
    "out:Electricity (inc PV)": 31.71,
    "out:Total Energy Use Pre PV": 110.63,
    "out:Total Energy Use Post PV": 99.71,
    "out:Primary Energy": 97.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.83,
    "out:CO2 Operational/m2": 20.49,
    "out:Total CO2/m2": 44.32,
    "out:Total CO2 (tons)": 151.08,
    "out:Klimatpaverkan": -65.92,
    "out:Initial Cost/MSEK": 5.527375,
    "out:Running cost/(Apt SEK y)": 21460,
    "out:Running Cost over RSP/MSEK": 6.204,
    "out:LCP/MSEK": 0.971,
    "out:ROI% old": 16.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2346.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 830,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5842.68,
    "out:PV (% sold (El))": 15.33,
    "out:PV (kWh self-consumed)": 32260.21,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 351127,
    "out:EL kWh savings": -53884,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235371,
    "out:DH kr savings": 319526,
    "out:El kr savings": -91604,
    "out:El kr sold": 7449,
    "out:El kr saved": 54842,
    "out:El kr return": 62292,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 230817,
    "out:% savings (space heating)": 46.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 58.29,
    "out:Etvv": 0,
    "out:Ef": 31.71,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.55,
    "out:Electricity (inc PV)": 31.6,
    "out:Total Energy Use Pre PV": 105.55,
    "out:Total Energy Use Post PV": 94.6,
    "out:Primary Energy": 94.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.83,
    "out:CO2 Operational/m2": 18.64,
    "out:Total CO2/m2": 42.47,
    "out:Total CO2 (tons)": 144.77,
    "out:Klimatpaverkan": -72.23,
    "out:Initial Cost/MSEK": 5.612625,
    "out:Running cost/(Apt SEK y)": 20237,
    "out:Running Cost over RSP/MSEK": 5.85,
    "out:LCP/MSEK": 1.24,
    "out:ROI% old": 17.44,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2341.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 875,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5870.59,
    "out:PV (% sold (El))": 15.41,
    "out:PV (kWh self-consumed)": 32232.3,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -53517,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 251543,
    "out:DH kr savings": 335037,
    "out:El kr savings": -90978,
    "out:El kr sold": 7485,
    "out:El kr saved": 54795,
    "out:El kr return": 62280,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 215053,
    "out:% savings (space heating)": 50.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 53.8,
    "out:Etvv": 0,
    "out:Ef": 31.6,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.08,
    "out:Electricity (inc PV)": 31.39,
    "out:Total Energy Use Pre PV": 106.08,
    "out:Total Energy Use Post PV": 95.39,
    "out:Primary Energy": 96.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.32,
    "out:CO2 Operational/m2": 18.7,
    "out:Total CO2/m2": 43.02,
    "out:Total CO2 (tons)": 146.66,
    "out:Klimatpaverkan": -70.34,
    "out:Initial Cost/MSEK": 5.732375,
    "out:Running cost/(Apt SEK y)": 20407,
    "out:Running Cost over RSP/MSEK": 5.899,
    "out:LCP/MSEK": 1.071,
    "out:ROI% old": 16.95,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2311.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 869,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6036.53,
    "out:PV (% sold (El))": 15.84,
    "out:PV (kWh self-consumed)": 32066.36,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364763,
    "out:EL kWh savings": -52803,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249865,
    "out:DH kr savings": 331934,
    "out:El kr savings": -89766,
    "out:El kr sold": 7697,
    "out:El kr saved": 54513,
    "out:El kr return": 62209,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219211,
    "out:% savings (space heating)": 49.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.47,
    "out:Etvv": 0,
    "out:Ef": 31.39,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.98,
    "out:Electricity (inc PV)": 31.27,
    "out:Total Energy Use Pre PV": 101.98,
    "out:Total Energy Use Post PV": 91.27,
    "out:Primary Energy": 92.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.32,
    "out:CO2 Operational/m2": 17.19,
    "out:Total CO2/m2": 41.52,
    "out:Total CO2 (tons)": 141.54,
    "out:Klimatpaverkan": -75.46,
    "out:Initial Cost/MSEK": 5.817625,
    "out:Running cost/(Apt SEK y)": 19424,
    "out:Running Cost over RSP/MSEK": 5.615,
    "out:LCP/MSEK": 1.27,
    "out:ROI% old": 17.4,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2305.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6070.32,
    "out:PV (% sold (El))": 15.93,
    "out:PV (kWh self-consumed)": 32032.57,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -52410,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 262986,
    "out:DH kr savings": 344343,
    "out:El kr savings": -89096,
    "out:El kr sold": 7740,
    "out:El kr saved": 54455,
    "out:El kr return": 62195,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 203620,
    "out:% savings (space heating)": 53.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.01,
    "out:Etvv": 0,
    "out:Ef": 31.27,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.63,
    "out:Electricity (inc PV)": 28.66,
    "out:Total Energy Use Pre PV": 110.63,
    "out:Total Energy Use Post PV": 96.66,
    "out:Primary Energy": 92.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.18,
    "out:CO2 Operational/m2": -20.31,
    "out:Total CO2/m2": 15.87,
    "out:Total CO2 (tons)": 54.12,
    "out:Klimatpaverkan": -162.88,
    "out:Initial Cost/MSEK": 6.2365,
    "out:Running cost/(Apt SEK y)": 20022,
    "out:Running Cost over RSP/MSEK": 5.799,
    "out:LCP/MSEK": 0.667,
    "out:ROI% old": 15.81,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 2118.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 883,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.43,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33053.77,
    "out:PV (% sold (El))": 43.37,
    "out:PV (kWh self-consumed)": 43152.02,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 351127,
    "out:EL kWh savings": -43508,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 287705,
    "out:DH kr savings": 319526,
    "out:El kr savings": -73964,
    "out:El kr sold": 42144,
    "out:El kr saved": 73358,
    "out:El kr return": 115502,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 230817,
    "out:% savings (space heating)": 46.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 58.29,
    "out:Etvv": 0,
    "out:Ef": 28.66,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.55,
    "out:Electricity (inc PV)": 28.57,
    "out:Total Energy Use Pre PV": 105.55,
    "out:Total Energy Use Post PV": 91.57,
    "out:Primary Energy": 89.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.18,
    "out:CO2 Operational/m2": -22.19,
    "out:Total CO2/m2": 13.99,
    "out:Total CO2 (tons)": 47.68,
    "out:Klimatpaverkan": -169.32,
    "out:Initial Cost/MSEK": 6.32175,
    "out:Running cost/(Apt SEK y)": 18799,
    "out:Running Cost over RSP/MSEK": 5.445,
    "out:LCP/MSEK": 0.936,
    "out:ROI% old": 16.4,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2114.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 929,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33107.14,
    "out:PV (% sold (El))": 43.44,
    "out:PV (kWh self-consumed)": 43098.64,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -43178,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303845,
    "out:DH kr savings": 335037,
    "out:El kr savings": -73403,
    "out:El kr sold": 42212,
    "out:El kr saved": 73268,
    "out:El kr return": 115479,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 215053,
    "out:% savings (space heating)": 50.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 53.8,
    "out:Etvv": 0,
    "out:Ef": 28.57,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.08,
    "out:Electricity (inc PV)": 28.38,
    "out:Total Energy Use Pre PV": 106.08,
    "out:Total Energy Use Post PV": 92.38,
    "out:Primary Energy": 90.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.68,
    "out:CO2 Operational/m2": -22.36,
    "out:Total CO2/m2": 14.32,
    "out:Total CO2 (tons)": 48.81,
    "out:Klimatpaverkan": -168.19,
    "out:Initial Cost/MSEK": 6.4415,
    "out:Running cost/(Apt SEK y)": 18971,
    "out:Running Cost over RSP/MSEK": 5.495,
    "out:LCP/MSEK": 0.766,
    "out:ROI% old": 15.98,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2087.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 922,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33422.49,
    "out:PV (% sold (El))": 43.86,
    "out:PV (kWh self-consumed)": 42783.29,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 364763,
    "out:EL kWh savings": -42538,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 302234,
    "out:DH kr savings": 331934,
    "out:El kr savings": -72315,
    "out:El kr sold": 42614,
    "out:El kr saved": 72732,
    "out:El kr return": 115345,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219211,
    "out:% savings (space heating)": 49.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 56.47,
    "out:Etvv": 0,
    "out:Ef": 28.38,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.98,
    "out:Electricity (inc PV)": 28.27,
    "out:Total Energy Use Pre PV": 101.98,
    "out:Total Energy Use Post PV": 88.27,
    "out:Primary Energy": 87.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.68,
    "out:CO2 Operational/m2": -23.91,
    "out:Total CO2/m2": 12.77,
    "out:Total CO2 (tons)": 43.54,
    "out:Klimatpaverkan": -173.46,
    "out:Initial Cost/MSEK": 6.52675,
    "out:Running cost/(Apt SEK y)": 17988,
    "out:Running Cost over RSP/MSEK": 5.21,
    "out:LCP/MSEK": 0.965,
    "out:ROI% old": 16.4,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2082.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.78,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33486.46,
    "out:PV (% sold (El))": 43.94,
    "out:PV (kWh self-consumed)": 42719.32,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -42185,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 315325,
    "out:DH kr savings": 344343,
    "out:El kr savings": -71714,
    "out:El kr sold": 42695,
    "out:El kr saved": 72623,
    "out:El kr return": 115318,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 203620,
    "out:% savings (space heating)": 53.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.01,
    "out:Etvv": 0,
    "out:Ef": 28.27,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.56,
    "out:Electricity (inc PV)": 56.73,
    "out:Total Energy Use Pre PV": 77.56,
    "out:Total Energy Use Post PV": 73.73,
    "out:Primary Energy": 110.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.07,
    "out:CO2 Operational/m2": 14.11,
    "out:Total CO2/m2": 26.18,
    "out:Total CO2 (tons)": 89.25,
    "out:Klimatpaverkan": -127.75,
    "out:Initial Cost/MSEK": 6.576875,
    "out:Running cost/(Apt SEK y)": 13602,
    "out:Running Cost over RSP/MSEK": 3.905,
    "out:LCP/MSEK": 2.22,
    "out:ROI% old": 19.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 4239.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1121,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 524986,
    "out:EL kWh savings": -139188,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241117,
    "out:DH kr savings": 477737,
    "out:El kr savings": -236620,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 58749,
    "out:% savings (space heating)": 86.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.38,
    "out:Etvv": 0,
    "out:Ef": 56.73,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.02,
    "out:Electricity (inc PV)": 56.03,
    "out:Total Energy Use Pre PV": 74.02,
    "out:Total Energy Use Post PV": 70.03,
    "out:Primary Energy": 107.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.07,
    "out:CO2 Operational/m2": 12.96,
    "out:Total CO2/m2": 25.03,
    "out:Total CO2 (tons)": 85.33,
    "out:Klimatpaverkan": -131.67,
    "out:Initial Cost/MSEK": 6.662125,
    "out:Running cost/(Apt SEK y)": 12791,
    "out:Running Cost over RSP/MSEK": 3.671,
    "out:LCP/MSEK": 2.369,
    "out:ROI% old": 19.37,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4201.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1151,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -136789,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254503,
    "out:DH kr savings": 487044,
    "out:El kr savings": -232541,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48169,
    "out:% savings (space heating)": 88.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.81,
    "out:Etvv": 0,
    "out:Ef": 56.03,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.26,
    "out:Electricity (inc PV)": 55.91,
    "out:Total Energy Use Pre PV": 74.26,
    "out:Total Energy Use Post PV": 70.91,
    "out:Primary Energy": 107.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.56,
    "out:CO2 Operational/m2": 13.22,
    "out:Total CO2/m2": 25.78,
    "out:Total CO2 (tons)": 87.9,
    "out:Klimatpaverkan": -129.1,
    "out:Initial Cost/MSEK": 6.781875,
    "out:Running cost/(Apt SEK y)": 12913,
    "out:Running Cost over RSP/MSEK": 3.707,
    "out:LCP/MSEK": 2.214,
    "out:ROI% old": 18.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 163.4,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 4147.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1146,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 531804,
    "out:EL kWh savings": -136377,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252100,
    "out:DH kr savings": 483942,
    "out:El kr savings": -231842,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 51200,
    "out:% savings (space heating)": 88.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.35,
    "out:Etvv": 0,
    "out:Ef": 55.91,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.66,
    "out:Electricity (inc PV)": 55.16,
    "out:Total Energy Use Pre PV": 70.66,
    "out:Total Energy Use Post PV": 67.16,
    "out:Primary Energy": 104.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.56,
    "out:CO2 Operational/m2": 12.06,
    "out:Total CO2/m2": 24.63,
    "out:Total CO2 (tons)": 83.95,
    "out:Klimatpaverkan": -133.05,
    "out:Initial Cost/MSEK": 6.867,
    "out:Running cost/(Apt SEK y)": 12094,
    "out:Running Cost over RSP/MSEK": 3.469,
    "out:LCP/MSEK": 2.366,
    "out:ROI% old": 19.21,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4106.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1177,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -133853,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265697,
    "out:DH kr savings": 493248,
    "out:El kr savings": -227551,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 41240,
    "out:% savings (space heating)": 90.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 55.16,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.56,
    "out:Electricity (inc PV)": 46.17,
    "out:Total Energy Use Pre PV": 77.56,
    "out:Total Energy Use Post PV": 63.17,
    "out:Primary Energy": 91.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.42,
    "out:CO2 Operational/m2": 10.73,
    "out:Total CO2/m2": 35.15,
    "out:Total CO2 (tons)": 119.83,
    "out:Klimatpaverkan": -97.17,
    "out:Initial Cost/MSEK": 7.286,
    "out:Running cost/(Apt SEK y)": 11868,
    "out:Running Cost over RSP/MSEK": 3.417,
    "out:LCP/MSEK": 1.999,
    "out:ROI% old": 18.2,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3525.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1185,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1470.38,
    "out:PV (% sold (El))": 3.86,
    "out:PV (kWh self-consumed)": 36632.51,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 524986,
    "out:EL kWh savings": -103206,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304162,
    "out:DH kr savings": 477737,
    "out:El kr savings": -175450,
    "out:El kr sold": 1875,
    "out:El kr saved": 62275,
    "out:El kr return": 64150,
    "out:% solar/total": 68,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 58749,
    "out:% savings (space heating)": 86.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.38,
    "out:Etvv": 0,
    "out:Ef": 46.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.02,
    "out:Electricity (inc PV)": 45.51,
    "out:Total Energy Use Pre PV": 74.02,
    "out:Total Energy Use Post PV": 59.51,
    "out:Primary Energy": 88.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.42,
    "out:CO2 Operational/m2": 9.46,
    "out:Total CO2/m2": 33.88,
    "out:Total CO2 (tons)": 115.51,
    "out:Klimatpaverkan": -101.49,
    "out:Initial Cost/MSEK": 7.37125,
    "out:Running cost/(Apt SEK y)": 11058,
    "out:Running Cost over RSP/MSEK": 3.183,
    "out:LCP/MSEK": 2.148,
    "out:ROI% old": 18.45,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3489.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1215,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1554.69,
    "out:PV (% sold (El))": 4.08,
    "out:PV (kWh self-consumed)": 36548.21,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -100940,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317428,
    "out:DH kr savings": 487044,
    "out:El kr savings": -171598,
    "out:El kr sold": 1982,
    "out:El kr saved": 62132,
    "out:El kr return": 64114,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48169,
    "out:% savings (space heating)": 88.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.81,
    "out:Etvv": 0,
    "out:Ef": 45.51,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.26,
    "out:Electricity (inc PV)": 45.4,
    "out:Total Energy Use Pre PV": 74.26,
    "out:Total Energy Use Post PV": 60.4,
    "out:Primary Energy": 88.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 9.55,
    "out:Total CO2/m2": 34.47,
    "out:Total CO2 (tons)": 117.49,
    "out:Klimatpaverkan": -99.51,
    "out:Initial Cost/MSEK": 7.490875,
    "out:Running cost/(Apt SEK y)": 11182,
    "out:Running Cost over RSP/MSEK": 3.219,
    "out:LCP/MSEK": 1.992,
    "out:ROI% old": 18.08,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3437.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1210,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1678.57,
    "out:PV (% sold (El))": 4.41,
    "out:PV (kWh self-consumed)": 36424.32,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 531804,
    "out:EL kWh savings": -100553,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315142,
    "out:DH kr savings": 483942,
    "out:El kr savings": -170940,
    "out:El kr sold": 2140,
    "out:El kr saved": 61921,
    "out:El kr return": 64062,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 51200,
    "out:% savings (space heating)": 88.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.35,
    "out:Etvv": 0,
    "out:Ef": 45.4,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.66,
    "out:Electricity (inc PV)": 44.7,
    "out:Total Energy Use Pre PV": 70.66,
    "out:Total Energy Use Post PV": 56.7,
    "out:Primary Energy": 85.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 8.25,
    "out:Total CO2/m2": 33.17,
    "out:Total CO2 (tons)": 113.07,
    "out:Klimatpaverkan": -103.93,
    "out:Initial Cost/MSEK": 7.576125,
    "out:Running cost/(Apt SEK y)": 10363,
    "out:Running Cost over RSP/MSEK": 2.982,
    "out:LCP/MSEK": 2.144,
    "out:ROI% old": 18.33,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3397.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1241,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1777.76,
    "out:PV (% sold (El))": 4.67,
    "out:PV (kWh self-consumed)": 36325.13,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -98176,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328616,
    "out:DH kr savings": 493248,
    "out:El kr savings": -166899,
    "out:El kr sold": 2267,
    "out:El kr saved": 61753,
    "out:El kr return": 64019,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 41240,
    "out:% savings (space heating)": 90.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 44.7,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.56,
    "out:Electricity (inc PV)": 41.56,
    "out:Total Energy Use Pre PV": 77.56,
    "out:Total Energy Use Post PV": 58.56,
    "out:Primary Energy": 82.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.77,
    "out:CO2 Operational/m2": -20.71,
    "out:Total CO2/m2": 16.07,
    "out:Total CO2 (tons)": 54.77,
    "out:Klimatpaverkan": -162.23,
    "out:Initial Cost/MSEK": 7.995125,
    "out:Running cost/(Apt SEK y)": 10358,
    "out:Running Cost over RSP/MSEK": 2.992,
    "out:LCP/MSEK": 1.716,
    "out:ROI% old": 17.35,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3165.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1241,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 42.36,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22416.01,
    "out:PV (% sold (El))": 29.42,
    "out:PV (kWh self-consumed)": 53789.78,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 524986,
    "out:EL kWh savings": -87475,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 357610,
    "out:DH kr savings": 477737,
    "out:El kr savings": -148707,
    "out:El kr sold": 28580,
    "out:El kr saved": 91443,
    "out:El kr return": 120023,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 58749,
    "out:% savings (space heating)": 86.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 11.38,
    "out:Etvv": 0,
    "out:Ef": 41.56,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.02,
    "out:Electricity (inc PV)": 40.97,
    "out:Total Energy Use Pre PV": 74.02,
    "out:Total Energy Use Post PV": 54.97,
    "out:Primary Energy": 79.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.77,
    "out:CO2 Operational/m2": -22.28,
    "out:Total CO2/m2": 14.5,
    "out:Total CO2 (tons)": 49.42,
    "out:Klimatpaverkan": -167.58,
    "out:Initial Cost/MSEK": 8.080375,
    "out:Running cost/(Apt SEK y)": 9551,
    "out:Running Cost over RSP/MSEK": 2.758,
    "out:LCP/MSEK": 1.864,
    "out:ROI% old": 17.58,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3133.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1271,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22701.92,
    "out:PV (% sold (El))": 29.79,
    "out:PV (kWh self-consumed)": 53503.86,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -85467,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 370695,
    "out:DH kr savings": 487044,
    "out:El kr savings": -145293,
    "out:El kr sold": 28945,
    "out:El kr saved": 90957,
    "out:El kr return": 119902,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48169,
    "out:% savings (space heating)": 88.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.81,
    "out:Etvv": 0,
    "out:Ef": 40.97,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 15.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.26,
    "out:Electricity (inc PV)": 40.87,
    "out:Total Energy Use Pre PV": 74.26,
    "out:Total Energy Use Post PV": 55.87,
    "out:Primary Energy": 80.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.27,
    "out:CO2 Operational/m2": -22.62,
    "out:Total CO2/m2": 14.65,
    "out:Total CO2 (tons)": 49.95,
    "out:Klimatpaverkan": -167.05,
    "out:Initial Cost/MSEK": 8.200125,
    "out:Running cost/(Apt SEK y)": 9677,
    "out:Running Cost over RSP/MSEK": 2.795,
    "out:LCP/MSEK": 1.707,
    "out:ROI% old": 17.26,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 15,
    "out:El bought/kWh/m2": 3087.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1266,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 41.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23110.95,
    "out:PV (% sold (El))": 30.33,
    "out:PV (kWh self-consumed)": 53094.83,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 531804,
    "out:EL kWh savings": -85123,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368699,
    "out:DH kr savings": 483942,
    "out:El kr savings": -144709,
    "out:El kr sold": 29466,
    "out:El kr saved": 90261,
    "out:El kr return": 119728,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 51200,
    "out:% savings (space heating)": 88.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.35,
    "out:Etvv": 0,
    "out:Ef": 40.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.66,
    "out:Electricity (inc PV)": 40.25,
    "out:Total Energy Use Pre PV": 70.66,
    "out:Total Energy Use Post PV": 52.25,
    "out:Primary Energy": 77.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.27,
    "out:CO2 Operational/m2": -24.24,
    "out:Total CO2/m2": 13.02,
    "out:Total CO2 (tons)": 44.4,
    "out:Klimatpaverkan": -172.6,
    "out:Initial Cost/MSEK": 8.28525,
    "out:Running cost/(Apt SEK y)": 8861,
    "out:Running Cost over RSP/MSEK": 2.559,
    "out:LCP/MSEK": 1.858,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3052.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1296,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23433.68,
    "out:PV (% sold (El))": 30.75,
    "out:PV (kWh self-consumed)": 52772.1,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -83015,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382001,
    "out:DH kr savings": 493248,
    "out:El kr savings": -141125,
    "out:El kr sold": 29878,
    "out:El kr saved": 89713,
    "out:El kr return": 119591,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 41240,
    "out:% savings (space heating)": 90.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.9,
    "out:Etvv": 0,
    "out:Ef": 40.25,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.81,
    "out:Electricity (inc PV)": 55.63,
    "out:Total Energy Use Pre PV": 72.81,
    "out:Total Energy Use Post PV": 69.63,
    "out:Primary Energy": 107.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.07,
    "out:CO2 Operational/m2": 12.8,
    "out:Total CO2/m2": 24.87,
    "out:Total CO2 (tons)": 84.78,
    "out:Klimatpaverkan": -132.22,
    "out:Initial Cost/MSEK": 6.674625,
    "out:Running cost/(Apt SEK y)": 12601,
    "out:Running Cost over RSP/MSEK": 3.616,
    "out:LCP/MSEK": 2.411,
    "out:ROI% old": 19.45,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4116.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1158,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -135427,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256817,
    "out:DH kr savings": 487044,
    "out:El kr savings": -230227,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48804,
    "out:% savings (space heating)": 88.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.02,
    "out:Etvv": 0,
    "out:Ef": 55.63,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.19,
    "out:Electricity (inc PV)": 54.87,
    "out:Total Energy Use Pre PV": 69.19,
    "out:Total Energy Use Post PV": 65.87,
    "out:Primary Energy": 104.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.07,
    "out:CO2 Operational/m2": 11.64,
    "out:Total CO2/m2": 23.71,
    "out:Total CO2 (tons)": 80.82,
    "out:Klimatpaverkan": -136.18,
    "out:Initial Cost/MSEK": 6.75975,
    "out:Running cost/(Apt SEK y)": 11779,
    "out:Running Cost over RSP/MSEK": 3.378,
    "out:LCP/MSEK": 2.564,
    "out:ROI% old": 19.7,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 4073.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1188,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 545440,
    "out:EL kWh savings": -132860,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270488,
    "out:DH kr savings": 496350,
    "out:El kr savings": -225862,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39065,
    "out:% savings (space heating)": 91.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.62,
    "out:Etvv": 0,
    "out:Ef": 54.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.72,
    "out:Electricity (inc PV)": 54.95,
    "out:Total Energy Use Pre PV": 70.72,
    "out:Total Energy Use Post PV": 67.95,
    "out:Primary Energy": 105.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.56,
    "out:CO2 Operational/m2": 12.3,
    "out:Total CO2/m2": 24.86,
    "out:Total CO2 (tons)": 84.75,
    "out:Klimatpaverkan": -132.25,
    "out:Initial Cost/MSEK": 6.8795,
    "out:Running cost/(Apt SEK y)": 12188,
    "out:Running Cost over RSP/MSEK": 3.497,
    "out:LCP/MSEK": 2.325,
    "out:ROI% old": 19.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 175,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 4040.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538622,
    "out:EL kWh savings": -133122,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263838,
    "out:DH kr savings": 490146,
    "out:El kr savings": -226308,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 43308,
    "out:% savings (space heating)": 90.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.25,
    "out:Etvv": 0,
    "out:Ef": 54.95,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.05,
    "out:Electricity (inc PV)": 54.17,
    "out:Total Energy Use Pre PV": 67.05,
    "out:Total Energy Use Post PV": 64.17,
    "out:Primary Energy": 102.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.56,
    "out:CO2 Operational/m2": 11.13,
    "out:Total CO2/m2": 23.69,
    "out:Total CO2 (tons)": 80.77,
    "out:Klimatpaverkan": -136.23,
    "out:Initial Cost/MSEK": 6.96475,
    "out:Running cost/(Apt SEK y)": 11358,
    "out:Running Cost over RSP/MSEK": 3.257,
    "out:LCP/MSEK": 2.48,
    "out:ROI% old": 19.37,
    "out:Payback discounted": 6,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3993.8,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1204,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -130446,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 277695,
    "out:DH kr savings": 499453,
    "out:El kr savings": -221758,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 34095,
    "out:% savings (space heating)": 92.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 6.95,
    "out:Etvv": 0,
    "out:Ef": 54.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.81,
    "out:Electricity (inc PV)": 45.13,
    "out:Total Energy Use Pre PV": 72.81,
    "out:Total Energy Use Post PV": 59.13,
    "out:Primary Energy": 88.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.42,
    "out:CO2 Operational/m2": 9.03,
    "out:Total CO2/m2": 33.45,
    "out:Total CO2 (tons)": 114.02,
    "out:Klimatpaverkan": -102.98,
    "out:Initial Cost/MSEK": 7.383625,
    "out:Running cost/(Apt SEK y)": 10871,
    "out:Running Cost over RSP/MSEK": 3.129,
    "out:LCP/MSEK": 2.189,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3407.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1222,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1752.59,
    "out:PV (% sold (El))": 4.6,
    "out:PV (kWh self-consumed)": 36350.3,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -99658,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 319861,
    "out:DH kr savings": 487044,
    "out:El kr savings": -169418,
    "out:El kr sold": 2235,
    "out:El kr saved": 61796,
    "out:El kr return": 64030,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48804,
    "out:% savings (space heating)": 88.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.02,
    "out:Etvv": 0,
    "out:Ef": 45.13,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.19,
    "out:Electricity (inc PV)": 44.43,
    "out:Total Energy Use Pre PV": 69.19,
    "out:Total Energy Use Post PV": 55.43,
    "out:Primary Energy": 85.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.42,
    "out:CO2 Operational/m2": 7.72,
    "out:Total CO2/m2": 32.14,
    "out:Total CO2 (tons)": 109.55,
    "out:Klimatpaverkan": -107.45,
    "out:Initial Cost/MSEK": 7.468875,
    "out:Running cost/(Apt SEK y)": 10049,
    "out:Running Cost over RSP/MSEK": 2.892,
    "out:LCP/MSEK": 2.342,
    "out:ROI% old": 18.76,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3366.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1252,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1857.25,
    "out:PV (% sold (El))": 4.87,
    "out:PV (kWh self-consumed)": 36245.64,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 545440,
    "out:EL kWh savings": -97242,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333407,
    "out:DH kr savings": 496350,
    "out:El kr savings": -165311,
    "out:El kr sold": 2368,
    "out:El kr saved": 61618,
    "out:El kr return": 63986,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39065,
    "out:% savings (space heating)": 91.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.62,
    "out:Etvv": 0,
    "out:Ef": 44.43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.72,
    "out:Electricity (inc PV)": 44.5,
    "out:Total Energy Use Pre PV": 70.72,
    "out:Total Energy Use Post PV": 57.5,
    "out:Primary Energy": 86.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 8.26,
    "out:Total CO2/m2": 33.17,
    "out:Total CO2 (tons)": 113.08,
    "out:Klimatpaverkan": -103.92,
    "out:Initial Cost/MSEK": 7.588625,
    "out:Running cost/(Apt SEK y)": 10460,
    "out:Running Cost over RSP/MSEK": 3.011,
    "out:LCP/MSEK": 2.103,
    "out:ROI% old": 18.24,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 3334,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1237,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1940.88,
    "out:PV (% sold (El))": 5.09,
    "out:PV (kWh self-consumed)": 36162.01,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 538622,
    "out:EL kWh savings": -97488,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 326891,
    "out:DH kr savings": 490146,
    "out:El kr savings": -165730,
    "out:El kr sold": 2475,
    "out:El kr saved": 61475,
    "out:El kr return": 63950,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 43308,
    "out:% savings (space heating)": 90.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.25,
    "out:Etvv": 0,
    "out:Ef": 44.5,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.05,
    "out:Electricity (inc PV)": 43.76,
    "out:Total Energy Use Pre PV": 67.05,
    "out:Total Energy Use Post PV": 53.76,
    "out:Primary Energy": 83.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.92,
    "out:CO2 Operational/m2": 6.92,
    "out:Total CO2/m2": 31.84,
    "out:Total CO2 (tons)": 108.53,
    "out:Klimatpaverkan": -108.47,
    "out:Initial Cost/MSEK": 7.673875,
    "out:Running cost/(Apt SEK y)": 9631,
    "out:Running Cost over RSP/MSEK": 2.771,
    "out:LCP/MSEK": 2.257,
    "out:ROI% old": 18.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3289.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1268,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2060.67,
    "out:PV (% sold (El))": 5.41,
    "out:PV (kWh self-consumed)": 36042.22,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -94975,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 340622,
    "out:DH kr savings": 499453,
    "out:El kr savings": -161458,
    "out:El kr sold": 2627,
    "out:El kr saved": 61272,
    "out:El kr return": 63899,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 34095,
    "out:% savings (space heating)": 92.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 6.95,
    "out:Etvv": 0,
    "out:Ef": 43.76,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.81,
    "out:Electricity (inc PV)": 40.64,
    "out:Total Energy Use Pre PV": 72.81,
    "out:Total Energy Use Post PV": 54.64,
    "out:Primary Energy": 80.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.77,
    "out:CO2 Operational/m2": -23.39,
    "out:Total CO2/m2": 13.39,
    "out:Total CO2 (tons)": 45.63,
    "out:Klimatpaverkan": -171.37,
    "out:Initial Cost/MSEK": 8.09275,
    "out:Running cost/(Apt SEK y)": 9368,
    "out:Running Cost over RSP/MSEK": 2.706,
    "out:LCP/MSEK": 1.904,
    "out:ROI% old": 17.65,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3061.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1278,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.61,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23352.53,
    "out:PV (% sold (El))": 30.64,
    "out:PV (kWh self-consumed)": 52853.25,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -84329,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373459,
    "out:DH kr savings": 487044,
    "out:El kr savings": -143359,
    "out:El kr sold": 29774,
    "out:El kr saved": 89851,
    "out:El kr return": 119625,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48804,
    "out:% savings (space heating)": 88.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 10.02,
    "out:Etvv": 0,
    "out:Ef": 40.64,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.46,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.19,
    "out:Electricity (inc PV)": 40.01,
    "out:Total Energy Use Pre PV": 69.19,
    "out:Total Energy Use Post PV": 51.01,
    "out:Primary Energy": 77.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.77,
    "out:CO2 Operational/m2": -25.04,
    "out:Total CO2/m2": 11.74,
    "out:Total CO2 (tons)": 40.01,
    "out:Klimatpaverkan": -176.99,
    "out:Initial Cost/MSEK": 8.178,
    "out:Running cost/(Apt SEK y)": 8549,
    "out:Running Cost over RSP/MSEK": 2.469,
    "out:LCP/MSEK": 2.055,
    "out:ROI% old": 17.88,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 266.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3024.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1308,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.99,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23687.21,
    "out:PV (% sold (El))": 31.08,
    "out:PV (kWh self-consumed)": 52518.57,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 545440,
    "out:EL kWh savings": -82186,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 386836,
    "out:DH kr savings": 496350,
    "out:El kr savings": -139716,
    "out:El kr sold": 30201,
    "out:El kr saved": 89282,
    "out:El kr return": 119483,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39065,
    "out:% savings (space heating)": 91.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.62,
    "out:Etvv": 0,
    "out:Ef": 40.01,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.72,
    "out:Electricity (inc PV)": 40.07,
    "out:Total Energy Use Pre PV": 70.72,
    "out:Total Energy Use Post PV": 53.07,
    "out:Primary Energy": 78.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.27,
    "out:CO2 Operational/m2": -24.76,
    "out:Total CO2/m2": 12.51,
    "out:Total CO2 (tons)": 42.64,
    "out:Klimatpaverkan": -174.36,
    "out:Initial Cost/MSEK": 8.29775,
    "out:Running cost/(Apt SEK y)": 8962,
    "out:Running Cost over RSP/MSEK": 2.589,
    "out:LCP/MSEK": 1.816,
    "out:ROI% old": 17.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 13,
    "out:El bought/kWh/m2": 2996.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1293,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 39.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23948.96,
    "out:PV (% sold (El))": 31.43,
    "out:PV (kWh self-consumed)": 52256.82,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 538622,
    "out:EL kWh savings": -82404,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 380593,
    "out:DH kr savings": 490146,
    "out:El kr savings": -140088,
    "out:El kr sold": 30535,
    "out:El kr saved": 88837,
    "out:El kr return": 119372,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 43308,
    "out:% savings (space heating)": 90.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 9.25,
    "out:Etvv": 0,
    "out:Ef": 40.07,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.05,
    "out:Electricity (inc PV)": 39.42,
    "out:Total Energy Use Pre PV": 67.05,
    "out:Total Energy Use Post PV": 49.42,
    "out:Primary Energy": 75.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.27,
    "out:CO2 Operational/m2": -26.46,
    "out:Total CO2/m2": 10.8,
    "out:Total CO2 (tons)": 36.83,
    "out:Klimatpaverkan": -180.17,
    "out:Initial Cost/MSEK": 8.383,
    "out:Running cost/(Apt SEK y)": 8136,
    "out:Running Cost over RSP/MSEK": 2.35,
    "out:LCP/MSEK": 1.97,
    "out:ROI% old": 17.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 281.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2956.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1323,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24313.38,
    "out:PV (% sold (El))": 31.9,
    "out:PV (kWh self-consumed)": 51892.41,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -80172,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 394159,
    "out:DH kr savings": 499453,
    "out:El kr savings": -136293,
    "out:El kr sold": 31000,
    "out:El kr saved": 88217,
    "out:El kr return": 119217,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 34095,
    "out:% savings (space heating)": 92.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 6.95,
    "out:Etvv": 0,
    "out:Ef": 39.42,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 168.9,
    "out:Primary Energy": 122.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.43,
    "out:CO2 Operational/m2": 57.18,
    "out:Total CO2/m2": 67.61,
    "out:Total CO2 (tons)": 230.48,
    "out:Klimatpaverkan": 13.48,
    "out:Initial Cost/MSEK": 2.553125,
    "out:Running cost/(Apt SEK y)": 39539,
    "out:Running Cost over RSP/MSEK": 11.44,
    "out:LCP/MSEK": -1.291,
    "out:ROI% old": 7.06,
    "out:Payback discounted": 17,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 10.7,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 6,
    "out:Return/kSEK/y": 161,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 61362,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55839,
    "out:DH kr savings": 55839,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 163.9,
    "out:Primary Energy": 119.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.43,
    "out:CO2 Operational/m2": 55.38,
    "out:Total CO2/m2": 65.81,
    "out:Total CO2 (tons)": 224.34,
    "out:Klimatpaverkan": 7.34,
    "out:Initial Cost/MSEK": 2.638375,
    "out:Running cost/(Apt SEK y)": 38329,
    "out:Running Cost over RSP/MSEK": 11.09,
    "out:LCP/MSEK": -1.026,
    "out:ROI% old": 8.73,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 78407,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71350,
    "out:DH kr savings": 71350,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 161.9,
    "out:Primary Energy": 119.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.93,
    "out:CO2 Operational/m2": 54.66,
    "out:Total CO2/m2": 65.59,
    "out:Total CO2 (tons)": 223.58,
    "out:Klimatpaverkan": 6.58,
    "out:Initial Cost/MSEK": 2.758,
    "out:Running cost/(Apt SEK y)": 37844,
    "out:Running Cost over RSP/MSEK": 10.949,
    "out:LCP/MSEK": -1.005,
    "out:ROI% old": 9.08,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 15.4,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 77555,
    "out:DH kr savings": 77555,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 116.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.93,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 63.79,
    "out:Total CO2 (tons)": 217.44,
    "out:Klimatpaverkan": 0.44,
    "out:Initial Cost/MSEK": 2.84325,
    "out:Running cost/(Apt SEK y)": 36634,
    "out:Running Cost over RSP/MSEK": 10.599,
    "out:LCP/MSEK": -0.74,
    "out:ROI% old": 10.57,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 93066,
    "out:DH kr savings": 93066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 163.73,
    "out:Primary Energy": 113.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.79,
    "out:CO2 Operational/m2": 26.18,
    "out:Total CO2/m2": 48.97,
    "out:Total CO2 (tons)": 166.93,
    "out:Klimatpaverkan": -50.07,
    "out:Initial Cost/MSEK": 3.26225,
    "out:Running cost/(Apt SEK y)": 38024,
    "out:Running Cost over RSP/MSEK": 11.013,
    "out:LCP/MSEK": -1.573,
    "out:ROI% old": 7.4,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 14,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 217,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 61362,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111913,
    "out:DH kr savings": 55839,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 158.73,
    "out:Primary Energy": 109.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.79,
    "out:CO2 Operational/m2": 24.38,
    "out:Total CO2/m2": 47.17,
    "out:Total CO2 (tons)": 160.79,
    "out:Klimatpaverkan": -56.21,
    "out:Initial Cost/MSEK": 3.3475,
    "out:Running cost/(Apt SEK y)": 36813,
    "out:Running Cost over RSP/MSEK": 10.663,
    "out:LCP/MSEK": -1.308,
    "out:ROI% old": 8.7,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 262,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 78407,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127424,
    "out:DH kr savings": 71350,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 156.73,
    "out:Primary Energy": 110.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.28,
    "out:CO2 Operational/m2": 23.66,
    "out:Total CO2/m2": 46.94,
    "out:Total CO2 (tons)": 160.03,
    "out:Klimatpaverkan": -56.97,
    "out:Initial Cost/MSEK": 3.467125,
    "out:Running cost/(Apt SEK y)": 36329,
    "out:Running Cost over RSP/MSEK": 10.523,
    "out:LCP/MSEK": -1.287,
    "out:ROI% old": 8.98,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 280,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133629,
    "out:DH kr savings": 77555,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 107.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.28,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 45.14,
    "out:Total CO2 (tons)": 153.89,
    "out:Klimatpaverkan": -63.11,
    "out:Initial Cost/MSEK": 3.552375,
    "out:Running cost/(Apt SEK y)": 35118,
    "out:Running Cost over RSP/MSEK": 10.172,
    "out:LCP/MSEK": -1.022,
    "out:ROI% old": 10.18,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 325,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149140,
    "out:DH kr savings": 93066,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 168.9,
    "out:Total Energy Use Post PV": 162.88,
    "out:Primary Energy": 111.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.14,
    "out:CO2 Operational/m2": -26.5,
    "out:Total CO2/m2": 8.64,
    "out:Total CO2 (tons)": 29.46,
    "out:Klimatpaverkan": -187.54,
    "out:Initial Cost/MSEK": 3.971375,
    "out:Running cost/(Apt SEK y)": 36678,
    "out:Running Cost over RSP/MSEK": 10.635,
    "out:LCP/MSEK": -1.904,
    "out:ROI% old": 7.44,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61362,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 161724,
    "out:DH kr savings": 55839,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 163.9,
    "out:Total Energy Use Post PV": 157.88,
    "out:Primary Energy": 108.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.14,
    "out:CO2 Operational/m2": -28.3,
    "out:Total CO2/m2": 6.84,
    "out:Total CO2 (tons)": 23.32,
    "out:Klimatpaverkan": -193.68,
    "out:Initial Cost/MSEK": 4.056625,
    "out:Running cost/(Apt SEK y)": 35467,
    "out:Running Cost over RSP/MSEK": 10.284,
    "out:LCP/MSEK": -1.638,
    "out:ROI% old": 8.52,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 312,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 78407,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177235,
    "out:DH kr savings": 71350,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 161.9,
    "out:Total Energy Use Post PV": 155.88,
    "out:Primary Energy": 109.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.64,
    "out:CO2 Operational/m2": -29.02,
    "out:Total CO2/m2": 6.62,
    "out:Total CO2 (tons)": 22.56,
    "out:Klimatpaverkan": -194.44,
    "out:Initial Cost/MSEK": 4.17625,
    "out:Running cost/(Apt SEK y)": 34982,
    "out:Running Cost over RSP/MSEK": 10.144,
    "out:LCP/MSEK": -1.618,
    "out:ROI% old": 8.75,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 330,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 85225,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183440,
    "out:DH kr savings": 77555,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 105.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.64,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 4.82,
    "out:Total CO2 (tons)": 16.42,
    "out:Klimatpaverkan": -200.58,
    "out:Initial Cost/MSEK": 4.2615,
    "out:Running cost/(Apt SEK y)": 33772,
    "out:Running Cost over RSP/MSEK": 9.793,
    "out:LCP/MSEK": -1.352,
    "out:ROI% old": 9.75,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198951,
    "out:DH kr savings": 93066,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 159.9,
    "out:Primary Energy": 119.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.43,
    "out:CO2 Operational/m2": 53.94,
    "out:Total CO2/m2": 64.37,
    "out:Total CO2 (tons)": 219.43,
    "out:Klimatpaverkan": 2.43,
    "out:Initial Cost/MSEK": 2.65075,
    "out:Running cost/(Apt SEK y)": 37360,
    "out:Running Cost over RSP/MSEK": 10.809,
    "out:LCP/MSEK": -0.758,
    "out:ROI% old": 10.2,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 242,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 92043,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83759,
    "out:DH kr savings": 83759,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 154.9,
    "out:Primary Energy": 115.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.43,
    "out:CO2 Operational/m2": 52.14,
    "out:Total CO2/m2": 62.57,
    "out:Total CO2 (tons)": 213.3,
    "out:Klimatpaverkan": -3.7,
    "out:Initial Cost/MSEK": 2.736,
    "out:Running cost/(Apt SEK y)": 36149,
    "out:Running Cost over RSP/MSEK": 10.459,
    "out:LCP/MSEK": -0.492,
    "out:ROI% old": 11.72,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109088,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 99270,
    "out:DH kr savings": 99270,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 154.9,
    "out:Primary Energy": 116.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.93,
    "out:CO2 Operational/m2": 52.14,
    "out:Total CO2/m2": 63.07,
    "out:Total CO2 (tons)": 214.99,
    "out:Klimatpaverkan": -2.01,
    "out:Initial Cost/MSEK": 2.85575,
    "out:Running cost/(Apt SEK y)": 36149,
    "out:Running Cost over RSP/MSEK": 10.459,
    "out:LCP/MSEK": -0.612,
    "out:ROI% old": 11.22,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109088,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99270,
    "out:DH kr savings": 99270,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 149.9,
    "out:Primary Energy": 113.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.93,
    "out:CO2 Operational/m2": 50.34,
    "out:Total CO2/m2": 61.27,
    "out:Total CO2 (tons)": 208.85,
    "out:Klimatpaverkan": -8.15,
    "out:Initial Cost/MSEK": 2.940875,
    "out:Running cost/(Apt SEK y)": 34938,
    "out:Running Cost over RSP/MSEK": 10.108,
    "out:LCP/MSEK": -0.347,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114781,
    "out:DH kr savings": 114781,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 154.73,
    "out:Primary Energy": 109.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.79,
    "out:CO2 Operational/m2": 22.94,
    "out:Total CO2/m2": 45.73,
    "out:Total CO2 (tons)": 155.88,
    "out:Klimatpaverkan": -61.12,
    "out:Initial Cost/MSEK": 3.359875,
    "out:Running cost/(Apt SEK y)": 35844,
    "out:Running Cost over RSP/MSEK": 10.382,
    "out:LCP/MSEK": -1.04,
    "out:ROI% old": 9.86,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 92043,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139833,
    "out:DH kr savings": 83759,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 149.73,
    "out:Primary Energy": 106.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.79,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 43.93,
    "out:Total CO2 (tons)": 149.75,
    "out:Klimatpaverkan": -67.25,
    "out:Initial Cost/MSEK": 3.445125,
    "out:Running cost/(Apt SEK y)": 34634,
    "out:Running Cost over RSP/MSEK": 10.032,
    "out:LCP/MSEK": -0.775,
    "out:ROI% old": 11.07,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 109088,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155344,
    "out:DH kr savings": 99270,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 149.73,
    "out:Primary Energy": 107.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.28,
    "out:CO2 Operational/m2": 21.14,
    "out:Total CO2/m2": 44.42,
    "out:Total CO2 (tons)": 151.44,
    "out:Klimatpaverkan": -65.56,
    "out:Initial Cost/MSEK": 3.56475,
    "out:Running cost/(Apt SEK y)": 34634,
    "out:Running Cost over RSP/MSEK": 10.032,
    "out:LCP/MSEK": -0.894,
    "out:ROI% old": 10.7,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 109088,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155344,
    "out:DH kr savings": 99270,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 144.73,
    "out:Primary Energy": 104.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.28,
    "out:CO2 Operational/m2": 19.34,
    "out:Total CO2/m2": 42.62,
    "out:Total CO2 (tons)": 145.3,
    "out:Klimatpaverkan": -71.7,
    "out:Initial Cost/MSEK": 3.65,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 9.681,
    "out:LCP/MSEK": -0.629,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 388,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170855,
    "out:DH kr savings": 114781,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 159.9,
    "out:Total Energy Use Post PV": 153.88,
    "out:Primary Energy": 108.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.14,
    "out:CO2 Operational/m2": -29.74,
    "out:Total CO2/m2": 5.4,
    "out:Total CO2 (tons)": 18.41,
    "out:Klimatpaverkan": -198.59,
    "out:Initial Cost/MSEK": 4.069,
    "out:Running cost/(Apt SEK y)": 34498,
    "out:Running Cost over RSP/MSEK": 10.004,
    "out:LCP/MSEK": -1.37,
    "out:ROI% old": 9.47,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 348,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 92043,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189644,
    "out:DH kr savings": 83759,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 148.88,
    "out:Primary Energy": 104.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.14,
    "out:CO2 Operational/m2": -31.54,
    "out:Total CO2/m2": 3.6,
    "out:Total CO2 (tons)": 12.28,
    "out:Klimatpaverkan": -204.72,
    "out:Initial Cost/MSEK": 4.15425,
    "out:Running cost/(Apt SEK y)": 33287,
    "out:Running Cost over RSP/MSEK": 9.653,
    "out:LCP/MSEK": -1.105,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 393,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 109088,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205155,
    "out:DH kr savings": 99270,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 109.13,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 154.9,
    "out:Total Energy Use Post PV": 148.88,
    "out:Primary Energy": 106.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.64,
    "out:CO2 Operational/m2": -31.54,
    "out:Total CO2/m2": 4.1,
    "out:Total CO2 (tons)": 13.97,
    "out:Klimatpaverkan": -203.03,
    "out:Initial Cost/MSEK": 4.273875,
    "out:Running cost/(Apt SEK y)": 33287,
    "out:Running Cost over RSP/MSEK": 9.653,
    "out:LCP/MSEK": -1.225,
    "out:ROI% old": 10.19,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 393,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 109088,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205155,
    "out:DH kr savings": 99270,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 372011,
    "out:% savings (space heating)": 14.43,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 109.13,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.44,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 143.88,
    "out:Primary Energy": 102.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.64,
    "out:CO2 Operational/m2": -33.34,
    "out:Total CO2/m2": 2.3,
    "out:Total CO2 (tons)": 7.83,
    "out:Klimatpaverkan": -209.17,
    "out:Initial Cost/MSEK": 4.359125,
    "out:Running cost/(Apt SEK y)": 32077,
    "out:Running Cost over RSP/MSEK": 9.302,
    "out:LCP/MSEK": -0.959,
    "out:ROI% old": 11.14,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220666,
    "out:DH kr savings": 114781,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 356031,
    "out:% savings (space heating)": 18.11,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.44,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.44,
    "out:Electricity (inc PV)": 41.49,
    "out:Total Energy Use Pre PV": 112.44,
    "out:Total Energy Use Post PV": 110.49,
    "out:Primary Energy": 114.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.94,
    "out:CO2 Operational/m2": 30.57,
    "out:Total CO2/m2": 44.51,
    "out:Total CO2 (tons)": 151.74,
    "out:Klimatpaverkan": -65.26,
    "out:Initial Cost/MSEK": 5.399125,
    "out:Running cost/(Apt SEK y)": 23513,
    "out:Running Cost over RSP/MSEK": 6.784,
    "out:LCP/MSEK": 0.519,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 3041,
    "out:Return %": 14,
    "out:Return/kSEK/y": 754,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -87248,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168102,
    "out:DH kr savings": 316423,
    "out:El kr savings": -148321,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 236325,
    "out:% savings (space heating)": 45.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 57.26,
    "out:Etvv": 0,
    "out:Ef": 41.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.38,
    "out:Electricity (inc PV)": 41.38,
    "out:Total Energy Use Pre PV": 108.38,
    "out:Total Energy Use Post PV": 106.38,
    "out:Primary Energy": 111.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.94,
    "out:CO2 Operational/m2": 29.13,
    "out:Total CO2/m2": 43.06,
    "out:Total CO2 (tons)": 146.8,
    "out:Klimatpaverkan": -70.2,
    "out:Initial Cost/MSEK": 5.484375,
    "out:Running cost/(Apt SEK y)": 22534,
    "out:Running Cost over RSP/MSEK": 6.501,
    "out:LCP/MSEK": 0.717,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 3036.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 790,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -86875,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181145,
    "out:DH kr savings": 328832,
    "out:El kr savings": -147687,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 221051,
    "out:% savings (space heating)": 49.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.93,
    "out:Etvv": 0,
    "out:Ef": 41.38,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.8,
    "out:Electricity (inc PV)": 41.06,
    "out:Total Energy Use Pre PV": 107.8,
    "out:Total Energy Use Post PV": 106.06,
    "out:Primary Energy": 112.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.43,
    "out:CO2 Operational/m2": 29.05,
    "out:Total CO2/m2": 43.48,
    "out:Total CO2 (tons)": 148.23,
    "out:Klimatpaverkan": -68.77,
    "out:Initial Cost/MSEK": 5.604125,
    "out:Running cost/(Apt SEK y)": 22443,
    "out:Running Cost over RSP/MSEK": 6.475,
    "out:LCP/MSEK": 0.623,
    "out:ROI% old": 15.87,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2995.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -85757,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183045,
    "out:DH kr savings": 328832,
    "out:El kr savings": -145787,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 221356,
    "out:% savings (space heating)": 49.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.95,
    "out:Etvv": 0,
    "out:Ef": 41.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.72,
    "out:Electricity (inc PV)": 40.94,
    "out:Total Energy Use Pre PV": 102.72,
    "out:Total Energy Use Post PV": 100.94,
    "out:Primary Energy": 109.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.43,
    "out:CO2 Operational/m2": 27.24,
    "out:Total CO2/m2": 41.67,
    "out:Total CO2 (tons)": 142.06,
    "out:Klimatpaverkan": -74.94,
    "out:Initial Cost/MSEK": 5.68925,
    "out:Running cost/(Apt SEK y)": 21220,
    "out:Running Cost over RSP/MSEK": 6.121,
    "out:LCP/MSEK": 0.892,
    "out:ROI% old": 16.53,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2990.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 839,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -85346,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199255,
    "out:DH kr savings": 344343,
    "out:El kr savings": -145089,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206242,
    "out:% savings (space heating)": 52.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 50.66,
    "out:Etvv": 0,
    "out:Ef": 40.94,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.44,
    "out:Electricity (inc PV)": 32.15,
    "out:Total Energy Use Pre PV": 112.44,
    "out:Total Energy Use Post PV": 101.15,
    "out:Primary Energy": 97.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.29,
    "out:CO2 Operational/m2": 21.36,
    "out:Total CO2/m2": 47.65,
    "out:Total CO2 (tons)": 162.45,
    "out:Klimatpaverkan": -54.55,
    "out:Initial Cost/MSEK": 6.10825,
    "out:Running cost/(Apt SEK y)": 21826,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": 0.285,
    "out:ROI% old": 14.95,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2398.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5562.17,
    "out:PV (% sold (El))": 14.6,
    "out:PV (kWh self-consumed)": 32540.72,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -55392,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229349,
    "out:DH kr savings": 316423,
    "out:El kr savings": -94166,
    "out:El kr sold": 7092,
    "out:El kr saved": 55319,
    "out:El kr return": 62411,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 236325,
    "out:% savings (space heating)": 45.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 57.26,
    "out:Etvv": 0,
    "out:Ef": 32.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.38,
    "out:Electricity (inc PV)": 32.05,
    "out:Total Energy Use Pre PV": 108.38,
    "out:Total Energy Use Post PV": 97.05,
    "out:Primary Energy": 94.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.29,
    "out:CO2 Operational/m2": 19.88,
    "out:Total CO2/m2": 46.18,
    "out:Total CO2 (tons)": 157.41,
    "out:Klimatpaverkan": -59.59,
    "out:Initial Cost/MSEK": 6.1935,
    "out:Running cost/(Apt SEK y)": 20848,
    "out:Running Cost over RSP/MSEK": 6.026,
    "out:LCP/MSEK": 0.483,
    "out:ROI% old": 15.4,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2394.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 853,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5583.6,
    "out:PV (% sold (El))": 14.65,
    "out:PV (kWh self-consumed)": 32519.3,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -55059,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242352,
    "out:DH kr savings": 328832,
    "out:El kr savings": -93599,
    "out:El kr sold": 7119,
    "out:El kr saved": 55283,
    "out:El kr return": 62402,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 221051,
    "out:% savings (space heating)": 49.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.93,
    "out:Etvv": 0,
    "out:Ef": 32.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.8,
    "out:Electricity (inc PV)": 31.76,
    "out:Total Energy Use Pre PV": 107.8,
    "out:Total Energy Use Post PV": 96.76,
    "out:Primary Energy": 95.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.79,
    "out:CO2 Operational/m2": 19.52,
    "out:Total CO2/m2": 46.3,
    "out:Total CO2 (tons)": 157.85,
    "out:Klimatpaverkan": -59.15,
    "out:Initial Cost/MSEK": 6.313125,
    "out:Running cost/(Apt SEK y)": 20759,
    "out:Running Cost over RSP/MSEK": 6.001,
    "out:LCP/MSEK": 0.389,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2356.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5784.8,
    "out:PV (% sold (El))": 15.18,
    "out:PV (kWh self-consumed)": 32318.09,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -54061,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 244304,
    "out:DH kr savings": 328832,
    "out:El kr savings": -91904,
    "out:El kr sold": 7376,
    "out:El kr saved": 54941,
    "out:El kr return": 62316,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 221356,
    "out:% savings (space heating)": 49.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.95,
    "out:Etvv": 0,
    "out:Ef": 31.76,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.72,
    "out:Electricity (inc PV)": 31.65,
    "out:Total Energy Use Pre PV": 102.72,
    "out:Total Energy Use Post PV": 91.65,
    "out:Primary Energy": 92.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.79,
    "out:CO2 Operational/m2": 17.67,
    "out:Total CO2/m2": 44.45,
    "out:Total CO2 (tons)": 151.55,
    "out:Klimatpaverkan": -65.45,
    "out:Initial Cost/MSEK": 6.398375,
    "out:Running cost/(Apt SEK y)": 19536,
    "out:Running Cost over RSP/MSEK": 5.647,
    "out:LCP/MSEK": 0.657,
    "out:ROI% old": 15.75,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2352,
    "out:Return %": 14,
    "out:Return/kSEK/y": 901,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5811.55,
    "out:PV (% sold (El))": 15.25,
    "out:PV (kWh self-consumed)": 32291.34,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -53695,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260472,
    "out:DH kr savings": 344343,
    "out:El kr savings": -91281,
    "out:El kr sold": 7410,
    "out:El kr saved": 54895,
    "out:El kr return": 62305,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206242,
    "out:% savings (space heating)": 52.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 50.66,
    "out:Etvv": 0,
    "out:Ef": 31.65,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.44,
    "out:Electricity (inc PV)": 29.06,
    "out:Total Energy Use Pre PV": 112.44,
    "out:Total Energy Use Post PV": 98.06,
    "out:Primary Energy": 92.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.64,
    "out:CO2 Operational/m2": -19.05,
    "out:Total CO2/m2": 19.6,
    "out:Total CO2 (tons)": 66.81,
    "out:Klimatpaverkan": -150.19,
    "out:Initial Cost/MSEK": 6.817375,
    "out:Running cost/(Apt SEK y)": 20385,
    "out:Running Cost over RSP/MSEK": 5.903,
    "out:LCP/MSEK": -0.019,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2165,
    "out:Return %": 13,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32513.75,
    "out:PV (% sold (El))": 42.67,
    "out:PV (kWh self-consumed)": 43692.03,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 347718,
    "out:EL kWh savings": -44860,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281616,
    "out:DH kr savings": 316423,
    "out:El kr savings": -76262,
    "out:El kr sold": 41455,
    "out:El kr saved": 74276,
    "out:El kr return": 115731,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 236325,
    "out:% savings (space heating)": 45.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 57.26,
    "out:Etvv": 0,
    "out:Ef": 29.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.38,
    "out:Electricity (inc PV)": 28.97,
    "out:Total Energy Use Pre PV": 108.38,
    "out:Total Energy Use Post PV": 93.97,
    "out:Primary Energy": 89.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.64,
    "out:CO2 Operational/m2": -20.56,
    "out:Total CO2/m2": 18.09,
    "out:Total CO2 (tons)": 61.67,
    "out:Klimatpaverkan": -155.33,
    "out:Initial Cost/MSEK": 6.902625,
    "out:Running cost/(Apt SEK y)": 19407,
    "out:Running Cost over RSP/MSEK": 5.62,
    "out:LCP/MSEK": 0.179,
    "out:ROI% old": 14.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2161.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32555.2,
    "out:PV (% sold (El))": 42.72,
    "out:PV (kWh self-consumed)": 43650.59,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -44561,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294586,
    "out:DH kr savings": 328832,
    "out:El kr savings": -75754,
    "out:El kr sold": 41508,
    "out:El kr saved": 74206,
    "out:El kr return": 115714,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 221051,
    "out:% savings (space heating)": 49.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.93,
    "out:Etvv": 0,
    "out:Ef": 28.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.8,
    "out:Electricity (inc PV)": 28.71,
    "out:Total Energy Use Pre PV": 107.8,
    "out:Total Energy Use Post PV": 93.71,
    "out:Primary Energy": 90.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.14,
    "out:CO2 Operational/m2": -21.2,
    "out:Total CO2/m2": 17.94,
    "out:Total CO2 (tons)": 61.16,
    "out:Klimatpaverkan": -155.84,
    "out:Initial Cost/MSEK": 7.02225,
    "out:Running cost/(Apt SEK y)": 19320,
    "out:Running Cost over RSP/MSEK": 5.595,
    "out:LCP/MSEK": 0.085,
    "out:ROI% old": 14.46,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2128.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 909,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32942.82,
    "out:PV (% sold (El))": 43.23,
    "out:PV (kWh self-consumed)": 43262.97,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 361354,
    "out:EL kWh savings": -43667,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 296601,
    "out:DH kr savings": 328832,
    "out:El kr savings": -74233,
    "out:El kr sold": 42002,
    "out:El kr saved": 73547,
    "out:El kr return": 115549,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 221356,
    "out:% savings (space heating)": 49.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.95,
    "out:Etvv": 0,
    "out:Ef": 28.71,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.72,
    "out:Electricity (inc PV)": 28.61,
    "out:Total Energy Use Pre PV": 102.72,
    "out:Total Energy Use Post PV": 88.61,
    "out:Primary Energy": 86.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.14,
    "out:CO2 Operational/m2": -23.09,
    "out:Total CO2/m2": 16.05,
    "out:Total CO2 (tons)": 54.73,
    "out:Klimatpaverkan": -162.27,
    "out:Initial Cost/MSEK": 7.1075,
    "out:Running cost/(Apt SEK y)": 18098,
    "out:Running Cost over RSP/MSEK": 5.241,
    "out:LCP/MSEK": 0.353,
    "out:ROI% old": 15,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2123.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32994.11,
    "out:PV (% sold (El))": 43.3,
    "out:PV (kWh self-consumed)": 43211.68,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -43338,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 312736,
    "out:DH kr savings": 344343,
    "out:El kr savings": -73674,
    "out:El kr sold": 42067,
    "out:El kr saved": 73460,
    "out:El kr return": 115527,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206242,
    "out:% savings (space heating)": 52.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 50.66,
    "out:Etvv": 0,
    "out:Ef": 28.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 40.91,
    "out:Total Energy Use Pre PV": 106.57,
    "out:Total Energy Use Post PV": 104.91,
    "out:Primary Energy": 111.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.94,
    "out:CO2 Operational/m2": 28.66,
    "out:Total CO2/m2": 42.6,
    "out:Total CO2 (tons)": 145.21,
    "out:Klimatpaverkan": -71.79,
    "out:Initial Cost/MSEK": 5.49675,
    "out:Running cost/(Apt SEK y)": 22165,
    "out:Running Cost over RSP/MSEK": 6.395,
    "out:LCP/MSEK": 0.811,
    "out:ROI% old": 16.39,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2980,
    "out:Return %": 15,
    "out:Return/kSEK/y": 804,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 364763,
    "out:EL kWh savings": -85250,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187010,
    "out:DH kr savings": 331934,
    "out:El kr savings": -144925,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 216472,
    "out:% savings (space heating)": 50.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.19,
    "out:Etvv": 0,
    "out:Ef": 40.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.49,
    "out:Electricity (inc PV)": 40.78,
    "out:Total Energy Use Pre PV": 101.49,
    "out:Total Energy Use Post PV": 99.78,
    "out:Primary Energy": 108.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.94,
    "out:CO2 Operational/m2": 26.85,
    "out:Total CO2/m2": 40.79,
    "out:Total CO2 (tons)": 139.04,
    "out:Klimatpaverkan": -77.96,
    "out:Initial Cost/MSEK": 5.582,
    "out:Running cost/(Apt SEK y)": 20942,
    "out:Running Cost over RSP/MSEK": 6.04,
    "out:LCP/MSEK": 1.08,
    "out:ROI% old": 17.05,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2974.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 849,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -84828,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 203238,
    "out:DH kr savings": 347445,
    "out:El kr savings": -144207,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 201429,
    "out:% savings (space heating)": 53.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 49.91,
    "out:Etvv": 0,
    "out:Ef": 40.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.01,
    "out:Electricity (inc PV)": 40.54,
    "out:Total Energy Use Pre PV": 102.01,
    "out:Total Energy Use Post PV": 100.54,
    "out:Primary Energy": 109.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.43,
    "out:CO2 Operational/m2": 27.14,
    "out:Total CO2/m2": 41.58,
    "out:Total CO2 (tons)": 141.74,
    "out:Klimatpaverkan": -75.26,
    "out:Initial Cost/MSEK": 5.70175,
    "out:Running cost/(Apt SEK y)": 21109,
    "out:Running Cost over RSP/MSEK": 6.089,
    "out:LCP/MSEK": 0.911,
    "out:ROI% old": 16.57,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2940.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 843,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -84013,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201522,
    "out:DH kr savings": 344343,
    "out:El kr savings": -142822,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 205001,
    "out:% savings (space heating)": 52.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.39,
    "out:Etvv": 0,
    "out:Ef": 40.54,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.91,
    "out:Electricity (inc PV)": 40.41,
    "out:Total Energy Use Pre PV": 97.91,
    "out:Total Energy Use Post PV": 96.41,
    "out:Primary Energy": 106.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.43,
    "out:CO2 Operational/m2": 25.69,
    "out:Total CO2/m2": 40.13,
    "out:Total CO2 (tons)": 136.79,
    "out:Klimatpaverkan": -80.21,
    "out:Initial Cost/MSEK": 5.787,
    "out:Running cost/(Apt SEK y)": 20125,
    "out:Running Cost over RSP/MSEK": 5.804,
    "out:LCP/MSEK": 1.111,
    "out:ROI% old": 17.03,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2933.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 880,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392035,
    "out:EL kWh savings": -83561,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214698,
    "out:DH kr savings": 356752,
    "out:El kr savings": -142053,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 190112,
    "out:% savings (space heating)": 56.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 48.14,
    "out:Etvv": 0,
    "out:Ef": 40.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 31.63,
    "out:Total Energy Use Pre PV": 106.57,
    "out:Total Energy Use Post PV": 95.63,
    "out:Primary Energy": 94.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.29,
    "out:CO2 Operational/m2": 19.01,
    "out:Total CO2/m2": 45.3,
    "out:Total CO2 (tons)": 154.44,
    "out:Klimatpaverkan": -62.56,
    "out:Initial Cost/MSEK": 6.205875,
    "out:Running cost/(Apt SEK y)": 20482,
    "out:Running Cost over RSP/MSEK": 5.921,
    "out:LCP/MSEK": 0.576,
    "out:ROI% old": 15.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2342.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 866,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5863.18,
    "out:PV (% sold (El))": 15.39,
    "out:PV (kWh self-consumed)": 32239.71,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364763,
    "out:EL kWh savings": -53609,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248275,
    "out:DH kr savings": 331934,
    "out:El kr savings": -91135,
    "out:El kr sold": 7476,
    "out:El kr saved": 54808,
    "out:El kr return": 62283,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 216472,
    "out:% savings (space heating)": 50.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.19,
    "out:Etvv": 0,
    "out:Ef": 31.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.49,
    "out:Electricity (inc PV)": 31.52,
    "out:Total Energy Use Pre PV": 101.49,
    "out:Total Energy Use Post PV": 90.52,
    "out:Primary Energy": 91.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.29,
    "out:CO2 Operational/m2": 17.16,
    "out:Total CO2/m2": 43.45,
    "out:Total CO2 (tons)": 148.12,
    "out:Klimatpaverkan": -68.88,
    "out:Initial Cost/MSEK": 6.291125,
    "out:Running cost/(Apt SEK y)": 19259,
    "out:Running Cost over RSP/MSEK": 5.566,
    "out:LCP/MSEK": 0.845,
    "out:ROI% old": 16.2,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2337.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 912,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5892.34,
    "out:PV (% sold (El))": 15.46,
    "out:PV (kWh self-consumed)": 32210.55,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -53233,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264463,
    "out:DH kr savings": 347445,
    "out:El kr savings": -90495,
    "out:El kr sold": 7513,
    "out:El kr saved": 54758,
    "out:El kr return": 62271,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 201429,
    "out:% savings (space heating)": 53.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 49.91,
    "out:Etvv": 0,
    "out:Ef": 31.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.01,
    "out:Electricity (inc PV)": 31.3,
    "out:Total Energy Use Pre PV": 102.01,
    "out:Total Energy Use Post PV": 91.3,
    "out:Primary Energy": 93.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.79,
    "out:CO2 Operational/m2": 17.21,
    "out:Total CO2/m2": 44,
    "out:Total CO2 (tons)": 149.99,
    "out:Klimatpaverkan": -67.01,
    "out:Initial Cost/MSEK": 6.410875,
    "out:Running cost/(Apt SEK y)": 19428,
    "out:Running Cost over RSP/MSEK": 5.616,
    "out:LCP/MSEK": 0.676,
    "out:ROI% old": 15.79,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2306.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 905,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6061.59,
    "out:PV (% sold (El))": 15.91,
    "out:PV (kWh self-consumed)": 32041.3,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -52507,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262810,
    "out:DH kr savings": 344343,
    "out:El kr savings": -89262,
    "out:El kr sold": 7729,
    "out:El kr saved": 54470,
    "out:El kr return": 62199,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 205001,
    "out:% savings (space heating)": 52.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.39,
    "out:Etvv": 0,
    "out:Ef": 31.3,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.91,
    "out:Electricity (inc PV)": 31.18,
    "out:Total Energy Use Pre PV": 97.91,
    "out:Total Energy Use Post PV": 87.18,
    "out:Primary Energy": 89.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.79,
    "out:CO2 Operational/m2": 15.71,
    "out:Total CO2/m2": 42.49,
    "out:Total CO2 (tons)": 144.87,
    "out:Klimatpaverkan": -72.13,
    "out:Initial Cost/MSEK": 6.496,
    "out:Running cost/(Apt SEK y)": 18444,
    "out:Running Cost over RSP/MSEK": 5.331,
    "out:LCP/MSEK": 0.875,
    "out:ROI% old": 16.21,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2300.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 942,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6096.13,
    "out:PV (% sold (El))": 16,
    "out:PV (kWh self-consumed)": 32006.77,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392035,
    "out:EL kWh savings": -52105,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275945,
    "out:DH kr savings": 356752,
    "out:El kr savings": -88579,
    "out:El kr sold": 7773,
    "out:El kr saved": 54412,
    "out:El kr return": 62184,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 190112,
    "out:% savings (space heating)": 56.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 48.14,
    "out:Etvv": 0,
    "out:Ef": 31.18,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.57,
    "out:Electricity (inc PV)": 28.59,
    "out:Total Energy Use Pre PV": 106.57,
    "out:Total Energy Use Post PV": 92.59,
    "out:Primary Energy": 89.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.64,
    "out:CO2 Operational/m2": -21.81,
    "out:Total CO2/m2": 16.83,
    "out:Total CO2 (tons)": 57.38,
    "out:Klimatpaverkan": -159.62,
    "out:Initial Cost/MSEK": 6.915,
    "out:Running cost/(Apt SEK y)": 19044,
    "out:Running Cost over RSP/MSEK": 5.516,
    "out:LCP/MSEK": 0.272,
    "out:ROI% old": 14.85,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2115.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 920,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.37,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33092.99,
    "out:PV (% sold (El))": 43.43,
    "out:PV (kWh self-consumed)": 43112.79,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 364763,
    "out:EL kWh savings": -43261,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300585,
    "out:DH kr savings": 331934,
    "out:El kr savings": -73543,
    "out:El kr sold": 42194,
    "out:El kr saved": 73292,
    "out:El kr return": 115485,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 216472,
    "out:% savings (space heating)": 50.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 54.19,
    "out:Etvv": 0,
    "out:Ef": 28.59,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.49,
    "out:Electricity (inc PV)": 28.49,
    "out:Total Energy Use Pre PV": 101.49,
    "out:Total Energy Use Post PV": 87.49,
    "out:Primary Energy": 86.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.64,
    "out:CO2 Operational/m2": -23.7,
    "out:Total CO2/m2": 14.94,
    "out:Total CO2 (tons)": 50.93,
    "out:Klimatpaverkan": -166.07,
    "out:Initial Cost/MSEK": 7.00025,
    "out:Running cost/(Apt SEK y)": 17821,
    "out:Running Cost over RSP/MSEK": 5.162,
    "out:LCP/MSEK": 0.54,
    "out:ROI% old": 15.39,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2110.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33148.68,
    "out:PV (% sold (El))": 43.5,
    "out:PV (kWh self-consumed)": 43057.11,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -42923,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 316740,
    "out:DH kr savings": 347445,
    "out:El kr savings": -72969,
    "out:El kr sold": 42265,
    "out:El kr saved": 73197,
    "out:El kr return": 115462,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 201429,
    "out:% savings (space heating)": 53.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 49.91,
    "out:Etvv": 0,
    "out:Ef": 28.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.01,
    "out:Electricity (inc PV)": 28.3,
    "out:Total Energy Use Pre PV": 102.01,
    "out:Total Energy Use Post PV": 88.3,
    "out:Primary Energy": 87.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.14,
    "out:CO2 Operational/m2": -23.88,
    "out:Total CO2/m2": 15.26,
    "out:Total CO2 (tons)": 52.03,
    "out:Klimatpaverkan": -164.97,
    "out:Initial Cost/MSEK": 7.12,
    "out:Running cost/(Apt SEK y)": 17992,
    "out:Running Cost over RSP/MSEK": 5.211,
    "out:LCP/MSEK": 0.371,
    "out:ROI% old": 15.03,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 2083.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.81,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33469.95,
    "out:PV (% sold (El))": 43.92,
    "out:PV (kWh self-consumed)": 42735.84,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 378399,
    "out:EL kWh savings": -42272,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315155,
    "out:DH kr savings": 344343,
    "out:El kr savings": -71862,
    "out:El kr sold": 42674,
    "out:El kr saved": 72651,
    "out:El kr return": 115325,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 205001,
    "out:% savings (space heating)": 52.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 52.39,
    "out:Etvv": 0,
    "out:Ef": 28.3,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.91,
    "out:Electricity (inc PV)": 28.19,
    "out:Total Energy Use Pre PV": 97.91,
    "out:Total Energy Use Post PV": 84.19,
    "out:Primary Energy": 84.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.14,
    "out:CO2 Operational/m2": -25.43,
    "out:Total CO2/m2": 13.71,
    "out:Total CO2 (tons)": 46.75,
    "out:Klimatpaverkan": -170.25,
    "out:Initial Cost/MSEK": 7.205125,
    "out:Running cost/(Apt SEK y)": 17009,
    "out:Running Cost over RSP/MSEK": 4.927,
    "out:LCP/MSEK": 0.57,
    "out:ROI% old": 15.42,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2078,
    "out:Return %": 14,
    "out:Return/kSEK/y": 995,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33535.18,
    "out:PV (% sold (El))": 44.01,
    "out:PV (kWh self-consumed)": 42670.6,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 392035,
    "out:EL kWh savings": -41911,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328260,
    "out:DH kr savings": 356752,
    "out:El kr savings": -71249,
    "out:El kr sold": 42757,
    "out:El kr saved": 72540,
    "out:El kr return": 115297,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 190112,
    "out:% savings (space heating)": 56.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 48.14,
    "out:Etvv": 0,
    "out:Ef": 28.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.11,
    "out:Electricity (inc PV)": 56.13,
    "out:Total Energy Use Pre PV": 74.11,
    "out:Total Energy Use Post PV": 70.13,
    "out:Primary Energy": 107.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.53,
    "out:CO2 Operational/m2": 12.98,
    "out:Total CO2/m2": 27.51,
    "out:Total CO2 (tons)": 93.77,
    "out:Klimatpaverkan": -123.23,
    "out:Initial Cost/MSEK": 7.255375,
    "out:Running cost/(Apt SEK y)": 12806,
    "out:Running Cost over RSP/MSEK": 3.675,
    "out:LCP/MSEK": 1.772,
    "out:ROI% old": 17.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 4208,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1150,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -137149,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253890,
    "out:DH kr savings": 487044,
    "out:El kr savings": -233154,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48775,
    "out:% savings (space heating)": 88.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.95,
    "out:Etvv": 0,
    "out:Ef": 56.13,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.54,
    "out:Electricity (inc PV)": 55.38,
    "out:Total Energy Use Pre PV": 71.54,
    "out:Total Energy Use Post PV": 67.38,
    "out:Primary Energy": 104.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.53,
    "out:CO2 Operational/m2": 12.18,
    "out:Total CO2/m2": 26.71,
    "out:Total CO2 (tons)": 91.05,
    "out:Klimatpaverkan": -125.95,
    "out:Initial Cost/MSEK": 7.340625,
    "out:Running cost/(Apt SEK y)": 12232,
    "out:Running Cost over RSP/MSEK": 3.509,
    "out:LCP/MSEK": 1.853,
    "out:ROI% old": 17.89,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4167.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1172,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -134582,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264459,
    "out:DH kr savings": 493248,
    "out:El kr savings": -228789,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39247,
    "out:% savings (space heating)": 90.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 6.75,
    "out:Etvv": 0,
    "out:Ef": 55.38,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.76,
    "out:Electricity (inc PV)": 55.27,
    "out:Total Energy Use Pre PV": 70.76,
    "out:Total Energy Use Post PV": 67.27,
    "out:Primary Energy": 105.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.03,
    "out:CO2 Operational/m2": 12.08,
    "out:Total CO2/m2": 27.1,
    "out:Total CO2 (tons)": 92.39,
    "out:Klimatpaverkan": -124.61,
    "out:Initial Cost/MSEK": 7.460375,
    "out:Running cost/(Apt SEK y)": 12109,
    "out:Running Cost over RSP/MSEK": 3.474,
    "out:LCP/MSEK": 1.768,
    "out:ROI% old": 17.67,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4113,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1176,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -134215,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265083,
    "out:DH kr savings": 493248,
    "out:El kr savings": -228165,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 41790,
    "out:% savings (space heating)": 90.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.04,
    "out:Etvv": 0,
    "out:Ef": 55.27,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.13,
    "out:Electricity (inc PV)": 54.47,
    "out:Total Energy Use Pre PV": 68.13,
    "out:Total Energy Use Post PV": 64.47,
    "out:Primary Energy": 102.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.03,
    "out:CO2 Operational/m2": 11.27,
    "out:Total CO2/m2": 26.3,
    "out:Total CO2 (tons)": 89.65,
    "out:Klimatpaverkan": -127.35,
    "out:Initial Cost/MSEK": 7.5455,
    "out:Running cost/(Apt SEK y)": 11526,
    "out:Running Cost over RSP/MSEK": 3.305,
    "out:LCP/MSEK": 1.851,
    "out:ROI% old": 17.79,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4068.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1198,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -131498,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275906,
    "out:DH kr savings": 499453,
    "out:El kr savings": -223546,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 32929,
    "out:% savings (space heating)": 92.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.98,
    "out:Etvv": 0,
    "out:Ef": 54.47,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.11,
    "out:Electricity (inc PV)": 45.61,
    "out:Total Energy Use Pre PV": 74.11,
    "out:Total Energy Use Post PV": 59.61,
    "out:Primary Energy": 88.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.88,
    "out:CO2 Operational/m2": 9.5,
    "out:Total CO2/m2": 36.38,
    "out:Total CO2 (tons)": 124.02,
    "out:Klimatpaverkan": -92.98,
    "out:Initial Cost/MSEK": 7.9645,
    "out:Running cost/(Apt SEK y)": 11073,
    "out:Running Cost over RSP/MSEK": 3.187,
    "out:LCP/MSEK": 1.551,
    "out:ROI% old": 17.07,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3495.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1539.98,
    "out:PV (% sold (El))": 4.04,
    "out:PV (kWh self-consumed)": 36562.91,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -101281,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 316830,
    "out:DH kr savings": 487044,
    "out:El kr savings": -172177,
    "out:El kr sold": 1963,
    "out:El kr saved": 62157,
    "out:El kr return": 64120,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48775,
    "out:% savings (space heating)": 88.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.95,
    "out:Etvv": 0,
    "out:Ef": 45.61,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.54,
    "out:Electricity (inc PV)": 44.9,
    "out:Total Energy Use Pre PV": 71.54,
    "out:Total Energy Use Post PV": 56.9,
    "out:Primary Energy": 85.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.88,
    "out:CO2 Operational/m2": 8.57,
    "out:Total CO2/m2": 35.46,
    "out:Total CO2 (tons)": 120.87,
    "out:Klimatpaverkan": -96.13,
    "out:Initial Cost/MSEK": 8.04975,
    "out:Running cost/(Apt SEK y)": 10500,
    "out:Running Cost over RSP/MSEK": 3.022,
    "out:LCP/MSEK": 1.631,
    "out:ROI% old": 17.18,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3456.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1236,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1631.85,
    "out:PV (% sold (El))": 4.28,
    "out:PV (kWh self-consumed)": 36471.04,
    "out:PV (ratio sold/self-consumed)": 0.04,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -98861,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327265,
    "out:DH kr savings": 493248,
    "out:El kr savings": -168064,
    "out:El kr sold": 2081,
    "out:El kr saved": 62001,
    "out:El kr return": 64081,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39247,
    "out:% savings (space heating)": 90.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 6.75,
    "out:Etvv": 0,
    "out:Ef": 44.9,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.76,
    "out:Electricity (inc PV)": 44.8,
    "out:Total Energy Use Pre PV": 70.76,
    "out:Total Energy Use Post PV": 56.8,
    "out:Primary Energy": 86.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.38,
    "out:CO2 Operational/m2": 8.29,
    "out:Total CO2/m2": 35.67,
    "out:Total CO2 (tons)": 121.59,
    "out:Klimatpaverkan": -95.41,
    "out:Initial Cost/MSEK": 8.169375,
    "out:Running cost/(Apt SEK y)": 10379,
    "out:Running Cost over RSP/MSEK": 2.987,
    "out:LCP/MSEK": 1.546,
    "out:ROI% old": 16.99,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3404.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1761.19,
    "out:PV (% sold (El))": 4.62,
    "out:PV (kWh self-consumed)": 36341.71,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -98516,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328017,
    "out:DH kr savings": 493248,
    "out:El kr savings": -167477,
    "out:El kr sold": 2246,
    "out:El kr saved": 61781,
    "out:El kr return": 64026,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 41790,
    "out:% savings (space heating)": 90.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.04,
    "out:Etvv": 0,
    "out:Ef": 44.8,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.13,
    "out:Electricity (inc PV)": 44.05,
    "out:Total Energy Use Pre PV": 68.13,
    "out:Total Energy Use Post PV": 54.05,
    "out:Primary Energy": 83.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.38,
    "out:CO2 Operational/m2": 7.33,
    "out:Total CO2/m2": 34.71,
    "out:Total CO2 (tons)": 118.33,
    "out:Klimatpaverkan": -98.67,
    "out:Initial Cost/MSEK": 8.254625,
    "out:Running cost/(Apt SEK y)": 9796,
    "out:Running Cost over RSP/MSEK": 2.819,
    "out:LCP/MSEK": 1.629,
    "out:ROI% old": 17.1,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3361.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1262,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1869.15,
    "out:PV (% sold (El))": 4.91,
    "out:PV (kWh self-consumed)": 36233.74,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -95962,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338700,
    "out:DH kr savings": 499453,
    "out:El kr savings": -163135,
    "out:El kr sold": 2383,
    "out:El kr saved": 61597,
    "out:El kr return": 63981,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 32929,
    "out:% savings (space heating)": 92.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.98,
    "out:Etvv": 0,
    "out:Ef": 44.05,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 60.11,
    "out:Electricity (inc PV)": 41.06,
    "out:Total Energy Use Pre PV": 74.11,
    "out:Total Energy Use Post PV": 55.06,
    "out:Primary Energy": 80.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.24,
    "out:CO2 Operational/m2": -22.19,
    "out:Total CO2/m2": 17.04,
    "out:Total CO2 (tons)": 58.1,
    "out:Klimatpaverkan": -158.9,
    "out:Initial Cost/MSEK": 8.673625,
    "out:Running cost/(Apt SEK y)": 9565,
    "out:Running Cost over RSP/MSEK": 2.762,
    "out:LCP/MSEK": 1.267,
    "out:ROI% old": 16.37,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3139.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1270,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22652.44,
    "out:PV (% sold (El))": 29.73,
    "out:PV (kWh self-consumed)": 53553.34,
    "out:PV (ratio sold/self-consumed)": 0.42,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 535213,
    "out:EL kWh savings": -85768,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 370120,
    "out:DH kr savings": 487044,
    "out:El kr savings": -145806,
    "out:El kr sold": 28882,
    "out:El kr saved": 91041,
    "out:El kr return": 119923,
    "out:% solar/total": 99,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 48775,
    "out:% savings (space heating)": 88.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.95,
    "out:Etvv": 0,
    "out:Ef": 41.06,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.54,
    "out:Electricity (inc PV)": 40.43,
    "out:Total Energy Use Pre PV": 71.54,
    "out:Total Energy Use Post PV": 52.43,
    "out:Primary Energy": 77.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.24,
    "out:CO2 Operational/m2": -23.43,
    "out:Total CO2/m2": 15.8,
    "out:Total CO2 (tons)": 53.87,
    "out:Klimatpaverkan": -163.13,
    "out:Initial Cost/MSEK": 8.758875,
    "out:Running cost/(Apt SEK y)": 8994,
    "out:Running Cost over RSP/MSEK": 2.597,
    "out:LCP/MSEK": 1.346,
    "out:ROI% old": 16.48,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3104.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1291,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.34,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 22957.57,
    "out:PV (% sold (El))": 30.13,
    "out:PV (kWh self-consumed)": 53248.22,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -83623,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 380361,
    "out:DH kr savings": 493248,
    "out:El kr savings": -142159,
    "out:El kr sold": 29271,
    "out:El kr saved": 90522,
    "out:El kr return": 119793,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39247,
    "out:% savings (space heating)": 90.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 6.75,
    "out:Etvv": 0,
    "out:Ef": 40.43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.76,
    "out:Electricity (inc PV)": 40.34,
    "out:Total Energy Use Pre PV": 70.76,
    "out:Total Energy Use Post PV": 52.34,
    "out:Primary Energy": 78.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.73,
    "out:CO2 Operational/m2": -24.15,
    "out:Total CO2/m2": 15.58,
    "out:Total CO2 (tons)": 53.11,
    "out:Klimatpaverkan": -163.89,
    "out:Initial Cost/MSEK": 8.8785,
    "out:Running cost/(Apt SEK y)": 8876,
    "out:Running Cost over RSP/MSEK": 2.563,
    "out:LCP/MSEK": 1.26,
    "out:ROI% old": 16.31,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3058.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1296,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 40.56,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23380.32,
    "out:PV (% sold (El))": 30.68,
    "out:PV (kWh self-consumed)": 52825.46,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -83316,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 381420,
    "out:DH kr savings": 493248,
    "out:El kr savings": -141638,
    "out:El kr sold": 29810,
    "out:El kr saved": 89803,
    "out:El kr return": 119613,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 41790,
    "out:% savings (space heating)": 90.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 8.04,
    "out:Etvv": 0,
    "out:Ef": 40.34,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.13,
    "out:Electricity (inc PV)": 39.68,
    "out:Total Energy Use Pre PV": 68.13,
    "out:Total Energy Use Post PV": 49.68,
    "out:Primary Energy": 75.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.73,
    "out:CO2 Operational/m2": -25.46,
    "out:Total CO2/m2": 14.27,
    "out:Total CO2 (tons)": 48.65,
    "out:Klimatpaverkan": -168.35,
    "out:Initial Cost/MSEK": 8.96375,
    "out:Running cost/(Apt SEK y)": 8297,
    "out:Running Cost over RSP/MSEK": 2.396,
    "out:LCP/MSEK": 1.343,
    "out:ROI% old": 16.43,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3020.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1317,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23724.7,
    "out:PV (% sold (El))": 31.13,
    "out:PV (kWh self-consumed)": 52481.08,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -81050,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 391917,
    "out:DH kr savings": 499453,
    "out:El kr savings": -137784,
    "out:El kr sold": 30249,
    "out:El kr saved": 89218,
    "out:El kr return": 119467,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 32929,
    "out:% savings (space heating)": 92.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.98,
    "out:Etvv": 0,
    "out:Ef": 39.68,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.29,
    "out:Electricity (inc PV)": 54.98,
    "out:Total Energy Use Pre PV": 70.29,
    "out:Total Energy Use Post PV": 66.98,
    "out:Primary Energy": 104.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.53,
    "out:CO2 Operational/m2": 12.01,
    "out:Total CO2/m2": 26.55,
    "out:Total CO2 (tons)": 90.49,
    "out:Klimatpaverkan": -126.51,
    "out:Initial Cost/MSEK": 7.353,
    "out:Running cost/(Apt SEK y)": 12036,
    "out:Running Cost over RSP/MSEK": 3.453,
    "out:LCP/MSEK": 1.896,
    "out:ROI% old": 17.97,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 179.1,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 4080.5,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1179,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -133223,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266769,
    "out:DH kr savings": 493248,
    "out:El kr savings": -226479,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39602,
    "out:% savings (space heating)": 90.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.75,
    "out:Etvv": 0,
    "out:Ef": 54.98,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.64,
    "out:Electricity (inc PV)": 54.17,
    "out:Total Energy Use Pre PV": 66.64,
    "out:Total Energy Use Post PV": 63.17,
    "out:Primary Energy": 101.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.53,
    "out:CO2 Operational/m2": 10.85,
    "out:Total CO2/m2": 25.38,
    "out:Total CO2 (tons)": 86.52,
    "out:Klimatpaverkan": -130.48,
    "out:Initial Cost/MSEK": 7.43825,
    "out:Running cost/(Apt SEK y)": 11208,
    "out:Running Cost over RSP/MSEK": 3.214,
    "out:LCP/MSEK": 2.05,
    "out:ROI% old": 18.22,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4034.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1210,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 552258,
    "out:EL kWh savings": -130449,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280792,
    "out:DH kr savings": 502555,
    "out:El kr savings": -221763,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 30958,
    "out:% savings (space heating)": 92.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.75,
    "out:Etvv": 0,
    "out:Ef": 54.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.16,
    "out:Electricity (inc PV)": 54.27,
    "out:Total Energy Use Pre PV": 67.16,
    "out:Total Energy Use Post PV": 64.27,
    "out:Primary Energy": 102.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.03,
    "out:CO2 Operational/m2": 11.14,
    "out:Total CO2/m2": 26.17,
    "out:Total CO2 (tons)": 89.22,
    "out:Klimatpaverkan": -127.78,
    "out:Initial Cost/MSEK": 7.558,
    "out:Running cost/(Apt SEK y)": 11374,
    "out:Running Cost over RSP/MSEK": 3.262,
    "out:LCP/MSEK": 1.882,
    "out:ROI% old": 17.84,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 192.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4001,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1203,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -130813,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277071,
    "out:DH kr savings": 499453,
    "out:El kr savings": -222382,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 34592,
    "out:% savings (space heating)": 92.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.08,
    "out:Etvv": 0,
    "out:Ef": 54.27,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.45,
    "out:Electricity (inc PV)": 53.42,
    "out:Total Energy Use Pre PV": 64.45,
    "out:Total Energy Use Post PV": 61.42,
    "out:Primary Energy": 99.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.03,
    "out:CO2 Operational/m2": 10.33,
    "out:Total CO2/m2": 25.36,
    "out:Total CO2 (tons)": 86.45,
    "out:Klimatpaverkan": -130.55,
    "out:Initial Cost/MSEK": 7.64325,
    "out:Running cost/(Apt SEK y)": 10779,
    "out:Running Cost over RSP/MSEK": 3.09,
    "out:LCP/MSEK": 1.969,
    "out:ROI% old": 17.97,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 206.6,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3951.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1225,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -127895,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288236,
    "out:DH kr savings": 505657,
    "out:El kr savings": -217422,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 26534,
    "out:% savings (space heating)": 93.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.22,
    "out:Etvv": 0,
    "out:Ef": 53.42,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.29,
    "out:Electricity (inc PV)": 44.53,
    "out:Total Energy Use Pre PV": 70.29,
    "out:Total Energy Use Post PV": 56.53,
    "out:Primary Energy": 85.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.88,
    "out:CO2 Operational/m2": 8.11,
    "out:Total CO2/m2": 35,
    "out:Total CO2 (tons)": 119.31,
    "out:Klimatpaverkan": -97.69,
    "out:Initial Cost/MSEK": 8.062125,
    "out:Running cost/(Apt SEK y)": 10307,
    "out:Running Cost over RSP/MSEK": 2.966,
    "out:LCP/MSEK": 1.674,
    "out:ROI% old": 17.25,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3372.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1243,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1840.11,
    "out:PV (% sold (El))": 4.83,
    "out:PV (kWh self-consumed)": 36262.78,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -97583,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 329703,
    "out:DH kr savings": 493248,
    "out:El kr savings": -165891,
    "out:El kr sold": 2346,
    "out:El kr saved": 61647,
    "out:El kr return": 63993,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39602,
    "out:% savings (space heating)": 90.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.75,
    "out:Etvv": 0,
    "out:Ef": 44.53,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.64,
    "out:Electricity (inc PV)": 43.76,
    "out:Total Energy Use Pre PV": 66.64,
    "out:Total Energy Use Post PV": 52.76,
    "out:Primary Energy": 82.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.88,
    "out:CO2 Operational/m2": 6.79,
    "out:Total CO2/m2": 33.67,
    "out:Total CO2 (tons)": 114.78,
    "out:Klimatpaverkan": -102.22,
    "out:Initial Cost/MSEK": 8.147375,
    "out:Running cost/(Apt SEK y)": 9479,
    "out:Running Cost over RSP/MSEK": 2.727,
    "out:LCP/MSEK": 1.828,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3329,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1954.15,
    "out:PV (% sold (El))": 5.13,
    "out:PV (kWh self-consumed)": 36148.74,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 552258,
    "out:EL kWh savings": -94978,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 343584,
    "out:DH kr savings": 502555,
    "out:El kr savings": -161463,
    "out:El kr sold": 2492,
    "out:El kr saved": 61453,
    "out:El kr return": 63944,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 30958,
    "out:% savings (space heating)": 92.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.75,
    "out:Etvv": 0,
    "out:Ef": 43.76,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.16,
    "out:Electricity (inc PV)": 43.86,
    "out:Total Energy Use Pre PV": 67.16,
    "out:Total Energy Use Post PV": 53.86,
    "out:Primary Energy": 83.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.38,
    "out:CO2 Operational/m2": 6.96,
    "out:Total CO2/m2": 34.34,
    "out:Total CO2 (tons)": 117.06,
    "out:Klimatpaverkan": -99.94,
    "out:Initial Cost/MSEK": 8.267125,
    "out:Running cost/(Apt SEK y)": 9647,
    "out:Running Cost over RSP/MSEK": 2.776,
    "out:LCP/MSEK": 1.659,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3296.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1267,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2041.79,
    "out:PV (% sold (El))": 5.36,
    "out:PV (kWh self-consumed)": 36061.1,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -95319,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 340013,
    "out:DH kr savings": 499453,
    "out:El kr savings": -162043,
    "out:El kr sold": 2603,
    "out:El kr saved": 61304,
    "out:El kr return": 63907,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 34592,
    "out:% savings (space heating)": 92.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.08,
    "out:Etvv": 0,
    "out:Ef": 43.86,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.45,
    "out:Electricity (inc PV)": 43.06,
    "out:Total Energy Use Pre PV": 64.45,
    "out:Total Energy Use Post PV": 51.06,
    "out:Primary Energy": 81.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.38,
    "out:CO2 Operational/m2": 5.96,
    "out:Total CO2/m2": 33.34,
    "out:Total CO2 (tons)": 113.66,
    "out:Klimatpaverkan": -103.34,
    "out:Initial Cost/MSEK": 8.35225,
    "out:Running cost/(Apt SEK y)": 9054,
    "out:Running Cost over RSP/MSEK": 2.604,
    "out:LCP/MSEK": 1.746,
    "out:ROI% old": 17.27,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 266.7,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3249.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1289,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2172.91,
    "out:PV (% sold (El))": 5.7,
    "out:PV (kWh self-consumed)": 35929.98,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -92587,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351029,
    "out:DH kr savings": 505657,
    "out:El kr savings": -157398,
    "out:El kr sold": 2770,
    "out:El kr saved": 61081,
    "out:El kr return": 63851,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 26534,
    "out:% savings (space heating)": 93.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.22,
    "out:Etvv": 0,
    "out:Ef": 43.06,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 11.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.29,
    "out:Electricity (inc PV)": 40.1,
    "out:Total Energy Use Pre PV": 70.29,
    "out:Total Energy Use Post PV": 52.1,
    "out:Primary Energy": 77.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.24,
    "out:CO2 Operational/m2": -24.58,
    "out:Total CO2/m2": 14.65,
    "out:Total CO2 (tons)": 49.95,
    "out:Klimatpaverkan": -167.05,
    "out:Initial Cost/MSEK": 8.77125,
    "out:Running cost/(Apt SEK y)": 8806,
    "out:Running Cost over RSP/MSEK": 2.543,
    "out:LCP/MSEK": 1.388,
    "out:ROI% old": 16.55,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3030.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 40.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23632.88,
    "out:PV (% sold (El))": 31.01,
    "out:PV (kWh self-consumed)": 52572.9,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 542031,
    "out:EL kWh savings": -82488,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 383150,
    "out:DH kr savings": 493248,
    "out:El kr savings": -140230,
    "out:El kr sold": 30132,
    "out:El kr saved": 89374,
    "out:El kr return": 119506,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 39602,
    "out:% savings (space heating)": 90.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.75,
    "out:Etvv": 0,
    "out:Ef": 40.1,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.64,
    "out:Electricity (inc PV)": 39.42,
    "out:Total Energy Use Pre PV": 66.64,
    "out:Total Energy Use Post PV": 48.42,
    "out:Primary Energy": 74.98,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.24,
    "out:CO2 Operational/m2": -26.27,
    "out:Total CO2/m2": 12.96,
    "out:Total CO2 (tons)": 44.19,
    "out:Klimatpaverkan": -172.81,
    "out:Initial Cost/MSEK": 8.8565,
    "out:Running cost/(Apt SEK y)": 7982,
    "out:Running Cost over RSP/MSEK": 2.305,
    "out:LCP/MSEK": 1.541,
    "out:ROI% old": 16.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2991.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1329,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.44,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23989.91,
    "out:PV (% sold (El))": 31.48,
    "out:PV (kWh self-consumed)": 52215.87,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 552258,
    "out:EL kWh savings": -80175,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 396845,
    "out:DH kr savings": 502555,
    "out:El kr savings": -136297,
    "out:El kr sold": 30587,
    "out:El kr saved": 88767,
    "out:El kr return": 119354,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 30958,
    "out:% savings (space heating)": 92.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.75,
    "out:Etvv": 0,
    "out:Ef": 39.42,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.16,
    "out:Electricity (inc PV)": 39.51,
    "out:Total Energy Use Pre PV": 67.16,
    "out:Total Energy Use Post PV": 49.51,
    "out:Primary Energy": 76.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.73,
    "out:CO2 Operational/m2": -26.37,
    "out:Total CO2/m2": 13.37,
    "out:Total CO2 (tons)": 45.56,
    "out:Klimatpaverkan": -171.44,
    "out:Initial Cost/MSEK": 8.97625,
    "out:Running cost/(Apt SEK y)": 8151,
    "out:Running Cost over RSP/MSEK": 2.354,
    "out:LCP/MSEK": 1.372,
    "out:ROI% old": 16.47,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2963.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24256.54,
    "out:PV (% sold (El))": 31.83,
    "out:PV (kWh self-consumed)": 51949.24,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -80478,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 393567,
    "out:DH kr savings": 499453,
    "out:El kr savings": -136813,
    "out:El kr sold": 30927,
    "out:El kr saved": 88314,
    "out:El kr return": 119241,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 34592,
    "out:% savings (space heating)": 92.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 7.08,
    "out:Etvv": 0,
    "out:Ef": 39.51,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.45,
    "out:Electricity (inc PV)": 38.8,
    "out:Total Energy Use Pre PV": 64.45,
    "out:Total Energy Use Post PV": 46.8,
    "out:Primary Energy": 73.49,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 39.73,
    "out:CO2 Operational/m2": -27.75,
    "out:Total CO2/m2": 11.98,
    "out:Total CO2 (tons)": 40.84,
    "out:Klimatpaverkan": -176.16,
    "out:Initial Cost/MSEK": 9.061375,
    "out:Running cost/(Apt SEK y)": 7561,
    "out:Running Cost over RSP/MSEK": 2.184,
    "out:LCP/MSEK": 1.457,
    "out:ROI% old": 16.58,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2921.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1344,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24646.09,
    "out:PV (% sold (El))": 32.34,
    "out:PV (kWh self-consumed)": 51559.69,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -78050,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 404396,
    "out:DH kr savings": 505657,
    "out:El kr savings": -132684,
    "out:El kr sold": 31424,
    "out:El kr saved": 87651,
    "out:El kr return": 119075,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 26534,
    "out:% savings (space heating)": 93.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.22,
    "out:Etvv": 0,
    "out:Ef": 38.8,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 156.9,
    "out:Primary Energy": 114.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.61,
    "out:CO2 Operational/m2": 52.86,
    "out:Total CO2/m2": 65.47,
    "out:Total CO2 (tons)": 223.19,
    "out:Klimatpaverkan": 6.19,
    "out:Initial Cost/MSEK": 3.09675,
    "out:Running cost/(Apt SEK y)": 36634,
    "out:Running Cost over RSP/MSEK": 10.599,
    "out:LCP/MSEK": -0.993,
    "out:ROI% old": 9.7,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93066,
    "out:DH kr savings": 93066,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 152.9,
    "out:Primary Energy": 111.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.61,
    "out:CO2 Operational/m2": 51.42,
    "out:Total CO2/m2": 64.03,
    "out:Total CO2 (tons)": 218.28,
    "out:Klimatpaverkan": 1.28,
    "out:Initial Cost/MSEK": 3.182,
    "out:Running cost/(Apt SEK y)": 35665,
    "out:Running Cost over RSP/MSEK": 10.318,
    "out:LCP/MSEK": -0.798,
    "out:ROI% old": 10.7,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 305,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 115906,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 105474,
    "out:DH kr savings": 105474,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 112.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.11,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 63.81,
    "out:Total CO2 (tons)": 217.51,
    "out:Klimatpaverkan": 0.51,
    "out:Initial Cost/MSEK": 3.301625,
    "out:Running cost/(Apt SEK y)": 35181,
    "out:Running Cost over RSP/MSEK": 10.178,
    "out:LCP/MSEK": -0.777,
    "out:ROI% old": 10.92,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111679,
    "out:DH kr savings": 111679,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 146.9,
    "out:Primary Energy": 109.12,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.11,
    "out:CO2 Operational/m2": 49.26,
    "out:Total CO2/m2": 62.37,
    "out:Total CO2 (tons)": 212.61,
    "out:Klimatpaverkan": -4.39,
    "out:Initial Cost/MSEK": 3.386875,
    "out:Running cost/(Apt SEK y)": 34212,
    "out:Running Cost over RSP/MSEK": 9.898,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 11.83,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 136360,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124088,
    "out:DH kr savings": 124088,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 151.73,
    "out:Primary Energy": 105.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.97,
    "out:CO2 Operational/m2": 21.86,
    "out:Total CO2/m2": 46.83,
    "out:Total CO2 (tons)": 159.64,
    "out:Klimatpaverkan": -57.36,
    "out:Initial Cost/MSEK": 3.805875,
    "out:Running cost/(Apt SEK y)": 35118,
    "out:Running Cost over RSP/MSEK": 10.172,
    "out:LCP/MSEK": -1.276,
    "out:ROI% old": 9.5,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 325,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149140,
    "out:DH kr savings": 93066,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 147.73,
    "out:Primary Energy": 102.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.97,
    "out:CO2 Operational/m2": 20.42,
    "out:Total CO2/m2": 45.39,
    "out:Total CO2 (tons)": 154.73,
    "out:Klimatpaverkan": -62.27,
    "out:Initial Cost/MSEK": 3.891,
    "out:Running cost/(Apt SEK y)": 34149,
    "out:Running Cost over RSP/MSEK": 9.892,
    "out:LCP/MSEK": -1.08,
    "out:ROI% old": 10.32,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 115906,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161549,
    "out:DH kr savings": 105474,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 102.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.46,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 45.16,
    "out:Total CO2 (tons)": 153.96,
    "out:Klimatpaverkan": -63.04,
    "out:Initial Cost/MSEK": 4.01075,
    "out:Running cost/(Apt SEK y)": 33665,
    "out:Running Cost over RSP/MSEK": 9.751,
    "out:LCP/MSEK": -1.06,
    "out:ROI% old": 10.51,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167753,
    "out:DH kr savings": 111679,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 141.73,
    "out:Primary Energy": 99.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.46,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 43.72,
    "out:Total CO2 (tons)": 149.06,
    "out:Klimatpaverkan": -67.94,
    "out:Initial Cost/MSEK": 4.096,
    "out:Running cost/(Apt SEK y)": 32696,
    "out:Running Cost over RSP/MSEK": 9.471,
    "out:LCP/MSEK": -0.865,
    "out:ROI% old": 11.27,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 136360,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180162,
    "out:DH kr savings": 124088,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 156.9,
    "out:Total Energy Use Post PV": 150.88,
    "out:Primary Energy": 103.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.32,
    "out:CO2 Operational/m2": -30.82,
    "out:Total CO2/m2": 6.5,
    "out:Total CO2 (tons)": 22.16,
    "out:Klimatpaverkan": -194.84,
    "out:Initial Cost/MSEK": 4.515,
    "out:Running cost/(Apt SEK y)": 33772,
    "out:Running Cost over RSP/MSEK": 9.793,
    "out:LCP/MSEK": -1.606,
    "out:ROI% old": 9.2,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198951,
    "out:DH kr savings": 93066,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 152.9,
    "out:Total Energy Use Post PV": 146.88,
    "out:Primary Energy": 100.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.32,
    "out:CO2 Operational/m2": -32.26,
    "out:Total CO2/m2": 5.06,
    "out:Total CO2 (tons)": 17.25,
    "out:Klimatpaverkan": -199.75,
    "out:Initial Cost/MSEK": 4.60025,
    "out:Running cost/(Apt SEK y)": 32803,
    "out:Running Cost over RSP/MSEK": 9.513,
    "out:LCP/MSEK": -1.411,
    "out:ROI% old": 9.9,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 411,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 115906,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211359,
    "out:DH kr savings": 105474,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 101.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.82,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 4.84,
    "out:Total CO2 (tons)": 16.49,
    "out:Klimatpaverkan": -200.51,
    "out:Initial Cost/MSEK": 4.719875,
    "out:Running cost/(Apt SEK y)": 32319,
    "out:Running Cost over RSP/MSEK": 9.373,
    "out:LCP/MSEK": -1.39,
    "out:ROI% old": 10.08,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217564,
    "out:DH kr savings": 111679,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 140.88,
    "out:Primary Energy": 98.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.82,
    "out:CO2 Operational/m2": -34.42,
    "out:Total CO2/m2": 3.4,
    "out:Total CO2 (tons)": 11.58,
    "out:Klimatpaverkan": -205.42,
    "out:Initial Cost/MSEK": 4.805125,
    "out:Running cost/(Apt SEK y)": 31350,
    "out:Running Cost over RSP/MSEK": 9.092,
    "out:LCP/MSEK": -1.195,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 464,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 136360,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229973,
    "out:DH kr savings": 124088,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 111.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.61,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 62.59,
    "out:Total CO2 (tons)": 213.37,
    "out:Klimatpaverkan": -3.63,
    "out:Initial Cost/MSEK": 3.194375,
    "out:Running cost/(Apt SEK y)": 34696,
    "out:Running Cost over RSP/MSEK": 10.038,
    "out:LCP/MSEK": -0.53,
    "out:ROI% old": 11.92,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117883,
    "out:DH kr savings": 117883,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 108.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.61,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 61.15,
    "out:Total CO2 (tons)": 208.46,
    "out:Klimatpaverkan": -8.54,
    "out:Initial Cost/MSEK": 3.279625,
    "out:Running cost/(Apt SEK y)": 33728,
    "out:Running Cost over RSP/MSEK": 9.757,
    "out:LCP/MSEK": -0.335,
    "out:ROI% old": 12.83,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 130292,
    "out:DH kr savings": 130292,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 109.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.11,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 61.29,
    "out:Total CO2 (tons)": 208.92,
    "out:Klimatpaverkan": -8.08,
    "out:Initial Cost/MSEK": 3.39925,
    "out:Running cost/(Apt SEK y)": 33486,
    "out:Running Cost over RSP/MSEK": 9.687,
    "out:LCP/MSEK": -0.384,
    "out:ROI% old": 12.67,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133394,
    "out:DH kr savings": 133394,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 106.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.11,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 59.49,
    "out:Total CO2 (tons)": 202.79,
    "out:Klimatpaverkan": -14.21,
    "out:Initial Cost/MSEK": 3.4845,
    "out:Running cost/(Apt SEK y)": 32275,
    "out:Running Cost over RSP/MSEK": 9.337,
    "out:LCP/MSEK": -0.119,
    "out:ROI% old": 13.8,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148905,
    "out:DH kr savings": 148905,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 101.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.97,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 43.95,
    "out:Total CO2 (tons)": 149.82,
    "out:Klimatpaverkan": -67.18,
    "out:Initial Cost/MSEK": 3.9035,
    "out:Running cost/(Apt SEK y)": 33181,
    "out:Running Cost over RSP/MSEK": 9.611,
    "out:LCP/MSEK": -0.812,
    "out:ROI% old": 11.31,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 397,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173957,
    "out:DH kr savings": 117883,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 98.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.97,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 42.51,
    "out:Total CO2 (tons)": 144.91,
    "out:Klimatpaverkan": -72.09,
    "out:Initial Cost/MSEK": 3.98875,
    "out:Running cost/(Apt SEK y)": 32212,
    "out:Running Cost over RSP/MSEK": 9.331,
    "out:LCP/MSEK": -0.617,
    "out:ROI% old": 12.08,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186366,
    "out:DH kr savings": 130292,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 99.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.46,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 42.64,
    "out:Total CO2 (tons)": 145.37,
    "out:Klimatpaverkan": -71.63,
    "out:Initial Cost/MSEK": 4.108375,
    "out:Running cost/(Apt SEK y)": 31970,
    "out:Running Cost over RSP/MSEK": 9.26,
    "out:LCP/MSEK": -0.667,
    "out:ROI% old": 11.97,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189468,
    "out:DH kr savings": 133394,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 96.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.46,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 40.84,
    "out:Total CO2 (tons)": 139.24,
    "out:Klimatpaverkan": -77.76,
    "out:Initial Cost/MSEK": 4.193625,
    "out:Running cost/(Apt SEK y)": 30759,
    "out:Running Cost over RSP/MSEK": 8.91,
    "out:LCP/MSEK": -0.401,
    "out:ROI% old": 12.92,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204979,
    "out:DH kr savings": 148905,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 100.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.32,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 3.62,
    "out:Total CO2 (tons)": 12.35,
    "out:Klimatpaverkan": -204.65,
    "out:Initial Cost/MSEK": 4.612625,
    "out:Running cost/(Apt SEK y)": 31835,
    "out:Running Cost over RSP/MSEK": 9.232,
    "out:LCP/MSEK": -1.143,
    "out:ROI% old": 10.75,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223768,
    "out:DH kr savings": 117883,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 97.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.32,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 2.18,
    "out:Total CO2 (tons)": 7.44,
    "out:Klimatpaverkan": -209.56,
    "out:Initial Cost/MSEK": 4.697875,
    "out:Running cost/(Apt SEK y)": 30866,
    "out:Running Cost over RSP/MSEK": 8.952,
    "out:LCP/MSEK": -0.947,
    "out:ROI% old": 11.4,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236177,
    "out:DH kr savings": 130292,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.93,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 98.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.82,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 2.32,
    "out:Total CO2 (tons)": 7.9,
    "out:Klimatpaverkan": -209.1,
    "out:Initial Cost/MSEK": 4.8175,
    "out:Running cost/(Apt SEK y)": 30624,
    "out:Running Cost over RSP/MSEK": 8.882,
    "out:LCP/MSEK": -0.997,
    "out:ROI% old": 11.33,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239279,
    "out:DH kr savings": 133394,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 333852,
    "out:% savings (space heating)": 23.21,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 97.93,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.75,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 95.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.82,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 0.52,
    "out:Total CO2 (tons)": 1.77,
    "out:Klimatpaverkan": -215.23,
    "out:Initial Cost/MSEK": 4.90275,
    "out:Running cost/(Apt SEK y)": 29413,
    "out:Running Cost over RSP/MSEK": 8.531,
    "out:LCP/MSEK": -0.732,
    "out:ROI% old": 12.15,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254790,
    "out:DH kr savings": 148905,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 319607,
    "out:% savings (space heating)": 26.49,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 93.75,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.22,
    "out:Electricity (inc PV)": 41.1,
    "out:Total Energy Use Pre PV": 102.22,
    "out:Total Energy Use Post PV": 100.1,
    "out:Primary Energy": 107.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.12,
    "out:CO2 Operational/m2": 26.94,
    "out:Total CO2/m2": 43.06,
    "out:Total CO2 (tons)": 146.8,
    "out:Klimatpaverkan": -70.2,
    "out:Initial Cost/MSEK": 5.94275,
    "out:Running cost/(Apt SEK y)": 21056,
    "out:Running Cost over RSP/MSEK": 6.073,
    "out:LCP/MSEK": 0.686,
    "out:ROI% old": 15.94,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 3025.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 845,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -85908,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201401,
    "out:DH kr savings": 347445,
    "out:El kr savings": -146044,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 200688,
    "out:% savings (space heating)": 53.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 47.36,
    "out:Etvv": 0,
    "out:Ef": 41.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.15,
    "out:Electricity (inc PV)": 40.99,
    "out:Total Energy Use Pre PV": 98.15,
    "out:Total Energy Use Post PV": 95.99,
    "out:Primary Energy": 104.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.12,
    "out:CO2 Operational/m2": 25.49,
    "out:Total CO2/m2": 41.61,
    "out:Total CO2 (tons)": 141.86,
    "out:Klimatpaverkan": -75.14,
    "out:Initial Cost/MSEK": 6.028,
    "out:Running cost/(Apt SEK y)": 20076,
    "out:Running Cost over RSP/MSEK": 5.79,
    "out:LCP/MSEK": 0.885,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 3020.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 881,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.95,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -85515,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214478,
    "out:DH kr savings": 359854,
    "out:El kr savings": -145376,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 187235,
    "out:% savings (space heating)": 56.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 43.56,
    "out:Etvv": 0,
    "out:Ef": 40.99,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.51,
    "out:Electricity (inc PV)": 40.63,
    "out:Total Energy Use Pre PV": 97.51,
    "out:Total Energy Use Post PV": 95.63,
    "out:Primary Energy": 104.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.61,
    "out:CO2 Operational/m2": 25.41,
    "out:Total CO2/m2": 42.03,
    "out:Total CO2 (tons)": 143.26,
    "out:Klimatpaverkan": -73.74,
    "out:Initial Cost/MSEK": 6.147625,
    "out:Running cost/(Apt SEK y)": 19977,
    "out:Running Cost over RSP/MSEK": 5.761,
    "out:LCP/MSEK": 0.794,
    "out:ROI% old": 16.13,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2975.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 885,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -84309,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216528,
    "out:DH kr savings": 359854,
    "out:El kr savings": -143326,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 186416,
    "out:% savings (space heating)": 57.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 45.16,
    "out:Etvv": 0,
    "out:Ef": 40.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.79,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.42,
    "out:Electricity (inc PV)": 40.51,
    "out:Total Energy Use Pre PV": 93.42,
    "out:Total Energy Use Post PV": 91.51,
    "out:Primary Energy": 101.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.61,
    "out:CO2 Operational/m2": 23.96,
    "out:Total CO2/m2": 40.57,
    "out:Total CO2 (tons)": 138.32,
    "out:Klimatpaverkan": -78.68,
    "out:Initial Cost/MSEK": 6.232875,
    "out:Running cost/(Apt SEK y)": 18995,
    "out:Running Cost over RSP/MSEK": 5.477,
    "out:LCP/MSEK": 0.993,
    "out:ROI% old": 16.56,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2969.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 921,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 409080,
    "out:EL kWh savings": -83880,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229667,
    "out:DH kr savings": 372263,
    "out:El kr savings": -142596,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 173131,
    "out:% savings (space heating)": 60.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.39,
    "out:Etvv": 0,
    "out:Ef": 40.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.22,
    "out:Electricity (inc PV)": 31.8,
    "out:Total Energy Use Pre PV": 102.22,
    "out:Total Energy Use Post PV": 90.8,
    "out:Primary Energy": 90.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.47,
    "out:CO2 Operational/m2": 17.62,
    "out:Total CO2/m2": 46.09,
    "out:Total CO2 (tons)": 157.14,
    "out:Klimatpaverkan": -59.86,
    "out:Initial Cost/MSEK": 6.651875,
    "out:Running cost/(Apt SEK y)": 19370,
    "out:Running Cost over RSP/MSEK": 5.598,
    "out:LCP/MSEK": 0.452,
    "out:ROI% old": 15.26,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2383.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5638.84,
    "out:PV (% sold (El))": 14.8,
    "out:PV (kWh self-consumed)": 32464.05,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -54196,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262502,
    "out:DH kr savings": 347445,
    "out:El kr savings": -92133,
    "out:El kr sold": 7190,
    "out:El kr saved": 55189,
    "out:El kr return": 62378,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 200688,
    "out:% savings (space heating)": 53.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 47.36,
    "out:Etvv": 0,
    "out:Ef": 31.8,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.15,
    "out:Electricity (inc PV)": 31.7,
    "out:Total Energy Use Pre PV": 98.15,
    "out:Total Energy Use Post PV": 86.7,
    "out:Primary Energy": 87.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.47,
    "out:CO2 Operational/m2": 16.14,
    "out:Total CO2/m2": 44.61,
    "out:Total CO2 (tons)": 152.08,
    "out:Klimatpaverkan": -64.92,
    "out:Initial Cost/MSEK": 6.737125,
    "out:Running cost/(Apt SEK y)": 18391,
    "out:Running Cost over RSP/MSEK": 5.315,
    "out:LCP/MSEK": 0.65,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2379.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 944,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.95,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5663.04,
    "out:PV (% sold (El))": 14.86,
    "out:PV (kWh self-consumed)": 32439.86,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -53846,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275537,
    "out:DH kr savings": 359854,
    "out:El kr savings": -91537,
    "out:El kr sold": 7220,
    "out:El kr saved": 55148,
    "out:El kr return": 62368,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 187235,
    "out:% savings (space heating)": 56.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 43.56,
    "out:Etvv": 0,
    "out:Ef": 31.7,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.51,
    "out:Electricity (inc PV)": 31.38,
    "out:Total Energy Use Pre PV": 97.51,
    "out:Total Energy Use Post PV": 86.38,
    "out:Primary Energy": 88.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.97,
    "out:CO2 Operational/m2": 15.73,
    "out:Total CO2/m2": 44.7,
    "out:Total CO2 (tons)": 152.39,
    "out:Klimatpaverkan": -64.61,
    "out:Initial Cost/MSEK": 6.85675,
    "out:Running cost/(Apt SEK y)": 18293,
    "out:Running Cost over RSP/MSEK": 5.287,
    "out:LCP/MSEK": 0.559,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2338.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 947,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5884.62,
    "out:PV (% sold (El))": 15.44,
    "out:PV (kWh self-consumed)": 32218.27,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -52771,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277646,
    "out:DH kr savings": 359854,
    "out:El kr savings": -89711,
    "out:El kr sold": 7503,
    "out:El kr saved": 54771,
    "out:El kr return": 62274,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 186416,
    "out:% savings (space heating)": 57.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 45.16,
    "out:Etvv": 0,
    "out:Ef": 31.38,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.79,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.42,
    "out:Electricity (inc PV)": 31.27,
    "out:Total Energy Use Pre PV": 93.42,
    "out:Total Energy Use Post PV": 82.27,
    "out:Primary Energy": 85.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.97,
    "out:CO2 Operational/m2": 14.24,
    "out:Total CO2/m2": 43.21,
    "out:Total CO2 (tons)": 147.29,
    "out:Klimatpaverkan": -69.71,
    "out:Initial Cost/MSEK": 6.942,
    "out:Running cost/(Apt SEK y)": 17312,
    "out:Running Cost over RSP/MSEK": 5.003,
    "out:LCP/MSEK": 0.758,
    "out:ROI% old": 15.84,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2333.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5914.55,
    "out:PV (% sold (El))": 15.52,
    "out:PV (kWh self-consumed)": 32188.34,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 409080,
    "out:EL kWh savings": -52389,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 290742,
    "out:DH kr savings": 372263,
    "out:El kr savings": -89062,
    "out:El kr sold": 7541,
    "out:El kr saved": 54720,
    "out:El kr return": 62261,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 173131,
    "out:% savings (space heating)": 60.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.39,
    "out:Etvv": 0,
    "out:Ef": 31.27,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.22,
    "out:Electricity (inc PV)": 28.74,
    "out:Total Energy Use Pre PV": 102.22,
    "out:Total Energy Use Post PV": 87.74,
    "out:Primary Energy": 84.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.82,
    "out:CO2 Operational/m2": -22.89,
    "out:Total CO2/m2": 17.93,
    "out:Total CO2 (tons)": 61.13,
    "out:Klimatpaverkan": -155.87,
    "out:Initial Cost/MSEK": 7.361,
    "out:Running cost/(Apt SEK y)": 17930,
    "out:Running Cost over RSP/MSEK": 5.193,
    "out:LCP/MSEK": 0.149,
    "out:ROI% old": 14.57,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2152.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 961,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32661.99,
    "out:PV (% sold (El))": 42.86,
    "out:PV (kWh self-consumed)": 43543.79,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -43788,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314651,
    "out:DH kr savings": 347445,
    "out:El kr savings": -74439,
    "out:El kr sold": 41644,
    "out:El kr saved": 74024,
    "out:El kr return": 115668,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 200688,
    "out:% savings (space heating)": 53.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 47.36,
    "out:Etvv": 0,
    "out:Ef": 28.74,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.15,
    "out:Electricity (inc PV)": 28.65,
    "out:Total Energy Use Pre PV": 98.15,
    "out:Total Energy Use Post PV": 83.65,
    "out:Primary Energy": 82.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.82,
    "out:CO2 Operational/m2": -24.41,
    "out:Total CO2/m2": 16.41,
    "out:Total CO2 (tons)": 55.95,
    "out:Klimatpaverkan": -161.05,
    "out:Initial Cost/MSEK": 7.44625,
    "out:Running cost/(Apt SEK y)": 16951,
    "out:Running Cost over RSP/MSEK": 4.909,
    "out:LCP/MSEK": 0.347,
    "out:ROI% old": 14.95,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2148.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 997,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.95,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32708.7,
    "out:PV (% sold (El))": 42.92,
    "out:PV (kWh self-consumed)": 43497.08,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -43473,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327653,
    "out:DH kr savings": 359854,
    "out:El kr savings": -73905,
    "out:El kr sold": 41704,
    "out:El kr saved": 73945,
    "out:El kr return": 115649,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 187235,
    "out:% savings (space heating)": 56.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 43.56,
    "out:Etvv": 0,
    "out:Ef": 28.65,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.51,
    "out:Electricity (inc PV)": 28.37,
    "out:Total Energy Use Pre PV": 97.51,
    "out:Total Energy Use Post PV": 83.37,
    "out:Primary Energy": 82.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.32,
    "out:CO2 Operational/m2": -25.12,
    "out:Total CO2/m2": 16.2,
    "out:Total CO2 (tons)": 55.23,
    "out:Klimatpaverkan": -161.77,
    "out:Initial Cost/MSEK": 7.565875,
    "out:Running cost/(Apt SEK y)": 16856,
    "out:Running Cost over RSP/MSEK": 4.882,
    "out:LCP/MSEK": 0.254,
    "out:ROI% old": 14.77,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 125.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2111.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1001,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.31,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33133.94,
    "out:PV (% sold (El))": 43.48,
    "out:PV (kWh self-consumed)": 43071.85,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -42509,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 329834,
    "out:DH kr savings": 359854,
    "out:El kr savings": -72265,
    "out:El kr sold": 42246,
    "out:El kr saved": 73222,
    "out:El kr return": 115468,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 186416,
    "out:% savings (space heating)": 57.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 45.16,
    "out:Etvv": 0,
    "out:Ef": 28.37,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.79,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.42,
    "out:Electricity (inc PV)": 28.27,
    "out:Total Energy Use Pre PV": 93.42,
    "out:Total Energy Use Post PV": 79.27,
    "out:Primary Energy": 79.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.32,
    "out:CO2 Operational/m2": -26.65,
    "out:Total CO2/m2": 14.67,
    "out:Total CO2 (tons)": 50,
    "out:Klimatpaverkan": -167,
    "out:Initial Cost/MSEK": 7.651125,
    "out:Running cost/(Apt SEK y)": 15874,
    "out:Running Cost over RSP/MSEK": 4.598,
    "out:LCP/MSEK": 0.453,
    "out:ROI% old": 15.13,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2107.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1037,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33191.06,
    "out:PV (% sold (El))": 43.55,
    "out:PV (kWh self-consumed)": 43014.72,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 409080,
    "out:EL kWh savings": -42166,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342899,
    "out:DH kr savings": 372263,
    "out:El kr savings": -71683,
    "out:El kr sold": 42319,
    "out:El kr saved": 73125,
    "out:El kr return": 115444,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 173131,
    "out:% savings (space heating)": 60.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.39,
    "out:Etvv": 0,
    "out:Ef": 28.27,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.26,
    "out:Electricity (inc PV)": 40.47,
    "out:Total Energy Use Pre PV": 95.26,
    "out:Total Energy Use Post PV": 93.47,
    "out:Primary Energy": 103.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.12,
    "out:CO2 Operational/m2": 24.66,
    "out:Total CO2/m2": 40.78,
    "out:Total CO2 (tons)": 139.01,
    "out:Klimatpaverkan": -77.99,
    "out:Initial Cost/MSEK": 6.040375,
    "out:Running cost/(Apt SEK y)": 19454,
    "out:Running Cost over RSP/MSEK": 5.61,
    "out:LCP/MSEK": 1.052,
    "out:ROI% old": 16.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2958.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 904,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -83766,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223657,
    "out:DH kr savings": 366058,
    "out:El kr savings": -142402,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 181766,
    "out:% savings (space heating)": 58.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.43,
    "out:Etvv": 0,
    "out:Ef": 40.47,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.17,
    "out:Electricity (inc PV)": 40.34,
    "out:Total Energy Use Pre PV": 91.17,
    "out:Total Energy Use Post PV": 89.34,
    "out:Primary Energy": 101.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.12,
    "out:CO2 Operational/m2": 23.21,
    "out:Total CO2/m2": 39.32,
    "out:Total CO2 (tons)": 134.06,
    "out:Klimatpaverkan": -82.94,
    "out:Initial Cost/MSEK": 6.125625,
    "out:Running cost/(Apt SEK y)": 18471,
    "out:Running Cost over RSP/MSEK": 5.325,
    "out:LCP/MSEK": 1.251,
    "out:ROI% old": 17.2,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2952.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 941,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -83326,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236813,
    "out:DH kr savings": 378467,
    "out:El kr savings": -141654,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 168537,
    "out:% savings (space heating)": 61.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 40.68,
    "out:Etvv": 0,
    "out:Ef": 40.34,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.66,
    "out:Electricity (inc PV)": 40.08,
    "out:Total Energy Use Pre PV": 91.66,
    "out:Total Energy Use Post PV": 90.08,
    "out:Primary Energy": 102.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.61,
    "out:CO2 Operational/m2": 23.5,
    "out:Total CO2/m2": 40.11,
    "out:Total CO2 (tons)": 136.74,
    "out:Klimatpaverkan": -80.26,
    "out:Initial Cost/MSEK": 6.245375,
    "out:Running cost/(Apt SEK y)": 18632,
    "out:Running Cost over RSP/MSEK": 5.372,
    "out:LCP/MSEK": 1.085,
    "out:ROI% old": 16.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2915.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 935,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -82444,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235209,
    "out:DH kr savings": 375365,
    "out:El kr savings": -140156,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170815,
    "out:% savings (space heating)": 60.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.71,
    "out:Etvv": 0,
    "out:Ef": 40.08,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 39.95,
    "out:Total Energy Use Pre PV": 87.55,
    "out:Total Energy Use Post PV": 85.95,
    "out:Primary Energy": 99.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.61,
    "out:CO2 Operational/m2": 22.04,
    "out:Total CO2/m2": 38.66,
    "out:Total CO2 (tons)": 131.79,
    "out:Klimatpaverkan": -85.21,
    "out:Initial Cost/MSEK": 6.3305,
    "out:Running cost/(Apt SEK y)": 17647,
    "out:Running Cost over RSP/MSEK": 5.087,
    "out:LCP/MSEK": 1.285,
    "out:ROI% old": 17.18,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2908.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -81977,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248412,
    "out:DH kr savings": 387774,
    "out:El kr savings": -139361,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 157736,
    "out:% savings (space heating)": 63.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.99,
    "out:Etvv": 0,
    "out:Ef": 39.95,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.26,
    "out:Electricity (inc PV)": 31.24,
    "out:Total Energy Use Pre PV": 95.26,
    "out:Total Energy Use Post PV": 84.24,
    "out:Primary Energy": 87.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.47,
    "out:CO2 Operational/m2": 14.86,
    "out:Total CO2/m2": 43.33,
    "out:Total CO2 (tons)": 147.7,
    "out:Klimatpaverkan": -69.3,
    "out:Initial Cost/MSEK": 6.7495,
    "out:Running cost/(Apt SEK y)": 17772,
    "out:Running Cost over RSP/MSEK": 5.136,
    "out:LCP/MSEK": 0.817,
    "out:ROI% old": 16.01,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2323,
    "out:Return %": 14,
    "out:Return/kSEK/y": 967,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5971.12,
    "out:PV (% sold (El))": 15.67,
    "out:PV (kWh self-consumed)": 32131.77,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -52287,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284783,
    "out:DH kr savings": 366058,
    "out:El kr savings": -88889,
    "out:El kr sold": 7613,
    "out:El kr saved": 54624,
    "out:El kr return": 62237,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 181766,
    "out:% savings (space heating)": 58.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.43,
    "out:Etvv": 0,
    "out:Ef": 31.24,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.17,
    "out:Electricity (inc PV)": 31.12,
    "out:Total Energy Use Pre PV": 91.17,
    "out:Total Energy Use Post PV": 80.12,
    "out:Primary Energy": 84.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.47,
    "out:CO2 Operational/m2": 13.36,
    "out:Total CO2/m2": 41.83,
    "out:Total CO2 (tons)": 142.59,
    "out:Klimatpaverkan": -74.41,
    "out:Initial Cost/MSEK": 6.83475,
    "out:Running cost/(Apt SEK y)": 16789,
    "out:Running Cost over RSP/MSEK": 4.852,
    "out:LCP/MSEK": 1.016,
    "out:ROI% old": 16.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2317.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6003.1,
    "out:PV (% sold (El))": 15.75,
    "out:PV (kWh self-consumed)": 32099.79,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -51897,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297897,
    "out:DH kr savings": 378467,
    "out:El kr savings": -88224,
    "out:El kr sold": 7654,
    "out:El kr saved": 54570,
    "out:El kr return": 62224,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 168537,
    "out:% savings (space heating)": 61.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 40.68,
    "out:Etvv": 0,
    "out:Ef": 31.12,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.66,
    "out:Electricity (inc PV)": 30.89,
    "out:Total Energy Use Pre PV": 91.66,
    "out:Total Energy Use Post PV": 80.89,
    "out:Primary Energy": 85.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.97,
    "out:CO2 Operational/m2": 13.38,
    "out:Total CO2/m2": 42.35,
    "out:Total CO2 (tons)": 144.37,
    "out:Klimatpaverkan": -72.63,
    "out:Initial Cost/MSEK": 6.954375,
    "out:Running cost/(Apt SEK y)": 16952,
    "out:Running Cost over RSP/MSEK": 4.899,
    "out:LCP/MSEK": 0.849,
    "out:ROI% old": 16.03,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2284.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 997,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6188.07,
    "out:PV (% sold (El))": 16.24,
    "out:PV (kWh self-consumed)": 31914.83,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -51113,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 296362,
    "out:DH kr savings": 375365,
    "out:El kr savings": -86893,
    "out:El kr sold": 7890,
    "out:El kr saved": 54255,
    "out:El kr return": 62145,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170815,
    "out:% savings (space heating)": 60.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.71,
    "out:Etvv": 0,
    "out:Ef": 30.89,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 30.77,
    "out:Total Energy Use Pre PV": 87.55,
    "out:Total Energy Use Post PV": 76.77,
    "out:Primary Energy": 82.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.97,
    "out:CO2 Operational/m2": 11.87,
    "out:Total CO2/m2": 40.84,
    "out:Total CO2 (tons)": 139.23,
    "out:Klimatpaverkan": -77.77,
    "out:Initial Cost/MSEK": 7.039625,
    "out:Running cost/(Apt SEK y)": 15968,
    "out:Running Cost over RSP/MSEK": 4.614,
    "out:LCP/MSEK": 1.049,
    "out:ROI% old": 16.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2277.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1033,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6225.52,
    "out:PV (% sold (El))": 16.34,
    "out:PV (kWh self-consumed)": 31877.37,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -50699,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 309523,
    "out:DH kr savings": 387774,
    "out:El kr savings": -86188,
    "out:El kr sold": 7938,
    "out:El kr saved": 54192,
    "out:El kr return": 62129,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 157736,
    "out:% savings (space heating)": 63.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.99,
    "out:Etvv": 0,
    "out:Ef": 30.77,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.26,
    "out:Electricity (inc PV)": 28.24,
    "out:Total Energy Use Pre PV": 95.26,
    "out:Total Energy Use Post PV": 81.24,
    "out:Primary Energy": 81.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.82,
    "out:CO2 Operational/m2": -26.11,
    "out:Total CO2/m2": 14.71,
    "out:Total CO2 (tons)": 50.15,
    "out:Klimatpaverkan": -166.85,
    "out:Initial Cost/MSEK": 7.458625,
    "out:Running cost/(Apt SEK y)": 16335,
    "out:Running Cost over RSP/MSEK": 4.731,
    "out:LCP/MSEK": 0.512,
    "out:ROI% old": 15.27,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2098,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1020,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.06,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33298.55,
    "out:PV (% sold (El))": 43.7,
    "out:PV (kWh self-consumed)": 42907.24,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -42075,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336987,
    "out:DH kr savings": 366058,
    "out:El kr savings": -71527,
    "out:El kr sold": 42456,
    "out:El kr saved": 72942,
    "out:El kr return": 115398,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 181766,
    "out:% savings (space heating)": 58.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.43,
    "out:Etvv": 0,
    "out:Ef": 28.24,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.44,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.17,
    "out:Electricity (inc PV)": 28.14,
    "out:Total Energy Use Pre PV": 91.17,
    "out:Total Energy Use Post PV": 77.14,
    "out:Primary Energy": 79.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.82,
    "out:CO2 Operational/m2": -27.65,
    "out:Total CO2/m2": 13.17,
    "out:Total CO2 (tons)": 44.9,
    "out:Klimatpaverkan": -172.1,
    "out:Initial Cost/MSEK": 7.543875,
    "out:Running cost/(Apt SEK y)": 15353,
    "out:Running Cost over RSP/MSEK": 4.447,
    "out:LCP/MSEK": 0.711,
    "out:ROI% old": 15.63,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2092.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1056,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33359.18,
    "out:PV (% sold (El))": 43.78,
    "out:PV (kWh self-consumed)": 42846.61,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -41724,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350070,
    "out:DH kr savings": 378467,
    "out:El kr savings": -70931,
    "out:El kr sold": 42533,
    "out:El kr saved": 72839,
    "out:El kr return": 115372,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 168537,
    "out:% savings (space heating)": 61.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 40.68,
    "out:Etvv": 0,
    "out:Ef": 28.14,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.66,
    "out:Electricity (inc PV)": 27.93,
    "out:Total Energy Use Pre PV": 91.66,
    "out:Total Energy Use Post PV": 77.93,
    "out:Primary Energy": 80.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.32,
    "out:CO2 Operational/m2": -27.88,
    "out:Total CO2/m2": 13.45,
    "out:Total CO2 (tons)": 45.84,
    "out:Klimatpaverkan": -171.16,
    "out:Initial Cost/MSEK": 7.6635,
    "out:Running cost/(Apt SEK y)": 15518,
    "out:Running Cost over RSP/MSEK": 4.495,
    "out:LCP/MSEK": 0.544,
    "out:ROI% old": 15.3,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2063.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1050,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33708.22,
    "out:PV (% sold (El))": 44.23,
    "out:PV (kWh self-consumed)": 42497.57,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -41020,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 348608,
    "out:DH kr savings": 375365,
    "out:El kr savings": -69735,
    "out:El kr sold": 42978,
    "out:El kr saved": 72246,
    "out:El kr return": 115224,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170815,
    "out:% savings (space heating)": 60.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.71,
    "out:Etvv": 0,
    "out:Ef": 27.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 27.82,
    "out:Total Energy Use Pre PV": 87.55,
    "out:Total Energy Use Post PV": 73.82,
    "out:Primary Energy": 77.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.32,
    "out:CO2 Operational/m2": -29.43,
    "out:Total CO2/m2": 11.89,
    "out:Total CO2 (tons)": 40.53,
    "out:Klimatpaverkan": -176.47,
    "out:Initial Cost/MSEK": 7.74875,
    "out:Running cost/(Apt SEK y)": 14534,
    "out:Running Cost over RSP/MSEK": 4.21,
    "out:LCP/MSEK": 0.743,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2057.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1086,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33778.38,
    "out:PV (% sold (El))": 44.33,
    "out:PV (kWh self-consumed)": 42427.41,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -40648,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 361740,
    "out:DH kr savings": 387774,
    "out:El kr savings": -69101,
    "out:El kr sold": 43067,
    "out:El kr saved": 72127,
    "out:El kr return": 115194,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 157736,
    "out:% savings (space heating)": 63.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.99,
    "out:Etvv": 0,
    "out:Ef": 27.82,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.5,
    "out:Electricity (inc PV)": 54.06,
    "out:Total Energy Use Pre PV": 66.5,
    "out:Total Energy Use Post PV": 62.06,
    "out:Primary Energy": 100.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.71,
    "out:CO2 Operational/m2": 10.6,
    "out:Total CO2/m2": 27.31,
    "out:Total CO2 (tons)": 93.11,
    "out:Klimatpaverkan": -123.89,
    "out:Initial Cost/MSEK": 7.799,
    "out:Running cost/(Apt SEK y)": 11101,
    "out:Running Cost over RSP/MSEK": 3.182,
    "out:LCP/MSEK": 1.721,
    "out:ROI% old": 17.44,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4095.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1214,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -130086,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284510,
    "out:DH kr savings": 505657,
    "out:El kr savings": -221147,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 28740,
    "out:% savings (space heating)": 93.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.59,
    "out:Etvv": 0,
    "out:Ef": 54.06,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.85,
    "out:Electricity (inc PV)": 53.17,
    "out:Total Energy Use Pre PV": 63.85,
    "out:Total Energy Use Post PV": 59.17,
    "out:Primary Energy": 98.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.71,
    "out:CO2 Operational/m2": 9.8,
    "out:Total CO2/m2": 26.51,
    "out:Total CO2 (tons)": 90.36,
    "out:Klimatpaverkan": -126.64,
    "out:Initial Cost/MSEK": 7.88425,
    "out:Running cost/(Apt SEK y)": 10514,
    "out:Running Cost over RSP/MSEK": 3.012,
    "out:LCP/MSEK": 1.806,
    "out:ROI% old": 17.56,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 4049.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1235,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -127069,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 295844,
    "out:DH kr savings": 511861,
    "out:El kr savings": -216017,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 21859,
    "out:% savings (space heating)": 94.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.34,
    "out:Etvv": 0,
    "out:Ef": 53.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57,
    "out:Electricity (inc PV)": 53.09,
    "out:Total Energy Use Pre PV": 64,
    "out:Total Energy Use Post PV": 60.09,
    "out:Primary Energy": 98.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.21,
    "out:CO2 Operational/m2": 10.04,
    "out:Total CO2/m2": 27.25,
    "out:Total CO2 (tons)": 92.9,
    "out:Klimatpaverkan": -124.1,
    "out:Initial Cost/MSEK": 8.003875,
    "out:Running cost/(Apt SEK y)": 10623,
    "out:Running Cost over RSP/MSEK": 3.045,
    "out:LCP/MSEK": 1.654,
    "out:ROI% old": 17.24,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3990,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1231,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -126777,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 293239,
    "out:DH kr savings": 508759,
    "out:El kr savings": -215520,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 23403,
    "out:% savings (space heating)": 94.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 53.09,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.27,
    "out:Electricity (inc PV)": 52.17,
    "out:Total Energy Use Pre PV": 61.27,
    "out:Total Energy Use Post PV": 57.17,
    "out:Primary Energy": 95.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.21,
    "out:CO2 Operational/m2": 9.23,
    "out:Total CO2/m2": 26.43,
    "out:Total CO2 (tons)": 90.12,
    "out:Klimatpaverkan": -126.88,
    "out:Initial Cost/MSEK": 8.089125,
    "out:Running cost/(Apt SEK y)": 10024,
    "out:Running Cost over RSP/MSEK": 2.872,
    "out:LCP/MSEK": 1.742,
    "out:ROI% old": 17.36,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3938.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1253,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -123635,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304785,
    "out:DH kr savings": 514964,
    "out:El kr savings": -210179,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17384,
    "out:% savings (space heating)": 96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.91,
    "out:Etvv": 0,
    "out:Ef": 52.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.5,
    "out:Electricity (inc PV)": 43.66,
    "out:Total Energy Use Pre PV": 66.5,
    "out:Total Energy Use Post PV": 51.66,
    "out:Primary Energy": 81.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.06,
    "out:CO2 Operational/m2": 6.75,
    "out:Total CO2/m2": 35.82,
    "out:Total CO2 (tons)": 122.1,
    "out:Klimatpaverkan": -94.9,
    "out:Initial Cost/MSEK": 8.508125,
    "out:Running cost/(Apt SEK y)": 9371,
    "out:Running Cost over RSP/MSEK": 2.695,
    "out:LCP/MSEK": 1.499,
    "out:ROI% old": 16.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3386.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1278,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1804.22,
    "out:PV (% sold (El))": 4.74,
    "out:PV (kWh self-consumed)": 36298.67,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -94639,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 347072,
    "out:DH kr savings": 505657,
    "out:El kr savings": -160886,
    "out:El kr sold": 2300,
    "out:El kr saved": 61708,
    "out:El kr return": 64008,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 28740,
    "out:% savings (space heating)": 93.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.59,
    "out:Etvv": 0,
    "out:Ef": 43.66,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.85,
    "out:Electricity (inc PV)": 42.83,
    "out:Total Energy Use Pre PV": 63.85,
    "out:Total Energy Use Post PV": 48.83,
    "out:Primary Energy": 79.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.06,
    "out:CO2 Operational/m2": 5.79,
    "out:Total CO2/m2": 34.85,
    "out:Total CO2 (tons)": 118.8,
    "out:Klimatpaverkan": -98.2,
    "out:Initial Cost/MSEK": 8.593375,
    "out:Running cost/(Apt SEK y)": 8785,
    "out:Running Cost over RSP/MSEK": 2.526,
    "out:LCP/MSEK": 1.583,
    "out:ROI% old": 16.92,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 281.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3342.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1299,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1917.41,
    "out:PV (% sold (El))": 5.03,
    "out:PV (kWh self-consumed)": 36185.49,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -91815,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 358221,
    "out:DH kr savings": 511861,
    "out:El kr savings": -156086,
    "out:El kr sold": 2445,
    "out:El kr saved": 61515,
    "out:El kr return": 63960,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 21859,
    "out:% savings (space heating)": 94.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.34,
    "out:Etvv": 0,
    "out:Ef": 42.83,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57,
    "out:Electricity (inc PV)": 42.75,
    "out:Total Energy Use Pre PV": 64,
    "out:Total Energy Use Post PV": 49.75,
    "out:Primary Energy": 79.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.56,
    "out:CO2 Operational/m2": 5.82,
    "out:Total CO2/m2": 35.38,
    "out:Total CO2 (tons)": 120.61,
    "out:Klimatpaverkan": -96.39,
    "out:Initial Cost/MSEK": 8.713,
    "out:Running cost/(Apt SEK y)": 8896,
    "out:Running Cost over RSP/MSEK": 2.558,
    "out:LCP/MSEK": 1.431,
    "out:ROI% old": 16.63,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3286,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1295,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2070.5,
    "out:PV (% sold (El))": 5.43,
    "out:PV (kWh self-consumed)": 36032.39,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -91542,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355778,
    "out:DH kr savings": 508759,
    "out:El kr savings": -155621,
    "out:El kr sold": 2640,
    "out:El kr saved": 61255,
    "out:El kr return": 63895,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 23403,
    "out:% savings (space heating)": 94.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 42.75,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.27,
    "out:Electricity (inc PV)": 41.89,
    "out:Total Energy Use Pre PV": 61.27,
    "out:Total Energy Use Post PV": 46.89,
    "out:Primary Energy": 77.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.56,
    "out:CO2 Operational/m2": 4.81,
    "out:Total CO2/m2": 34.37,
    "out:Total CO2 (tons)": 117.16,
    "out:Klimatpaverkan": -99.84,
    "out:Initial Cost/MSEK": 8.79825,
    "out:Running cost/(Apt SEK y)": 8299,
    "out:Running Cost over RSP/MSEK": 2.386,
    "out:LCP/MSEK": 1.518,
    "out:ROI% old": 16.75,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3237.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1317,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2207.86,
    "out:PV (% sold (El))": 5.79,
    "out:PV (kWh self-consumed)": 35895.03,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -88612,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 367139,
    "out:DH kr savings": 514964,
    "out:El kr savings": -150640,
    "out:El kr sold": 2815,
    "out:El kr saved": 61022,
    "out:El kr return": 63837,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17384,
    "out:% savings (space heating)": 96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.91,
    "out:Etvv": 0,
    "out:Ef": 41.89,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.5,
    "out:Electricity (inc PV)": 39.33,
    "out:Total Energy Use Pre PV": 66.5,
    "out:Total Energy Use Post PV": 47.33,
    "out:Primary Energy": 74.01,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.42,
    "out:CO2 Operational/m2": -25.83,
    "out:Total CO2/m2": 15.59,
    "out:Total CO2 (tons)": 53.14,
    "out:Klimatpaverkan": -163.86,
    "out:Initial Cost/MSEK": 9.21725,
    "out:Running cost/(Apt SEK y)": 7869,
    "out:Running Cost over RSP/MSEK": 2.272,
    "out:LCP/MSEK": 1.213,
    "out:ROI% old": 16.17,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3043,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1333,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23518.59,
    "out:PV (% sold (El))": 30.86,
    "out:PV (kWh self-consumed)": 52687.19,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -79873,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 399859,
    "out:DH kr savings": 505657,
    "out:El kr savings": -135784,
    "out:El kr sold": 29986,
    "out:El kr saved": 89568,
    "out:El kr return": 119554,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 28740,
    "out:% savings (space heating)": 93.39,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.59,
    "out:Etvv": 0,
    "out:Ef": 39.33,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.85,
    "out:Electricity (inc PV)": 38.59,
    "out:Total Energy Use Pre PV": 63.85,
    "out:Total Energy Use Post PV": 44.59,
    "out:Primary Energy": 71.8,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.42,
    "out:CO2 Operational/m2": -27.16,
    "out:Total CO2/m2": 14.26,
    "out:Total CO2 (tons)": 48.61,
    "out:Klimatpaverkan": -168.39,
    "out:Initial Cost/MSEK": 9.3025,
    "out:Running cost/(Apt SEK y)": 7287,
    "out:Running Cost over RSP/MSEK": 2.103,
    "out:LCP/MSEK": 1.296,
    "out:ROI% old": 16.28,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3004.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1355,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.65,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23876.09,
    "out:PV (% sold (El))": 31.33,
    "out:PV (kWh self-consumed)": 52329.69,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -77363,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 410786,
    "out:DH kr savings": 511861,
    "out:El kr savings": -131517,
    "out:El kr sold": 30442,
    "out:El kr saved": 88960,
    "out:El kr return": 119402,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 21859,
    "out:% savings (space heating)": 94.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.34,
    "out:Etvv": 0,
    "out:Ef": 38.59,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57,
    "out:Electricity (inc PV)": 38.52,
    "out:Total Energy Use Pre PV": 64,
    "out:Total Energy Use Post PV": 45.52,
    "out:Primary Energy": 72.17,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.91,
    "out:CO2 Operational/m2": -27.6,
    "out:Total CO2/m2": 14.32,
    "out:Total CO2 (tons)": 48.81,
    "out:Klimatpaverkan": -168.19,
    "out:Initial Cost/MSEK": 9.422125,
    "out:Running cost/(Apt SEK y)": 7401,
    "out:Running Cost over RSP/MSEK": 2.137,
    "out:LCP/MSEK": 1.143,
    "out:ROI% old": 16.02,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2953.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1350,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.8,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24342.84,
    "out:PV (% sold (El))": 31.94,
    "out:PV (kWh self-consumed)": 51862.95,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -77120,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 408692,
    "out:DH kr savings": 508759,
    "out:El kr savings": -131105,
    "out:El kr sold": 31037,
    "out:El kr saved": 88167,
    "out:El kr return": 119204,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 23403,
    "out:% savings (space heating)": 94.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.05,
    "out:Etvv": 0,
    "out:Ef": 38.52,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.27,
    "out:Electricity (inc PV)": 37.76,
    "out:Total Energy Use Pre PV": 61.27,
    "out:Total Energy Use Post PV": 42.76,
    "out:Primary Energy": 70,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.91,
    "out:CO2 Operational/m2": -29.01,
    "out:Total CO2/m2": 12.91,
    "out:Total CO2 (tons)": 44,
    "out:Klimatpaverkan": -173,
    "out:Initial Cost/MSEK": 9.507375,
    "out:Running cost/(Apt SEK y)": 6807,
    "out:Running Cost over RSP/MSEK": 1.965,
    "out:LCP/MSEK": 1.229,
    "out:ROI% old": 16.13,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2910.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1372,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24748.25,
    "out:PV (% sold (El))": 32.48,
    "out:PV (kWh self-consumed)": 51457.54,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -74513,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 419845,
    "out:DH kr savings": 514964,
    "out:El kr savings": -126672,
    "out:El kr sold": 31554,
    "out:El kr saved": 87478,
    "out:El kr return": 119032,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17384,
    "out:% savings (space heating)": 96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.91,
    "out:Etvv": 0,
    "out:Ef": 37.76,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.49,
    "out:Electricity (inc PV)": 52.76,
    "out:Total Energy Use Pre PV": 62.49,
    "out:Total Energy Use Post PV": 58.76,
    "out:Primary Energy": 97.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.71,
    "out:CO2 Operational/m2": 9.62,
    "out:Total CO2/m2": 26.33,
    "out:Total CO2 (tons)": 89.75,
    "out:Klimatpaverkan": -127.25,
    "out:Initial Cost/MSEK": 7.896625,
    "out:Running cost/(Apt SEK y)": 10300,
    "out:Running Cost over RSP/MSEK": 2.951,
    "out:LCP/MSEK": 1.854,
    "out:ROI% old": 17.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3954,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1243,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -125658,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298243,
    "out:DH kr savings": 511861,
    "out:El kr savings": -213618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 21781,
    "out:% savings (space heating)": 94.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.88,
    "out:Etvv": 0,
    "out:Ef": 52.76,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.72,
    "out:Electricity (inc PV)": 51.83,
    "out:Total Energy Use Pre PV": 60.72,
    "out:Total Energy Use Post PV": 56.83,
    "out:Primary Energy": 95.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.71,
    "out:CO2 Operational/m2": 9.16,
    "out:Total CO2/m2": 25.87,
    "out:Total CO2 (tons)": 88.18,
    "out:Klimatpaverkan": -128.82,
    "out:Initial Cost/MSEK": 7.981875,
    "out:Running cost/(Apt SEK y)": 9939,
    "out:Running Cost over RSP/MSEK": 2.847,
    "out:LCP/MSEK": 1.873,
    "out:ROI% old": 17.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3900.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1256,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -122479,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 306750,
    "out:DH kr savings": 514964,
    "out:El kr savings": -208213,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 16098,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.78,
    "out:Etvv": 0,
    "out:Ef": 51.83,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.22,
    "out:Electricity (inc PV)": 51.97,
    "out:Total Energy Use Pre PV": 60.22,
    "out:Total Energy Use Post PV": 56.97,
    "out:Primary Energy": 95.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.21,
    "out:CO2 Operational/m2": 9.09,
    "out:Total CO2/m2": 26.3,
    "out:Total CO2 (tons)": 89.64,
    "out:Klimatpaverkan": -127.36,
    "out:Initial Cost/MSEK": 8.101625,
    "out:Running cost/(Apt SEK y)": 9859,
    "out:Running Cost over RSP/MSEK": 2.824,
    "out:LCP/MSEK": 1.776,
    "out:ROI% old": 17.42,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3865.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1259,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -122949,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 305951,
    "out:DH kr savings": 514964,
    "out:El kr savings": -209013,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18275,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.5,
    "out:Etvv": 0,
    "out:Ef": 51.97,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.38,
    "out:Electricity (inc PV)": 51.01,
    "out:Total Energy Use Pre PV": 58.38,
    "out:Total Energy Use Post PV": 55.01,
    "out:Primary Energy": 93.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.21,
    "out:CO2 Operational/m2": 8.62,
    "out:Total CO2/m2": 25.83,
    "out:Total CO2 (tons)": 88.04,
    "out:Klimatpaverkan": -128.96,
    "out:Initial Cost/MSEK": 8.18675,
    "out:Running cost/(Apt SEK y)": 9487,
    "out:Running Cost over RSP/MSEK": 2.717,
    "out:LCP/MSEK": 1.799,
    "out:ROI% old": 17.42,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3806.8,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1273,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -119687,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314597,
    "out:DH kr savings": 518066,
    "out:El kr savings": -203468,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13387,
    "out:% savings (space heating)": 96.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.48,
    "out:Etvv": 0,
    "out:Ef": 51.01,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.49,
    "out:Electricity (inc PV)": 42.45,
    "out:Total Energy Use Pre PV": 62.49,
    "out:Total Energy Use Post PV": 48.45,
    "out:Primary Energy": 79.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.06,
    "out:CO2 Operational/m2": 5.25,
    "out:Total CO2/m2": 34.32,
    "out:Total CO2 (tons)": 116.99,
    "out:Klimatpaverkan": -100.01,
    "out:Initial Cost/MSEK": 8.60575,
    "out:Running cost/(Apt SEK y)": 8574,
    "out:Running Cost over RSP/MSEK": 2.466,
    "out:LCP/MSEK": 1.631,
    "out:ROI% old": 16.99,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3251.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1307,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2166.72,
    "out:PV (% sold (El))": 5.69,
    "out:PV (kWh self-consumed)": 35936.17,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -90497,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 360779,
    "out:DH kr savings": 511861,
    "out:El kr savings": -153845,
    "out:El kr sold": 2763,
    "out:El kr saved": 61091,
    "out:El kr return": 63854,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 21781,
    "out:% savings (space heating)": 94.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.88,
    "out:Etvv": 0,
    "out:Ef": 42.45,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.72,
    "out:Electricity (inc PV)": 41.58,
    "out:Total Energy Use Pre PV": 60.72,
    "out:Total Energy Use Post PV": 46.58,
    "out:Primary Energy": 76.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.06,
    "out:CO2 Operational/m2": 4.59,
    "out:Total CO2/m2": 33.65,
    "out:Total CO2 (tons)": 114.71,
    "out:Klimatpaverkan": -102.29,
    "out:Initial Cost/MSEK": 8.691,
    "out:Running cost/(Apt SEK y)": 8215,
    "out:Running Cost over RSP/MSEK": 2.362,
    "out:LCP/MSEK": 1.649,
    "out:ROI% old": 17,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3200.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1320,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2313.72,
    "out:PV (% sold (El))": 6.07,
    "out:PV (kWh self-consumed)": 35789.17,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -87536,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 369103,
    "out:DH kr savings": 514964,
    "out:El kr savings": -148811,
    "out:El kr sold": 2950,
    "out:El kr saved": 60842,
    "out:El kr return": 63792,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 16098,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.78,
    "out:Etvv": 0,
    "out:Ef": 41.58,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.22,
    "out:Electricity (inc PV)": 41.71,
    "out:Total Energy Use Pre PV": 60.22,
    "out:Total Energy Use Post PV": 46.71,
    "out:Primary Energy": 77.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.56,
    "out:CO2 Operational/m2": 4.38,
    "out:Total CO2/m2": 33.94,
    "out:Total CO2 (tons)": 115.69,
    "out:Klimatpaverkan": -101.31,
    "out:Initial Cost/MSEK": 8.810625,
    "out:Running cost/(Apt SEK y)": 8136,
    "out:Running Cost over RSP/MSEK": 2.339,
    "out:LCP/MSEK": 1.552,
    "out:ROI% old": 16.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3166.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1323,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2415.25,
    "out:PV (% sold (El))": 6.34,
    "out:PV (kWh self-consumed)": 35687.64,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -87973,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368489,
    "out:DH kr savings": 514964,
    "out:El kr savings": -149555,
    "out:El kr sold": 3079,
    "out:El kr saved": 60669,
    "out:El kr return": 63748,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18275,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.5,
    "out:Etvv": 0,
    "out:Ef": 41.71,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.38,
    "out:Electricity (inc PV)": 40.82,
    "out:Total Energy Use Pre PV": 58.38,
    "out:Total Energy Use Post PV": 44.82,
    "out:Primary Energy": 75.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.56,
    "out:CO2 Operational/m2": 3.66,
    "out:Total CO2/m2": 33.22,
    "out:Total CO2 (tons)": 113.26,
    "out:Klimatpaverkan": -103.74,
    "out:Initial Cost/MSEK": 8.895875,
    "out:Running cost/(Apt SEK y)": 7766,
    "out:Running Cost over RSP/MSEK": 2.233,
    "out:LCP/MSEK": 1.574,
    "out:ROI% old": 16.81,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3111.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1337,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2586.15,
    "out:PV (% sold (El))": 6.79,
    "out:PV (kWh self-consumed)": 35516.74,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -84943,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376961,
    "out:DH kr savings": 518066,
    "out:El kr savings": -144402,
    "out:El kr sold": 3297,
    "out:El kr saved": 60378,
    "out:El kr return": 63676,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13387,
    "out:% savings (space heating)": 96.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.48,
    "out:Etvv": 0,
    "out:Ef": 40.82,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.39,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.49,
    "out:Electricity (inc PV)": 38.25,
    "out:Total Energy Use Pre PV": 62.49,
    "out:Total Energy Use Post PV": 44.25,
    "out:Primary Energy": 71.57,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.42,
    "out:CO2 Operational/m2": -28.44,
    "out:Total CO2/m2": 12.98,
    "out:Total CO2 (tons)": 44.23,
    "out:Klimatpaverkan": -172.77,
    "out:Initial Cost/MSEK": 9.314875,
    "out:Running cost/(Apt SEK y)": 7082,
    "out:Running Cost over RSP/MSEK": 2.045,
    "out:LCP/MSEK": 1.343,
    "out:ROI% old": 16.34,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2923.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1362,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24627.9,
    "out:PV (% sold (El))": 32.32,
    "out:PV (kWh self-consumed)": 51577.88,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -76191,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 413737,
    "out:DH kr savings": 511861,
    "out:El kr savings": -129525,
    "out:El kr sold": 31401,
    "out:El kr saved": 87682,
    "out:El kr return": 119083,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 21781,
    "out:% savings (space heating)": 94.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.88,
    "out:Etvv": 0,
    "out:Ef": 38.25,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.72,
    "out:Electricity (inc PV)": 37.48,
    "out:Total Energy Use Pre PV": 60.72,
    "out:Total Energy Use Post PV": 42.48,
    "out:Primary Energy": 69.4,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.42,
    "out:CO2 Operational/m2": -29.53,
    "out:Total CO2/m2": 11.89,
    "out:Total CO2 (tons)": 40.54,
    "out:Klimatpaverkan": -176.46,
    "out:Initial Cost/MSEK": 9.400125,
    "out:Running cost/(Apt SEK y)": 6725,
    "out:Running Cost over RSP/MSEK": 1.942,
    "out:LCP/MSEK": 1.36,
    "out:ROI% old": 16.35,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2878.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1375,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25052.67,
    "out:PV (% sold (El))": 32.88,
    "out:PV (kWh self-consumed)": 51153.12,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -73556,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 421861,
    "out:DH kr savings": 514964,
    "out:El kr savings": -125044,
    "out:El kr sold": 31942,
    "out:El kr saved": 86960,
    "out:El kr return": 118902,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 16098,
    "out:% savings (space heating)": 96.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.78,
    "out:Etvv": 0,
    "out:Ef": 37.48,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.22,
    "out:Electricity (inc PV)": 37.59,
    "out:Total Energy Use Pre PV": 60.22,
    "out:Total Energy Use Post PV": 42.59,
    "out:Primary Energy": 70.12,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.91,
    "out:CO2 Operational/m2": -30.01,
    "out:Total CO2/m2": 11.9,
    "out:Total CO2 (tons)": 40.57,
    "out:Klimatpaverkan": -176.43,
    "out:Initial Cost/MSEK": 9.51975,
    "out:Running cost/(Apt SEK y)": 6649,
    "out:Running Cost over RSP/MSEK": 1.92,
    "out:LCP/MSEK": 1.262,
    "out:ROI% old": 16.18,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2848.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1378,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.02,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25338.64,
    "out:PV (% sold (El))": 33.25,
    "out:PV (kWh self-consumed)": 50867.14,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -73945,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 421564,
    "out:DH kr savings": 514964,
    "out:El kr savings": -125707,
    "out:El kr sold": 32307,
    "out:El kr saved": 86474,
    "out:El kr return": 118781,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18275,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.5,
    "out:Etvv": 0,
    "out:Ef": 37.59,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.38,
    "out:Electricity (inc PV)": 36.8,
    "out:Total Energy Use Pre PV": 58.38,
    "out:Total Energy Use Post PV": 40.8,
    "out:Primary Energy": 67.98,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.91,
    "out:CO2 Operational/m2": -31.17,
    "out:Total CO2/m2": 10.74,
    "out:Total CO2 (tons)": 36.62,
    "out:Klimatpaverkan": -180.38,
    "out:Initial Cost/MSEK": 9.605,
    "out:Running cost/(Apt SEK y)": 6282,
    "out:Running Cost over RSP/MSEK": 1.814,
    "out:LCP/MSEK": 1.283,
    "out:ROI% old": 16.19,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2799.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1392,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25808.71,
    "out:PV (% sold (El))": 33.87,
    "out:PV (kWh self-consumed)": 50397.07,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -71248,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 429851,
    "out:DH kr savings": 518066,
    "out:El kr savings": -121121,
    "out:El kr sold": 32906,
    "out:El kr saved": 85675,
    "out:El kr return": 118581,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13387,
    "out:% savings (space heating)": 96.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.48,
    "out:Etvv": 0,
    "out:Ef": 36.8,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 153.9,
    "out:Primary Energy": 112.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.4,
    "out:CO2 Operational/m2": 51.78,
    "out:Total CO2/m2": 70.18,
    "out:Total CO2 (tons)": 239.24,
    "out:Klimatpaverkan": 22.24,
    "out:Initial Cost/MSEK": 4.42775,
    "out:Running cost/(Apt SEK y)": 35907,
    "out:Running Cost over RSP/MSEK": 10.388,
    "out:LCP/MSEK": -2.114,
    "out:ROI% old": 7.47,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 296,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102372,
    "out:DH kr savings": 102372,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 149.9,
    "out:Primary Energy": 109.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.4,
    "out:CO2 Operational/m2": 50.34,
    "out:Total CO2/m2": 68.74,
    "out:Total CO2 (tons)": 234.33,
    "out:Klimatpaverkan": 17.33,
    "out:Initial Cost/MSEK": 4.513,
    "out:Running cost/(Apt SEK y)": 34938,
    "out:Running Cost over RSP/MSEK": 10.108,
    "out:LCP/MSEK": -1.919,
    "out:ROI% old": 8.21,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114781,
    "out:DH kr savings": 114781,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 109.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.9,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 68.51,
    "out:Total CO2 (tons)": 233.57,
    "out:Klimatpaverkan": 16.57,
    "out:Initial Cost/MSEK": 4.632625,
    "out:Running cost/(Apt SEK y)": 34454,
    "out:Running Cost over RSP/MSEK": 9.968,
    "out:LCP/MSEK": -1.898,
    "out:ROI% old": 8.43,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120985,
    "out:DH kr savings": 120985,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 106.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.9,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 67.07,
    "out:Total CO2 (tons)": 228.66,
    "out:Klimatpaverkan": 11.66,
    "out:Initial Cost/MSEK": 4.717875,
    "out:Running cost/(Apt SEK y)": 33486,
    "out:Running Cost over RSP/MSEK": 9.687,
    "out:LCP/MSEK": -1.703,
    "out:ROI% old": 9.13,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133394,
    "out:DH kr savings": 133394,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 148.73,
    "out:Primary Energy": 103.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 30.75,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 51.54,
    "out:Total CO2 (tons)": 175.69,
    "out:Klimatpaverkan": -41.31,
    "out:Initial Cost/MSEK": 5.136875,
    "out:Running cost/(Apt SEK y)": 34392,
    "out:Running Cost over RSP/MSEK": 9.962,
    "out:LCP/MSEK": -2.396,
    "out:ROI% old": 7.62,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158446,
    "out:DH kr savings": 102372,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 144.73,
    "out:Primary Energy": 100.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.75,
    "out:CO2 Operational/m2": 19.34,
    "out:Total CO2/m2": 50.1,
    "out:Total CO2 (tons)": 170.78,
    "out:Klimatpaverkan": -46.22,
    "out:Initial Cost/MSEK": 5.222125,
    "out:Running cost/(Apt SEK y)": 33423,
    "out:Running Cost over RSP/MSEK": 9.681,
    "out:LCP/MSEK": -2.201,
    "out:ROI% old": 8.26,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 388,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170855,
    "out:DH kr savings": 114781,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 100.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.25,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 49.87,
    "out:Total CO2 (tons)": 170.02,
    "out:Klimatpaverkan": -46.98,
    "out:Initial Cost/MSEK": 5.34175,
    "out:Running cost/(Apt SEK y)": 32939,
    "out:Running Cost over RSP/MSEK": 9.541,
    "out:LCP/MSEK": -2.18,
    "out:ROI% old": 8.45,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177059,
    "out:DH kr savings": 120985,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 97.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.25,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 48.43,
    "out:Total CO2 (tons)": 165.11,
    "out:Klimatpaverkan": -51.89,
    "out:Initial Cost/MSEK": 5.427,
    "out:Running cost/(Apt SEK y)": 31970,
    "out:Running Cost over RSP/MSEK": 9.26,
    "out:LCP/MSEK": -1.985,
    "out:ROI% old": 9.06,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189468,
    "out:DH kr savings": 133394,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 147.88,
    "out:Primary Energy": 101.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 43.11,
    "out:CO2 Operational/m2": -31.9,
    "out:Total CO2/m2": 11.21,
    "out:Total CO2 (tons)": 38.21,
    "out:Klimatpaverkan": -178.79,
    "out:Initial Cost/MSEK": 5.846,
    "out:Running cost/(Apt SEK y)": 33045,
    "out:Running Cost over RSP/MSEK": 9.583,
    "out:LCP/MSEK": -2.727,
    "out:ROI% old": 7.62,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 402,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208257,
    "out:DH kr savings": 102372,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 149.9,
    "out:Total Energy Use Post PV": 143.88,
    "out:Primary Energy": 98.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.11,
    "out:CO2 Operational/m2": -33.34,
    "out:Total CO2/m2": 9.77,
    "out:Total CO2 (tons)": 33.31,
    "out:Klimatpaverkan": -183.69,
    "out:Initial Cost/MSEK": 5.93125,
    "out:Running cost/(Apt SEK y)": 32077,
    "out:Running Cost over RSP/MSEK": 9.302,
    "out:LCP/MSEK": -2.531,
    "out:ROI% old": 8.19,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 437,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 220666,
    "out:DH kr savings": 114781,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 98.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.6,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 9.55,
    "out:Total CO2 (tons)": 32.54,
    "out:Klimatpaverkan": -184.46,
    "out:Initial Cost/MSEK": 6.050875,
    "out:Running cost/(Apt SEK y)": 31592,
    "out:Running Cost over RSP/MSEK": 9.162,
    "out:LCP/MSEK": -2.511,
    "out:ROI% old": 8.36,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 455,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226870,
    "out:DH kr savings": 120985,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 96.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.6,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 8.11,
    "out:Total CO2 (tons)": 27.63,
    "out:Klimatpaverkan": -189.37,
    "out:Initial Cost/MSEK": 6.136125,
    "out:Running cost/(Apt SEK y)": 30624,
    "out:Running Cost over RSP/MSEK": 8.882,
    "out:LCP/MSEK": -2.316,
    "out:ROI% old": 8.89,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239279,
    "out:DH kr savings": 133394,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 108.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.4,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 66.94,
    "out:Total CO2 (tons)": 228.19,
    "out:Klimatpaverkan": 11.19,
    "out:Initial Cost/MSEK": 4.525375,
    "out:Running cost/(Apt SEK y)": 33728,
    "out:Running Cost over RSP/MSEK": 9.757,
    "out:LCP/MSEK": -1.58,
    "out:ROI% old": 9.3,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130292,
    "out:DH kr savings": 130292,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 140.9,
    "out:Primary Energy": 106.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.4,
    "out:CO2 Operational/m2": 47.1,
    "out:Total CO2/m2": 65.5,
    "out:Total CO2 (tons)": 223.28,
    "out:Klimatpaverkan": 6.28,
    "out:Initial Cost/MSEK": 4.610625,
    "out:Running cost/(Apt SEK y)": 32759,
    "out:Running Cost over RSP/MSEK": 9.477,
    "out:LCP/MSEK": -1.385,
    "out:ROI% old": 9.99,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142701,
    "out:DH kr savings": 142701,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 139.9,
    "out:Primary Energy": 106.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.9,
    "out:CO2 Operational/m2": 46.74,
    "out:Total CO2/m2": 65.63,
    "out:Total CO2 (tons)": 223.75,
    "out:Klimatpaverkan": 6.75,
    "out:Initial Cost/MSEK": 4.730375,
    "out:Running cost/(Apt SEK y)": 32517,
    "out:Running Cost over RSP/MSEK": 9.407,
    "out:LCP/MSEK": -1.435,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 160223,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145803,
    "out:DH kr savings": 145803,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 135.9,
    "out:Total Energy Use Post PV": 135.9,
    "out:Primary Energy": 103.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.9,
    "out:CO2 Operational/m2": 45.3,
    "out:Total CO2/m2": 64.19,
    "out:Total CO2 (tons)": 218.84,
    "out:Klimatpaverkan": 1.84,
    "out:Initial Cost/MSEK": 4.8155,
    "out:Running cost/(Apt SEK y)": 31548,
    "out:Running Cost over RSP/MSEK": 9.126,
    "out:LCP/MSEK": -1.24,
    "out:ROI% old": 10.61,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173859,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158212,
    "out:DH kr savings": 158212,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 99.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.75,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 48.3,
    "out:Total CO2 (tons)": 164.64,
    "out:Klimatpaverkan": -52.36,
    "out:Initial Cost/MSEK": 5.2345,
    "out:Running cost/(Apt SEK y)": 32212,
    "out:Running Cost over RSP/MSEK": 9.331,
    "out:LCP/MSEK": -1.863,
    "out:ROI% old": 9.2,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186366,
    "out:DH kr savings": 130292,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 135.73,
    "out:Primary Energy": 96.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.75,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 46.86,
    "out:Total CO2 (tons)": 159.73,
    "out:Klimatpaverkan": -57.27,
    "out:Initial Cost/MSEK": 5.31975,
    "out:Running cost/(Apt SEK y)": 31244,
    "out:Running Cost over RSP/MSEK": 9.05,
    "out:LCP/MSEK": -1.668,
    "out:ROI% old": 9.81,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198775,
    "out:DH kr savings": 142701,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 134.73,
    "out:Primary Energy": 97.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.25,
    "out:CO2 Operational/m2": 15.74,
    "out:Total CO2/m2": 46.99,
    "out:Total CO2 (tons)": 160.2,
    "out:Klimatpaverkan": -56.8,
    "out:Initial Cost/MSEK": 5.4395,
    "out:Running cost/(Apt SEK y)": 31001,
    "out:Running Cost over RSP/MSEK": 8.98,
    "out:LCP/MSEK": -1.717,
    "out:ROI% old": 9.78,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 477,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160223,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201877,
    "out:DH kr savings": 145803,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 135.9,
    "out:Total Energy Use Post PV": 130.73,
    "out:Primary Energy": 94.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.25,
    "out:CO2 Operational/m2": 14.3,
    "out:Total CO2/m2": 45.55,
    "out:Total CO2 (tons)": 155.29,
    "out:Klimatpaverkan": -61.71,
    "out:Initial Cost/MSEK": 5.524625,
    "out:Running cost/(Apt SEK y)": 30033,
    "out:Running Cost over RSP/MSEK": 8.7,
    "out:LCP/MSEK": -1.522,
    "out:ROI% old": 10.35,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 513,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 173859,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214286,
    "out:DH kr savings": 158212,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 98.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.11,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 7.97,
    "out:Total CO2 (tons)": 27.17,
    "out:Klimatpaverkan": -189.83,
    "out:Initial Cost/MSEK": 5.943625,
    "out:Running cost/(Apt SEK y)": 30866,
    "out:Running Cost over RSP/MSEK": 8.952,
    "out:LCP/MSEK": -2.193,
    "out:ROI% old": 9.01,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236177,
    "out:DH kr savings": 130292,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 134.88,
    "out:Primary Energy": 95.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.11,
    "out:CO2 Operational/m2": -36.58,
    "out:Total CO2/m2": 6.53,
    "out:Total CO2 (tons)": 22.26,
    "out:Klimatpaverkan": -194.74,
    "out:Initial Cost/MSEK": 6.028875,
    "out:Running cost/(Apt SEK y)": 29897,
    "out:Running Cost over RSP/MSEK": 8.671,
    "out:LCP/MSEK": -1.998,
    "out:ROI% old": 9.55,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248586,
    "out:DH kr savings": 142701,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 139.9,
    "out:Total Energy Use Post PV": 133.88,
    "out:Primary Energy": 95.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.6,
    "out:CO2 Operational/m2": -36.94,
    "out:Total CO2/m2": 6.67,
    "out:Total CO2 (tons)": 22.73,
    "out:Klimatpaverkan": -194.27,
    "out:Initial Cost/MSEK": 6.148625,
    "out:Running cost/(Apt SEK y)": 29655,
    "out:Running Cost over RSP/MSEK": 8.601,
    "out:LCP/MSEK": -2.048,
    "out:ROI% old": 9.53,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160223,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251688,
    "out:DH kr savings": 145803,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 322553,
    "out:% savings (space heating)": 25.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.62,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.59,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 135.9,
    "out:Total Energy Use Post PV": 129.88,
    "out:Primary Energy": 93.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.6,
    "out:CO2 Operational/m2": -38.38,
    "out:Total CO2/m2": 5.23,
    "out:Total CO2 (tons)": 17.82,
    "out:Klimatpaverkan": -199.18,
    "out:Initial Cost/MSEK": 6.23375,
    "out:Running cost/(Apt SEK y)": 28687,
    "out:Running Cost over RSP/MSEK": 8.321,
    "out:LCP/MSEK": -1.852,
    "out:ROI% old": 10.04,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 563,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 173859,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264097,
    "out:DH kr savings": 158212,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 308821,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.59,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z01000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.2,
    "out:Electricity (inc PV)": 41.05,
    "out:Total Energy Use Pre PV": 99.2,
    "out:Total Energy Use Post PV": 97.05,
    "out:Primary Energy": 104.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.91,
    "out:CO2 Operational/m2": 25.86,
    "out:Total CO2/m2": 47.77,
    "out:Total CO2 (tons)": 162.84,
    "out:Klimatpaverkan": -54.16,
    "out:Initial Cost/MSEK": 7.27375,
    "out:Running cost/(Apt SEK y)": 20326,
    "out:Running Cost over RSP/MSEK": 5.862,
    "out:LCP/MSEK": -0.433,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 3023.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 872,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 392035,
    "out:EL kWh savings": -85722,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211025,
    "out:DH kr savings": 356752,
    "out:El kr savings": -145727,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 189649,
    "out:% savings (space heating)": 56.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.23,
    "out:Etvv": 0,
    "out:Ef": 41.05,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.12,
    "out:Electricity (inc PV)": 40.93,
    "out:Total Energy Use Pre PV": 95.12,
    "out:Total Energy Use Post PV": 92.93,
    "out:Primary Energy": 102.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.91,
    "out:CO2 Operational/m2": 24.41,
    "out:Total CO2/m2": 46.32,
    "out:Total CO2 (tons)": 157.9,
    "out:Klimatpaverkan": -59.1,
    "out:Initial Cost/MSEK": 7.359,
    "out:Running cost/(Apt SEK y)": 19346,
    "out:Running Cost over RSP/MSEK": 5.578,
    "out:LCP/MSEK": -0.235,
    "out:ROI% old": 13.83,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 3018.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 405671,
    "out:EL kWh savings": -85318,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 224121,
    "out:DH kr savings": 369161,
    "out:El kr savings": -145040,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 176738,
    "out:% savings (space heating)": 59.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 40.59,
    "out:Etvv": 0,
    "out:Ef": 40.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.48,
    "out:Electricity (inc PV)": 40.57,
    "out:Total Energy Use Pre PV": 93.48,
    "out:Total Energy Use Post PV": 91.57,
    "out:Primary Energy": 102.46,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 23.97,
    "out:Total CO2/m2": 46.37,
    "out:Total CO2 (tons)": 158.07,
    "out:Klimatpaverkan": -58.93,
    "out:Initial Cost/MSEK": 7.47875,
    "out:Running cost/(Apt SEK y)": 19003,
    "out:Running Cost over RSP/MSEK": 5.479,
    "out:LCP/MSEK": -0.255,
    "out:ROI% old": 13.8,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2973.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 921,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 409080,
    "out:EL kWh savings": -84097,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 229298,
    "out:DH kr savings": 372263,
    "out:El kr savings": -142965,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 175499,
    "out:% savings (space heating)": 59.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.05,
    "out:Etvv": 0,
    "out:Ef": 40.57,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.39,
    "out:Electricity (inc PV)": 40.44,
    "out:Total Energy Use Pre PV": 90.39,
    "out:Total Energy Use Post PV": 88.44,
    "out:Primary Energy": 99.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 22.87,
    "out:Total CO2/m2": 45.28,
    "out:Total CO2 (tons)": 154.35,
    "out:Klimatpaverkan": -62.65,
    "out:Initial Cost/MSEK": 7.563875,
    "out:Running cost/(Apt SEK y)": 18262,
    "out:Running Cost over RSP/MSEK": 5.265,
    "out:LCP/MSEK": -0.126,
    "out:ROI% old": 14.05,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2967.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.19,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 419307,
    "out:EL kWh savings": -83659,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239348,
    "out:DH kr savings": 381569,
    "out:El kr savings": -142221,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 162749,
    "out:% savings (space heating)": 62.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.44,
    "out:Etvv": 0,
    "out:Ef": 40.44,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.2,
    "out:Electricity (inc PV)": 31.75,
    "out:Total Energy Use Pre PV": 99.2,
    "out:Total Energy Use Post PV": 87.75,
    "out:Primary Energy": 88.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.26,
    "out:CO2 Operational/m2": 16.53,
    "out:Total CO2/m2": 50.79,
    "out:Total CO2 (tons)": 173.14,
    "out:Klimatpaverkan": -43.86,
    "out:Initial Cost/MSEK": 7.982875,
    "out:Running cost/(Apt SEK y)": 18640,
    "out:Running Cost over RSP/MSEK": 5.387,
    "out:LCP/MSEK": -0.668,
    "out:ROI% old": 13.09,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2382.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 935,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5646.77,
    "out:PV (% sold (El))": 14.82,
    "out:PV (kWh self-consumed)": 32456.12,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 392035,
    "out:EL kWh savings": -54030,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272101,
    "out:DH kr savings": 356752,
    "out:El kr savings": -91850,
    "out:El kr sold": 7200,
    "out:El kr saved": 55175,
    "out:El kr return": 62375,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 189649,
    "out:% savings (space heating)": 56.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.23,
    "out:Etvv": 0,
    "out:Ef": 31.75,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.12,
    "out:Electricity (inc PV)": 31.64,
    "out:Total Energy Use Pre PV": 95.12,
    "out:Total Energy Use Post PV": 83.64,
    "out:Primary Energy": 85.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.26,
    "out:CO2 Operational/m2": 15.04,
    "out:Total CO2/m2": 49.3,
    "out:Total CO2 (tons)": 168.07,
    "out:Klimatpaverkan": -48.93,
    "out:Initial Cost/MSEK": 8.068125,
    "out:Running cost/(Apt SEK y)": 17660,
    "out:Running Cost over RSP/MSEK": 5.103,
    "out:LCP/MSEK": -0.469,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2377.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 971,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5671.87,
    "out:PV (% sold (El))": 14.89,
    "out:PV (kWh self-consumed)": 32431.02,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 405671,
    "out:EL kWh savings": -53669,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285155,
    "out:DH kr savings": 369161,
    "out:El kr savings": -91237,
    "out:El kr sold": 7232,
    "out:El kr saved": 55133,
    "out:El kr return": 62364,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 176738,
    "out:% savings (space heating)": 59.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 40.59,
    "out:Etvv": 0,
    "out:Ef": 31.64,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.48,
    "out:Electricity (inc PV)": 31.32,
    "out:Total Energy Use Pre PV": 93.48,
    "out:Total Energy Use Post PV": 82.32,
    "out:Primary Energy": 85.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.76,
    "out:CO2 Operational/m2": 14.27,
    "out:Total CO2/m2": 49.03,
    "out:Total CO2 (tons)": 167.14,
    "out:Klimatpaverkan": -49.86,
    "out:Initial Cost/MSEK": 8.18775,
    "out:Running cost/(Apt SEK y)": 17320,
    "out:Running Cost over RSP/MSEK": 5.005,
    "out:LCP/MSEK": -0.49,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2336.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 983,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5896.52,
    "out:PV (% sold (El))": 15.48,
    "out:PV (kWh self-consumed)": 32206.37,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 409080,
    "out:EL kWh savings": -52582,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 290391,
    "out:DH kr savings": 372263,
    "out:El kr savings": -89390,
    "out:El kr sold": 7518,
    "out:El kr saved": 54751,
    "out:El kr return": 62269,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 175499,
    "out:% savings (space heating)": 59.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.05,
    "out:Etvv": 0,
    "out:Ef": 31.32,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.39,
    "out:Electricity (inc PV)": 31.21,
    "out:Total Energy Use Pre PV": 90.39,
    "out:Total Energy Use Post PV": 79.21,
    "out:Primary Energy": 83.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.76,
    "out:CO2 Operational/m2": 13.14,
    "out:Total CO2/m2": 47.89,
    "out:Total CO2 (tons)": 163.26,
    "out:Klimatpaverkan": -53.74,
    "out:Initial Cost/MSEK": 8.273,
    "out:Running cost/(Apt SEK y)": 16580,
    "out:Running Cost over RSP/MSEK": 4.791,
    "out:LCP/MSEK": -0.362,
    "out:ROI% old": 13.66,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2330.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.19,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5927.17,
    "out:PV (% sold (El))": 15.56,
    "out:PV (kWh self-consumed)": 32175.72,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 419307,
    "out:EL kWh savings": -52193,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300399,
    "out:DH kr savings": 381569,
    "out:El kr savings": -88728,
    "out:El kr sold": 7557,
    "out:El kr saved": 54699,
    "out:El kr return": 62256,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 162749,
    "out:% savings (space heating)": 62.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.44,
    "out:Etvv": 0,
    "out:Ef": 31.21,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 55.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.2,
    "out:Electricity (inc PV)": 28.7,
    "out:Total Energy Use Pre PV": 99.2,
    "out:Total Energy Use Post PV": 84.7,
    "out:Primary Energy": 82.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.61,
    "out:CO2 Operational/m2": -24,
    "out:Total CO2/m2": 22.61,
    "out:Total CO2 (tons)": 77.09,
    "out:Klimatpaverkan": -139.91,
    "out:Initial Cost/MSEK": 8.692,
    "out:Running cost/(Apt SEK y)": 17200,
    "out:Running Cost over RSP/MSEK": 4.981,
    "out:LCP/MSEK": -0.971,
    "out:ROI% old": 12.69,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2150.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 988,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32677.32,
    "out:PV (% sold (El))": 42.88,
    "out:PV (kWh self-consumed)": 43528.47,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 392035,
    "out:EL kWh savings": -43638,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 324230,
    "out:DH kr savings": 356752,
    "out:El kr savings": -74185,
    "out:El kr sold": 41664,
    "out:El kr saved": 73998,
    "out:El kr return": 115662,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 189649,
    "out:% savings (space heating)": 56.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.23,
    "out:Etvv": 0,
    "out:Ef": 28.7,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.12,
    "out:Electricity (inc PV)": 28.61,
    "out:Total Energy Use Pre PV": 95.12,
    "out:Total Energy Use Post PV": 80.61,
    "out:Primary Energy": 79.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.61,
    "out:CO2 Operational/m2": -25.52,
    "out:Total CO2/m2": 21.09,
    "out:Total CO2 (tons)": 71.91,
    "out:Klimatpaverkan": -145.09,
    "out:Initial Cost/MSEK": 8.77725,
    "out:Running cost/(Apt SEK y)": 16221,
    "out:Running Cost over RSP/MSEK": 4.698,
    "out:LCP/MSEK": -0.773,
    "out:ROI% old": 13.03,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 2146.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1024,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32725.74,
    "out:PV (% sold (El))": 42.94,
    "out:PV (kWh self-consumed)": 43480.05,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 405671,
    "out:EL kWh savings": -43315,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337251,
    "out:DH kr savings": 369161,
    "out:El kr savings": -73635,
    "out:El kr sold": 41725,
    "out:El kr saved": 73916,
    "out:El kr return": 115641,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 176738,
    "out:% savings (space heating)": 59.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 40.59,
    "out:Etvv": 0,
    "out:Ef": 28.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.48,
    "out:Electricity (inc PV)": 28.32,
    "out:Total Energy Use Pre PV": 93.48,
    "out:Total Energy Use Post PV": 79.32,
    "out:Primary Energy": 80.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.11,
    "out:CO2 Operational/m2": -26.6,
    "out:Total CO2/m2": 20.51,
    "out:Total CO2 (tons)": 69.93,
    "out:Klimatpaverkan": -147.07,
    "out:Initial Cost/MSEK": 8.897,
    "out:Running cost/(Apt SEK y)": 15882,
    "out:Running Cost over RSP/MSEK": 4.6,
    "out:LCP/MSEK": -0.795,
    "out:ROI% old": 13.01,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 136.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2110,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1037,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33156.65,
    "out:PV (% sold (El))": 43.51,
    "out:PV (kWh self-consumed)": 43049.14,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 409080,
    "out:EL kWh savings": -42339,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 342561,
    "out:DH kr savings": 372263,
    "out:El kr savings": -71977,
    "out:El kr sold": 42275,
    "out:El kr saved": 73184,
    "out:El kr return": 115458,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 175499,
    "out:% savings (space heating)": 59.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.05,
    "out:Etvv": 0,
    "out:Ef": 28.32,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.39,
    "out:Electricity (inc PV)": 28.22,
    "out:Total Energy Use Pre PV": 90.39,
    "out:Total Energy Use Post PV": 76.22,
    "out:Primary Energy": 77.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.11,
    "out:CO2 Operational/m2": -27.77,
    "out:Total CO2/m2": 19.34,
    "out:Total CO2 (tons)": 65.91,
    "out:Klimatpaverkan": -151.09,
    "out:Initial Cost/MSEK": 8.982125,
    "out:Running cost/(Apt SEK y)": 15143,
    "out:Running Cost over RSP/MSEK": 4.386,
    "out:LCP/MSEK": -0.666,
    "out:ROI% old": 13.23,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2105,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1064,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.19,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33215.1,
    "out:PV (% sold (El))": 43.59,
    "out:PV (kWh self-consumed)": 42990.68,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 419307,
    "out:EL kWh savings": -41990,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 352536,
    "out:DH kr savings": 381569,
    "out:El kr savings": -71383,
    "out:El kr sold": 42349,
    "out:El kr saved": 73084,
    "out:El kr return": 115433,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 162749,
    "out:% savings (space heating)": 62.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.44,
    "out:Etvv": 0,
    "out:Ef": 28.22,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.23,
    "out:Electricity (inc PV)": 40.41,
    "out:Total Energy Use Pre PV": 92.23,
    "out:Total Energy Use Post PV": 90.41,
    "out:Primary Energy": 101.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.91,
    "out:CO2 Operational/m2": 23.57,
    "out:Total CO2/m2": 45.48,
    "out:Total CO2 (tons)": 155.04,
    "out:Klimatpaverkan": -61.96,
    "out:Initial Cost/MSEK": 7.3715,
    "out:Running cost/(Apt SEK y)": 18721,
    "out:Running Cost over RSP/MSEK": 5.398,
    "out:LCP/MSEK": -0.067,
    "out:ROI% old": 14.16,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2955.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 932,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -83547,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233336,
    "out:DH kr savings": 375365,
    "out:El kr savings": -142029,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170886,
    "out:% savings (space heating)": 60.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.33,
    "out:Etvv": 0,
    "out:Ef": 40.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.13,
    "out:Electricity (inc PV)": 40.28,
    "out:Total Energy Use Pre PV": 88.13,
    "out:Total Energy Use Post PV": 86.28,
    "out:Primary Energy": 98.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.91,
    "out:CO2 Operational/m2": 22.12,
    "out:Total CO2/m2": 44.03,
    "out:Total CO2 (tons)": 150.09,
    "out:Klimatpaverkan": -66.91,
    "out:Initial Cost/MSEK": 7.456625,
    "out:Running cost/(Apt SEK y)": 17738,
    "out:Running Cost over RSP/MSEK": 5.113,
    "out:LCP/MSEK": 0.133,
    "out:ROI% old": 14.54,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 117.4,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2949.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 968,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -83097,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246509,
    "out:DH kr savings": 387774,
    "out:El kr savings": -141265,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 158199,
    "out:% savings (space heating)": 63.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 37.74,
    "out:Etvv": 0,
    "out:Ef": 40.28,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.61,
    "out:Electricity (inc PV)": 40.01,
    "out:Total Energy Use Pre PV": 88.61,
    "out:Total Energy Use Post PV": 87.01,
    "out:Primary Energy": 99.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 44.81,
    "out:Total CO2 (tons)": 152.77,
    "out:Klimatpaverkan": -64.23,
    "out:Initial Cost/MSEK": 7.576375,
    "out:Running cost/(Apt SEK y)": 17898,
    "out:Running Cost over RSP/MSEK": 5.16,
    "out:LCP/MSEK": -0.034,
    "out:ROI% old": 14.22,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2912.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.41,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 422716,
    "out:EL kWh savings": -82206,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 244922,
    "out:DH kr savings": 384672,
    "out:El kr savings": -139749,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 160043,
    "out:% savings (space heating)": 63.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 39.63,
    "out:Etvv": 0,
    "out:Ef": 40.01,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.5,
    "out:Electricity (inc PV)": 39.87,
    "out:Total Energy Use Pre PV": 84.5,
    "out:Total Energy Use Post PV": 82.87,
    "out:Primary Energy": 97.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.4,
    "out:CO2 Operational/m2": 20.96,
    "out:Total CO2/m2": 43.36,
    "out:Total CO2 (tons)": 147.82,
    "out:Klimatpaverkan": -69.18,
    "out:Initial Cost/MSEK": 7.661625,
    "out:Running cost/(Apt SEK y)": 16913,
    "out:Running Cost over RSP/MSEK": 4.874,
    "out:LCP/MSEK": 0.166,
    "out:ROI% old": 14.6,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 125.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2905.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 436352,
    "out:EL kWh savings": -81723,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258151,
    "out:DH kr savings": 397080,
    "out:El kr savings": -138929,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 147501,
    "out:% savings (space heating)": 66.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.07,
    "out:Etvv": 0,
    "out:Ef": 39.87,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.23,
    "out:Electricity (inc PV)": 31.18,
    "out:Total Energy Use Pre PV": 92.23,
    "out:Total Energy Use Post PV": 81.18,
    "out:Primary Energy": 85.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.26,
    "out:CO2 Operational/m2": 13.75,
    "out:Total CO2/m2": 48.01,
    "out:Total CO2 (tons)": 163.67,
    "out:Klimatpaverkan": -53.33,
    "out:Initial Cost/MSEK": 8.0805,
    "out:Running cost/(Apt SEK y)": 17040,
    "out:Running Cost over RSP/MSEK": 4.924,
    "out:LCP/MSEK": -0.302,
    "out:ROI% old": 13.75,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2320.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 994,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5984.31,
    "out:PV (% sold (El))": 15.71,
    "out:PV (kWh self-consumed)": 32118.58,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -52093,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294438,
    "out:DH kr savings": 375365,
    "out:El kr savings": -88557,
    "out:El kr sold": 7630,
    "out:El kr saved": 54602,
    "out:El kr return": 62232,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170886,
    "out:% savings (space heating)": 60.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.33,
    "out:Etvv": 0,
    "out:Ef": 31.18,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.13,
    "out:Electricity (inc PV)": 31.06,
    "out:Total Energy Use Pre PV": 88.13,
    "out:Total Energy Use Post PV": 77.06,
    "out:Primary Energy": 82.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.26,
    "out:CO2 Operational/m2": 12.25,
    "out:Total CO2/m2": 46.51,
    "out:Total CO2 (tons)": 158.56,
    "out:Klimatpaverkan": -58.44,
    "out:Initial Cost/MSEK": 8.16575,
    "out:Running cost/(Apt SEK y)": 16057,
    "out:Running Cost over RSP/MSEK": 4.639,
    "out:LCP/MSEK": -0.103,
    "out:ROI% old": 14.11,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2314.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1030,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6017.22,
    "out:PV (% sold (El))": 15.79,
    "out:PV (kWh self-consumed)": 32085.67,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -51693,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 307568,
    "out:DH kr savings": 387774,
    "out:El kr savings": -87878,
    "out:El kr sold": 7672,
    "out:El kr saved": 54546,
    "out:El kr return": 62218,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 158199,
    "out:% savings (space heating)": 63.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 37.74,
    "out:Etvv": 0,
    "out:Ef": 31.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.61,
    "out:Electricity (inc PV)": 30.83,
    "out:Total Energy Use Pre PV": 88.61,
    "out:Total Energy Use Post PV": 77.83,
    "out:Primary Energy": 83.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.76,
    "out:CO2 Operational/m2": 12.27,
    "out:Total CO2/m2": 47.03,
    "out:Total CO2 (tons)": 160.31,
    "out:Klimatpaverkan": -56.69,
    "out:Initial Cost/MSEK": 8.2855,
    "out:Running cost/(Apt SEK y)": 16219,
    "out:Running Cost over RSP/MSEK": 4.687,
    "out:LCP/MSEK": -0.27,
    "out:ROI% old": 13.82,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2281.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1024,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.41,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6204.98,
    "out:PV (% sold (El))": 16.28,
    "out:PV (kWh self-consumed)": 31897.91,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422716,
    "out:EL kWh savings": -50902,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 306050,
    "out:DH kr savings": 384672,
    "out:El kr savings": -86533,
    "out:El kr sold": 7911,
    "out:El kr saved": 54226,
    "out:El kr return": 62138,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 160043,
    "out:% savings (space heating)": 63.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 39.63,
    "out:Etvv": 0,
    "out:Ef": 30.83,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.5,
    "out:Electricity (inc PV)": 30.71,
    "out:Total Energy Use Pre PV": 84.5,
    "out:Total Energy Use Post PV": 73.71,
    "out:Primary Energy": 80.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.76,
    "out:CO2 Operational/m2": 10.76,
    "out:Total CO2/m2": 45.52,
    "out:Total CO2 (tons)": 155.17,
    "out:Klimatpaverkan": -61.83,
    "out:Initial Cost/MSEK": 8.37075,
    "out:Running cost/(Apt SEK y)": 15234,
    "out:Running Cost over RSP/MSEK": 4.402,
    "out:LCP/MSEK": -0.07,
    "out:ROI% old": 14.17,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2274.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6243.26,
    "out:PV (% sold (El))": 16.39,
    "out:PV (kWh self-consumed)": 31859.63,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 436352,
    "out:EL kWh savings": -50474,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 319236,
    "out:DH kr savings": 397080,
    "out:El kr savings": -85805,
    "out:El kr sold": 7960,
    "out:El kr saved": 54161,
    "out:El kr return": 62122,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 147501,
    "out:% savings (space heating)": 66.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.07,
    "out:Etvv": 0,
    "out:Ef": 30.71,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.23,
    "out:Electricity (inc PV)": 28.19,
    "out:Total Energy Use Pre PV": 92.23,
    "out:Total Energy Use Post PV": 78.19,
    "out:Primary Energy": 79.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.61,
    "out:CO2 Operational/m2": -27.23,
    "out:Total CO2/m2": 19.38,
    "out:Total CO2 (tons)": 66.06,
    "out:Klimatpaverkan": -150.94,
    "out:Initial Cost/MSEK": 8.789625,
    "out:Running cost/(Apt SEK y)": 15603,
    "out:Running Cost over RSP/MSEK": 4.519,
    "out:LCP/MSEK": -0.607,
    "out:ROI% old": 13.3,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2095.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1047,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.03,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33323.55,
    "out:PV (% sold (El))": 43.73,
    "out:PV (kWh self-consumed)": 42882.24,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -41900,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346623,
    "out:DH kr savings": 375365,
    "out:El kr savings": -71230,
    "out:El kr sold": 42488,
    "out:El kr saved": 72900,
    "out:El kr return": 115387,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170886,
    "out:% savings (space heating)": 60.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.33,
    "out:Etvv": 0,
    "out:Ef": 28.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.13,
    "out:Electricity (inc PV)": 28.09,
    "out:Total Energy Use Pre PV": 88.13,
    "out:Total Energy Use Post PV": 74.09,
    "out:Primary Energy": 76.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.61,
    "out:CO2 Operational/m2": -28.78,
    "out:Total CO2/m2": 17.83,
    "out:Total CO2 (tons)": 60.8,
    "out:Klimatpaverkan": -156.2,
    "out:Initial Cost/MSEK": 8.874875,
    "out:Running cost/(Apt SEK y)": 14620,
    "out:Running Cost over RSP/MSEK": 4.235,
    "out:LCP/MSEK": -0.408,
    "out:ROI% old": 13.63,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2090.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1083,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33385.93,
    "out:PV (% sold (El))": 43.81,
    "out:PV (kWh self-consumed)": 42819.85,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -41541,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 359721,
    "out:DH kr savings": 387774,
    "out:El kr savings": -70620,
    "out:El kr sold": 42567,
    "out:El kr saved": 72794,
    "out:El kr return": 115361,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 158199,
    "out:% savings (space heating)": 63.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 37.74,
    "out:Etvv": 0,
    "out:Ef": 28.09,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.95,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.61,
    "out:Electricity (inc PV)": 27.88,
    "out:Total Energy Use Pre PV": 88.61,
    "out:Total Energy Use Post PV": 74.88,
    "out:Primary Energy": 77.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.11,
    "out:CO2 Operational/m2": -29.01,
    "out:Total CO2/m2": 18.1,
    "out:Total CO2 (tons)": 61.71,
    "out:Klimatpaverkan": -155.29,
    "out:Initial Cost/MSEK": 8.994625,
    "out:Running cost/(Apt SEK y)": 14784,
    "out:Running Cost over RSP/MSEK": 4.283,
    "out:LCP/MSEK": -0.575,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2060.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1077,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.41,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33739.9,
    "out:PV (% sold (El))": 44.27,
    "out:PV (kWh self-consumed)": 42465.89,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422716,
    "out:EL kWh savings": -40830,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 358279,
    "out:DH kr savings": 384672,
    "out:El kr savings": -69411,
    "out:El kr sold": 43018,
    "out:El kr saved": 72192,
    "out:El kr return": 115210,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 160043,
    "out:% savings (space heating)": 63.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 39.63,
    "out:Etvv": 0,
    "out:Ef": 27.88,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.5,
    "out:Electricity (inc PV)": 27.76,
    "out:Total Energy Use Pre PV": 84.5,
    "out:Total Energy Use Post PV": 70.76,
    "out:Primary Energy": 75.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.11,
    "out:CO2 Operational/m2": -30.57,
    "out:Total CO2/m2": 16.54,
    "out:Total CO2 (tons)": 56.39,
    "out:Klimatpaverkan": -160.61,
    "out:Initial Cost/MSEK": 9.079875,
    "out:Running cost/(Apt SEK y)": 13800,
    "out:Running Cost over RSP/MSEK": 3.998,
    "out:LCP/MSEK": -0.375,
    "out:ROI% old": 13.7,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 163.4,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2054.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1114,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33811.53,
    "out:PV (% sold (El))": 44.37,
    "out:PV (kWh self-consumed)": 42394.26,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 436352,
    "out:EL kWh savings": -40445,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 371433,
    "out:DH kr savings": 397080,
    "out:El kr savings": -68757,
    "out:El kr sold": 43110,
    "out:El kr saved": 72070,
    "out:El kr return": 115180,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 147501,
    "out:% savings (space heating)": 66.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.07,
    "out:Etvv": 0,
    "out:Ef": 27.76,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.02,
    "out:Electricity (inc PV)": 53.38,
    "out:Total Energy Use Pre PV": 65.02,
    "out:Total Energy Use Post PV": 60.38,
    "out:Primary Energy": 98.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.5,
    "out:CO2 Operational/m2": 10.18,
    "out:Total CO2/m2": 32.68,
    "out:Total CO2 (tons)": 111.4,
    "out:Klimatpaverkan": -105.6,
    "out:Initial Cost/MSEK": 9.13,
    "out:Running cost/(Apt SEK y)": 10782,
    "out:Running Cost over RSP/MSEK": 3.09,
    "out:LCP/MSEK": 0.482,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4061.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1225,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -127765,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 291559,
    "out:DH kr savings": 508759,
    "out:El kr savings": -217200,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 22893,
    "out:% savings (space heating)": 94.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.53,
    "out:Etvv": 0,
    "out:Ef": 53.38,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 52.46,
    "out:Total Energy Use Pre PV": 62.32,
    "out:Total Energy Use Post PV": 57.46,
    "out:Primary Energy": 96.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.5,
    "out:CO2 Operational/m2": 9.37,
    "out:Total CO2/m2": 31.87,
    "out:Total CO2 (tons)": 108.63,
    "out:Klimatpaverkan": -108.37,
    "out:Initial Cost/MSEK": 9.21525,
    "out:Running cost/(Apt SEK y)": 10188,
    "out:Running Cost over RSP/MSEK": 2.919,
    "out:LCP/MSEK": 0.568,
    "out:ROI% old": 15.17,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4012.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1247,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -124650,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303059,
    "out:DH kr savings": 514964,
    "out:El kr savings": -211905,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17044,
    "out:% savings (space heating)": 96.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 52.46,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.45,
    "out:Electricity (inc PV)": 52.38,
    "out:Total Energy Use Pre PV": 61.45,
    "out:Total Energy Use Post PV": 57.38,
    "out:Primary Energy": 96.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23,
    "out:CO2 Operational/m2": 9.25,
    "out:Total CO2/m2": 32.25,
    "out:Total CO2 (tons)": 109.93,
    "out:Klimatpaverkan": -107.07,
    "out:Initial Cost/MSEK": 9.335,
    "out:Running cost/(Apt SEK y)": 10053,
    "out:Running Cost over RSP/MSEK": 2.88,
    "out:LCP/MSEK": 0.487,
    "out:ROI% old": 15.03,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3951.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1252,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -124348,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 303572,
    "out:DH kr savings": 514964,
    "out:El kr savings": -211392,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18273,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.08,
    "out:Etvv": 0,
    "out:Ef": 52.38,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.66,
    "out:Electricity (inc PV)": 51.43,
    "out:Total Energy Use Pre PV": 59.66,
    "out:Total Energy Use Post PV": 55.43,
    "out:Primary Energy": 94.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23,
    "out:CO2 Operational/m2": 8.79,
    "out:Total CO2/m2": 31.78,
    "out:Total CO2 (tons)": 108.35,
    "out:Klimatpaverkan": -108.65,
    "out:Initial Cost/MSEK": 9.420125,
    "out:Running cost/(Apt SEK y)": 9687,
    "out:Running Cost over RSP/MSEK": 2.774,
    "out:LCP/MSEK": 0.508,
    "out:ROI% old": 15.06,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3896.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1266,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -121139,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 312130,
    "out:DH kr savings": 518066,
    "out:El kr savings": -205936,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13426,
    "out:% savings (space heating)": 96.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.16,
    "out:Etvv": 0,
    "out:Ef": 51.43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.02,
    "out:Electricity (inc PV)": 43.02,
    "out:Total Energy Use Pre PV": 65.02,
    "out:Total Energy Use Post PV": 50.02,
    "out:Primary Energy": 79.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.85,
    "out:CO2 Operational/m2": 6.21,
    "out:Total CO2/m2": 41.06,
    "out:Total CO2 (tons)": 139.98,
    "out:Klimatpaverkan": -77.02,
    "out:Initial Cost/MSEK": 9.839125,
    "out:Running cost/(Apt SEK y)": 9053,
    "out:Running Cost over RSP/MSEK": 2.604,
    "out:LCP/MSEK": 0.26,
    "out:ROI% old": 14.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3354.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1289,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1887.64,
    "out:PV (% sold (El))": 4.95,
    "out:PV (kWh self-consumed)": 36215.25,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -92466,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 353975,
    "out:DH kr savings": 508759,
    "out:El kr savings": -157191,
    "out:El kr sold": 2407,
    "out:El kr saved": 61566,
    "out:El kr return": 63973,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 22893,
    "out:% savings (space heating)": 94.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.53,
    "out:Etvv": 0,
    "out:Ef": 43.02,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 42.17,
    "out:Total Energy Use Pre PV": 62.32,
    "out:Total Energy Use Post PV": 47.17,
    "out:Primary Energy": 77.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.85,
    "out:CO2 Operational/m2": 5.22,
    "out:Total CO2/m2": 40.07,
    "out:Total CO2 (tons)": 136.61,
    "out:Klimatpaverkan": -80.39,
    "out:Initial Cost/MSEK": 9.924375,
    "out:Running cost/(Apt SEK y)": 8461,
    "out:Running Cost over RSP/MSEK": 2.432,
    "out:LCP/MSEK": 0.346,
    "out:ROI% old": 14.78,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3307.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1311,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2012.5,
    "out:PV (% sold (El))": 5.28,
    "out:PV (kWh self-consumed)": 36090.39,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -89557,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365282,
    "out:DH kr savings": 514964,
    "out:El kr savings": -152247,
    "out:El kr sold": 2566,
    "out:El kr saved": 61354,
    "out:El kr return": 63920,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17044,
    "out:% savings (space heating)": 96.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 42.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.45,
    "out:Electricity (inc PV)": 42.09,
    "out:Total Energy Use Pre PV": 61.45,
    "out:Total Energy Use Post PV": 47.09,
    "out:Primary Energy": 77.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.35,
    "out:CO2 Operational/m2": 4.88,
    "out:Total CO2/m2": 40.23,
    "out:Total CO2 (tons)": 137.14,
    "out:Klimatpaverkan": -79.86,
    "out:Initial Cost/MSEK": 10.044,
    "out:Running cost/(Apt SEK y)": 8327,
    "out:Running Cost over RSP/MSEK": 2.394,
    "out:LCP/MSEK": 0.264,
    "out:ROI% old": 14.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3249.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1316,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2173.05,
    "out:PV (% sold (El))": 5.7,
    "out:PV (kWh self-consumed)": 35929.84,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -89276,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 365965,
    "out:DH kr savings": 514964,
    "out:El kr savings": -151770,
    "out:El kr sold": 2771,
    "out:El kr saved": 61081,
    "out:El kr return": 63851,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18273,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.08,
    "out:Etvv": 0,
    "out:Ef": 42.09,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.66,
    "out:Electricity (inc PV)": 41.21,
    "out:Total Energy Use Pre PV": 59.66,
    "out:Total Energy Use Post PV": 45.21,
    "out:Primary Energy": 75.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.35,
    "out:CO2 Operational/m2": 4.2,
    "out:Total CO2/m2": 39.55,
    "out:Total CO2 (tons)": 134.83,
    "out:Klimatpaverkan": -82.17,
    "out:Initial Cost/MSEK": 10.12925,
    "out:Running cost/(Apt SEK y)": 7963,
    "out:Running Cost over RSP/MSEK": 2.289,
    "out:LCP/MSEK": 0.284,
    "out:ROI% old": 14.69,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3196.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1330,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2326.01,
    "out:PV (% sold (El))": 6.1,
    "out:PV (kWh self-consumed)": 35776.88,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -86290,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 374338,
    "out:DH kr savings": 518066,
    "out:El kr savings": -146693,
    "out:El kr sold": 2966,
    "out:El kr saved": 60821,
    "out:El kr return": 63786,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13426,
    "out:% savings (space heating)": 96.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.16,
    "out:Etvv": 0,
    "out:Ef": 41.21,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.02,
    "out:Electricity (inc PV)": 38.76,
    "out:Total Energy Use Pre PV": 65.02,
    "out:Total Energy Use Post PV": 45.76,
    "out:Primary Energy": 72.24,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.21,
    "out:CO2 Operational/m2": -26.64,
    "out:Total CO2/m2": 20.57,
    "out:Total CO2 (tons)": 70.11,
    "out:Klimatpaverkan": -146.89,
    "out:Initial Cost/MSEK": 10.54825,
    "out:Running cost/(Apt SEK y)": 7554,
    "out:Running Cost over RSP/MSEK": 2.181,
    "out:LCP/MSEK": -0.027,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3014.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1345,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23782.92,
    "out:PV (% sold (El))": 31.21,
    "out:PV (kWh self-consumed)": 52422.86,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -77941,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 406582,
    "out:DH kr savings": 508759,
    "out:El kr savings": -132500,
    "out:El kr sold": 30323,
    "out:El kr saved": 89119,
    "out:El kr return": 119442,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 22893,
    "out:% savings (space heating)": 94.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.53,
    "out:Etvv": 0,
    "out:Ef": 38.76,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.32,
    "out:Electricity (inc PV)": 38,
    "out:Total Energy Use Pre PV": 62.32,
    "out:Total Energy Use Post PV": 43,
    "out:Primary Energy": 70.17,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.21,
    "out:CO2 Operational/m2": -28.02,
    "out:Total CO2/m2": 19.19,
    "out:Total CO2 (tons)": 65.42,
    "out:Klimatpaverkan": -151.58,
    "out:Initial Cost/MSEK": 10.6335,
    "out:Running cost/(Apt SEK y)": 6965,
    "out:Running Cost over RSP/MSEK": 2.01,
    "out:LCP/MSEK": 0.058,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2972.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1367,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24167.81,
    "out:PV (% sold (El))": 31.71,
    "out:PV (kWh self-consumed)": 52037.97,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -75355,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 417674,
    "out:DH kr savings": 514964,
    "out:El kr savings": -128103,
    "out:El kr sold": 30814,
    "out:El kr saved": 88465,
    "out:El kr return": 119279,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17044,
    "out:% savings (space heating)": 96.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.51,
    "out:Etvv": 0,
    "out:Ef": 38,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.45,
    "out:Electricity (inc PV)": 37.93,
    "out:Total Energy Use Pre PV": 61.45,
    "out:Total Energy Use Post PV": 42.93,
    "out:Primary Energy": 70.44,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.7,
    "out:CO2 Operational/m2": -28.83,
    "out:Total CO2/m2": 18.87,
    "out:Total CO2 (tons)": 64.32,
    "out:Klimatpaverkan": -152.68,
    "out:Initial Cost/MSEK": 10.75325,
    "out:Running cost/(Apt SEK y)": 6835,
    "out:Running Cost over RSP/MSEK": 1.973,
    "out:LCP/MSEK": -0.024,
    "out:ROI% old": 14.25,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2921.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1371,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 38.25,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24646.5,
    "out:PV (% sold (El))": 32.34,
    "out:PV (kWh self-consumed)": 51559.28,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -75105,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 418710,
    "out:DH kr savings": 514964,
    "out:El kr savings": -127678,
    "out:El kr sold": 31424,
    "out:El kr saved": 87651,
    "out:El kr return": 119075,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18273,
    "out:% savings (space heating)": 95.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.08,
    "out:Etvv": 0,
    "out:Ef": 37.93,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.66,
    "out:Electricity (inc PV)": 37.15,
    "out:Total Energy Use Pre PV": 59.66,
    "out:Total Energy Use Post PV": 41.15,
    "out:Primary Energy": 68.39,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.7,
    "out:CO2 Operational/m2": -29.94,
    "out:Total CO2/m2": 17.76,
    "out:Total CO2 (tons)": 60.53,
    "out:Klimatpaverkan": -156.47,
    "out:Initial Cost/MSEK": 10.838375,
    "out:Running cost/(Apt SEK y)": 6474,
    "out:Running Cost over RSP/MSEK": 1.869,
    "out:LCP/MSEK": -0.005,
    "out:ROI% old": 14.28,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2874.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1385,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.46,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25087.5,
    "out:PV (% sold (El))": 32.92,
    "out:PV (kWh self-consumed)": 51118.28,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -72447,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 426893,
    "out:DH kr savings": 518066,
    "out:El kr savings": -123160,
    "out:El kr sold": 31987,
    "out:El kr saved": 86901,
    "out:El kr return": 118888,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13426,
    "out:% savings (space heating)": 96.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.16,
    "out:Etvv": 0,
    "out:Ef": 37.15,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.91,
    "out:Electricity (inc PV)": 52.04,
    "out:Total Energy Use Pre PV": 60.91,
    "out:Total Energy Use Post PV": 57.04,
    "out:Primary Energy": 95.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.5,
    "out:CO2 Operational/m2": 9.18,
    "out:Total CO2/m2": 31.68,
    "out:Total CO2 (tons)": 108,
    "out:Klimatpaverkan": -109,
    "out:Initial Cost/MSEK": 9.22775,
    "out:Running cost/(Apt SEK y)": 9968,
    "out:Running Cost over RSP/MSEK": 2.856,
    "out:LCP/MSEK": 0.619,
    "out:ROI% old": 15.24,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3913.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1255,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -123197,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 305528,
    "out:DH kr savings": 514964,
    "out:El kr savings": -209435,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 16938,
    "out:% savings (space heating)": 96.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.95,
    "out:Etvv": 0,
    "out:Ef": 52.04,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.09,
    "out:Electricity (inc PV)": 51.09,
    "out:Total Energy Use Pre PV": 59.09,
    "out:Total Energy Use Post PV": 55.09,
    "out:Primary Energy": 93.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.5,
    "out:CO2 Operational/m2": 8.71,
    "out:Total CO2/m2": 31.21,
    "out:Total CO2 (tons)": 106.4,
    "out:Klimatpaverkan": -110.6,
    "out:Initial Cost/MSEK": 9.312875,
    "out:Running cost/(Apt SEK y)": 9598,
    "out:Running Cost over RSP/MSEK": 2.749,
    "out:LCP/MSEK": 0.641,
    "out:ROI% old": 15.27,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3856.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1269,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -119960,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314134,
    "out:DH kr savings": 518066,
    "out:El kr savings": -203932,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 12398,
    "out:% savings (space heating)": 97.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 51.09,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.58,
    "out:Electricity (inc PV)": 51.22,
    "out:Total Energy Use Pre PV": 58.58,
    "out:Total Energy Use Post PV": 55.22,
    "out:Primary Energy": 94.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23,
    "out:CO2 Operational/m2": 8.64,
    "out:Total CO2/m2": 31.64,
    "out:Total CO2 (tons)": 107.86,
    "out:Klimatpaverkan": -109.14,
    "out:Initial Cost/MSEK": 9.432625,
    "out:Running cost/(Apt SEK y)": 9518,
    "out:Running Cost over RSP/MSEK": 2.726,
    "out:LCP/MSEK": 0.544,
    "out:ROI% old": 15.11,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3820.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1272,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -120416,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 313359,
    "out:DH kr savings": 518066,
    "out:El kr savings": -204707,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 14117,
    "out:% savings (space heating)": 96.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.64,
    "out:Etvv": 0,
    "out:Ef": 51.22,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.71,
    "out:Electricity (inc PV)": 50.25,
    "out:Total Energy Use Pre PV": 56.71,
    "out:Total Energy Use Post PV": 53.25,
    "out:Primary Energy": 91.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23,
    "out:CO2 Operational/m2": 8.17,
    "out:Total CO2/m2": 31.17,
    "out:Total CO2 (tons)": 106.24,
    "out:Klimatpaverkan": -110.76,
    "out:Initial Cost/MSEK": 9.517875,
    "out:Running cost/(Apt SEK y)": 9139,
    "out:Running Cost over RSP/MSEK": 2.617,
    "out:LCP/MSEK": 0.568,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3759.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1286,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -117114,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 322074,
    "out:DH kr savings": 521168,
    "out:El kr savings": -199094,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10210,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.81,
    "out:Etvv": 0,
    "out:Ef": 50.25,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.91,
    "out:Electricity (inc PV)": 41.77,
    "out:Total Energy Use Pre PV": 60.91,
    "out:Total Energy Use Post PV": 46.77,
    "out:Primary Energy": 77.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.85,
    "out:CO2 Operational/m2": 4.66,
    "out:Total CO2/m2": 39.52,
    "out:Total CO2 (tons)": 134.71,
    "out:Klimatpaverkan": -82.29,
    "out:Initial Cost/MSEK": 9.93675,
    "out:Running cost/(Apt SEK y)": 8244,
    "out:Running Cost over RSP/MSEK": 2.37,
    "out:LCP/MSEK": 0.395,
    "out:ROI% old": 14.85,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3213.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1319,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2276.89,
    "out:PV (% sold (El))": 5.98,
    "out:PV (kWh self-consumed)": 35826.01,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -88204,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 367919,
    "out:DH kr savings": 514964,
    "out:El kr savings": -149948,
    "out:El kr sold": 2903,
    "out:El kr saved": 60904,
    "out:El kr return": 63807,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 16938,
    "out:% savings (space heating)": 96.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.95,
    "out:Etvv": 0,
    "out:Ef": 41.77,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.09,
    "out:Electricity (inc PV)": 40.89,
    "out:Total Energy Use Pre PV": 59.09,
    "out:Total Energy Use Post PV": 44.89,
    "out:Primary Energy": 75.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.85,
    "out:CO2 Operational/m2": 3.97,
    "out:Total CO2/m2": 38.82,
    "out:Total CO2 (tons)": 132.33,
    "out:Klimatpaverkan": -84.67,
    "out:Initial Cost/MSEK": 10.022,
    "out:Running cost/(Apt SEK y)": 7875,
    "out:Running Cost over RSP/MSEK": 2.264,
    "out:LCP/MSEK": 0.416,
    "out:ROI% old": 14.88,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3158.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1333,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2439.69,
    "out:PV (% sold (El))": 6.4,
    "out:PV (kWh self-consumed)": 35663.2,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -85195,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376344,
    "out:DH kr savings": 518066,
    "out:El kr savings": -144832,
    "out:El kr sold": 3111,
    "out:El kr saved": 60627,
    "out:El kr return": 63738,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 12398,
    "out:% savings (space heating)": 97.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 40.89,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.58,
    "out:Electricity (inc PV)": 41.02,
    "out:Total Energy Use Pre PV": 58.58,
    "out:Total Energy Use Post PV": 45.02,
    "out:Primary Energy": 75.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.35,
    "out:CO2 Operational/m2": 3.75,
    "out:Total CO2/m2": 39.1,
    "out:Total CO2 (tons)": 133.28,
    "out:Klimatpaverkan": -83.72,
    "out:Initial Cost/MSEK": 10.14175,
    "out:Running cost/(Apt SEK y)": 7796,
    "out:Running Cost over RSP/MSEK": 2.241,
    "out:LCP/MSEK": 0.319,
    "out:ROI% old": 14.74,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3124.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1336,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2544.87,
    "out:PV (% sold (El))": 6.68,
    "out:PV (kWh self-consumed)": 35558.02,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -85619,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 375758,
    "out:DH kr savings": 518066,
    "out:El kr savings": -145552,
    "out:El kr sold": 3245,
    "out:El kr saved": 60449,
    "out:El kr return": 63693,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 14117,
    "out:% savings (space heating)": 96.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.64,
    "out:Etvv": 0,
    "out:Ef": 41.02,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.71,
    "out:Electricity (inc PV)": 40.12,
    "out:Total Energy Use Pre PV": 56.71,
    "out:Total Energy Use Post PV": 43.12,
    "out:Primary Energy": 73.48,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.35,
    "out:CO2 Operational/m2": 3.01,
    "out:Total CO2/m2": 38.36,
    "out:Total CO2 (tons)": 130.76,
    "out:Klimatpaverkan": -86.24,
    "out:Initial Cost/MSEK": 10.227,
    "out:Running cost/(Apt SEK y)": 7419,
    "out:Running Cost over RSP/MSEK": 2.133,
    "out:LCP/MSEK": 0.343,
    "out:ROI% old": 14.76,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3066.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1350,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2731.45,
    "out:PV (% sold (El))": 7.17,
    "out:PV (kWh self-consumed)": 35371.44,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -82558,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384302,
    "out:DH kr savings": 521168,
    "out:El kr savings": -140348,
    "out:El kr sold": 3483,
    "out:El kr saved": 60131,
    "out:El kr return": 63614,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10210,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.81,
    "out:Etvv": 0,
    "out:Ef": 40.12,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.91,
    "out:Electricity (inc PV)": 37.65,
    "out:Total Energy Use Pre PV": 60.91,
    "out:Total Energy Use Post PV": 42.65,
    "out:Primary Energy": 69.84,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.21,
    "out:CO2 Operational/m2": -29.35,
    "out:Total CO2/m2": 17.86,
    "out:Total CO2 (tons)": 60.88,
    "out:Klimatpaverkan": -156.12,
    "out:Initial Cost/MSEK": 10.645875,
    "out:Running cost/(Apt SEK y)": 6753,
    "out:Running Cost over RSP/MSEK": 1.95,
    "out:LCP/MSEK": 0.106,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2889.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1374,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.71,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24947.89,
    "out:PV (% sold (El))": 32.74,
    "out:PV (kWh self-consumed)": 51257.89,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -74151,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 420716,
    "out:DH kr savings": 514964,
    "out:El kr savings": -126057,
    "out:El kr sold": 31809,
    "out:El kr saved": 87138,
    "out:El kr return": 118947,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 16938,
    "out:% savings (space heating)": 96.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.95,
    "out:Etvv": 0,
    "out:Ef": 37.65,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.09,
    "out:Electricity (inc PV)": 36.87,
    "out:Total Energy Use Pre PV": 59.09,
    "out:Total Energy Use Post PV": 40.87,
    "out:Primary Energy": 67.8,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.21,
    "out:CO2 Operational/m2": -30.49,
    "out:Total CO2/m2": 16.72,
    "out:Total CO2 (tons)": 56.99,
    "out:Klimatpaverkan": -160.01,
    "out:Initial Cost/MSEK": 10.731125,
    "out:Running cost/(Apt SEK y)": 6388,
    "out:Running Cost over RSP/MSEK": 1.845,
    "out:LCP/MSEK": 0.126,
    "out:ROI% old": 14.45,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2841.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1388,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25406.74,
    "out:PV (% sold (El))": 33.34,
    "out:PV (kWh self-consumed)": 50799.04,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -71473,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 428956,
    "out:DH kr savings": 518066,
    "out:El kr savings": -121504,
    "out:El kr sold": 32394,
    "out:El kr saved": 86358,
    "out:El kr return": 118752,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 12398,
    "out:% savings (space heating)": 97.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.05,
    "out:Etvv": 0,
    "out:Ef": 36.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.58,
    "out:Electricity (inc PV)": 36.98,
    "out:Total Energy Use Pre PV": 58.58,
    "out:Total Energy Use Post PV": 40.98,
    "out:Primary Energy": 68.41,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.7,
    "out:CO2 Operational/m2": -30.98,
    "out:Total CO2/m2": 16.72,
    "out:Total CO2 (tons)": 57,
    "out:Klimatpaverkan": -160,
    "out:Initial Cost/MSEK": 10.850875,
    "out:Running cost/(Apt SEK y)": 6311,
    "out:Running Cost over RSP/MSEK": 1.823,
    "out:LCP/MSEK": 0.029,
    "out:ROI% old": 14.32,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2810.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1391,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 36.38,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25697.15,
    "out:PV (% sold (El))": 33.72,
    "out:PV (kWh self-consumed)": 50508.64,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -71850,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 428685,
    "out:DH kr savings": 518066,
    "out:El kr savings": -122144,
    "out:El kr sold": 32764,
    "out:El kr saved": 85865,
    "out:El kr return": 118629,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 14117,
    "out:% savings (space heating)": 96.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.64,
    "out:Etvv": 0,
    "out:Ef": 36.98,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.71,
    "out:Electricity (inc PV)": 36.18,
    "out:Total Energy Use Pre PV": 56.71,
    "out:Total Energy Use Post PV": 39.18,
    "out:Primary Energy": 66.39,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.7,
    "out:CO2 Operational/m2": -32.19,
    "out:Total CO2/m2": 15.51,
    "out:Total CO2 (tons)": 52.89,
    "out:Klimatpaverkan": -164.11,
    "out:Initial Cost/MSEK": 10.936125,
    "out:Running cost/(Apt SEK y)": 5938,
    "out:Running Cost over RSP/MSEK": 1.715,
    "out:LCP/MSEK": 0.051,
    "out:ROI% old": 14.35,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2759.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1405,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.51,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 26193.64,
    "out:PV (% sold (El))": 34.37,
    "out:PV (kWh self-consumed)": 50012.15,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -69125,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 437052,
    "out:DH kr savings": 521168,
    "out:El kr savings": -117513,
    "out:El kr sold": 33397,
    "out:El kr saved": 85021,
    "out:El kr return": 118418,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10210,
    "out:% savings (space heating)": 97.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.81,
    "out:Etvv": 0,
    "out:Ef": 36.18,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 162.9,
    "out:Primary Energy": 118.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.79,
    "out:CO2 Operational/m2": 55.02,
    "out:Total CO2/m2": 67.81,
    "out:Total CO2 (tons)": 231.16,
    "out:Klimatpaverkan": 14.16,
    "out:Initial Cost/MSEK": 3.136,
    "out:Running cost/(Apt SEK y)": 38086,
    "out:Running Cost over RSP/MSEK": 11.019,
    "out:LCP/MSEK": -1.453,
    "out:ROI% old": 7.67,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 81816,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74453,
    "out:DH kr savings": 74453,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 157.9,
    "out:Primary Energy": 115.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.79,
    "out:CO2 Operational/m2": 53.22,
    "out:Total CO2/m2": 66.01,
    "out:Total CO2 (tons)": 225.02,
    "out:Klimatpaverkan": 8.02,
    "out:Initial Cost/MSEK": 3.22125,
    "out:Running cost/(Apt SEK y)": 36876,
    "out:Running Cost over RSP/MSEK": 10.669,
    "out:LCP/MSEK": -1.188,
    "out:ROI% old": 9.02,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 260,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 98861,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 89964,
    "out:DH kr savings": 89964,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 155.9,
    "out:Primary Energy": 115.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.29,
    "out:CO2 Operational/m2": 52.5,
    "out:Total CO2/m2": 65.79,
    "out:Total CO2 (tons)": 224.26,
    "out:Klimatpaverkan": 7.26,
    "out:Initial Cost/MSEK": 3.340875,
    "out:Running cost/(Apt SEK y)": 36391,
    "out:Running Cost over RSP/MSEK": 10.529,
    "out:LCP/MSEK": -1.167,
    "out:ROI% old": 9.29,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 19.9,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 105679,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96168,
    "out:DH kr savings": 96168,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 151.9,
    "out:Primary Energy": 112.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.29,
    "out:CO2 Operational/m2": 51.06,
    "out:Total CO2/m2": 64.35,
    "out:Total CO2 (tons)": 219.35,
    "out:Klimatpaverkan": 2.35,
    "out:Initial Cost/MSEK": 3.426125,
    "out:Running cost/(Apt SEK y)": 35423,
    "out:Running Cost over RSP/MSEK": 10.248,
    "out:LCP/MSEK": -0.972,
    "out:ROI% old": 10.23,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 314,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 119315,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 108577,
    "out:DH kr savings": 108577,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 157.73,
    "out:Primary Energy": 108.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.15,
    "out:CO2 Operational/m2": 24.02,
    "out:Total CO2/m2": 49.17,
    "out:Total CO2 (tons)": 167.61,
    "out:Klimatpaverkan": -49.39,
    "out:Initial Cost/MSEK": 3.845125,
    "out:Running cost/(Apt SEK y)": 36571,
    "out:Running Cost over RSP/MSEK": 10.593,
    "out:LCP/MSEK": -1.736,
    "out:ROI% old": 7.84,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 18.4,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 81816,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130527,
    "out:DH kr savings": 74453,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 152.73,
    "out:Primary Energy": 105.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.15,
    "out:CO2 Operational/m2": 22.22,
    "out:Total CO2/m2": 47.37,
    "out:Total CO2 (tons)": 161.47,
    "out:Klimatpaverkan": -55.53,
    "out:Initial Cost/MSEK": 3.930375,
    "out:Running cost/(Apt SEK y)": 35360,
    "out:Running Cost over RSP/MSEK": 10.242,
    "out:LCP/MSEK": -1.47,
    "out:ROI% old": 8.94,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 22.2,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 316,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 98861,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146038,
    "out:DH kr savings": 89964,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 150.73,
    "out:Primary Energy": 106.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.64,
    "out:CO2 Operational/m2": 21.5,
    "out:Total CO2/m2": 47.14,
    "out:Total CO2 (tons)": 160.71,
    "out:Klimatpaverkan": -56.29,
    "out:Initial Cost/MSEK": 4.05,
    "out:Running cost/(Apt SEK y)": 34876,
    "out:Running Cost over RSP/MSEK": 10.102,
    "out:LCP/MSEK": -1.45,
    "out:ROI% old": 9.17,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 334,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105679,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152242,
    "out:DH kr savings": 96168,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 146.73,
    "out:Primary Energy": 103.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.64,
    "out:CO2 Operational/m2": 20.06,
    "out:Total CO2/m2": 45.7,
    "out:Total CO2 (tons)": 155.8,
    "out:Klimatpaverkan": -61.2,
    "out:Initial Cost/MSEK": 4.13525,
    "out:Running cost/(Apt SEK y)": 33907,
    "out:Running Cost over RSP/MSEK": 9.821,
    "out:LCP/MSEK": -1.254,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 370,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 119315,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164651,
    "out:DH kr savings": 108577,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 162.9,
    "out:Total Energy Use Post PV": 156.88,
    "out:Primary Energy": 107.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.5,
    "out:CO2 Operational/m2": -28.66,
    "out:Total CO2/m2": 8.84,
    "out:Total CO2 (tons)": 30.14,
    "out:Klimatpaverkan": -186.86,
    "out:Initial Cost/MSEK": 4.55425,
    "out:Running cost/(Apt SEK y)": 35225,
    "out:Running Cost over RSP/MSEK": 10.214,
    "out:LCP/MSEK": -2.066,
    "out:ROI% old": 7.81,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 19.1,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 81816,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180338,
    "out:DH kr savings": 74453,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 157.9,
    "out:Total Energy Use Post PV": 151.88,
    "out:Primary Energy": 104.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.5,
    "out:CO2 Operational/m2": -30.46,
    "out:Total CO2/m2": 7.04,
    "out:Total CO2 (tons)": 24,
    "out:Klimatpaverkan": -193,
    "out:Initial Cost/MSEK": 4.6395,
    "out:Running cost/(Apt SEK y)": 34014,
    "out:Running Cost over RSP/MSEK": 9.863,
    "out:LCP/MSEK": -1.801,
    "out:ROI% old": 8.74,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 366,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 98861,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195848,
    "out:DH kr savings": 89964,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 155.9,
    "out:Total Energy Use Post PV": 149.88,
    "out:Primary Energy": 104.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.99,
    "out:CO2 Operational/m2": -31.18,
    "out:Total CO2/m2": 6.82,
    "out:Total CO2 (tons)": 23.24,
    "out:Klimatpaverkan": -193.76,
    "out:Initial Cost/MSEK": 4.759125,
    "out:Running cost/(Apt SEK y)": 33530,
    "out:Running Cost over RSP/MSEK": 9.723,
    "out:LCP/MSEK": -1.78,
    "out:ROI% old": 8.94,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 384,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 105679,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202053,
    "out:DH kr savings": 96168,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 151.9,
    "out:Total Energy Use Post PV": 145.88,
    "out:Primary Energy": 101.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.99,
    "out:CO2 Operational/m2": -32.62,
    "out:Total CO2/m2": 5.38,
    "out:Total CO2 (tons)": 18.33,
    "out:Klimatpaverkan": -198.67,
    "out:Initial Cost/MSEK": 4.844375,
    "out:Running cost/(Apt SEK y)": 32561,
    "out:Running Cost over RSP/MSEK": 9.443,
    "out:LCP/MSEK": -1.585,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 419,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 119315,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214462,
    "out:DH kr savings": 108577,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 153.9,
    "out:Primary Energy": 114.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.79,
    "out:CO2 Operational/m2": 51.78,
    "out:Total CO2/m2": 64.57,
    "out:Total CO2 (tons)": 220.11,
    "out:Klimatpaverkan": 3.11,
    "out:Initial Cost/MSEK": 3.233625,
    "out:Running cost/(Apt SEK y)": 35907,
    "out:Running Cost over RSP/MSEK": 10.388,
    "out:LCP/MSEK": -0.92,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 296,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 102372,
    "out:DH kr savings": 102372,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 111.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.79,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 62.77,
    "out:Total CO2 (tons)": 213.98,
    "out:Klimatpaverkan": -3.02,
    "out:Initial Cost/MSEK": 3.318875,
    "out:Running cost/(Apt SEK y)": 34696,
    "out:Running Cost over RSP/MSEK": 10.038,
    "out:LCP/MSEK": -0.654,
    "out:ROI% old": 11.47,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117883,
    "out:DH kr savings": 117883,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 148.9,
    "out:Primary Energy": 112.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.29,
    "out:CO2 Operational/m2": 49.98,
    "out:Total CO2/m2": 63.27,
    "out:Total CO2 (tons)": 215.67,
    "out:Klimatpaverkan": -1.33,
    "out:Initial Cost/MSEK": 3.438625,
    "out:Running cost/(Apt SEK y)": 34696,
    "out:Running Cost over RSP/MSEK": 10.038,
    "out:LCP/MSEK": -0.774,
    "out:ROI% old": 11.07,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117883,
    "out:DH kr savings": 117883,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 109.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.29,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 61.47,
    "out:Total CO2 (tons)": 209.54,
    "out:Klimatpaverkan": -7.46,
    "out:Initial Cost/MSEK": 3.52375,
    "out:Running cost/(Apt SEK y)": 33486,
    "out:Running Cost over RSP/MSEK": 9.687,
    "out:LCP/MSEK": -0.509,
    "out:ROI% old": 12.22,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133394,
    "out:DH kr savings": 133394,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 148.73,
    "out:Primary Energy": 105.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.15,
    "out:CO2 Operational/m2": 20.78,
    "out:Total CO2/m2": 45.93,
    "out:Total CO2 (tons)": 156.57,
    "out:Klimatpaverkan": -60.43,
    "out:Initial Cost/MSEK": 3.94275,
    "out:Running cost/(Apt SEK y)": 34392,
    "out:Running Cost over RSP/MSEK": 9.962,
    "out:LCP/MSEK": -1.202,
    "out:ROI% old": 9.93,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158446,
    "out:DH kr savings": 102372,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 102.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.15,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 44.13,
    "out:Total CO2 (tons)": 150.43,
    "out:Klimatpaverkan": -66.57,
    "out:Initial Cost/MSEK": 4.028,
    "out:Running cost/(Apt SEK y)": 33181,
    "out:Running Cost over RSP/MSEK": 9.611,
    "out:LCP/MSEK": -0.937,
    "out:ROI% old": 10.96,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 397,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173957,
    "out:DH kr savings": 117883,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 143.73,
    "out:Primary Energy": 103.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.64,
    "out:CO2 Operational/m2": 18.98,
    "out:Total CO2/m2": 44.62,
    "out:Total CO2 (tons)": 152.12,
    "out:Klimatpaverkan": -64.88,
    "out:Initial Cost/MSEK": 4.147625,
    "out:Running cost/(Apt SEK y)": 33181,
    "out:Running Cost over RSP/MSEK": 9.611,
    "out:LCP/MSEK": -1.056,
    "out:ROI% old": 10.65,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 397,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173957,
    "out:DH kr savings": 117883,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 100.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.64,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 42.82,
    "out:Total CO2 (tons)": 145.99,
    "out:Klimatpaverkan": -71.01,
    "out:Initial Cost/MSEK": 4.232875,
    "out:Running cost/(Apt SEK y)": 31970,
    "out:Running Cost over RSP/MSEK": 9.26,
    "out:LCP/MSEK": -0.791,
    "out:ROI% old": 11.62,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189468,
    "out:DH kr savings": 133394,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 153.9,
    "out:Total Energy Use Post PV": 147.88,
    "out:Primary Energy": 103.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.5,
    "out:CO2 Operational/m2": -31.9,
    "out:Total CO2/m2": 5.6,
    "out:Total CO2 (tons)": 19.09,
    "out:Klimatpaverkan": -197.91,
    "out:Initial Cost/MSEK": 4.651875,
    "out:Running cost/(Apt SEK y)": 33045,
    "out:Running Cost over RSP/MSEK": 9.583,
    "out:LCP/MSEK": -1.533,
    "out:ROI% old": 9.58,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 402,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 112497,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208257,
    "out:DH kr savings": 102372,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 100.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.5,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 3.8,
    "out:Total CO2 (tons)": 12.96,
    "out:Klimatpaverkan": -204.04,
    "out:Initial Cost/MSEK": 4.737125,
    "out:Running cost/(Apt SEK y)": 31835,
    "out:Running Cost over RSP/MSEK": 9.232,
    "out:LCP/MSEK": -1.267,
    "out:ROI% old": 10.46,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223768,
    "out:DH kr savings": 117883,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 103.01,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 148.9,
    "out:Total Energy Use Post PV": 142.88,
    "out:Primary Energy": 101.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 37.99,
    "out:CO2 Operational/m2": -33.7,
    "out:Total CO2/m2": 4.3,
    "out:Total CO2 (tons)": 14.65,
    "out:Klimatpaverkan": -202.35,
    "out:Initial Cost/MSEK": 4.85675,
    "out:Running cost/(Apt SEK y)": 31835,
    "out:Running Cost over RSP/MSEK": 9.232,
    "out:LCP/MSEK": -1.387,
    "out:ROI% old": 10.21,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 129542,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223768,
    "out:DH kr savings": 117883,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 351169,
    "out:% savings (space heating)": 19.23,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 103.01,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.6,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 98.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.99,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 2.5,
    "out:Total CO2 (tons)": 8.51,
    "out:Klimatpaverkan": -208.49,
    "out:Initial Cost/MSEK": 4.942,
    "out:Running cost/(Apt SEK y)": 30624,
    "out:Running Cost over RSP/MSEK": 8.882,
    "out:LCP/MSEK": -1.121,
    "out:ROI% old": 11.04,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239279,
    "out:DH kr savings": 133394,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 336137,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 98.6,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 41.42,
    "out:Total Energy Use Pre PV": 106.42,
    "out:Total Energy Use Post PV": 104.42,
    "out:Primary Energy": 110.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.3,
    "out:CO2 Operational/m2": 28.41,
    "out:Total CO2/m2": 44.71,
    "out:Total CO2 (tons)": 152.41,
    "out:Klimatpaverkan": -64.59,
    "out:Initial Cost/MSEK": 5.982,
    "out:Running cost/(Apt SEK y)": 22056,
    "out:Running Cost over RSP/MSEK": 6.363,
    "out:LCP/MSEK": 0.358,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 3039.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 808,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -86981,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187169,
    "out:DH kr savings": 335037,
    "out:El kr savings": -147867,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 215769,
    "out:% savings (space heating)": 50.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 51.4,
    "out:Etvv": 0,
    "out:Ef": 41.42,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.35,
    "out:Electricity (inc PV)": 41.3,
    "out:Total Energy Use Pre PV": 102.35,
    "out:Total Energy Use Post PV": 100.3,
    "out:Primary Energy": 107.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.3,
    "out:CO2 Operational/m2": 26.96,
    "out:Total CO2/m2": 43.26,
    "out:Total CO2 (tons)": 147.47,
    "out:Klimatpaverkan": -69.53,
    "out:Initial Cost/MSEK": 6.06725,
    "out:Running cost/(Apt SEK y)": 21077,
    "out:Running Cost over RSP/MSEK": 6.079,
    "out:LCP/MSEK": 0.556,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 3034.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 844,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -86590,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 200243,
    "out:DH kr savings": 347445,
    "out:El kr savings": -147202,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 201484,
    "out:% savings (space heating)": 53.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 47.37,
    "out:Etvv": 0,
    "out:Ef": 41.3,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.75,
    "out:Electricity (inc PV)": 40.97,
    "out:Total Energy Use Pre PV": 101.75,
    "out:Total Energy Use Post PV": 99.97,
    "out:Primary Energy": 108.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.79,
    "out:CO2 Operational/m2": 26.88,
    "out:Total CO2/m2": 43.68,
    "out:Total CO2 (tons)": 148.89,
    "out:Klimatpaverkan": -68.11,
    "out:Initial Cost/MSEK": 6.187,
    "out:Running cost/(Apt SEK y)": 20983,
    "out:Running Cost over RSP/MSEK": 6.052,
    "out:LCP/MSEK": 0.463,
    "out:ROI% old": 15.35,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2992.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -85450,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202180,
    "out:DH kr savings": 347445,
    "out:El kr savings": -145265,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 200992,
    "out:% savings (space heating)": 53.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 49.13,
    "out:Etvv": 0,
    "out:Ef": 40.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.67,
    "out:Electricity (inc PV)": 40.84,
    "out:Total Energy Use Pre PV": 97.67,
    "out:Total Energy Use Post PV": 95.84,
    "out:Primary Energy": 105.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.79,
    "out:CO2 Operational/m2": 25.43,
    "out:Total CO2/m2": 42.23,
    "out:Total CO2 (tons)": 143.95,
    "out:Klimatpaverkan": -73.05,
    "out:Initial Cost/MSEK": 6.272125,
    "out:Running cost/(Apt SEK y)": 20002,
    "out:Running Cost over RSP/MSEK": 5.768,
    "out:LCP/MSEK": 0.662,
    "out:ROI% old": 15.79,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2987,
    "out:Return %": 14,
    "out:Return/kSEK/y": 884,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -85025,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215312,
    "out:DH kr savings": 359854,
    "out:El kr savings": -144542,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 186885,
    "out:% savings (space heating)": 57.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 45.14,
    "out:Etvv": 0,
    "out:Ef": 40.84,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 32.08,
    "out:Total Energy Use Pre PV": 106.42,
    "out:Total Energy Use Post PV": 95.08,
    "out:Primary Energy": 93.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.65,
    "out:CO2 Operational/m2": 19.19,
    "out:Total CO2/m2": 47.84,
    "out:Total CO2 (tons)": 163.08,
    "out:Klimatpaverkan": -53.92,
    "out:Initial Cost/MSEK": 6.691125,
    "out:Running cost/(Apt SEK y)": 20369,
    "out:Running Cost over RSP/MSEK": 5.888,
    "out:LCP/MSEK": 0.124,
    "out:ROI% old": 14.55,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 96.8,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2396.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5570.84,
    "out:PV (% sold (El))": 14.62,
    "out:PV (kWh self-consumed)": 32532.06,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -55153,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248379,
    "out:DH kr savings": 335037,
    "out:El kr savings": -93760,
    "out:El kr sold": 7103,
    "out:El kr saved": 55304,
    "out:El kr return": 62407,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 215769,
    "out:% savings (space heating)": 50.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 51.4,
    "out:Etvv": 0,
    "out:Ef": 32.08,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.35,
    "out:Electricity (inc PV)": 31.98,
    "out:Total Energy Use Pre PV": 102.35,
    "out:Total Energy Use Post PV": 90.98,
    "out:Primary Energy": 90.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.65,
    "out:CO2 Operational/m2": 17.71,
    "out:Total CO2/m2": 46.36,
    "out:Total CO2 (tons)": 158.03,
    "out:Klimatpaverkan": -58.97,
    "out:Initial Cost/MSEK": 6.776375,
    "out:Running cost/(Apt SEK y)": 19390,
    "out:Running Cost over RSP/MSEK": 5.604,
    "out:LCP/MSEK": 0.322,
    "out:ROI% old": 14.96,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2392.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5593.54,
    "out:PV (% sold (El))": 14.68,
    "out:PV (kWh self-consumed)": 32509.35,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -54804,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261410,
    "out:DH kr savings": 347445,
    "out:El kr savings": -93167,
    "out:El kr sold": 7132,
    "out:El kr saved": 55266,
    "out:El kr return": 62398,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 201484,
    "out:% savings (space heating)": 53.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 47.37,
    "out:Etvv": 0,
    "out:Ef": 31.98,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.75,
    "out:Electricity (inc PV)": 31.68,
    "out:Total Energy Use Pre PV": 101.75,
    "out:Total Energy Use Post PV": 90.68,
    "out:Primary Energy": 91.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.15,
    "out:CO2 Operational/m2": 17.33,
    "out:Total CO2/m2": 46.48,
    "out:Total CO2 (tons)": 158.44,
    "out:Klimatpaverkan": -58.56,
    "out:Initial Cost/MSEK": 6.896,
    "out:Running cost/(Apt SEK y)": 19299,
    "out:Running Cost over RSP/MSEK": 5.578,
    "out:LCP/MSEK": 0.228,
    "out:ROI% old": 14.76,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 105.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2354.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 910,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5799.56,
    "out:PV (% sold (El))": 15.22,
    "out:PV (kWh self-consumed)": 32303.33,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -53787,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263402,
    "out:DH kr savings": 347445,
    "out:El kr savings": -91438,
    "out:El kr sold": 7394,
    "out:El kr saved": 54916,
    "out:El kr return": 62310,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 200992,
    "out:% savings (space heating)": 53.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 49.13,
    "out:Etvv": 0,
    "out:Ef": 31.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.67,
    "out:Electricity (inc PV)": 31.57,
    "out:Total Energy Use Pre PV": 97.67,
    "out:Total Energy Use Post PV": 86.57,
    "out:Primary Energy": 88.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.15,
    "out:CO2 Operational/m2": 15.84,
    "out:Total CO2/m2": 44.98,
    "out:Total CO2 (tons)": 153.35,
    "out:Klimatpaverkan": -63.65,
    "out:Initial Cost/MSEK": 6.98125,
    "out:Running cost/(Apt SEK y)": 18318,
    "out:Running Cost over RSP/MSEK": 5.294,
    "out:LCP/MSEK": 0.427,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2348.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5828.2,
    "out:PV (% sold (El))": 15.3,
    "out:PV (kWh self-consumed)": 32274.7,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -53408,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276491,
    "out:DH kr savings": 359854,
    "out:El kr savings": -90794,
    "out:El kr sold": 7431,
    "out:El kr saved": 54867,
    "out:El kr return": 62298,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 186885,
    "out:% savings (space heating)": 57.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 45.14,
    "out:Etvv": 0,
    "out:Ef": 31.57,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.42,
    "out:Electricity (inc PV)": 29,
    "out:Total Energy Use Pre PV": 106.42,
    "out:Total Energy Use Post PV": 92,
    "out:Primary Energy": 88.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41,
    "out:CO2 Operational/m2": -21.23,
    "out:Total CO2/m2": 19.77,
    "out:Total CO2 (tons)": 67.39,
    "out:Klimatpaverkan": -149.61,
    "out:Initial Cost/MSEK": 7.40025,
    "out:Running cost/(Apt SEK y)": 18928,
    "out:Running Cost over RSP/MSEK": 5.482,
    "out:LCP/MSEK": -0.18,
    "out:ROI% old": 13.94,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 103.3,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 2163.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.22,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32530.52,
    "out:PV (% sold (El))": 42.69,
    "out:PV (kWh self-consumed)": 43675.27,
    "out:PV (ratio sold/self-consumed)": 0.74,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 368172,
    "out:EL kWh savings": -44646,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 300614,
    "out:DH kr savings": 335037,
    "out:El kr savings": -75899,
    "out:El kr sold": 41476,
    "out:El kr saved": 74248,
    "out:El kr return": 115724,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 215769,
    "out:% savings (space heating)": 50.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 51.4,
    "out:Etvv": 0,
    "out:Ef": 29,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.35,
    "out:Electricity (inc PV)": 28.9,
    "out:Total Energy Use Pre PV": 102.35,
    "out:Total Energy Use Post PV": 87.9,
    "out:Primary Energy": 85.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41,
    "out:CO2 Operational/m2": -22.75,
    "out:Total CO2/m2": 18.26,
    "out:Total CO2 (tons)": 62.24,
    "out:Klimatpaverkan": -154.76,
    "out:Initial Cost/MSEK": 7.4855,
    "out:Running cost/(Apt SEK y)": 17950,
    "out:Running Cost over RSP/MSEK": 5.198,
    "out:LCP/MSEK": 0.018,
    "out:ROI% old": 14.32,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2159.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 960,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 25.15,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32574.41,
    "out:PV (% sold (El))": 42.75,
    "out:PV (kWh self-consumed)": 43631.38,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -44333,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 313612,
    "out:DH kr savings": 347445,
    "out:El kr savings": -75366,
    "out:El kr sold": 41532,
    "out:El kr saved": 74173,
    "out:El kr return": 115706,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 201484,
    "out:% savings (space heating)": 53.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 47.37,
    "out:Etvv": 0,
    "out:Ef": 28.9,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.75,
    "out:Electricity (inc PV)": 28.64,
    "out:Total Energy Use Pre PV": 101.75,
    "out:Total Energy Use Post PV": 87.64,
    "out:Primary Energy": 85.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.5,
    "out:CO2 Operational/m2": -23.41,
    "out:Total CO2/m2": 18.09,
    "out:Total CO2 (tons)": 61.68,
    "out:Klimatpaverkan": -155.32,
    "out:Initial Cost/MSEK": 7.605125,
    "out:Running cost/(Apt SEK y)": 17861,
    "out:Running Cost over RSP/MSEK": 5.173,
    "out:LCP/MSEK": -0.076,
    "out:ROI% old": 14.14,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 112.5,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2125.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 963,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.55,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32971.13,
    "out:PV (% sold (El))": 43.27,
    "out:PV (kWh self-consumed)": 43234.66,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 381808,
    "out:EL kWh savings": -43421,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315668,
    "out:DH kr savings": 347445,
    "out:El kr savings": -73815,
    "out:El kr sold": 42038,
    "out:El kr saved": 73499,
    "out:El kr return": 115537,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 200992,
    "out:% savings (space heating)": 53.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 49.13,
    "out:Etvv": 0,
    "out:Ef": 28.64,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.67,
    "out:Electricity (inc PV)": 28.54,
    "out:Total Energy Use Pre PV": 97.67,
    "out:Total Energy Use Post PV": 83.54,
    "out:Primary Energy": 82.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.5,
    "out:CO2 Operational/m2": -24.94,
    "out:Total CO2/m2": 16.56,
    "out:Total CO2 (tons)": 56.46,
    "out:Klimatpaverkan": -160.54,
    "out:Initial Cost/MSEK": 7.690375,
    "out:Running cost/(Apt SEK y)": 16880,
    "out:Running Cost over RSP/MSEK": 4.889,
    "out:LCP/MSEK": 0.123,
    "out:ROI% old": 14.51,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2121.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1000,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.47,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33026.01,
    "out:PV (% sold (El))": 43.34,
    "out:PV (kWh self-consumed)": 43179.78,
    "out:PV (ratio sold/self-consumed)": 0.76,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 395444,
    "out:EL kWh savings": -43081,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328725,
    "out:DH kr savings": 359854,
    "out:El kr savings": -73237,
    "out:El kr sold": 42108,
    "out:El kr saved": 73406,
    "out:El kr return": 115514,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 186885,
    "out:% savings (space heating)": 57.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 45.14,
    "out:Etvv": 0,
    "out:Ef": 28.54,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.52,
    "out:Electricity (inc PV)": 40.81,
    "out:Total Energy Use Pre PV": 100.52,
    "out:Total Energy Use Post PV": 98.81,
    "out:Primary Energy": 107.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.3,
    "out:CO2 Operational/m2": 26.49,
    "out:Total CO2/m2": 42.79,
    "out:Total CO2 (tons)": 145.87,
    "out:Klimatpaverkan": -71.13,
    "out:Initial Cost/MSEK": 6.079625,
    "out:Running cost/(Apt SEK y)": 20705,
    "out:Running Cost over RSP/MSEK": 5.972,
    "out:LCP/MSEK": 0.651,
    "out:ROI% old": 15.81,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2976.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 385217,
    "out:EL kWh savings": -84931,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206165,
    "out:DH kr savings": 350547,
    "out:El kr savings": -144382,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196188,
    "out:% savings (space heating)": 54.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 48.39,
    "out:Etvv": 0,
    "out:Ef": 40.81,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.43,
    "out:Electricity (inc PV)": 40.68,
    "out:Total Energy Use Pre PV": 95.43,
    "out:Total Energy Use Post PV": 93.68,
    "out:Primary Energy": 104.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.3,
    "out:CO2 Operational/m2": 24.68,
    "out:Total CO2/m2": 40.98,
    "out:Total CO2 (tons)": 139.69,
    "out:Klimatpaverkan": -77.31,
    "out:Initial Cost/MSEK": 6.164875,
    "out:Running cost/(Apt SEK y)": 19480,
    "out:Running Cost over RSP/MSEK": 5.617,
    "out:LCP/MSEK": 0.92,
    "out:ROI% old": 16.42,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2970.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 903,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -84492,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 222423,
    "out:DH kr savings": 366058,
    "out:El kr savings": -143636,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182142,
    "out:% savings (space heating)": 58.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.4,
    "out:Etvv": 0,
    "out:Ef": 40.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.94,
    "out:Electricity (inc PV)": 40.44,
    "out:Total Energy Use Pre PV": 95.94,
    "out:Total Energy Use Post PV": 94.44,
    "out:Primary Energy": 105.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.79,
    "out:CO2 Operational/m2": 24.98,
    "out:Total CO2/m2": 41.77,
    "out:Total CO2 (tons)": 142.39,
    "out:Klimatpaverkan": -74.61,
    "out:Initial Cost/MSEK": 6.284625,
    "out:Running cost/(Apt SEK y)": 19646,
    "out:Running Cost over RSP/MSEK": 5.665,
    "out:LCP/MSEK": 0.752,
    "out:ROI% old": 16,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2936.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 897,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 398853,
    "out:EL kWh savings": -83663,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220730,
    "out:DH kr savings": 362956,
    "out:El kr savings": -142227,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 184882,
    "out:% savings (space heating)": 57.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 46.61,
    "out:Etvv": 0,
    "out:Ef": 40.44,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.84,
    "out:Electricity (inc PV)": 40.3,
    "out:Total Energy Use Pre PV": 91.84,
    "out:Total Energy Use Post PV": 90.3,
    "out:Primary Energy": 102.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.79,
    "out:CO2 Operational/m2": 23.52,
    "out:Total CO2/m2": 40.32,
    "out:Total CO2 (tons)": 137.44,
    "out:Klimatpaverkan": -79.56,
    "out:Initial Cost/MSEK": 6.369875,
    "out:Running cost/(Apt SEK y)": 18661,
    "out:Running Cost over RSP/MSEK": 5.38,
    "out:LCP/MSEK": 0.952,
    "out:ROI% old": 16.42,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2929,
    "out:Return %": 15,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -83191,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233940,
    "out:DH kr savings": 375365,
    "out:El kr savings": -141425,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170996,
    "out:% savings (space heating)": 60.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.66,
    "out:Etvv": 0,
    "out:Ef": 40.3,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.52,
    "out:Electricity (inc PV)": 31.54,
    "out:Total Energy Use Pre PV": 100.52,
    "out:Total Energy Use Post PV": 89.54,
    "out:Primary Energy": 90.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.65,
    "out:CO2 Operational/m2": 16.82,
    "out:Total CO2/m2": 45.47,
    "out:Total CO2 (tons)": 155.01,
    "out:Klimatpaverkan": -61.99,
    "out:Initial Cost/MSEK": 6.78875,
    "out:Running cost/(Apt SEK y)": 19022,
    "out:Running Cost over RSP/MSEK": 5.498,
    "out:LCP/MSEK": 0.416,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2339.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 920,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5880.53,
    "out:PV (% sold (El))": 15.43,
    "out:PV (kWh self-consumed)": 32222.36,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385217,
    "out:EL kWh savings": -53324,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267394,
    "out:DH kr savings": 350547,
    "out:El kr savings": -90652,
    "out:El kr sold": 7498,
    "out:El kr saved": 54778,
    "out:El kr return": 62276,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196188,
    "out:% savings (space heating)": 54.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 48.39,
    "out:Etvv": 0,
    "out:Ef": 31.54,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.43,
    "out:Electricity (inc PV)": 31.43,
    "out:Total Energy Use Pre PV": 95.43,
    "out:Total Energy Use Post PV": 84.43,
    "out:Primary Energy": 87.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.65,
    "out:CO2 Operational/m2": 14.96,
    "out:Total CO2/m2": 43.62,
    "out:Total CO2 (tons)": 148.68,
    "out:Klimatpaverkan": -68.32,
    "out:Initial Cost/MSEK": 6.874,
    "out:Running cost/(Apt SEK y)": 17798,
    "out:Running Cost over RSP/MSEK": 5.143,
    "out:LCP/MSEK": 0.685,
    "out:ROI% old": 15.71,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2333.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 966,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5911.28,
    "out:PV (% sold (El))": 15.51,
    "out:PV (kWh self-consumed)": 32191.61,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -52933,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283608,
    "out:DH kr savings": 366058,
    "out:El kr savings": -89987,
    "out:El kr sold": 7537,
    "out:El kr saved": 54726,
    "out:El kr return": 62263,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182142,
    "out:% savings (space heating)": 58.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.4,
    "out:Etvv": 0,
    "out:Ef": 31.43,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.94,
    "out:Electricity (inc PV)": 31.21,
    "out:Total Energy Use Pre PV": 95.94,
    "out:Total Energy Use Post PV": 85.21,
    "out:Primary Energy": 88.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.15,
    "out:CO2 Operational/m2": 15.01,
    "out:Total CO2/m2": 44.16,
    "out:Total CO2 (tons)": 150.53,
    "out:Klimatpaverkan": -66.47,
    "out:Initial Cost/MSEK": 6.99375,
    "out:Running cost/(Apt SEK y)": 17965,
    "out:Running Cost over RSP/MSEK": 5.192,
    "out:LCP/MSEK": 0.516,
    "out:ROI% old": 15.34,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2302.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 960,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6084.46,
    "out:PV (% sold (El))": 15.97,
    "out:PV (kWh self-consumed)": 32018.43,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 398853,
    "out:EL kWh savings": -52196,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281981,
    "out:DH kr savings": 362956,
    "out:El kr savings": -88733,
    "out:El kr sold": 7758,
    "out:El kr saved": 54431,
    "out:El kr return": 62189,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 184882,
    "out:% savings (space heating)": 57.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 46.61,
    "out:Etvv": 0,
    "out:Ef": 31.21,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.84,
    "out:Electricity (inc PV)": 31.09,
    "out:Total Energy Use Pre PV": 91.84,
    "out:Total Energy Use Post PV": 81.09,
    "out:Primary Energy": 85.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.15,
    "out:CO2 Operational/m2": 13.5,
    "out:Total CO2/m2": 42.65,
    "out:Total CO2 (tons)": 145.39,
    "out:Klimatpaverkan": -71.61,
    "out:Initial Cost/MSEK": 7.078875,
    "out:Running cost/(Apt SEK y)": 16981,
    "out:Running Cost over RSP/MSEK": 4.907,
    "out:LCP/MSEK": 0.716,
    "out:ROI% old": 15.73,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2296.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 996,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6120.86,
    "out:PV (% sold (El))": 16.06,
    "out:PV (kWh self-consumed)": 31982.03,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -51777,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 295149,
    "out:DH kr savings": 375365,
    "out:El kr savings": -88020,
    "out:El kr sold": 7804,
    "out:El kr saved": 54369,
    "out:El kr return": 62174,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170996,
    "out:% savings (space heating)": 60.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.66,
    "out:Etvv": 0,
    "out:Ef": 31.09,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.52,
    "out:Electricity (inc PV)": 28.52,
    "out:Total Energy Use Pre PV": 100.52,
    "out:Total Energy Use Post PV": 86.52,
    "out:Primary Energy": 85.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41,
    "out:CO2 Operational/m2": -24.03,
    "out:Total CO2/m2": 16.98,
    "out:Total CO2 (tons)": 57.88,
    "out:Klimatpaverkan": -159.12,
    "out:Initial Cost/MSEK": 7.497875,
    "out:Running cost/(Apt SEK y)": 17584,
    "out:Running Cost over RSP/MSEK": 5.093,
    "out:LCP/MSEK": 0.112,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 2112.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33126.13,
    "out:PV (% sold (El))": 43.47,
    "out:PV (kWh self-consumed)": 43079.66,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 385217,
    "out:EL kWh savings": -43006,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 319674,
    "out:DH kr savings": 350547,
    "out:El kr savings": -73110,
    "out:El kr sold": 42236,
    "out:El kr saved": 73235,
    "out:El kr return": 115471,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196188,
    "out:% savings (space heating)": 54.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 48.39,
    "out:Etvv": 0,
    "out:Ef": 28.52,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.43,
    "out:Electricity (inc PV)": 28.41,
    "out:Total Energy Use Pre PV": 95.43,
    "out:Total Energy Use Post PV": 81.41,
    "out:Primary Energy": 82.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41,
    "out:CO2 Operational/m2": -25.92,
    "out:Total CO2/m2": 15.08,
    "out:Total CO2 (tons)": 51.41,
    "out:Klimatpaverkan": -165.59,
    "out:Initial Cost/MSEK": 7.583125,
    "out:Running cost/(Apt SEK y)": 16360,
    "out:Running Cost over RSP/MSEK": 4.738,
    "out:LCP/MSEK": 0.381,
    "out:ROI% old": 15,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2107.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1019,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 24.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33184.82,
    "out:PV (% sold (El))": 43.55,
    "out:PV (kWh self-consumed)": 43020.96,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -42655,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 335856,
    "out:DH kr savings": 366058,
    "out:El kr savings": -72513,
    "out:El kr sold": 42311,
    "out:El kr saved": 73136,
    "out:El kr return": 115446,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182142,
    "out:% savings (space heating)": 58.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 44.4,
    "out:Etvv": 0,
    "out:Ef": 28.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.94,
    "out:Electricity (inc PV)": 28.22,
    "out:Total Energy Use Pre PV": 95.94,
    "out:Total Energy Use Post PV": 82.22,
    "out:Primary Energy": 83.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.5,
    "out:CO2 Operational/m2": -26.11,
    "out:Total CO2/m2": 15.39,
    "out:Total CO2 (tons)": 52.46,
    "out:Klimatpaverkan": -164.54,
    "out:Initial Cost/MSEK": 7.702875,
    "out:Running cost/(Apt SEK y)": 16529,
    "out:Running Cost over RSP/MSEK": 4.788,
    "out:LCP/MSEK": 0.212,
    "out:ROI% old": 14.68,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 2079.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33513.19,
    "out:PV (% sold (El))": 43.98,
    "out:PV (kWh self-consumed)": 42692.6,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 398853,
    "out:EL kWh savings": -41993,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 334298,
    "out:DH kr savings": 362956,
    "out:El kr savings": -71387,
    "out:El kr sold": 42729,
    "out:El kr saved": 72577,
    "out:El kr return": 115307,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 184882,
    "out:% savings (space heating)": 57.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 46.61,
    "out:Etvv": 0,
    "out:Ef": 28.22,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.84,
    "out:Electricity (inc PV)": 28.11,
    "out:Total Energy Use Pre PV": 91.84,
    "out:Total Energy Use Post PV": 78.11,
    "out:Primary Energy": 80.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.5,
    "out:CO2 Operational/m2": -27.66,
    "out:Total CO2/m2": 13.84,
    "out:Total CO2 (tons)": 47.16,
    "out:Klimatpaverkan": -169.84,
    "out:Initial Cost/MSEK": 7.788,
    "out:Running cost/(Apt SEK y)": 15546,
    "out:Running Cost over RSP/MSEK": 4.503,
    "out:LCP/MSEK": 0.411,
    "out:ROI% old": 15.04,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2074.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1049,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.64,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33581.78,
    "out:PV (% sold (El))": 44.07,
    "out:PV (kWh self-consumed)": 42624,
    "out:PV (ratio sold/self-consumed)": 0.79,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412489,
    "out:EL kWh savings": -41616,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 347434,
    "out:DH kr savings": 375365,
    "out:El kr savings": -70748,
    "out:El kr sold": 42817,
    "out:El kr saved": 72461,
    "out:El kr return": 115278,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 170996,
    "out:% savings (space heating)": 60.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 42.66,
    "out:Etvv": 0,
    "out:Ef": 28.11,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.43,
    "out:Electricity (inc PV)": 55.18,
    "out:Total Energy Use Pre PV": 69.43,
    "out:Total Energy Use Post PV": 65.18,
    "out:Primary Energy": 103.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.89,
    "out:CO2 Operational/m2": 11.45,
    "out:Total CO2/m2": 28.34,
    "out:Total CO2 (tons)": 96.59,
    "out:Klimatpaverkan": -120.41,
    "out:Initial Cost/MSEK": 7.83825,
    "out:Running cost/(Apt SEK y)": 11730,
    "out:Running Cost over RSP/MSEK": 3.364,
    "out:LCP/MSEK": 1.5,
    "out:ROI% old": 17.02,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 23,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 4160.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1190,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -133918,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271792,
    "out:DH kr savings": 499453,
    "out:El kr savings": -227660,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 35607,
    "out:% savings (space heating)": 91.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.96,
    "out:Etvv": 0,
    "out:Ef": 55.18,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.8,
    "out:Electricity (inc PV)": 54.33,
    "out:Total Energy Use Pre PV": 66.8,
    "out:Total Energy Use Post PV": 62.33,
    "out:Primary Energy": 100.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.89,
    "out:CO2 Operational/m2": 10.64,
    "out:Total CO2/m2": 27.53,
    "out:Total CO2 (tons)": 93.86,
    "out:Klimatpaverkan": -123.14,
    "out:Initial Cost/MSEK": 7.9235,
    "out:Running cost/(Apt SEK y)": 11147,
    "out:Running Cost over RSP/MSEK": 3.195,
    "out:LCP/MSEK": 1.583,
    "out:ROI% old": 17.14,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4116,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1212,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -130994,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282968,
    "out:DH kr savings": 505657,
    "out:El kr savings": -222689,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 27685,
    "out:% savings (space heating)": 93.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.39,
    "out:Etvv": 0,
    "out:Ef": 54.33,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.99,
    "out:Electricity (inc PV)": 54.25,
    "out:Total Energy Use Pre PV": 66.99,
    "out:Total Energy Use Post PV": 63.25,
    "out:Primary Energy": 101.36,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.39,
    "out:CO2 Operational/m2": 10.9,
    "out:Total CO2/m2": 28.28,
    "out:Total CO2 (tons)": 96.41,
    "out:Klimatpaverkan": -120.59,
    "out:Initial Cost/MSEK": 8.04325,
    "out:Running cost/(Apt SEK y)": 11262,
    "out:Running Cost over RSP/MSEK": 3.229,
    "out:LCP/MSEK": 1.43,
    "out:ROI% old": 16.83,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 196.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 4059.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1208,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 552258,
    "out:EL kWh savings": -130749,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280282,
    "out:DH kr savings": 502555,
    "out:El kr savings": -222273,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 29576,
    "out:% savings (space heating)": 93.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 54.25,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.29,
    "out:Electricity (inc PV)": 53.35,
    "out:Total Energy Use Pre PV": 64.29,
    "out:Total Energy Use Post PV": 60.35,
    "out:Primary Energy": 98.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.39,
    "out:CO2 Operational/m2": 10.08,
    "out:Total CO2/m2": 27.47,
    "out:Total CO2 (tons)": 93.64,
    "out:Klimatpaverkan": -123.36,
    "out:Initial Cost/MSEK": 8.128375,
    "out:Running cost/(Apt SEK y)": 10668,
    "out:Running Cost over RSP/MSEK": 3.058,
    "out:LCP/MSEK": 1.516,
    "out:ROI% old": 16.95,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 4010.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1229,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -127662,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291733,
    "out:DH kr savings": 508759,
    "out:El kr savings": -217026,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 22427,
    "out:% savings (space heating)": 94.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.86,
    "out:Etvv": 0,
    "out:Ef": 53.35,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.43,
    "out:Electricity (inc PV)": 44.72,
    "out:Total Energy Use Pre PV": 69.43,
    "out:Total Energy Use Post PV": 54.72,
    "out:Primary Energy": 84.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.24,
    "out:CO2 Operational/m2": 7.81,
    "out:Total CO2/m2": 37.06,
    "out:Total CO2 (tons)": 126.33,
    "out:Klimatpaverkan": -90.67,
    "out:Initial Cost/MSEK": 8.547375,
    "out:Running cost/(Apt SEK y)": 9999,
    "out:Running Cost over RSP/MSEK": 2.876,
    "out:LCP/MSEK": 1.278,
    "out:ROI% old": 16.42,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3449.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1254,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1649.55,
    "out:PV (% sold (El))": 4.33,
    "out:PV (kWh self-consumed)": 36453.34,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -98236,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 334554,
    "out:DH kr savings": 499453,
    "out:El kr savings": -167002,
    "out:El kr sold": 2103,
    "out:El kr saved": 61971,
    "out:El kr return": 64074,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 35607,
    "out:% savings (space heating)": 91.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.96,
    "out:Etvv": 0,
    "out:Ef": 44.72,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.8,
    "out:Electricity (inc PV)": 43.91,
    "out:Total Energy Use Pre PV": 66.8,
    "out:Total Energy Use Post PV": 51.91,
    "out:Primary Energy": 82.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.24,
    "out:CO2 Operational/m2": 6.86,
    "out:Total CO2/m2": 36.11,
    "out:Total CO2 (tons)": 123.09,
    "out:Klimatpaverkan": -93.91,
    "out:Initial Cost/MSEK": 8.632625,
    "out:Running cost/(Apt SEK y)": 9417,
    "out:Running Cost over RSP/MSEK": 2.708,
    "out:LCP/MSEK": 1.361,
    "out:ROI% old": 16.54,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3406.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1276,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1754.1,
    "out:PV (% sold (El))": 4.6,
    "out:PV (kWh self-consumed)": 36348.79,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -95489,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 345562,
    "out:DH kr savings": 505657,
    "out:El kr savings": -162331,
    "out:El kr sold": 2236,
    "out:El kr saved": 61793,
    "out:El kr return": 64029,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 27685,
    "out:% savings (space heating)": 93.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.39,
    "out:Etvv": 0,
    "out:Ef": 43.91,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.99,
    "out:Electricity (inc PV)": 43.84,
    "out:Total Energy Use Pre PV": 66.99,
    "out:Total Energy Use Post PV": 52.84,
    "out:Primary Energy": 82.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.74,
    "out:CO2 Operational/m2": 6.92,
    "out:Total CO2/m2": 36.66,
    "out:Total CO2 (tons)": 124.97,
    "out:Klimatpaverkan": -92.03,
    "out:Initial Cost/MSEK": 8.75225,
    "out:Running cost/(Apt SEK y)": 9534,
    "out:Running Cost over RSP/MSEK": 2.743,
    "out:LCP/MSEK": 1.207,
    "out:ROI% old": 16.26,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3352.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1892.45,
    "out:PV (% sold (El))": 4.97,
    "out:PV (kWh self-consumed)": 36210.44,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 552258,
    "out:EL kWh savings": -95259,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 343027,
    "out:DH kr savings": 502555,
    "out:El kr savings": -161941,
    "out:El kr sold": 2413,
    "out:El kr saved": 61558,
    "out:El kr return": 63971,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 29576,
    "out:% savings (space heating)": 93.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 43.84,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.29,
    "out:Electricity (inc PV)": 43,
    "out:Total Energy Use Pre PV": 64.29,
    "out:Total Energy Use Post PV": 50,
    "out:Primary Energy": 80.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.74,
    "out:CO2 Operational/m2": 5.93,
    "out:Total CO2/m2": 35.67,
    "out:Total CO2 (tons)": 121.6,
    "out:Klimatpaverkan": -95.4,
    "out:Initial Cost/MSEK": 8.8375,
    "out:Running cost/(Apt SEK y)": 8941,
    "out:Running Cost over RSP/MSEK": 2.571,
    "out:LCP/MSEK": 1.293,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3305.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2017.5,
    "out:PV (% sold (El))": 5.29,
    "out:PV (kWh self-consumed)": 36085.39,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -92370,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 354303,
    "out:DH kr savings": 508759,
    "out:El kr savings": -157028,
    "out:El kr sold": 2572,
    "out:El kr saved": 61345,
    "out:El kr return": 63917,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 22427,
    "out:% savings (space heating)": 94.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.86,
    "out:Etvv": 0,
    "out:Ef": 43,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 59.43,
    "out:Electricity (inc PV)": 40.27,
    "out:Total Energy Use Pre PV": 69.43,
    "out:Total Energy Use Post PV": 50.27,
    "out:Primary Energy": 76.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.6,
    "out:CO2 Operational/m2": -24.25,
    "out:Total CO2/m2": 17.34,
    "out:Total CO2 (tons)": 59.12,
    "out:Klimatpaverkan": -157.88,
    "out:Initial Cost/MSEK": 9.2565,
    "out:Running cost/(Apt SEK y)": 8493,
    "out:Running Cost over RSP/MSEK": 2.452,
    "out:LCP/MSEK": 0.994,
    "out:ROI% old": 15.82,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3098.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1310,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 41.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23015.81,
    "out:PV (% sold (El))": 30.2,
    "out:PV (kWh self-consumed)": 53189.98,
    "out:PV (ratio sold/self-consumed)": 0.43,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 548849,
    "out:EL kWh savings": -83068,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 387581,
    "out:DH kr savings": 499453,
    "out:El kr savings": -141216,
    "out:El kr sold": 29345,
    "out:El kr saved": 90423,
    "out:El kr return": 119768,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 35607,
    "out:% savings (space heating)": 91.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.96,
    "out:Etvv": 0,
    "out:Ef": 40.27,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 58.8,
    "out:Electricity (inc PV)": 39.55,
    "out:Total Energy Use Pre PV": 66.8,
    "out:Total Energy Use Post PV": 47.55,
    "out:Primary Energy": 74.26,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.6,
    "out:CO2 Operational/m2": -25.55,
    "out:Total CO2/m2": 16.04,
    "out:Total CO2 (tons)": 54.69,
    "out:Klimatpaverkan": -162.31,
    "out:Initial Cost/MSEK": 9.34175,
    "out:Running cost/(Apt SEK y)": 7914,
    "out:Running Cost over RSP/MSEK": 2.285,
    "out:LCP/MSEK": 1.076,
    "out:ROI% old": 15.93,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3060.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 40.6,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23357.41,
    "out:PV (% sold (El))": 30.65,
    "out:PV (kWh self-consumed)": 52848.38,
    "out:PV (ratio sold/self-consumed)": 0.44,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -80629,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 398368,
    "out:DH kr savings": 505657,
    "out:El kr savings": -137069,
    "out:El kr sold": 29781,
    "out:El kr saved": 89842,
    "out:El kr return": 119623,
    "out:% solar/total": 98,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 27685,
    "out:% savings (space heating)": 93.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.39,
    "out:Etvv": 0,
    "out:Ef": 39.55,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.99,
    "out:Electricity (inc PV)": 39.49,
    "out:Total Energy Use Pre PV": 66.99,
    "out:Total Energy Use Post PV": 48.49,
    "out:Primary Energy": 74.78,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.09,
    "out:CO2 Operational/m2": -25.95,
    "out:Total CO2/m2": 16.15,
    "out:Total CO2 (tons)": 55.04,
    "out:Klimatpaverkan": -161.96,
    "out:Initial Cost/MSEK": 9.461375,
    "out:Running cost/(Apt SEK y)": 8034,
    "out:Running Cost over RSP/MSEK": 2.32,
    "out:LCP/MSEK": 0.921,
    "out:ROI% old": 15.68,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 289.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3012.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1327,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.79,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 23798.02,
    "out:PV (% sold (El))": 31.23,
    "out:PV (kWh self-consumed)": 52407.77,
    "out:PV (ratio sold/self-consumed)": 0.45,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 552258,
    "out:EL kWh savings": -80425,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 396175,
    "out:DH kr savings": 502555,
    "out:El kr savings": -136722,
    "out:El kr sold": 30342,
    "out:El kr saved": 89093,
    "out:El kr return": 119436,
    "out:% solar/total": 97,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 29576,
    "out:% savings (space heating)": 93.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.28,
    "out:Etvv": 0,
    "out:Ef": 39.49,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.29,
    "out:Electricity (inc PV)": 38.74,
    "out:Total Energy Use Pre PV": 64.29,
    "out:Total Energy Use Post PV": 45.74,
    "out:Primary Energy": 72.43,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.09,
    "out:CO2 Operational/m2": -27.32,
    "out:Total CO2/m2": 14.77,
    "out:Total CO2 (tons)": 50.35,
    "out:Klimatpaverkan": -166.65,
    "out:Initial Cost/MSEK": 9.546625,
    "out:Running cost/(Apt SEK y)": 7445,
    "out:Running Cost over RSP/MSEK": 2.15,
    "out:LCP/MSEK": 1.006,
    "out:ROI% old": 15.79,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2971,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1349,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 39.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24183.04,
    "out:PV (% sold (El))": 31.73,
    "out:PV (kWh self-consumed)": 52022.74,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -77856,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 407237,
    "out:DH kr savings": 508759,
    "out:El kr savings": -132356,
    "out:El kr sold": 30833,
    "out:El kr saved": 88439,
    "out:El kr return": 119272,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 22427,
    "out:% savings (space heating)": 94.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.86,
    "out:Etvv": 0,
    "out:Ef": 38.74,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.5,
    "out:Electricity (inc PV)": 53.94,
    "out:Total Energy Use Pre PV": 65.5,
    "out:Total Energy Use Post PV": 61.94,
    "out:Primary Energy": 100.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.89,
    "out:CO2 Operational/m2": 10.47,
    "out:Total CO2/m2": 27.36,
    "out:Total CO2 (tons)": 93.27,
    "out:Klimatpaverkan": -123.73,
    "out:Initial Cost/MSEK": 7.935875,
    "out:Running cost/(Apt SEK y)": 10943,
    "out:Running Cost over RSP/MSEK": 3.137,
    "out:LCP/MSEK": 1.629,
    "out:ROI% old": 17.22,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 201.6,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 4024.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1219,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -129673,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285213,
    "out:DH kr savings": 505657,
    "out:El kr savings": -220444,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 27721,
    "out:% savings (space heating)": 93.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.07,
    "out:Etvv": 0,
    "out:Ef": 53.94,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 53.02,
    "out:Total Energy Use Pre PV": 62.77,
    "out:Total Energy Use Post PV": 59.02,
    "out:Primary Energy": 98.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.89,
    "out:CO2 Operational/m2": 9.65,
    "out:Total CO2/m2": 26.54,
    "out:Total CO2 (tons)": 90.49,
    "out:Klimatpaverkan": -126.51,
    "out:Initial Cost/MSEK": 8.021125,
    "out:Running cost/(Apt SEK y)": 10345,
    "out:Running Cost over RSP/MSEK": 2.964,
    "out:LCP/MSEK": 1.717,
    "out:ROI% old": 17.34,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 216.9,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3974,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1241,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -126538,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 296747,
    "out:DH kr savings": 511861,
    "out:El kr savings": -215115,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 20842,
    "out:% savings (space heating)": 95.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.7,
    "out:Etvv": 0,
    "out:Ef": 53.02,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.28,
    "out:Electricity (inc PV)": 53.17,
    "out:Total Energy Use Pre PV": 63.28,
    "out:Total Energy Use Post PV": 60.17,
    "out:Primary Energy": 98.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.39,
    "out:CO2 Operational/m2": 9.95,
    "out:Total CO2/m2": 27.34,
    "out:Total CO2 (tons)": 93.19,
    "out:Klimatpaverkan": -123.81,
    "out:Initial Cost/MSEK": 8.140875,
    "out:Running cost/(Apt SEK y)": 10511,
    "out:Running Cost over RSP/MSEK": 3.012,
    "out:LCP/MSEK": 1.549,
    "out:ROI% old": 17,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 211.7,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3939.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -127058,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292760,
    "out:DH kr savings": 508759,
    "out:El kr savings": -215999,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 23573,
    "out:% savings (space heating)": 94.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.61,
    "out:Etvv": 0,
    "out:Ef": 53.17,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.49,
    "out:Electricity (inc PV)": 52.22,
    "out:Total Energy Use Pre PV": 60.49,
    "out:Total Energy Use Post PV": 57.22,
    "out:Primary Energy": 96.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.39,
    "out:CO2 Operational/m2": 9.13,
    "out:Total CO2/m2": 26.51,
    "out:Total CO2 (tons)": 90.38,
    "out:Klimatpaverkan": -126.62,
    "out:Initial Cost/MSEK": 8.226125,
    "out:Running cost/(Apt SEK y)": 9902,
    "out:Running Cost over RSP/MSEK": 2.837,
    "out:LCP/MSEK": 1.639,
    "out:ROI% old": 17.13,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 228.1,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3884.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1258,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -123815,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304478,
    "out:DH kr savings": 514964,
    "out:El kr savings": -210486,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17460,
    "out:% savings (space heating)": 95.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 52.22,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.5,
    "out:Electricity (inc PV)": 43.55,
    "out:Total Energy Use Pre PV": 65.5,
    "out:Total Energy Use Post PV": 51.55,
    "out:Primary Energy": 81.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.24,
    "out:CO2 Operational/m2": 6.37,
    "out:Total CO2/m2": 35.61,
    "out:Total CO2 (tons)": 121.41,
    "out:Klimatpaverkan": -95.59,
    "out:Initial Cost/MSEK": 8.645,
    "out:Running cost/(Apt SEK y)": 9215,
    "out:Running Cost over RSP/MSEK": 2.651,
    "out:LCP/MSEK": 1.407,
    "out:ROI% old": 16.61,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3319.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1283,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1980.12,
    "out:PV (% sold (El))": 5.2,
    "out:PV (kWh self-consumed)": 36122.77,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -94251,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 347954,
    "out:DH kr savings": 505657,
    "out:El kr savings": -160228,
    "out:El kr sold": 2525,
    "out:El kr saved": 61409,
    "out:El kr return": 63933,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 27721,
    "out:% savings (space heating)": 93.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.07,
    "out:Etvv": 0,
    "out:Ef": 43.55,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 42.69,
    "out:Total Energy Use Pre PV": 62.77,
    "out:Total Energy Use Post PV": 48.69,
    "out:Primary Energy": 79.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.24,
    "out:CO2 Operational/m2": 5.37,
    "out:Total CO2/m2": 34.61,
    "out:Total CO2 (tons)": 117.99,
    "out:Klimatpaverkan": -99.01,
    "out:Initial Cost/MSEK": 8.73025,
    "out:Running cost/(Apt SEK y)": 8619,
    "out:Running Cost over RSP/MSEK": 2.478,
    "out:LCP/MSEK": 1.494,
    "out:ROI% old": 16.73,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 281.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 3270.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1305,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2112.86,
    "out:PV (% sold (El))": 5.55,
    "out:PV (kWh self-consumed)": 35990.03,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -91319,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 359313,
    "out:DH kr savings": 511861,
    "out:El kr savings": -155242,
    "out:El kr sold": 2694,
    "out:El kr saved": 61183,
    "out:El kr return": 63877,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 20842,
    "out:% savings (space heating)": 95.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.7,
    "out:Etvv": 0,
    "out:Ef": 42.69,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.28,
    "out:Electricity (inc PV)": 42.83,
    "out:Total Energy Use Pre PV": 63.28,
    "out:Total Energy Use Post PV": 49.83,
    "out:Primary Energy": 80.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.74,
    "out:CO2 Operational/m2": 5.53,
    "out:Total CO2/m2": 35.27,
    "out:Total CO2 (tons)": 120.25,
    "out:Klimatpaverkan": -96.75,
    "out:Initial Cost/MSEK": 8.85,
    "out:Running cost/(Apt SEK y)": 8785,
    "out:Running Cost over RSP/MSEK": 2.527,
    "out:LCP/MSEK": 1.325,
    "out:ROI% old": 16.43,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 274,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3238.1,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1299,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2205.07,
    "out:PV (% sold (El))": 5.79,
    "out:PV (kWh self-consumed)": 35897.83,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -91805,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355502,
    "out:DH kr savings": 508759,
    "out:El kr savings": -156069,
    "out:El kr sold": 2811,
    "out:El kr saved": 61026,
    "out:El kr return": 63838,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 23573,
    "out:% savings (space heating)": 94.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.61,
    "out:Etvv": 0,
    "out:Ef": 42.83,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.49,
    "out:Electricity (inc PV)": 41.94,
    "out:Total Energy Use Pre PV": 60.49,
    "out:Total Energy Use Post PV": 46.94,
    "out:Primary Energy": 77.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.74,
    "out:CO2 Operational/m2": 4.49,
    "out:Total CO2/m2": 34.23,
    "out:Total CO2 (tons)": 116.69,
    "out:Klimatpaverkan": -100.31,
    "out:Initial Cost/MSEK": 8.935125,
    "out:Running cost/(Apt SEK y)": 8179,
    "out:Running Cost over RSP/MSEK": 2.352,
    "out:LCP/MSEK": 1.416,
    "out:ROI% old": 16.55,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3185.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2360.04,
    "out:PV (% sold (El))": 6.19,
    "out:PV (kWh self-consumed)": 35742.85,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -88780,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 367047,
    "out:DH kr savings": 514964,
    "out:El kr savings": -150926,
    "out:El kr sold": 3009,
    "out:El kr saved": 60763,
    "out:El kr return": 63772,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17460,
    "out:% savings (space heating)": 95.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 41.94,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.5,
    "out:Electricity (inc PV)": 39.23,
    "out:Total Energy Use Pre PV": 65.5,
    "out:Total Energy Use Post PV": 47.23,
    "out:Primary Energy": 74.16,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.6,
    "out:CO2 Operational/m2": -26.77,
    "out:Total CO2/m2": 14.83,
    "out:Total CO2 (tons)": 50.55,
    "out:Klimatpaverkan": -166.45,
    "out:Initial Cost/MSEK": 9.354125,
    "out:Running cost/(Apt SEK y)": 7718,
    "out:Running Cost over RSP/MSEK": 2.229,
    "out:LCP/MSEK": 1.12,
    "out:ROI% old": 16,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2983.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 39.3,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24069.29,
    "out:PV (% sold (El))": 31.58,
    "out:PV (kWh self-consumed)": 52136.49,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 555667,
    "out:EL kWh savings": -79529,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 401147,
    "out:DH kr savings": 505657,
    "out:El kr savings": -135199,
    "out:El kr sold": 30688,
    "out:El kr saved": 88632,
    "out:El kr return": 119320,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 27721,
    "out:% savings (space heating)": 93.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 5.07,
    "out:Etvv": 0,
    "out:Ef": 39.23,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 38.46,
    "out:Total Energy Use Pre PV": 62.77,
    "out:Total Energy Use Post PV": 44.46,
    "out:Primary Energy": 71.83,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 41.6,
    "out:CO2 Operational/m2": -28.17,
    "out:Total CO2/m2": 13.43,
    "out:Total CO2 (tons)": 45.77,
    "out:Klimatpaverkan": -171.23,
    "out:Initial Cost/MSEK": 9.439375,
    "out:Running cost/(Apt SEK y)": 7125,
    "out:Running Cost over RSP/MSEK": 2.057,
    "out:LCP/MSEK": 1.206,
    "out:ROI% old": 16.11,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 6,
    "out:El bought/kWh/m2": 2940.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24468.99,
    "out:PV (% sold (El))": 32.11,
    "out:PV (kWh self-consumed)": 51736.79,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 562485,
    "out:EL kWh savings": -76922,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 412292,
    "out:DH kr savings": 511861,
    "out:El kr savings": -130768,
    "out:El kr sold": 31198,
    "out:El kr saved": 87953,
    "out:El kr return": 119151,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 20842,
    "out:% savings (space heating)": 95.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.7,
    "out:Etvv": 0,
    "out:Ef": 38.46,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.28,
    "out:Electricity (inc PV)": 38.59,
    "out:Total Energy Use Pre PV": 63.28,
    "out:Total Energy Use Post PV": 45.59,
    "out:Primary Energy": 72.69,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.09,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": 13.82,
    "out:Total CO2 (tons)": 47.11,
    "out:Klimatpaverkan": -169.89,
    "out:Initial Cost/MSEK": 9.559125,
    "out:Running cost/(Apt SEK y)": 7294,
    "out:Running Cost over RSP/MSEK": 2.106,
    "out:LCP/MSEK": 1.037,
    "out:ROI% old": 15.84,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2911.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1354,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 38.08,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24740.1,
    "out:PV (% sold (El))": 32.46,
    "out:PV (kWh self-consumed)": 51465.68,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 559076,
    "out:EL kWh savings": -77354,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 408800,
    "out:DH kr savings": 508759,
    "out:El kr savings": -131502,
    "out:El kr sold": 31544,
    "out:El kr saved": 87492,
    "out:El kr return": 119035,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 23573,
    "out:% savings (space heating)": 94.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 4.61,
    "out:Etvv": 0,
    "out:Ef": 38.59,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.49,
    "out:Electricity (inc PV)": 37.8,
    "out:Total Energy Use Pre PV": 60.49,
    "out:Total Energy Use Post PV": 42.8,
    "out:Primary Energy": 70.38,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 42.09,
    "out:CO2 Operational/m2": -29.75,
    "out:Total CO2/m2": 12.34,
    "out:Total CO2 (tons)": 42.08,
    "out:Klimatpaverkan": -174.92,
    "out:Initial Cost/MSEK": 9.64425,
    "out:Running cost/(Apt SEK y)": 6690,
    "out:Running Cost over RSP/MSEK": 1.932,
    "out:LCP/MSEK": 1.126,
    "out:ROI% old": 15.95,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2864.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1377,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 37.29,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25183.61,
    "out:PV (% sold (El))": 33.05,
    "out:PV (kWh self-consumed)": 51022.17,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -74663,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 420146,
    "out:DH kr savings": 514964,
    "out:El kr savings": -126927,
    "out:El kr sold": 32109,
    "out:El kr saved": 86738,
    "out:El kr return": 118847,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 17460,
    "out:% savings (space heating)": 95.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 3.33,
    "out:Etvv": 0,
    "out:Ef": 37.8,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 150.9,
    "out:Primary Energy": 110.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.97,
    "out:CO2 Operational/m2": 50.7,
    "out:Total CO2/m2": 65.67,
    "out:Total CO2 (tons)": 223.87,
    "out:Klimatpaverkan": 6.87,
    "out:Initial Cost/MSEK": 3.679625,
    "out:Running cost/(Apt SEK y)": 35181,
    "out:Running Cost over RSP/MSEK": 10.178,
    "out:LCP/MSEK": -1.155,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 23.8,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111679,
    "out:DH kr savings": 111679,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 146.9,
    "out:Primary Energy": 107.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.97,
    "out:CO2 Operational/m2": 49.26,
    "out:Total CO2/m2": 64.23,
    "out:Total CO2 (tons)": 218.96,
    "out:Klimatpaverkan": 1.96,
    "out:Initial Cost/MSEK": 3.764875,
    "out:Running cost/(Apt SEK y)": 34212,
    "out:Running Cost over RSP/MSEK": 9.898,
    "out:LCP/MSEK": -0.96,
    "out:ROI% old": 10.64,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 358,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 136360,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124088,
    "out:DH kr savings": 124088,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 144.9,
    "out:Primary Energy": 107.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.47,
    "out:CO2 Operational/m2": 48.54,
    "out:Total CO2/m2": 64.01,
    "out:Total CO2 (tons)": 218.2,
    "out:Klimatpaverkan": 1.2,
    "out:Initial Cost/MSEK": 3.8845,
    "out:Running cost/(Apt SEK y)": 33728,
    "out:Running Cost over RSP/MSEK": 9.757,
    "out:LCP/MSEK": -0.94,
    "out:ROI% old": 10.83,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130292,
    "out:DH kr savings": 130292,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 140.9,
    "out:Primary Energy": 104.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.47,
    "out:CO2 Operational/m2": 47.1,
    "out:Total CO2/m2": 62.57,
    "out:Total CO2 (tons)": 213.29,
    "out:Klimatpaverkan": -3.71,
    "out:Initial Cost/MSEK": 3.96975,
    "out:Running cost/(Apt SEK y)": 32759,
    "out:Running Cost over RSP/MSEK": 9.477,
    "out:LCP/MSEK": -0.744,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 10,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142701,
    "out:DH kr savings": 142701,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 145.73,
    "out:Primary Energy": 100.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 19.7,
    "out:Total CO2/m2": 47.03,
    "out:Total CO2 (tons)": 160.32,
    "out:Klimatpaverkan": -56.68,
    "out:Initial Cost/MSEK": 4.38875,
    "out:Running cost/(Apt SEK y)": 33665,
    "out:Running Cost over RSP/MSEK": 9.751,
    "out:LCP/MSEK": -1.438,
    "out:ROI% old": 9.61,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 379,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167753,
    "out:DH kr savings": 111679,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 141.73,
    "out:Primary Energy": 98.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 18.26,
    "out:Total CO2/m2": 45.59,
    "out:Total CO2 (tons)": 155.41,
    "out:Klimatpaverkan": -61.59,
    "out:Initial Cost/MSEK": 4.473875,
    "out:Running cost/(Apt SEK y)": 32696,
    "out:Running Cost over RSP/MSEK": 9.471,
    "out:LCP/MSEK": -1.242,
    "out:ROI% old": 10.32,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 136360,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180162,
    "out:DH kr savings": 124088,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 139.73,
    "out:Primary Energy": 98.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.82,
    "out:CO2 Operational/m2": 17.54,
    "out:Total CO2/m2": 45.36,
    "out:Total CO2 (tons)": 154.65,
    "out:Klimatpaverkan": -62.35,
    "out:Initial Cost/MSEK": 4.593625,
    "out:Running cost/(Apt SEK y)": 32212,
    "out:Running Cost over RSP/MSEK": 9.331,
    "out:LCP/MSEK": -1.222,
    "out:ROI% old": 10.49,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 432,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186366,
    "out:DH kr savings": 130292,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 135.73,
    "out:Primary Energy": 95.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.82,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 43.92,
    "out:Total CO2 (tons)": 149.74,
    "out:Klimatpaverkan": -67.26,
    "out:Initial Cost/MSEK": 4.678875,
    "out:Running cost/(Apt SEK y)": 31244,
    "out:Running Cost over RSP/MSEK": 9.05,
    "out:LCP/MSEK": -1.027,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198775,
    "out:DH kr savings": 142701,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 150.9,
    "out:Total Energy Use Post PV": 144.88,
    "out:Primary Energy": 99.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.68,
    "out:CO2 Operational/m2": -32.98,
    "out:Total CO2/m2": 6.7,
    "out:Total CO2 (tons)": 22.84,
    "out:Klimatpaverkan": -194.16,
    "out:Initial Cost/MSEK": 5.097875,
    "out:Running cost/(Apt SEK y)": 32319,
    "out:Running Cost over RSP/MSEK": 9.373,
    "out:LCP/MSEK": -1.768,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217564,
    "out:DH kr savings": 111679,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 146.9,
    "out:Total Energy Use Post PV": 140.88,
    "out:Primary Energy": 96.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.68,
    "out:CO2 Operational/m2": -34.42,
    "out:Total CO2/m2": 5.26,
    "out:Total CO2 (tons)": 17.93,
    "out:Klimatpaverkan": -199.07,
    "out:Initial Cost/MSEK": 5.183125,
    "out:Running cost/(Apt SEK y)": 31350,
    "out:Running Cost over RSP/MSEK": 9.092,
    "out:LCP/MSEK": -1.573,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 131,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 464,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 136360,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229973,
    "out:DH kr savings": 124088,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 144.9,
    "out:Total Energy Use Post PV": 138.88,
    "out:Primary Energy": 96.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.18,
    "out:CO2 Operational/m2": -35.14,
    "out:Total CO2/m2": 5.04,
    "out:Total CO2 (tons)": 17.17,
    "out:Klimatpaverkan": -199.83,
    "out:Initial Cost/MSEK": 5.30275,
    "out:Running cost/(Apt SEK y)": 30866,
    "out:Running Cost over RSP/MSEK": 8.952,
    "out:LCP/MSEK": -1.552,
    "out:ROI% old": 10.1,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 482,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236177,
    "out:DH kr savings": 130292,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 134.88,
    "out:Primary Energy": 94.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.18,
    "out:CO2 Operational/m2": -36.58,
    "out:Total CO2/m2": 3.6,
    "out:Total CO2 (tons)": 12.26,
    "out:Klimatpaverkan": -204.74,
    "out:Initial Cost/MSEK": 5.388,
    "out:Running cost/(Apt SEK y)": 29897,
    "out:Running Cost over RSP/MSEK": 8.671,
    "out:LCP/MSEK": -1.357,
    "out:ROI% old": 10.69,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248586,
    "out:DH kr savings": 142701,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 141.9,
    "out:Primary Energy": 106.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.97,
    "out:CO2 Operational/m2": 47.46,
    "out:Total CO2/m2": 62.43,
    "out:Total CO2 (tons)": 212.82,
    "out:Klimatpaverkan": -4.18,
    "out:Initial Cost/MSEK": 3.77725,
    "out:Running cost/(Apt SEK y)": 33001,
    "out:Running Cost over RSP/MSEK": 9.547,
    "out:LCP/MSEK": -0.622,
    "out:ROI% old": 11.93,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153405,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139599,
    "out:DH kr savings": 139599,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 104.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.97,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 61.35,
    "out:Total CO2 (tons)": 209.14,
    "out:Klimatpaverkan": -7.86,
    "out:Initial Cost/MSEK": 3.8625,
    "out:Running cost/(Apt SEK y)": 32275,
    "out:Running Cost over RSP/MSEK": 9.337,
    "out:LCP/MSEK": -0.497,
    "out:ROI% old": 12.45,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148905,
    "out:DH kr savings": 148905,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 136.9,
    "out:Primary Energy": 104.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.47,
    "out:CO2 Operational/m2": 45.66,
    "out:Total CO2/m2": 61.13,
    "out:Total CO2 (tons)": 208.38,
    "out:Klimatpaverkan": -8.62,
    "out:Initial Cost/MSEK": 3.982125,
    "out:Running cost/(Apt SEK y)": 31791,
    "out:Running Cost over RSP/MSEK": 9.196,
    "out:LCP/MSEK": -0.476,
    "out:ROI% old": 12.58,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 11,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 155110,
    "out:DH kr savings": 155110,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 132.9,
    "out:Total Energy Use Post PV": 132.9,
    "out:Primary Energy": 101.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.47,
    "out:CO2 Operational/m2": 44.22,
    "out:Total CO2/m2": 59.69,
    "out:Total CO2 (tons)": 203.47,
    "out:Klimatpaverkan": -13.53,
    "out:Initial Cost/MSEK": 4.067375,
    "out:Running cost/(Apt SEK y)": 30822,
    "out:Running Cost over RSP/MSEK": 8.916,
    "out:LCP/MSEK": -0.281,
    "out:ROI% old": 13.3,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 12,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 184086,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167518,
    "out:DH kr savings": 167518,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 136.73,
    "out:Primary Energy": 97.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 16.46,
    "out:Total CO2/m2": 43.79,
    "out:Total CO2 (tons)": 149.27,
    "out:Klimatpaverkan": -67.73,
    "out:Initial Cost/MSEK": 4.486375,
    "out:Running cost/(Apt SEK y)": 31486,
    "out:Running Cost over RSP/MSEK": 9.12,
    "out:LCP/MSEK": -0.904,
    "out:ROI% old": 11.41,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 153405,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195673,
    "out:DH kr savings": 139599,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 94.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 42.71,
    "out:Total CO2 (tons)": 145.59,
    "out:Klimatpaverkan": -71.41,
    "out:Initial Cost/MSEK": 4.571625,
    "out:Running cost/(Apt SEK y)": 30759,
    "out:Running Cost over RSP/MSEK": 8.91,
    "out:LCP/MSEK": -0.779,
    "out:ROI% old": 11.85,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204979,
    "out:DH kr savings": 148905,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 131.73,
    "out:Primary Energy": 95.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.82,
    "out:CO2 Operational/m2": 14.66,
    "out:Total CO2/m2": 42.48,
    "out:Total CO2 (tons)": 144.83,
    "out:Klimatpaverkan": -72.17,
    "out:Initial Cost/MSEK": 4.69125,
    "out:Running cost/(Apt SEK y)": 30275,
    "out:Running Cost over RSP/MSEK": 8.77,
    "out:LCP/MSEK": -0.759,
    "out:ROI% old": 11.98,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211184,
    "out:DH kr savings": 155110,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 132.9,
    "out:Total Energy Use Post PV": 127.73,
    "out:Primary Energy": 92.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.82,
    "out:CO2 Operational/m2": 13.22,
    "out:Total CO2/m2": 41.04,
    "out:Total CO2 (tons)": 139.92,
    "out:Klimatpaverkan": -77.08,
    "out:Initial Cost/MSEK": 4.7765,
    "out:Running cost/(Apt SEK y)": 29306,
    "out:Running Cost over RSP/MSEK": 8.489,
    "out:LCP/MSEK": -0.563,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 184086,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223592,
    "out:DH kr savings": 167518,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 141.9,
    "out:Total Energy Use Post PV": 135.88,
    "out:Primary Energy": 95.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.68,
    "out:CO2 Operational/m2": -36.22,
    "out:Total CO2/m2": 3.46,
    "out:Total CO2 (tons)": 11.8,
    "out:Klimatpaverkan": -205.2,
    "out:Initial Cost/MSEK": 5.1955,
    "out:Running cost/(Apt SEK y)": 30140,
    "out:Running Cost over RSP/MSEK": 8.741,
    "out:LCP/MSEK": -1.235,
    "out:ROI% old": 10.89,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 509,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 153405,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245484,
    "out:DH kr savings": 139599,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 93.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.68,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 2.38,
    "out:Total CO2 (tons)": 8.12,
    "out:Klimatpaverkan": -208.88,
    "out:Initial Cost/MSEK": 5.28075,
    "out:Running cost/(Apt SEK y)": 29413,
    "out:Running Cost over RSP/MSEK": 8.531,
    "out:LCP/MSEK": -1.11,
    "out:ROI% old": 11.28,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254790,
    "out:DH kr savings": 148905,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.65,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 130.88,
    "out:Primary Energy": 93.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.18,
    "out:CO2 Operational/m2": -38.02,
    "out:Total CO2/m2": 2.16,
    "out:Total CO2 (tons)": 7.36,
    "out:Klimatpaverkan": -209.64,
    "out:Initial Cost/MSEK": 5.400375,
    "out:Running cost/(Apt SEK y)": 28929,
    "out:Running Cost over RSP/MSEK": 8.391,
    "out:LCP/MSEK": -1.089,
    "out:ROI% old": 11.4,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260994,
    "out:DH kr savings": 155110,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 312432,
    "out:% savings (space heating)": 28.14,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.65,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000100120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.76,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 132.9,
    "out:Total Energy Use Post PV": 126.88,
    "out:Primary Energy": 91.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.18,
    "out:CO2 Operational/m2": -39.46,
    "out:Total CO2/m2": 0.72,
    "out:Total CO2 (tons)": 2.45,
    "out:Klimatpaverkan": -214.55,
    "out:Initial Cost/MSEK": 5.485625,
    "out:Running cost/(Apt SEK y)": 27960,
    "out:Running Cost over RSP/MSEK": 8.11,
    "out:LCP/MSEK": -0.894,
    "out:ROI% old": 11.96,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 184086,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273403,
    "out:DH kr savings": 167518,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 299160,
    "out:% savings (space heating)": 31.19,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.76,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 40.97,
    "out:Total Energy Use Pre PV": 96.16,
    "out:Total Energy Use Post PV": 93.97,
    "out:Primary Energy": 102.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.48,
    "out:CO2 Operational/m2": 24.78,
    "out:Total CO2/m2": 43.25,
    "out:Total CO2 (tons)": 147.45,
    "out:Klimatpaverkan": -69.55,
    "out:Initial Cost/MSEK": 6.525625,
    "out:Running cost/(Apt SEK y)": 19594,
    "out:Running Cost over RSP/MSEK": 5.65,
    "out:LCP/MSEK": 0.527,
    "out:ROI% old": 15.44,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 3021.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 899,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -85475,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220750,
    "out:DH kr savings": 366058,
    "out:El kr savings": -145308,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 179940,
    "out:% savings (space heating)": 58.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.49,
    "out:Etvv": 0,
    "out:Ef": 40.97,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.08,
    "out:Electricity (inc PV)": 40.85,
    "out:Total Energy Use Pre PV": 92.08,
    "out:Total Energy Use Post PV": 89.85,
    "out:Primary Energy": 100.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.48,
    "out:CO2 Operational/m2": 23.33,
    "out:Total CO2/m2": 41.8,
    "out:Total CO2 (tons)": 142.51,
    "out:Klimatpaverkan": -74.49,
    "out:Initial Cost/MSEK": 6.610875,
    "out:Running cost/(Apt SEK y)": 18614,
    "out:Running Cost over RSP/MSEK": 5.366,
    "out:LCP/MSEK": 0.725,
    "out:ROI% old": 15.85,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 3015.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 936,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -85064,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233858,
    "out:DH kr savings": 378467,
    "out:El kr savings": -144609,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 167512,
    "out:% savings (space heating)": 61.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38,
    "out:Etvv": 0,
    "out:Ef": 40.85,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.43,
    "out:Electricity (inc PV)": 40.49,
    "out:Total Energy Use Pre PV": 91.43,
    "out:Total Energy Use Post PV": 89.49,
    "out:Primary Energy": 100.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.97,
    "out:CO2 Operational/m2": 23.24,
    "out:Total CO2/m2": 42.21,
    "out:Total CO2 (tons)": 143.91,
    "out:Klimatpaverkan": -73.09,
    "out:Initial Cost/MSEK": 6.7305,
    "out:Running cost/(Apt SEK y)": 18511,
    "out:Running Cost over RSP/MSEK": 5.337,
    "out:LCP/MSEK": 0.635,
    "out:ROI% old": 15.63,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 110.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2969.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 939,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -83827,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235962,
    "out:DH kr savings": 378467,
    "out:El kr savings": -142505,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 165930,
    "out:% savings (space heating)": 61.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 39.34,
    "out:Etvv": 0,
    "out:Ef": 40.49,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 40.36,
    "out:Total Energy Use Pre PV": 87.34,
    "out:Total Energy Use Post PV": 85.36,
    "out:Primary Energy": 97.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.97,
    "out:CO2 Operational/m2": 21.79,
    "out:Total CO2/m2": 40.76,
    "out:Total CO2 (tons)": 138.96,
    "out:Klimatpaverkan": -78.04,
    "out:Initial Cost/MSEK": 6.81575,
    "out:Running cost/(Apt SEK y)": 17528,
    "out:Running Cost over RSP/MSEK": 5.052,
    "out:LCP/MSEK": 0.834,
    "out:ROI% old": 16.03,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2963.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 976,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 429534,
    "out:EL kWh savings": -83384,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249124,
    "out:DH kr savings": 390876,
    "out:El kr savings": -141752,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 153670,
    "out:% savings (space heating)": 64.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.88,
    "out:Etvv": 0,
    "out:Ef": 40.36,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 31.68,
    "out:Total Energy Use Pre PV": 96.16,
    "out:Total Energy Use Post PV": 84.68,
    "out:Primary Energy": 86.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.83,
    "out:CO2 Operational/m2": 15.43,
    "out:Total CO2/m2": 46.26,
    "out:Total CO2 (tons)": 157.69,
    "out:Klimatpaverkan": -59.31,
    "out:Initial Cost/MSEK": 7.23475,
    "out:Running cost/(Apt SEK y)": 17908,
    "out:Running Cost over RSP/MSEK": 5.175,
    "out:LCP/MSEK": 0.292,
    "out:ROI% old": 14.86,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2380,
    "out:Return %": 13,
    "out:Return/kSEK/y": 962,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5659.35,
    "out:PV (% sold (El))": 14.85,
    "out:PV (kWh self-consumed)": 32443.54,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -53810,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281797,
    "out:DH kr savings": 366058,
    "out:El kr savings": -91477,
    "out:El kr sold": 7216,
    "out:El kr saved": 55154,
    "out:El kr return": 62370,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 179940,
    "out:% savings (space heating)": 58.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.49,
    "out:Etvv": 0,
    "out:Ef": 31.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.08,
    "out:Electricity (inc PV)": 31.58,
    "out:Total Energy Use Pre PV": 92.08,
    "out:Total Energy Use Post PV": 80.58,
    "out:Primary Energy": 83.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.83,
    "out:CO2 Operational/m2": 13.94,
    "out:Total CO2/m2": 44.77,
    "out:Total CO2 (tons)": 152.62,
    "out:Klimatpaverkan": -64.38,
    "out:Initial Cost/MSEK": 7.32,
    "out:Running cost/(Apt SEK y)": 16928,
    "out:Running Cost over RSP/MSEK": 4.891,
    "out:LCP/MSEK": 0.491,
    "out:ROI% old": 15.24,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2375.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5685.18,
    "out:PV (% sold (El))": 14.92,
    "out:PV (kWh self-consumed)": 32417.72,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -53443,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294862,
    "out:DH kr savings": 378467,
    "out:El kr savings": -90854,
    "out:El kr sold": 7249,
    "out:El kr saved": 55110,
    "out:El kr return": 62359,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 167512,
    "out:% savings (space heating)": 61.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38,
    "out:Etvv": 0,
    "out:Ef": 31.58,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.43,
    "out:Electricity (inc PV)": 31.25,
    "out:Total Energy Use Pre PV": 91.43,
    "out:Total Energy Use Post PV": 80.25,
    "out:Primary Energy": 83.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.33,
    "out:CO2 Operational/m2": 13.52,
    "out:Total CO2/m2": 44.85,
    "out:Total CO2 (tons)": 152.89,
    "out:Klimatpaverkan": -64.11,
    "out:Initial Cost/MSEK": 7.439625,
    "out:Running cost/(Apt SEK y)": 16828,
    "out:Running Cost over RSP/MSEK": 4.863,
    "out:LCP/MSEK": 0.4,
    "out:ROI% old": 15.05,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 133.8,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2333.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5913.75,
    "out:PV (% sold (El))": 15.52,
    "out:PV (kWh self-consumed)": 32189.14,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -52342,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 297026,
    "out:DH kr savings": 378467,
    "out:El kr savings": -88981,
    "out:El kr sold": 7540,
    "out:El kr saved": 54722,
    "out:El kr return": 62262,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 165930,
    "out:% savings (space heating)": 61.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 39.34,
    "out:Etvv": 0,
    "out:Ef": 31.25,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 31.14,
    "out:Total Energy Use Pre PV": 87.34,
    "out:Total Energy Use Post PV": 76.14,
    "out:Primary Energy": 81.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.33,
    "out:CO2 Operational/m2": 12.02,
    "out:Total CO2/m2": 43.35,
    "out:Total CO2 (tons)": 147.78,
    "out:Klimatpaverkan": -69.22,
    "out:Initial Cost/MSEK": 7.524875,
    "out:Running cost/(Apt SEK y)": 15846,
    "out:Running Cost over RSP/MSEK": 4.578,
    "out:LCP/MSEK": 0.599,
    "out:ROI% old": 15.42,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2327.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1038,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5945.41,
    "out:PV (% sold (El))": 15.6,
    "out:PV (kWh self-consumed)": 32157.48,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 429534,
    "out:EL kWh savings": -51948,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310145,
    "out:DH kr savings": 390876,
    "out:El kr savings": -88311,
    "out:El kr sold": 7580,
    "out:El kr saved": 54668,
    "out:El kr return": 62248,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 153670,
    "out:% savings (space heating)": 64.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.88,
    "out:Etvv": 0,
    "out:Ef": 31.14,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 52.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.16,
    "out:Electricity (inc PV)": 28.64,
    "out:Total Energy Use Pre PV": 96.16,
    "out:Total Energy Use Post PV": 81.64,
    "out:Primary Energy": 80.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.18,
    "out:CO2 Operational/m2": -25.12,
    "out:Total CO2/m2": 18.07,
    "out:Total CO2 (tons)": 61.58,
    "out:Klimatpaverkan": -155.42,
    "out:Initial Cost/MSEK": 7.943875,
    "out:Running cost/(Apt SEK y)": 16468,
    "out:Running Cost over RSP/MSEK": 4.769,
    "out:LCP/MSEK": -0.011,
    "out:ROI% old": 14.27,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 2148.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1015,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.96,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32701.6,
    "out:PV (% sold (El))": 42.91,
    "out:PV (kWh self-consumed)": 43504.19,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402262,
    "out:EL kWh savings": -43441,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 333903,
    "out:DH kr savings": 366058,
    "out:El kr savings": -73850,
    "out:El kr sold": 41695,
    "out:El kr saved": 73957,
    "out:El kr return": 115652,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 179940,
    "out:% savings (space heating)": 58.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 41.49,
    "out:Etvv": 0,
    "out:Ef": 28.64,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.08,
    "out:Electricity (inc PV)": 28.55,
    "out:Total Energy Use Pre PV": 92.08,
    "out:Total Energy Use Post PV": 77.55,
    "out:Primary Energy": 77.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.18,
    "out:CO2 Operational/m2": -26.64,
    "out:Total CO2/m2": 16.54,
    "out:Total CO2 (tons)": 56.39,
    "out:Klimatpaverkan": -160.61,
    "out:Initial Cost/MSEK": 8.029125,
    "out:Running cost/(Apt SEK y)": 15488,
    "out:Running Cost over RSP/MSEK": 4.486,
    "out:LCP/MSEK": 0.187,
    "out:ROI% old": 14.62,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2144.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1051,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32751.4,
    "out:PV (% sold (El))": 42.98,
    "out:PV (kWh self-consumed)": 43454.39,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -43112,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346934,
    "out:DH kr savings": 378467,
    "out:El kr savings": -73291,
    "out:El kr sold": 41758,
    "out:El kr saved": 73872,
    "out:El kr return": 115630,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 167512,
    "out:% savings (space heating)": 61.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38,
    "out:Etvv": 0,
    "out:Ef": 28.55,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.43,
    "out:Electricity (inc PV)": 28.26,
    "out:Total Energy Use Pre PV": 91.43,
    "out:Total Energy Use Post PV": 77.26,
    "out:Primary Energy": 78.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.68,
    "out:CO2 Operational/m2": -27.37,
    "out:Total CO2/m2": 16.31,
    "out:Total CO2 (tons)": 55.6,
    "out:Klimatpaverkan": -161.4,
    "out:Initial Cost/MSEK": 8.14875,
    "out:Running cost/(Apt SEK y)": 15391,
    "out:Running Cost over RSP/MSEK": 4.458,
    "out:LCP/MSEK": 0.096,
    "out:ROI% old": 14.45,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 142.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2107.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1055,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.23,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33189.53,
    "out:PV (% sold (El))": 43.55,
    "out:PV (kWh self-consumed)": 43016.26,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -42124,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 349174,
    "out:DH kr savings": 378467,
    "out:El kr savings": -71610,
    "out:El kr sold": 42317,
    "out:El kr saved": 73128,
    "out:El kr return": 115444,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 165930,
    "out:% savings (space heating)": 61.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 39.34,
    "out:Etvv": 0,
    "out:Ef": 28.26,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.34,
    "out:Electricity (inc PV)": 28.15,
    "out:Total Energy Use Pre PV": 87.34,
    "out:Total Energy Use Post PV": 73.15,
    "out:Primary Energy": 75.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.68,
    "out:CO2 Operational/m2": -28.91,
    "out:Total CO2/m2": 14.77,
    "out:Total CO2 (tons)": 50.35,
    "out:Klimatpaverkan": -166.65,
    "out:Initial Cost/MSEK": 8.234,
    "out:Running cost/(Apt SEK y)": 14409,
    "out:Running Cost over RSP/MSEK": 4.173,
    "out:LCP/MSEK": 0.295,
    "out:ROI% old": 14.8,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2102.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.14,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33249.77,
    "out:PV (% sold (El))": 43.63,
    "out:PV (kWh self-consumed)": 42956.02,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 429534,
    "out:EL kWh savings": -41770,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 362261,
    "out:DH kr savings": 390876,
    "out:El kr savings": -71009,
    "out:El kr sold": 42393,
    "out:El kr saved": 73025,
    "out:El kr return": 115419,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 153670,
    "out:% savings (space heating)": 64.65,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.88,
    "out:Etvv": 0,
    "out:Ef": 28.15,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.17,
    "out:Electricity (inc PV)": 40.33,
    "out:Total Energy Use Pre PV": 89.17,
    "out:Total Energy Use Post PV": 87.33,
    "out:Primary Energy": 99.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.48,
    "out:CO2 Operational/m2": 22.49,
    "out:Total CO2/m2": 40.96,
    "out:Total CO2 (tons)": 139.65,
    "out:Klimatpaverkan": -77.35,
    "out:Initial Cost/MSEK": 6.62325,
    "out:Running cost/(Apt SEK y)": 17987,
    "out:Running Cost over RSP/MSEK": 5.185,
    "out:LCP/MSEK": 0.894,
    "out:ROI% old": 16.21,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2952.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422716,
    "out:EL kWh savings": -83269,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 243115,
    "out:DH kr savings": 384672,
    "out:El kr savings": -141557,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 161371,
    "out:% savings (space heating)": 62.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.63,
    "out:Etvv": 0,
    "out:Ef": 40.33,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.08,
    "out:Electricity (inc PV)": 40.19,
    "out:Total Energy Use Pre PV": 86.08,
    "out:Total Energy Use Post PV": 84.19,
    "out:Primary Energy": 96.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.48,
    "out:CO2 Operational/m2": 21.39,
    "out:Total CO2/m2": 39.87,
    "out:Total CO2 (tons)": 135.92,
    "out:Klimatpaverkan": -81.08,
    "out:Initial Cost/MSEK": 6.7085,
    "out:Running cost/(Apt SEK y)": 17245,
    "out:Running Cost over RSP/MSEK": 4.97,
    "out:LCP/MSEK": 1.023,
    "out:ROI% old": 16.47,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2945.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -82811,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253200,
    "out:DH kr savings": 393978,
    "out:El kr savings": -140778,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 149168,
    "out:% savings (space heating)": 65.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.18,
    "out:Etvv": 0,
    "out:Ef": 40.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 39.93,
    "out:Total Energy Use Pre PV": 85.55,
    "out:Total Energy Use Post PV": 83.93,
    "out:Primary Energy": 97.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.97,
    "out:CO2 Operational/m2": 21.32,
    "out:Total CO2/m2": 40.3,
    "out:Total CO2 (tons)": 137.37,
    "out:Klimatpaverkan": -79.63,
    "out:Initial Cost/MSEK": 6.82825,
    "out:Running cost/(Apt SEK y)": 17162,
    "out:Running Cost over RSP/MSEK": 4.946,
    "out:LCP/MSEK": 0.928,
    "out:ROI% old": 16.23,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2908.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 989,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -81907,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254736,
    "out:DH kr savings": 393978,
    "out:El kr savings": -139242,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 150638,
    "out:% savings (space heating)": 65.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.95,
    "out:Etvv": 0,
    "out:Ef": 39.93,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.44,
    "out:Electricity (inc PV)": 39.78,
    "out:Total Energy Use Pre PV": 82.44,
    "out:Total Energy Use Post PV": 80.78,
    "out:Primary Energy": 95.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.97,
    "out:CO2 Operational/m2": 20.23,
    "out:Total CO2/m2": 39.2,
    "out:Total CO2 (tons)": 133.64,
    "out:Klimatpaverkan": -83.36,
    "out:Initial Cost/MSEK": 6.913375,
    "out:Running cost/(Apt SEK y)": 16419,
    "out:Running Cost over RSP/MSEK": 4.731,
    "out:LCP/MSEK": 1.058,
    "out:ROI% old": 16.47,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2900.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -81412,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264884,
    "out:DH kr savings": 403285,
    "out:El kr savings": -138401,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 138573,
    "out:% savings (space heating)": 68.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 33.54,
    "out:Etvv": 0,
    "out:Ef": 39.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.17,
    "out:Electricity (inc PV)": 31.11,
    "out:Total Energy Use Pre PV": 89.17,
    "out:Total Energy Use Post PV": 78.11,
    "out:Primary Energy": 83.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.83,
    "out:CO2 Operational/m2": 12.64,
    "out:Total CO2/m2": 43.47,
    "out:Total CO2 (tons)": 148.18,
    "out:Klimatpaverkan": -68.82,
    "out:Initial Cost/MSEK": 7.332375,
    "out:Running cost/(Apt SEK y)": 16305,
    "out:Running Cost over RSP/MSEK": 4.711,
    "out:LCP/MSEK": 0.659,
    "out:ROI% old": 15.57,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2317.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1021,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6003.38,
    "out:PV (% sold (El))": 15.76,
    "out:PV (kWh self-consumed)": 32099.51,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422716,
    "out:EL kWh savings": -51845,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304189,
    "out:DH kr savings": 384672,
    "out:El kr savings": -88137,
    "out:El kr sold": 7654,
    "out:El kr saved": 54569,
    "out:El kr return": 62223,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 161371,
    "out:% savings (space heating)": 62.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.63,
    "out:Etvv": 0,
    "out:Ef": 31.11,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.08,
    "out:Electricity (inc PV)": 30.99,
    "out:Total Energy Use Pre PV": 86.08,
    "out:Total Energy Use Post PV": 74.99,
    "out:Primary Energy": 80.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.83,
    "out:CO2 Operational/m2": 11.5,
    "out:Total CO2/m2": 42.33,
    "out:Total CO2 (tons)": 144.29,
    "out:Klimatpaverkan": -72.71,
    "out:Initial Cost/MSEK": 7.417625,
    "out:Running cost/(Apt SEK y)": 15564,
    "out:Running Cost over RSP/MSEK": 4.497,
    "out:LCP/MSEK": 0.788,
    "out:ROI% old": 15.8,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2311.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6037.1,
    "out:PV (% sold (El))": 15.84,
    "out:PV (kWh self-consumed)": 32065.79,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -51439,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314229,
    "out:DH kr savings": 393978,
    "out:El kr savings": -87446,
    "out:El kr sold": 7697,
    "out:El kr saved": 54512,
    "out:El kr return": 62209,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 149168,
    "out:% savings (space heating)": 65.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.18,
    "out:Etvv": 0,
    "out:Ef": 30.99,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 30.75,
    "out:Total Energy Use Pre PV": 85.55,
    "out:Total Energy Use Post PV": 74.75,
    "out:Primary Energy": 81.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.33,
    "out:CO2 Operational/m2": 11.15,
    "out:Total CO2/m2": 42.48,
    "out:Total CO2 (tons)": 144.8,
    "out:Klimatpaverkan": -72.2,
    "out:Initial Cost/MSEK": 7.53725,
    "out:Running cost/(Apt SEK y)": 15483,
    "out:Running Cost over RSP/MSEK": 4.474,
    "out:LCP/MSEK": 0.691,
    "out:ROI% old": 15.6,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2277.5,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1051,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6228.01,
    "out:PV (% sold (El))": 16.35,
    "out:PV (kWh self-consumed)": 31874.88,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -50637,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315836,
    "out:DH kr savings": 393978,
    "out:El kr savings": -86083,
    "out:El kr sold": 7941,
    "out:El kr saved": 54187,
    "out:El kr return": 62128,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 150638,
    "out:% savings (space heating)": 65.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.95,
    "out:Etvv": 0,
    "out:Ef": 30.75,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.44,
    "out:Electricity (inc PV)": 30.63,
    "out:Total Energy Use Pre PV": 82.44,
    "out:Total Energy Use Post PV": 71.63,
    "out:Primary Energy": 78.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.33,
    "out:CO2 Operational/m2": 10,
    "out:Total CO2/m2": 41.33,
    "out:Total CO2 (tons)": 140.88,
    "out:Klimatpaverkan": -76.12,
    "out:Initial Cost/MSEK": 7.6225,
    "out:Running cost/(Apt SEK y)": 14740,
    "out:Running Cost over RSP/MSEK": 4.258,
    "out:LCP/MSEK": 0.821,
    "out:ROI% old": 15.82,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2270.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1079,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6267.02,
    "out:PV (% sold (El))": 16.45,
    "out:PV (kWh self-consumed)": 31835.87,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -50198,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325939,
    "out:DH kr savings": 403285,
    "out:El kr savings": -85336,
    "out:El kr sold": 7990,
    "out:El kr saved": 54121,
    "out:El kr return": 62111,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 138573,
    "out:% savings (space heating)": 68.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 33.54,
    "out:Etvv": 0,
    "out:Ef": 30.63,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.17,
    "out:Electricity (inc PV)": 28.13,
    "out:Total Energy Use Pre PV": 89.17,
    "out:Total Energy Use Post PV": 75.13,
    "out:Primary Energy": 77.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.18,
    "out:CO2 Operational/m2": -28.37,
    "out:Total CO2/m2": 14.81,
    "out:Total CO2 (tons)": 50.48,
    "out:Klimatpaverkan": -166.52,
    "out:Initial Cost/MSEK": 8.0415,
    "out:Running cost/(Apt SEK y)": 14868,
    "out:Running Cost over RSP/MSEK": 4.307,
    "out:LCP/MSEK": 0.354,
    "out:ROI% old": 14.91,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2092.8,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1074,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.97,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33359.71,
    "out:PV (% sold (El))": 43.78,
    "out:PV (kWh self-consumed)": 42846.07,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 422716,
    "out:EL kWh savings": -41678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 356353,
    "out:DH kr savings": 384672,
    "out:El kr savings": -70853,
    "out:El kr sold": 42534,
    "out:El kr saved": 72838,
    "out:El kr return": 115372,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 161371,
    "out:% savings (space heating)": 62.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.63,
    "out:Etvv": 0,
    "out:Ef": 28.13,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.08,
    "out:Electricity (inc PV)": 28.02,
    "out:Total Energy Use Pre PV": 86.08,
    "out:Total Energy Use Post PV": 72.02,
    "out:Primary Energy": 75.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.18,
    "out:CO2 Operational/m2": -29.56,
    "out:Total CO2/m2": 13.62,
    "out:Total CO2 (tons)": 46.44,
    "out:Klimatpaverkan": -170.56,
    "out:Initial Cost/MSEK": 8.12675,
    "out:Running cost/(Apt SEK y)": 14128,
    "out:Running Cost over RSP/MSEK": 4.092,
    "out:LCP/MSEK": 0.483,
    "out:ROI% old": 15.14,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2087.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.88,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33423.56,
    "out:PV (% sold (El))": 43.86,
    "out:PV (kWh self-consumed)": 42782.23,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -41313,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366362,
    "out:DH kr savings": 393978,
    "out:El kr savings": -70231,
    "out:El kr sold": 42615,
    "out:El kr saved": 72730,
    "out:El kr return": 115345,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 149168,
    "out:% savings (space heating)": 65.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.18,
    "out:Etvv": 0,
    "out:Ef": 28.02,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.55,
    "out:Electricity (inc PV)": 27.81,
    "out:Total Energy Use Pre PV": 85.55,
    "out:Total Energy Use Post PV": 71.81,
    "out:Primary Energy": 75.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.68,
    "out:CO2 Operational/m2": -30.16,
    "out:Total CO2/m2": 13.52,
    "out:Total CO2 (tons)": 46.09,
    "out:Klimatpaverkan": -170.91,
    "out:Initial Cost/MSEK": 8.246375,
    "out:Running cost/(Apt SEK y)": 14048,
    "out:Running Cost over RSP/MSEK": 4.07,
    "out:LCP/MSEK": 0.386,
    "out:ROI% old": 14.95,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2057.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1104,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.35,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33783.04,
    "out:PV (% sold (El))": 44.33,
    "out:PV (kWh self-consumed)": 42422.75,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -40592,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368045,
    "out:DH kr savings": 393978,
    "out:El kr savings": -69006,
    "out:El kr sold": 43073,
    "out:El kr saved": 72119,
    "out:El kr return": 115192,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 150638,
    "out:% savings (space heating)": 65.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.95,
    "out:Etvv": 0,
    "out:Ef": 27.81,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000101120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.44,
    "out:Electricity (inc PV)": 27.69,
    "out:Total Energy Use Pre PV": 82.44,
    "out:Total Energy Use Post PV": 68.69,
    "out:Primary Energy": 73.32,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.68,
    "out:CO2 Operational/m2": -31.36,
    "out:Total CO2/m2": 12.32,
    "out:Total CO2 (tons)": 42,
    "out:Klimatpaverkan": -175,
    "out:Initial Cost/MSEK": 8.331625,
    "out:Running cost/(Apt SEK y)": 13306,
    "out:Running Cost over RSP/MSEK": 3.855,
    "out:LCP/MSEK": 0.516,
    "out:ROI% old": 15.17,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 171,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2051.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1132,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.24,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33855.73,
    "out:PV (% sold (El))": 44.43,
    "out:PV (kWh self-consumed)": 42350.06,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -40198,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378115,
    "out:DH kr savings": 403285,
    "out:El kr savings": -68336,
    "out:El kr sold": 43166,
    "out:El kr saved": 71995,
    "out:El kr return": 115161,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 138573,
    "out:% savings (space heating)": 68.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 33.54,
    "out:Etvv": 0,
    "out:Ef": 27.69,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.52,
    "out:Electricity (inc PV)": 52.71,
    "out:Total Energy Use Pre PV": 62.52,
    "out:Total Energy Use Post PV": 57.71,
    "out:Primary Energy": 96.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.07,
    "out:CO2 Operational/m2": 9.39,
    "out:Total CO2/m2": 28.46,
    "out:Total CO2 (tons)": 97.03,
    "out:Klimatpaverkan": -119.97,
    "out:Initial Cost/MSEK": 8.381875,
    "out:Running cost/(Apt SEK y)": 10221,
    "out:Running Cost over RSP/MSEK": 2.928,
    "out:LCP/MSEK": 1.393,
    "out:ROI% old": 16.66,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 222.4,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 4026.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1246,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -125497,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301618,
    "out:DH kr savings": 514964,
    "out:El kr savings": -213345,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18336,
    "out:% savings (space heating)": 95.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.75,
    "out:Etvv": 0,
    "out:Ef": 52.71,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 51.79,
    "out:Total Energy Use Pre PV": 60.77,
    "out:Total Energy Use Post PV": 55.79,
    "out:Primary Energy": 94.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.07,
    "out:CO2 Operational/m2": 8.93,
    "out:Total CO2/m2": 28,
    "out:Total CO2 (tons)": 95.47,
    "out:Klimatpaverkan": -121.53,
    "out:Initial Cost/MSEK": 8.467125,
    "out:Running cost/(Apt SEK y)": 9861,
    "out:Running Cost over RSP/MSEK": 2.824,
    "out:LCP/MSEK": 1.411,
    "out:ROI% old": 16.67,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3974,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1259,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -122344,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310082,
    "out:DH kr savings": 518066,
    "out:El kr savings": -207984,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13532,
    "out:% savings (space heating)": 96.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.91,
    "out:Etvv": 0,
    "out:Ef": 51.79,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.89,
    "out:Electricity (inc PV)": 51.69,
    "out:Total Energy Use Pre PV": 59.89,
    "out:Total Energy Use Post PV": 55.69,
    "out:Primary Energy": 94.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.57,
    "out:CO2 Operational/m2": 8.82,
    "out:Total CO2/m2": 28.38,
    "out:Total CO2 (tons)": 96.76,
    "out:Klimatpaverkan": -120.24,
    "out:Initial Cost/MSEK": 8.58675,
    "out:Running cost/(Apt SEK y)": 9722,
    "out:Running Cost over RSP/MSEK": 2.784,
    "out:LCP/MSEK": 1.331,
    "out:ROI% old": 16.5,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3912,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1265,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.69,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -122004,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310659,
    "out:DH kr savings": 518066,
    "out:El kr savings": -207407,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 14518,
    "out:% savings (space heating)": 96.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.38,
    "out:Etvv": 0,
    "out:Ef": 51.69,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.06,
    "out:Electricity (inc PV)": 50.74,
    "out:Total Energy Use Pre PV": 58.06,
    "out:Total Energy Use Post PV": 53.74,
    "out:Primary Energy": 92.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.57,
    "out:CO2 Operational/m2": 8.35,
    "out:Total CO2/m2": 27.91,
    "out:Total CO2 (tons)": 95.16,
    "out:Klimatpaverkan": -121.84,
    "out:Initial Cost/MSEK": 8.672,
    "out:Running cost/(Apt SEK y)": 9350,
    "out:Running Cost over RSP/MSEK": 2.677,
    "out:LCP/MSEK": 1.353,
    "out:ROI% old": 16.51,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3854,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1278,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -118773,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 319253,
    "out:DH kr savings": 521168,
    "out:El kr savings": -201915,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10570,
    "out:% savings (space heating)": 97.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.62,
    "out:Etvv": 0,
    "out:Ef": 50.74,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.52,
    "out:Electricity (inc PV)": 42.4,
    "out:Total Energy Use Pre PV": 62.52,
    "out:Total Energy Use Post PV": 47.4,
    "out:Primary Energy": 78.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.42,
    "out:CO2 Operational/m2": 5.3,
    "out:Total CO2/m2": 36.73,
    "out:Total CO2 (tons)": 125.2,
    "out:Klimatpaverkan": -91.8,
    "out:Initial Cost/MSEK": 9.091,
    "out:Running cost/(Apt SEK y)": 8493,
    "out:Running Cost over RSP/MSEK": 2.441,
    "out:LCP/MSEK": 1.17,
    "out:ROI% old": 16.12,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 297.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3321.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1310,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 1975.22,
    "out:PV (% sold (El))": 5.18,
    "out:PV (kWh self-consumed)": 36127.67,
    "out:PV (ratio sold/self-consumed)": 0.05,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -90347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 363892,
    "out:DH kr savings": 514964,
    "out:El kr savings": -153590,
    "out:El kr sold": 2518,
    "out:El kr saved": 61417,
    "out:El kr return": 63935,
    "out:% solar/total": 67,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18336,
    "out:% savings (space heating)": 95.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.75,
    "out:Etvv": 0,
    "out:Ef": 42.4,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 41.54,
    "out:Total Energy Use Pre PV": 60.77,
    "out:Total Energy Use Post PV": 45.54,
    "out:Primary Energy": 76.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.42,
    "out:CO2 Operational/m2": 4.65,
    "out:Total CO2/m2": 36.07,
    "out:Total CO2 (tons)": 122.97,
    "out:Klimatpaverkan": -94.03,
    "out:Initial Cost/MSEK": 9.17625,
    "out:Running cost/(Apt SEK y)": 8134,
    "out:Running Cost over RSP/MSEK": 2.338,
    "out:LCP/MSEK": 1.188,
    "out:ROI% old": 16.14,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3270.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1323,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2113,
    "out:PV (% sold (El))": 5.55,
    "out:PV (kWh self-consumed)": 35989.89,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -87410,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 372162,
    "out:DH kr savings": 518066,
    "out:El kr savings": -148598,
    "out:El kr sold": 2694,
    "out:El kr saved": 61183,
    "out:El kr return": 63877,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13532,
    "out:% savings (space heating)": 96.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.91,
    "out:Etvv": 0,
    "out:Ef": 41.54,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.89,
    "out:Electricity (inc PV)": 41.45,
    "out:Total Energy Use Pre PV": 59.89,
    "out:Total Energy Use Post PV": 45.45,
    "out:Primary Energy": 76.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.92,
    "out:CO2 Operational/m2": 4.29,
    "out:Total CO2/m2": 36.21,
    "out:Total CO2 (tons)": 123.45,
    "out:Klimatpaverkan": -93.55,
    "out:Initial Cost/MSEK": 9.295875,
    "out:Running cost/(Apt SEK y)": 7998,
    "out:Running Cost over RSP/MSEK": 2.299,
    "out:LCP/MSEK": 1.107,
    "out:ROI% old": 15.99,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3211.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1328,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.69,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2281.85,
    "out:PV (% sold (El))": 5.99,
    "out:PV (kWh self-consumed)": 35821.04,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -87095,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 372914,
    "out:DH kr savings": 518066,
    "out:El kr savings": -148061,
    "out:El kr sold": 2909,
    "out:El kr saved": 60896,
    "out:El kr return": 63805,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 14518,
    "out:% savings (space heating)": 96.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.38,
    "out:Etvv": 0,
    "out:Ef": 41.45,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.06,
    "out:Electricity (inc PV)": 40.57,
    "out:Total Energy Use Pre PV": 58.06,
    "out:Total Energy Use Post PV": 43.57,
    "out:Primary Energy": 74.16,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.92,
    "out:CO2 Operational/m2": 3.59,
    "out:Total CO2/m2": 35.51,
    "out:Total CO2 (tons)": 121.05,
    "out:Klimatpaverkan": -95.95,
    "out:Initial Cost/MSEK": 9.381125,
    "out:Running cost/(Apt SEK y)": 7627,
    "out:Running Cost over RSP/MSEK": 2.192,
    "out:LCP/MSEK": 1.129,
    "out:ROI% old": 16,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3156.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1342,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2447.23,
    "out:PV (% sold (El))": 6.42,
    "out:PV (kWh self-consumed)": 35655.67,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -84095,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 381327,
    "out:DH kr savings": 521168,
    "out:El kr savings": -142961,
    "out:El kr sold": 3120,
    "out:El kr saved": 60615,
    "out:El kr return": 63735,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10570,
    "out:% savings (space heating)": 97.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.62,
    "out:Etvv": 0,
    "out:Ef": 40.57,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 57.52,
    "out:Electricity (inc PV)": 38.21,
    "out:Total Energy Use Pre PV": 62.52,
    "out:Total Energy Use Post PV": 43.21,
    "out:Primary Energy": 70.71,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.78,
    "out:CO2 Operational/m2": -27.82,
    "out:Total CO2/m2": 15.95,
    "out:Total CO2 (tons)": 54.39,
    "out:Klimatpaverkan": -162.61,
    "out:Initial Cost/MSEK": 9.800125,
    "out:Running cost/(Apt SEK y)": 6996,
    "out:Running Cost over RSP/MSEK": 2.019,
    "out:LCP/MSEK": 0.883,
    "out:ROI% old": 15.57,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2984.8,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1365,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 39.32,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24054.35,
    "out:PV (% sold (El))": 31.56,
    "out:PV (kWh self-consumed)": 52151.44,
    "out:PV (ratio sold/self-consumed)": 0.46,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 565894,
    "out:EL kWh savings": -76058,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 416334,
    "out:DH kr savings": 514964,
    "out:El kr savings": -129299,
    "out:El kr sold": 30669,
    "out:El kr saved": 88657,
    "out:El kr return": 119327,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 18336,
    "out:% savings (space heating)": 95.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.75,
    "out:Etvv": 0,
    "out:Ef": 38.21,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.77,
    "out:Electricity (inc PV)": 37.44,
    "out:Total Energy Use Pre PV": 60.77,
    "out:Total Energy Use Post PV": 41.44,
    "out:Primary Energy": 68.74,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.78,
    "out:CO2 Operational/m2": -28.89,
    "out:Total CO2/m2": 14.89,
    "out:Total CO2 (tons)": 50.74,
    "out:Klimatpaverkan": -166.26,
    "out:Initial Cost/MSEK": 9.885375,
    "out:Running cost/(Apt SEK y)": 6640,
    "out:Running Cost over RSP/MSEK": 1.917,
    "out:LCP/MSEK": 0.9,
    "out:ROI% old": 15.59,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2940.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1379,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.57,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24469.41,
    "out:PV (% sold (El))": 32.11,
    "out:PV (kWh self-consumed)": 51736.38,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -73444,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 424410,
    "out:DH kr savings": 518066,
    "out:El kr savings": -124855,
    "out:El kr sold": 31198,
    "out:El kr saved": 87952,
    "out:El kr return": 119150,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13532,
    "out:% savings (space heating)": 96.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.91,
    "out:Etvv": 0,
    "out:Ef": 37.44,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.89,
    "out:Electricity (inc PV)": 37.36,
    "out:Total Energy Use Pre PV": 59.89,
    "out:Total Energy Use Post PV": 41.36,
    "out:Primary Energy": 68.92,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.27,
    "out:CO2 Operational/m2": -29.73,
    "out:Total CO2/m2": 14.54,
    "out:Total CO2 (tons)": 49.57,
    "out:Klimatpaverkan": -167.43,
    "out:Initial Cost/MSEK": 10.005,
    "out:Running cost/(Apt SEK y)": 6507,
    "out:Running Cost over RSP/MSEK": 1.879,
    "out:LCP/MSEK": 0.818,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2887.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1383,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 37.69,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24962.12,
    "out:PV (% sold (El))": 32.76,
    "out:PV (kWh self-consumed)": 51243.67,
    "out:PV (ratio sold/self-consumed)": 0.49,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -73163,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 425516,
    "out:DH kr savings": 518066,
    "out:El kr savings": -124377,
    "out:El kr sold": 31827,
    "out:El kr saved": 87114,
    "out:El kr return": 118941,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 14518,
    "out:% savings (space heating)": 96.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.38,
    "out:Etvv": 0,
    "out:Ef": 37.36,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.06,
    "out:Electricity (inc PV)": 36.58,
    "out:Total Energy Use Pre PV": 58.06,
    "out:Total Energy Use Post PV": 39.58,
    "out:Primary Energy": 66.97,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.27,
    "out:CO2 Operational/m2": -30.88,
    "out:Total CO2/m2": 13.39,
    "out:Total CO2 (tons)": 45.64,
    "out:Klimatpaverkan": -171.36,
    "out:Initial Cost/MSEK": 10.09025,
    "out:Running cost/(Apt SEK y)": 6141,
    "out:Running Cost over RSP/MSEK": 1.773,
    "out:LCP/MSEK": 0.839,
    "out:ROI% old": 15.47,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 367.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2839,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1397,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.86,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25427.7,
    "out:PV (% sold (El))": 33.37,
    "out:PV (kWh self-consumed)": 50778.08,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -70493,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 433749,
    "out:DH kr savings": 521168,
    "out:El kr savings": -119839,
    "out:El kr sold": 32420,
    "out:El kr saved": 86323,
    "out:El kr return": 118743,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10570,
    "out:% savings (space heating)": 97.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.62,
    "out:Etvv": 0,
    "out:Ef": 36.58,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.32,
    "out:Electricity (inc PV)": 51.34,
    "out:Total Energy Use Pre PV": 59.32,
    "out:Total Energy Use Post PV": 55.34,
    "out:Primary Energy": 94.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.07,
    "out:CO2 Operational/m2": 8.74,
    "out:Total CO2/m2": 27.81,
    "out:Total CO2 (tons)": 94.82,
    "out:Klimatpaverkan": -122.18,
    "out:Initial Cost/MSEK": 8.4795,
    "out:Running cost/(Apt SEK y)": 9634,
    "out:Running Cost over RSP/MSEK": 2.759,
    "out:LCP/MSEK": 1.464,
    "out:ROI% old": 16.75,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 240,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3872.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1268,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -120832,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 312651,
    "out:DH kr savings": 518066,
    "out:El kr savings": -205414,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13428,
    "out:% savings (space heating)": 96.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.27,
    "out:Etvv": 0,
    "out:Ef": 51.34,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.47,
    "out:Electricity (inc PV)": 50.39,
    "out:Total Energy Use Pre PV": 57.47,
    "out:Total Energy Use Post PV": 53.39,
    "out:Primary Energy": 91.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.07,
    "out:CO2 Operational/m2": 8.27,
    "out:Total CO2/m2": 27.34,
    "out:Total CO2 (tons)": 93.21,
    "out:Klimatpaverkan": -123.79,
    "out:Initial Cost/MSEK": 8.56475,
    "out:Running cost/(Apt SEK y)": 9259,
    "out:Running Cost over RSP/MSEK": 2.651,
    "out:LCP/MSEK": 1.487,
    "out:ROI% old": 16.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 252.8,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3813.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1282,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -117577,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 321287,
    "out:DH kr savings": 521168,
    "out:El kr savings": -199881,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 9718,
    "out:% savings (space heating)": 97.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.53,
    "out:Etvv": 0,
    "out:Ef": 50.39,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.95,
    "out:Electricity (inc PV)": 50.51,
    "out:Total Energy Use Pre PV": 56.95,
    "out:Total Energy Use Post PV": 53.51,
    "out:Primary Energy": 92.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.57,
    "out:CO2 Operational/m2": 8.2,
    "out:Total CO2/m2": 27.77,
    "out:Total CO2 (tons)": 94.66,
    "out:Klimatpaverkan": -122.34,
    "out:Initial Cost/MSEK": 8.6845,
    "out:Running cost/(Apt SEK y)": 9176,
    "out:Running Cost over RSP/MSEK": 2.627,
    "out:LCP/MSEK": 1.39,
    "out:ROI% old": 16.57,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3776.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1285,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -118001,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320566,
    "out:DH kr savings": 521168,
    "out:El kr savings": -200602,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 11116,
    "out:% savings (space heating)": 97.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.01,
    "out:Etvv": 0,
    "out:Ef": 50.51,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.05,
    "out:Electricity (inc PV)": 49.54,
    "out:Total Energy Use Pre PV": 55.05,
    "out:Total Energy Use Post PV": 51.54,
    "out:Primary Energy": 90.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 19.57,
    "out:CO2 Operational/m2": 7.72,
    "out:Total CO2/m2": 27.29,
    "out:Total CO2 (tons)": 93.03,
    "out:Klimatpaverkan": -123.97,
    "out:Initial Cost/MSEK": 8.769625,
    "out:Running cost/(Apt SEK y)": 8794,
    "out:Running Cost over RSP/MSEK": 2.517,
    "out:LCP/MSEK": 1.415,
    "out:ROI% old": 16.59,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3713.7,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1299,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -114690,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329298,
    "out:DH kr savings": 524270,
    "out:El kr savings": -194972,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7906,
    "out:% savings (space heating)": 98.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.32,
    "out:Etvv": 0,
    "out:Ef": 49.54,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.32,
    "out:Electricity (inc PV)": 41.13,
    "out:Total Energy Use Pre PV": 59.32,
    "out:Total Energy Use Post PV": 45.13,
    "out:Primary Energy": 75.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.42,
    "out:CO2 Operational/m2": 4.06,
    "out:Total CO2/m2": 35.48,
    "out:Total CO2 (tons)": 120.97,
    "out:Klimatpaverkan": -96.03,
    "out:Initial Cost/MSEK": 9.188625,
    "out:Running cost/(Apt SEK y)": 7911,
    "out:Running Cost over RSP/MSEK": 2.274,
    "out:LCP/MSEK": 1.24,
    "out:ROI% old": 16.21,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 315.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3174.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1332,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2393.4,
    "out:PV (% sold (El))": 6.28,
    "out:PV (kWh self-consumed)": 35709.49,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -86005,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 374908,
    "out:DH kr savings": 518066,
    "out:El kr savings": -146209,
    "out:El kr sold": 3052,
    "out:El kr saved": 60706,
    "out:El kr return": 63758,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13428,
    "out:% savings (space heating)": 96.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.27,
    "out:Etvv": 0,
    "out:Ef": 41.13,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.47,
    "out:Electricity (inc PV)": 40.24,
    "out:Total Energy Use Pre PV": 57.47,
    "out:Total Energy Use Post PV": 43.24,
    "out:Primary Energy": 73.51,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.42,
    "out:CO2 Operational/m2": 3.34,
    "out:Total CO2/m2": 34.77,
    "out:Total CO2 (tons)": 118.52,
    "out:Klimatpaverkan": -98.48,
    "out:Initial Cost/MSEK": 9.273875,
    "out:Running cost/(Apt SEK y)": 7537,
    "out:Running Cost over RSP/MSEK": 2.166,
    "out:LCP/MSEK": 1.262,
    "out:ROI% old": 16.23,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3117.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1345,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2567.15,
    "out:PV (% sold (El))": 6.74,
    "out:PV (kWh self-consumed)": 35535.74,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -82986,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 383364,
    "out:DH kr savings": 521168,
    "out:El kr savings": -141077,
    "out:El kr sold": 3273,
    "out:El kr saved": 60411,
    "out:El kr return": 63684,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 9718,
    "out:% savings (space heating)": 97.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.53,
    "out:Etvv": 0,
    "out:Ef": 40.24,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.95,
    "out:Electricity (inc PV)": 40.36,
    "out:Total Energy Use Pre PV": 56.95,
    "out:Total Energy Use Post PV": 43.36,
    "out:Primary Energy": 74.05,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.92,
    "out:CO2 Operational/m2": 3.11,
    "out:Total CO2/m2": 35.03,
    "out:Total CO2 (tons)": 119.43,
    "out:Klimatpaverkan": -97.57,
    "out:Initial Cost/MSEK": 9.3935,
    "out:Running cost/(Apt SEK y)": 7456,
    "out:Running Cost over RSP/MSEK": 2.143,
    "out:LCP/MSEK": 1.166,
    "out:ROI% old": 16.06,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3082.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1348,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2679.32,
    "out:PV (% sold (El))": 7.03,
    "out:PV (kWh self-consumed)": 35423.57,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -83379,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 382839,
    "out:DH kr savings": 521168,
    "out:El kr savings": -141745,
    "out:El kr sold": 3416,
    "out:El kr saved": 60220,
    "out:El kr return": 63636,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 11116,
    "out:% savings (space heating)": 97.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.01,
    "out:Etvv": 0,
    "out:Ef": 40.36,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.05,
    "out:Electricity (inc PV)": 39.46,
    "out:Total Energy Use Pre PV": 55.05,
    "out:Total Energy Use Post PV": 41.46,
    "out:Primary Energy": 71.95,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 31.92,
    "out:CO2 Operational/m2": 2.36,
    "out:Total CO2/m2": 34.28,
    "out:Total CO2 (tons)": 116.85,
    "out:Klimatpaverkan": -100.15,
    "out:Initial Cost/MSEK": 9.47875,
    "out:Running cost/(Apt SEK y)": 7076,
    "out:Running Cost over RSP/MSEK": 2.034,
    "out:LCP/MSEK": 1.19,
    "out:ROI% old": 16.08,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3023.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1362,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2876.27,
    "out:PV (% sold (El))": 7.55,
    "out:PV (kWh self-consumed)": 35226.62,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -80316,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 391400,
    "out:DH kr savings": 524270,
    "out:El kr savings": -136537,
    "out:El kr sold": 3667,
    "out:El kr saved": 59885,
    "out:El kr return": 63553,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7906,
    "out:% savings (space heating)": 98.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.32,
    "out:Etvv": 0,
    "out:Ef": 39.46,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.32,
    "out:Electricity (inc PV)": 37.08,
    "out:Total Energy Use Pre PV": 59.32,
    "out:Total Energy Use Post PV": 41.08,
    "out:Primary Energy": 68.33,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.78,
    "out:CO2 Operational/m2": -30.27,
    "out:Total CO2/m2": 13.51,
    "out:Total CO2 (tons)": 46.05,
    "out:Klimatpaverkan": -170.95,
    "out:Initial Cost/MSEK": 9.89775,
    "out:Running cost/(Apt SEK y)": 6423,
    "out:Running Cost over RSP/MSEK": 1.855,
    "out:LCP/MSEK": 0.95,
    "out:ROI% old": 15.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2854.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1387,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 37.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25277.51,
    "out:PV (% sold (El))": 33.17,
    "out:PV (kWh self-consumed)": 50928.28,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -72193,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 427566,
    "out:DH kr savings": 518066,
    "out:El kr savings": -122728,
    "out:El kr sold": 32229,
    "out:El kr saved": 86578,
    "out:El kr return": 118807,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13428,
    "out:% savings (space heating)": 96.91,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.27,
    "out:Etvv": 0,
    "out:Ef": 37.08,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.47,
    "out:Electricity (inc PV)": 36.29,
    "out:Total Energy Use Pre PV": 57.47,
    "out:Total Energy Use Post PV": 39.29,
    "out:Primary Energy": 66.39,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.78,
    "out:CO2 Operational/m2": -31.45,
    "out:Total CO2/m2": 12.33,
    "out:Total CO2 (tons)": 42.04,
    "out:Klimatpaverkan": -174.96,
    "out:Initial Cost/MSEK": 9.983,
    "out:Running cost/(Apt SEK y)": 6053,
    "out:Running Cost over RSP/MSEK": 1.748,
    "out:LCP/MSEK": 0.971,
    "out:ROI% old": 15.68,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2804.6,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1400,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.27,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25757.53,
    "out:PV (% sold (El))": 33.8,
    "out:PV (kWh self-consumed)": 50448.25,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -69507,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 435847,
    "out:DH kr savings": 521168,
    "out:El kr savings": -118162,
    "out:El kr sold": 32841,
    "out:El kr saved": 85762,
    "out:El kr return": 118603,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 9718,
    "out:% savings (space heating)": 97.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.53,
    "out:Etvv": 0,
    "out:Ef": 36.29,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.95,
    "out:Electricity (inc PV)": 36.39,
    "out:Total Energy Use Pre PV": 56.95,
    "out:Total Energy Use Post PV": 39.39,
    "out:Primary Energy": 66.91,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.27,
    "out:CO2 Operational/m2": -31.95,
    "out:Total CO2/m2": 12.32,
    "out:Total CO2 (tons)": 42,
    "out:Klimatpaverkan": -175,
    "out:Initial Cost/MSEK": 10.102625,
    "out:Running cost/(Apt SEK y)": 5974,
    "out:Running Cost over RSP/MSEK": 1.725,
    "out:LCP/MSEK": 0.874,
    "out:ROI% old": 15.52,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2773.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1403,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 35.75,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 26056.11,
    "out:PV (% sold (El))": 34.19,
    "out:PV (kWh self-consumed)": 50149.68,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -69857,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 435633,
    "out:DH kr savings": 521168,
    "out:El kr savings": -118756,
    "out:El kr sold": 33222,
    "out:El kr saved": 85254,
    "out:El kr return": 118476,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 11116,
    "out:% savings (space heating)": 97.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 2.01,
    "out:Etvv": 0,
    "out:Ef": 36.39,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000102120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.05,
    "out:Electricity (inc PV)": 35.59,
    "out:Total Energy Use Pre PV": 55.05,
    "out:Total Energy Use Post PV": 37.59,
    "out:Primary Energy": 64.99,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 44.27,
    "out:CO2 Operational/m2": -33.18,
    "out:Total CO2/m2": 11.09,
    "out:Total CO2 (tons)": 37.8,
    "out:Klimatpaverkan": -179.2,
    "out:Initial Cost/MSEK": 10.187875,
    "out:Running cost/(Apt SEK y)": 5598,
    "out:Running Cost over RSP/MSEK": 1.617,
    "out:LCP/MSEK": 0.897,
    "out:ROI% old": 15.54,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 392.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2721.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1417,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 26570.16,
    "out:PV (% sold (El))": 34.87,
    "out:PV (kWh self-consumed)": 49635.62,
    "out:PV (ratio sold/self-consumed)": 0.54,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -67129,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 444028,
    "out:DH kr savings": 524270,
    "out:El kr savings": -114120,
    "out:El kr sold": 33877,
    "out:El kr saved": 84381,
    "out:El kr return": 118258,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7906,
    "out:% savings (space heating)": 98.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.32,
    "out:Etvv": 0,
    "out:Ef": 35.59,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 147.9,
    "out:Primary Energy": 107.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.76,
    "out:CO2 Operational/m2": 49.62,
    "out:Total CO2/m2": 70.38,
    "out:Total CO2 (tons)": 239.92,
    "out:Klimatpaverkan": 22.92,
    "out:Initial Cost/MSEK": 5.010625,
    "out:Running cost/(Apt SEK y)": 34454,
    "out:Running Cost over RSP/MSEK": 9.968,
    "out:LCP/MSEK": -2.276,
    "out:ROI% old": 7.8,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 7,
    "out:Return/kSEK/y": 349,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 120985,
    "out:DH kr savings": 120985,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 143.9,
    "out:Primary Energy": 105.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.76,
    "out:CO2 Operational/m2": 48.18,
    "out:Total CO2/m2": 68.94,
    "out:Total CO2 (tons)": 235.01,
    "out:Klimatpaverkan": 18.01,
    "out:Initial Cost/MSEK": 5.095875,
    "out:Running cost/(Apt SEK y)": 33486,
    "out:Running Cost over RSP/MSEK": 9.687,
    "out:LCP/MSEK": -2.081,
    "out:ROI% old": 8.45,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 133394,
    "out:DH kr savings": 133394,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 140.9,
    "out:Primary Energy": 105.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.26,
    "out:CO2 Operational/m2": 47.1,
    "out:Total CO2/m2": 68.35,
    "out:Total CO2 (tons)": 233.02,
    "out:Klimatpaverkan": 16.02,
    "out:Initial Cost/MSEK": 5.2155,
    "out:Running cost/(Apt SEK y)": 32759,
    "out:Running Cost over RSP/MSEK": 9.477,
    "out:LCP/MSEK": -1.99,
    "out:ROI% old": 8.83,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142701,
    "out:DH kr savings": 142701,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 136.9,
    "out:Primary Energy": 102.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.26,
    "out:CO2 Operational/m2": 45.66,
    "out:Total CO2/m2": 66.91,
    "out:Total CO2 (tons)": 228.11,
    "out:Klimatpaverkan": 11.11,
    "out:Initial Cost/MSEK": 5.30075,
    "out:Running cost/(Apt SEK y)": 31791,
    "out:Running Cost over RSP/MSEK": 9.196,
    "out:LCP/MSEK": -1.795,
    "out:ROI% old": 9.45,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155110,
    "out:DH kr savings": 155110,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 21.25,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 142.73,
    "out:Primary Energy": 98.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 18.62,
    "out:Total CO2/m2": 51.74,
    "out:Total CO2 (tons)": 176.37,
    "out:Klimatpaverkan": -40.63,
    "out:Initial Cost/MSEK": 5.71975,
    "out:Running cost/(Apt SEK y)": 32939,
    "out:Running Cost over RSP/MSEK": 9.541,
    "out:LCP/MSEK": -2.558,
    "out:ROI% old": 7.9,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 177059,
    "out:DH kr savings": 120985,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 138.73,
    "out:Primary Energy": 95.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 17.18,
    "out:Total CO2/m2": 50.3,
    "out:Total CO2 (tons)": 171.46,
    "out:Klimatpaverkan": -45.54,
    "out:Initial Cost/MSEK": 5.805,
    "out:Running cost/(Apt SEK y)": 31970,
    "out:Running Cost over RSP/MSEK": 9.26,
    "out:LCP/MSEK": -2.363,
    "out:ROI% old": 8.47,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 441,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189468,
    "out:DH kr savings": 133394,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 135.73,
    "out:Primary Energy": 95.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.61,
    "out:CO2 Operational/m2": 16.1,
    "out:Total CO2/m2": 49.71,
    "out:Total CO2 (tons)": 169.47,
    "out:Klimatpaverkan": -47.53,
    "out:Initial Cost/MSEK": 5.924625,
    "out:Running cost/(Apt SEK y)": 31244,
    "out:Running Cost over RSP/MSEK": 9.05,
    "out:LCP/MSEK": -2.272,
    "out:ROI% old": 8.81,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198775,
    "out:DH kr savings": 142701,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 131.73,
    "out:Primary Energy": 93.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.61,
    "out:CO2 Operational/m2": 14.66,
    "out:Total CO2/m2": 48.27,
    "out:Total CO2 (tons)": 164.56,
    "out:Klimatpaverkan": -52.44,
    "out:Initial Cost/MSEK": 6.009875,
    "out:Running cost/(Apt SEK y)": 30275,
    "out:Running Cost over RSP/MSEK": 8.77,
    "out:LCP/MSEK": -2.077,
    "out:ROI% old": 9.35,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 504,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211184,
    "out:DH kr savings": 155110,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 21.25,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 147.9,
    "out:Total Energy Use Post PV": 141.88,
    "out:Primary Energy": 97.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.47,
    "out:CO2 Operational/m2": -34.06,
    "out:Total CO2/m2": 11.41,
    "out:Total CO2 (tons)": 38.9,
    "out:Klimatpaverkan": -178.1,
    "out:Initial Cost/MSEK": 6.428875,
    "out:Running cost/(Apt SEK y)": 31592,
    "out:Running Cost over RSP/MSEK": 9.162,
    "out:LCP/MSEK": -2.889,
    "out:ROI% old": 7.87,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 455,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226870,
    "out:DH kr savings": 120985,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 143.9,
    "out:Total Energy Use Post PV": 137.88,
    "out:Primary Energy": 94.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.47,
    "out:CO2 Operational/m2": -35.5,
    "out:Total CO2/m2": 9.97,
    "out:Total CO2 (tons)": 33.99,
    "out:Klimatpaverkan": -183.01,
    "out:Initial Cost/MSEK": 6.514125,
    "out:Running cost/(Apt SEK y)": 30624,
    "out:Running Cost over RSP/MSEK": 8.882,
    "out:LCP/MSEK": -2.694,
    "out:ROI% old": 8.38,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 146587,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 239279,
    "out:DH kr savings": 133394,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 140.9,
    "out:Total Energy Use Post PV": 134.88,
    "out:Primary Energy": 94.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.96,
    "out:CO2 Operational/m2": -36.58,
    "out:Total CO2/m2": 9.39,
    "out:Total CO2 (tons)": 32,
    "out:Klimatpaverkan": -185,
    "out:Initial Cost/MSEK": 6.63375,
    "out:Running cost/(Apt SEK y)": 29897,
    "out:Running Cost over RSP/MSEK": 8.671,
    "out:LCP/MSEK": -2.603,
    "out:ROI% old": 8.68,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 518,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248586,
    "out:DH kr savings": 142701,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 136.9,
    "out:Total Energy Use Post PV": 130.88,
    "out:Primary Energy": 91.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.96,
    "out:CO2 Operational/m2": -38.02,
    "out:Total CO2/m2": 7.95,
    "out:Total CO2 (tons)": 27.09,
    "out:Klimatpaverkan": -189.91,
    "out:Initial Cost/MSEK": 6.719,
    "out:Running cost/(Apt SEK y)": 28929,
    "out:Running Cost over RSP/MSEK": 8.391,
    "out:LCP/MSEK": -2.408,
    "out:ROI% old": 9.17,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260994,
    "out:DH kr savings": 155110,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 21.25,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 138.9,
    "out:Primary Energy": 104.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.76,
    "out:CO2 Operational/m2": 46.38,
    "out:Total CO2/m2": 67.14,
    "out:Total CO2 (tons)": 228.87,
    "out:Klimatpaverkan": 11.87,
    "out:Initial Cost/MSEK": 5.10825,
    "out:Running cost/(Apt SEK y)": 32275,
    "out:Running Cost over RSP/MSEK": 9.337,
    "out:LCP/MSEK": -1.743,
    "out:ROI% old": 9.41,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 8,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148905,
    "out:DH kr savings": 148905,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 134.9,
    "out:Primary Energy": 101.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.76,
    "out:CO2 Operational/m2": 44.94,
    "out:Total CO2/m2": 65.7,
    "out:Total CO2 (tons)": 223.96,
    "out:Klimatpaverkan": 6.96,
    "out:Initial Cost/MSEK": 5.1935,
    "out:Running cost/(Apt SEK y)": 31306,
    "out:Running Cost over RSP/MSEK": 9.056,
    "out:LCP/MSEK": -1.547,
    "out:ROI% old": 10.03,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 177268,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161314,
    "out:DH kr savings": 161314,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 20,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 133.9,
    "out:Primary Energy": 102.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.26,
    "out:CO2 Operational/m2": 44.58,
    "out:Total CO2/m2": 65.83,
    "out:Total CO2 (tons)": 224.43,
    "out:Klimatpaverkan": 7.43,
    "out:Initial Cost/MSEK": 5.31325,
    "out:Running cost/(Apt SEK y)": 31064,
    "out:Running Cost over RSP/MSEK": 8.986,
    "out:LCP/MSEK": -1.597,
    "out:ROI% old": 9.99,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164416,
    "out:DH kr savings": 164416,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 15.9,
    "out:Total Energy Use Pre PV": 129.9,
    "out:Total Energy Use Post PV": 129.9,
    "out:Primary Energy": 99.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.26,
    "out:CO2 Operational/m2": 43.14,
    "out:Total CO2/m2": 64.39,
    "out:Total CO2 (tons)": 219.52,
    "out:Klimatpaverkan": 2.52,
    "out:Initial Cost/MSEK": 5.398375,
    "out:Running cost/(Apt SEK y)": 30095,
    "out:Running Cost over RSP/MSEK": 8.706,
    "out:LCP/MSEK": -1.402,
    "out:ROI% old": 10.58,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1113,
    "out:Return %": 9,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 194313,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176825,
    "out:DH kr savings": 176825,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 17,
    "out:Ef": 15.9,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 133.73,
    "out:Primary Energy": 95.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 15.38,
    "out:Total CO2/m2": 48.5,
    "out:Total CO2 (tons)": 165.32,
    "out:Klimatpaverkan": -51.68,
    "out:Initial Cost/MSEK": 5.817375,
    "out:Running cost/(Apt SEK y)": 30759,
    "out:Running Cost over RSP/MSEK": 8.91,
    "out:LCP/MSEK": -2.025,
    "out:ROI% old": 9.31,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204979,
    "out:DH kr savings": 148905,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 129.73,
    "out:Primary Energy": 92.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 13.94,
    "out:Total CO2/m2": 47.06,
    "out:Total CO2 (tons)": 160.41,
    "out:Klimatpaverkan": -56.59,
    "out:Initial Cost/MSEK": 5.902625,
    "out:Running cost/(Apt SEK y)": 29791,
    "out:Running Cost over RSP/MSEK": 8.629,
    "out:LCP/MSEK": -1.83,
    "out:ROI% old": 9.86,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 522,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177268,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217388,
    "out:DH kr savings": 161314,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 20,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 128.73,
    "out:Primary Energy": 92.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.61,
    "out:CO2 Operational/m2": 13.58,
    "out:Total CO2/m2": 47.19,
    "out:Total CO2 (tons)": 160.88,
    "out:Klimatpaverkan": -56.12,
    "out:Initial Cost/MSEK": 6.022375,
    "out:Running cost/(Apt SEK y)": 29549,
    "out:Running Cost over RSP/MSEK": 8.559,
    "out:LCP/MSEK": -1.879,
    "out:ROI% old": 9.83,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 531,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220490,
    "out:DH kr savings": 164416,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 10.73,
    "out:Total Energy Use Pre PV": 129.9,
    "out:Total Energy Use Post PV": 124.73,
    "out:Primary Energy": 90.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.61,
    "out:CO2 Operational/m2": 12.14,
    "out:Total CO2/m2": 45.75,
    "out:Total CO2 (tons)": 155.97,
    "out:Klimatpaverkan": -61.03,
    "out:Initial Cost/MSEK": 6.1075,
    "out:Running cost/(Apt SEK y)": 28580,
    "out:Running Cost over RSP/MSEK": 8.279,
    "out:LCP/MSEK": -1.684,
    "out:ROI% old": 10.35,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 758.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 567,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 20472.6,
    "out:PV (% sold (El))": 53.73,
    "out:PV (kWh self-consumed)": 17630.3,
    "out:PV (ratio sold/self-consumed)": 1.16,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 194313,
    "out:EL kWh savings": 17630,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232899,
    "out:DH kr savings": 176825,
    "out:El kr savings": 29972,
    "out:El kr sold": 26103,
    "out:El kr saved": 29972,
    "out:El kr return": 56074,
    "out:% solar/total": 33,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 17,
    "out:Ef": 10.73,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 138.9,
    "out:Total Energy Use Post PV": 132.88,
    "out:Primary Energy": 93.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.47,
    "out:CO2 Operational/m2": -37.3,
    "out:Total CO2/m2": 8.17,
    "out:Total CO2 (tons)": 27.85,
    "out:Klimatpaverkan": -189.15,
    "out:Initial Cost/MSEK": 6.5265,
    "out:Running cost/(Apt SEK y)": 29413,
    "out:Running Cost over RSP/MSEK": 8.531,
    "out:LCP/MSEK": -2.355,
    "out:ROI% old": 9.13,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254790,
    "out:DH kr savings": 148905,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 134.9,
    "out:Total Energy Use Post PV": 128.88,
    "out:Primary Energy": 90.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.47,
    "out:CO2 Operational/m2": -38.74,
    "out:Total CO2/m2": 6.73,
    "out:Total CO2 (tons)": 22.94,
    "out:Klimatpaverkan": -194.06,
    "out:Initial Cost/MSEK": 6.61175,
    "out:Running cost/(Apt SEK y)": 28444,
    "out:Running Cost over RSP/MSEK": 8.251,
    "out:LCP/MSEK": -2.16,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 572,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177268,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 267199,
    "out:DH kr savings": 161314,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 20,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.24,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 133.9,
    "out:Total Energy Use Post PV": 127.88,
    "out:Primary Energy": 91.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.96,
    "out:CO2 Operational/m2": -39.1,
    "out:Total CO2/m2": 6.87,
    "out:Total CO2 (tons)": 23.41,
    "out:Klimatpaverkan": -193.59,
    "out:Initial Cost/MSEK": 6.7315,
    "out:Running cost/(Apt SEK y)": 28202,
    "out:Running Cost over RSP/MSEK": 8.181,
    "out:LCP/MSEK": -2.21,
    "out:ROI% old": 9.6,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 581,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270301,
    "out:DH kr savings": 164416,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 300824,
    "out:% savings (space heating)": 30.81,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.24,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000200120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 15.9,
    "out:Electricity (inc PV)": 9.88,
    "out:Total Energy Use Pre PV": 129.9,
    "out:Total Energy Use Post PV": 123.88,
    "out:Primary Energy": 88.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.96,
    "out:CO2 Operational/m2": -40.54,
    "out:Total CO2/m2": 5.43,
    "out:Total CO2 (tons)": 18.5,
    "out:Klimatpaverkan": -198.5,
    "out:Initial Cost/MSEK": 6.816625,
    "out:Running cost/(Apt SEK y)": 27234,
    "out:Running Cost over RSP/MSEK": 7.9,
    "out:LCP/MSEK": -2.014,
    "out:ROI% old": 10.06,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "F",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 696.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 617,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 55682.1,
    "out:PV (% sold (El))": 73.07,
    "out:PV (kWh self-consumed)": 20523.68,
    "out:PV (ratio sold/self-consumed)": 2.71,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 194313,
    "out:EL kWh savings": 20524,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282710,
    "out:DH kr savings": 176825,
    "out:El kr savings": 34890,
    "out:El kr sold": 70995,
    "out:El kr saved": 34890,
    "out:El kr return": 105885,
    "out:% solar/total": 38,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 288080,
    "out:% savings (space heating)": 33.74,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 0,
    "out:Eupp": 84.51,
    "out:Etvv": 17,
    "out:Ef": 9.88,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0
  },
  {
    "ID": "Z02000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.12,
    "out:Electricity (inc PV)": 40.9,
    "out:Total Energy Use Pre PV": 92.12,
    "out:Total Energy Use Post PV": 89.9,
    "out:Primary Energy": 100.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.27,
    "out:CO2 Operational/m2": 23.33,
    "out:Total CO2/m2": 47.6,
    "out:Total CO2 (tons)": 162.26,
    "out:Klimatpaverkan": -54.74,
    "out:Initial Cost/MSEK": 7.856625,
    "out:Running cost/(Apt SEK y)": 18620,
    "out:Running Cost over RSP/MSEK": 5.368,
    "out:LCP/MSEK": -0.522,
    "out:ROI% old": 13.34,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 107.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 3018.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 935,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -85218,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233596,
    "out:DH kr savings": 378467,
    "out:El kr savings": -144871,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 168741,
    "out:% savings (space heating)": 61.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.34,
    "out:Etvv": 0,
    "out:Ef": 40.9,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.05,
    "out:Electricity (inc PV)": 40.78,
    "out:Total Energy Use Pre PV": 89.05,
    "out:Total Energy Use Post PV": 86.78,
    "out:Primary Energy": 97.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.27,
    "out:CO2 Operational/m2": 22.24,
    "out:Total CO2/m2": 46.51,
    "out:Total CO2 (tons)": 158.55,
    "out:Klimatpaverkan": -58.45,
    "out:Initial Cost/MSEK": 7.941875,
    "out:Running cost/(Apt SEK y)": 17881,
    "out:Running Cost over RSP/MSEK": 5.154,
    "out:LCP/MSEK": -0.394,
    "out:ROI% old": 13.58,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 114.9,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 3013.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 963,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -84800,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243614,
    "out:DH kr savings": 387774,
    "out:El kr savings": -144160,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 156866,
    "out:% savings (space heating)": 63.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.01,
    "out:Etvv": 0,
    "out:Ef": 40.78,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.38,
    "out:Electricity (inc PV)": 40.41,
    "out:Total Energy Use Pre PV": 87.38,
    "out:Total Energy Use Post PV": 85.41,
    "out:Primary Energy": 98.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.76,
    "out:CO2 Operational/m2": 21.79,
    "out:Total CO2/m2": 46.56,
    "out:Total CO2 (tons)": 158.71,
    "out:Klimatpaverkan": -58.29,
    "out:Initial Cost/MSEK": 8.061625,
    "out:Running cost/(Apt SEK y)": 17534,
    "out:Running Cost over RSP/MSEK": 5.054,
    "out:LCP/MSEK": -0.413,
    "out:ROI% old": 13.55,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 120,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2966.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 975,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 429534,
    "out:EL kWh savings": -83544,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248852,
    "out:DH kr savings": 390876,
    "out:El kr savings": -142024,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 154877,
    "out:% savings (space heating)": 64.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.21,
    "out:Etvv": 0,
    "out:Ef": 40.41,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.29,
    "out:Electricity (inc PV)": 40.27,
    "out:Total Energy Use Pre PV": 84.29,
    "out:Total Energy Use Post PV": 82.27,
    "out:Primary Energy": 95.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.76,
    "out:CO2 Operational/m2": 20.7,
    "out:Total CO2/m2": 45.46,
    "out:Total CO2 (tons)": 154.99,
    "out:Klimatpaverkan": -62.01,
    "out:Initial Cost/MSEK": 8.14675,
    "out:Running cost/(Apt SEK y)": 16793,
    "out:Running Cost over RSP/MSEK": 4.839,
    "out:LCP/MSEK": -0.284,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 128,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2960,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 439761,
    "out:EL kWh savings": -83081,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258945,
    "out:DH kr savings": 400183,
    "out:El kr savings": -141238,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 143172,
    "out:% savings (space heating)": 67.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 32.92,
    "out:Etvv": 0,
    "out:Ef": 40.27,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.12,
    "out:Electricity (inc PV)": 31.62,
    "out:Total Energy Use Pre PV": 92.12,
    "out:Total Energy Use Post PV": 80.62,
    "out:Primary Energy": 83.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.62,
    "out:CO2 Operational/m2": 13.96,
    "out:Total CO2/m2": 50.58,
    "out:Total CO2 (tons)": 172.44,
    "out:Klimatpaverkan": -44.56,
    "out:Initial Cost/MSEK": 8.56575,
    "out:Running cost/(Apt SEK y)": 16934,
    "out:Running Cost over RSP/MSEK": 4.893,
    "out:LCP/MSEK": -0.757,
    "out:ROI% old": 13.02,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2377.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5671.86,
    "out:PV (% sold (El))": 14.89,
    "out:PV (kWh self-consumed)": 32431.03,
    "out:PV (ratio sold/self-consumed)": 0.17,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -53581,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 294612,
    "out:DH kr savings": 378467,
    "out:El kr savings": -91087,
    "out:El kr sold": 7232,
    "out:El kr saved": 55133,
    "out:El kr return": 62364,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 168741,
    "out:% savings (space heating)": 61.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.34,
    "out:Etvv": 0,
    "out:Ef": 31.62,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.05,
    "out:Electricity (inc PV)": 31.51,
    "out:Total Energy Use Pre PV": 89.05,
    "out:Total Energy Use Post PV": 77.51,
    "out:Primary Energy": 81.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.62,
    "out:CO2 Operational/m2": 12.83,
    "out:Total CO2/m2": 49.45,
    "out:Total CO2 (tons)": 168.59,
    "out:Klimatpaverkan": -48.41,
    "out:Initial Cost/MSEK": 8.651,
    "out:Running cost/(Apt SEK y)": 16196,
    "out:Running Cost over RSP/MSEK": 4.679,
    "out:LCP/MSEK": -0.628,
    "out:ROI% old": 13.25,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2372.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1025,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5698.5,
    "out:PV (% sold (El))": 14.96,
    "out:PV (kWh self-consumed)": 32404.4,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -53208,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304586,
    "out:DH kr savings": 387774,
    "out:El kr savings": -90453,
    "out:El kr sold": 7266,
    "out:El kr saved": 55087,
    "out:El kr return": 62353,
    "out:% solar/total": 60,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 156866,
    "out:% savings (space heating)": 63.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.01,
    "out:Etvv": 0,
    "out:Ef": 31.51,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.38,
    "out:Electricity (inc PV)": 31.18,
    "out:Total Energy Use Pre PV": 87.38,
    "out:Total Energy Use Post PV": 76.18,
    "out:Primary Energy": 81.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.12,
    "out:CO2 Operational/m2": 12.05,
    "out:Total CO2/m2": 49.16,
    "out:Total CO2 (tons)": 167.6,
    "out:Klimatpaverkan": -49.4,
    "out:Initial Cost/MSEK": 8.770625,
    "out:Running cost/(Apt SEK y)": 15852,
    "out:Running Cost over RSP/MSEK": 4.58,
    "out:LCP/MSEK": -0.648,
    "out:ROI% old": 13.23,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 146.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2330.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1038,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5931.11,
    "out:PV (% sold (El))": 15.57,
    "out:PV (kWh self-consumed)": 32171.78,
    "out:PV (ratio sold/self-consumed)": 0.18,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 429534,
    "out:EL kWh savings": -52090,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309885,
    "out:DH kr savings": 390876,
    "out:El kr savings": -88553,
    "out:El kr sold": 7562,
    "out:El kr saved": 54692,
    "out:El kr return": 62254,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 154877,
    "out:% savings (space heating)": 64.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.21,
    "out:Etvv": 0,
    "out:Ef": 31.18,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.29,
    "out:Electricity (inc PV)": 31.06,
    "out:Total Energy Use Pre PV": 84.29,
    "out:Total Energy Use Post PV": 73.06,
    "out:Primary Energy": 78.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.12,
    "out:CO2 Operational/m2": 10.91,
    "out:Total CO2/m2": 48.03,
    "out:Total CO2 (tons)": 163.72,
    "out:Klimatpaverkan": -53.28,
    "out:Initial Cost/MSEK": 8.855875,
    "out:Running cost/(Apt SEK y)": 15111,
    "out:Running Cost over RSP/MSEK": 4.366,
    "out:LCP/MSEK": -0.519,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2324.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1065,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 5963.71,
    "out:PV (% sold (El))": 15.65,
    "out:PV (kWh self-consumed)": 32139.18,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 439761,
    "out:EL kWh savings": -51679,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 319932,
    "out:DH kr savings": 400183,
    "out:El kr savings": -87854,
    "out:El kr sold": 7604,
    "out:El kr saved": 54637,
    "out:El kr return": 62240,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 143172,
    "out:% savings (space heating)": 67.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 32.92,
    "out:Etvv": 0,
    "out:Ef": 31.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.12,
    "out:Electricity (inc PV)": 28.58,
    "out:Total Energy Use Pre PV": 92.12,
    "out:Total Energy Use Post PV": 77.58,
    "out:Primary Energy": 78.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.97,
    "out:CO2 Operational/m2": -26.6,
    "out:Total CO2/m2": 22.37,
    "out:Total CO2 (tons)": 76.27,
    "out:Klimatpaverkan": -140.73,
    "out:Initial Cost/MSEK": 9.274875,
    "out:Running cost/(Apt SEK y)": 15494,
    "out:Running Cost over RSP/MSEK": 4.487,
    "out:LCP/MSEK": -1.06,
    "out:ROI% old": 12.65,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 49,
    "out:El bought/kWh/m2": 2146.8,
    "out:Return %": 11,
    "out:Return/kSEK/y": 1051,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32725.72,
    "out:PV (% sold (El))": 42.94,
    "out:PV (kWh self-consumed)": 43480.07,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415898,
    "out:EL kWh savings": -43236,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346692,
    "out:DH kr savings": 378467,
    "out:El kr savings": -73501,
    "out:El kr sold": 41725,
    "out:El kr saved": 73916,
    "out:El kr return": 115641,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 168741,
    "out:% savings (space heating)": 61.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 38.34,
    "out:Etvv": 0,
    "out:Ef": 28.58,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 43.05,
    "out:Electricity (inc PV)": 28.48,
    "out:Total Energy Use Pre PV": 89.05,
    "out:Total Energy Use Post PV": 74.48,
    "out:Primary Energy": 75.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.97,
    "out:CO2 Operational/m2": -27.76,
    "out:Total CO2/m2": 21.21,
    "out:Total CO2 (tons)": 72.3,
    "out:Klimatpaverkan": -144.7,
    "out:Initial Cost/MSEK": 9.360125,
    "out:Running cost/(Apt SEK y)": 14756,
    "out:Running Cost over RSP/MSEK": 4.274,
    "out:LCP/MSEK": -0.932,
    "out:ROI% old": 12.86,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 152.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2142.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1078,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 24.85,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 32777.04,
    "out:PV (% sold (El))": 43.01,
    "out:PV (kWh self-consumed)": 43428.74,
    "out:PV (ratio sold/self-consumed)": 0.75,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 426125,
    "out:EL kWh savings": -42901,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 356633,
    "out:DH kr savings": 387774,
    "out:El kr savings": -72932,
    "out:El kr sold": 41791,
    "out:El kr saved": 73829,
    "out:El kr return": 115620,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 156866,
    "out:% savings (space heating)": 63.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.01,
    "out:Etvv": 0,
    "out:Ef": 28.48,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.38,
    "out:Electricity (inc PV)": 28.19,
    "out:Total Energy Use Pre PV": 87.38,
    "out:Total Energy Use Post PV": 73.19,
    "out:Primary Energy": 76.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.47,
    "out:CO2 Operational/m2": -28.87,
    "out:Total CO2/m2": 20.6,
    "out:Total CO2 (tons)": 70.23,
    "out:Klimatpaverkan": -146.77,
    "out:Initial Cost/MSEK": 9.479875,
    "out:Running cost/(Apt SEK y)": 14415,
    "out:Running Cost over RSP/MSEK": 4.175,
    "out:LCP/MSEK": -0.953,
    "out:ROI% old": 12.85,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2104.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.18,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33222.59,
    "out:PV (% sold (El))": 43.6,
    "out:PV (kWh self-consumed)": 42983.19,
    "out:PV (ratio sold/self-consumed)": 0.77,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 429534,
    "out:EL kWh savings": -41898,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 362009,
    "out:DH kr savings": 390876,
    "out:El kr savings": -71226,
    "out:El kr sold": 42359,
    "out:El kr saved": 73071,
    "out:El kr return": 115430,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 154877,
    "out:% savings (space heating)": 64.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 36.21,
    "out:Etvv": 0,
    "out:Ef": 28.19,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.29,
    "out:Electricity (inc PV)": 28.08,
    "out:Total Energy Use Pre PV": 84.29,
    "out:Total Energy Use Post PV": 70.08,
    "out:Primary Energy": 73.59,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.47,
    "out:CO2 Operational/m2": -30.05,
    "out:Total CO2/m2": 19.42,
    "out:Total CO2 (tons)": 66.2,
    "out:Klimatpaverkan": -150.8,
    "out:Initial Cost/MSEK": 9.565,
    "out:Running cost/(Apt SEK y)": 13674,
    "out:Running Cost over RSP/MSEK": 3.961,
    "out:LCP/MSEK": -0.824,
    "out:ROI% old": 13.06,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 167.1,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 42,
    "out:El bought/kWh/m2": 2099.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1118,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 24.09,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33284.49,
    "out:PV (% sold (El))": 43.68,
    "out:PV (kWh self-consumed)": 42921.29,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 439761,
    "out:EL kWh savings": -41528,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 372022,
    "out:DH kr savings": 400183,
    "out:El kr savings": -70598,
    "out:El kr sold": 42438,
    "out:El kr saved": 72966,
    "out:El kr return": 115404,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 143172,
    "out:% savings (space heating)": 67.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 32.92,
    "out:Etvv": 0,
    "out:Ef": 28.08,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.12,
    "out:Electricity (inc PV)": 40.24,
    "out:Total Energy Use Pre PV": 86.12,
    "out:Total Energy Use Post PV": 84.24,
    "out:Primary Energy": 97.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.27,
    "out:CO2 Operational/m2": 21.4,
    "out:Total CO2/m2": 45.67,
    "out:Total CO2 (tons)": 155.67,
    "out:Klimatpaverkan": -61.33,
    "out:Initial Cost/MSEK": 7.954375,
    "out:Running cost/(Apt SEK y)": 17252,
    "out:Running Cost over RSP/MSEK": 4.972,
    "out:LCP/MSEK": -0.224,
    "out:ROI% old": 13.88,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 122.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2948.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 986,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -82974,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 252923,
    "out:DH kr savings": 393978,
    "out:El kr savings": -141055,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 150369,
    "out:% savings (space heating)": 65.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.51,
    "out:Etvv": 0,
    "out:Ef": 40.24,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.02,
    "out:Electricity (inc PV)": 40.1,
    "out:Total Energy Use Pre PV": 83.02,
    "out:Total Energy Use Post PV": 81.1,
    "out:Primary Energy": 94.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.27,
    "out:CO2 Operational/m2": 20.31,
    "out:Total CO2/m2": 44.57,
    "out:Total CO2 (tons)": 151.95,
    "out:Klimatpaverkan": -65.05,
    "out:Initial Cost/MSEK": 8.0395,
    "out:Running cost/(Apt SEK y)": 16510,
    "out:Running Cost over RSP/MSEK": 4.757,
    "out:LCP/MSEK": -0.095,
    "out:ROI% old": 14.12,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2941.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -82496,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 263041,
    "out:DH kr savings": 403285,
    "out:El kr savings": -140243,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 138720,
    "out:% savings (space heating)": 68.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 32.24,
    "out:Etvv": 0,
    "out:Ef": 40.1,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 39.83,
    "out:Total Energy Use Pre PV": 82.48,
    "out:Total Energy Use Post PV": 80.83,
    "out:Primary Energy": 95.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.76,
    "out:CO2 Operational/m2": 20.24,
    "out:Total CO2/m2": 45,
    "out:Total CO2 (tons)": 153.4,
    "out:Klimatpaverkan": -63.6,
    "out:Initial Cost/MSEK": 8.15925,
    "out:Running cost/(Apt SEK y)": 16425,
    "out:Running Cost over RSP/MSEK": 4.733,
    "out:LCP/MSEK": -0.19,
    "out:ROI% old": 13.95,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 130.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2903.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1016,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -81579,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264600,
    "out:DH kr savings": 403285,
    "out:El kr savings": -138684,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 139752,
    "out:% savings (space heating)": 67.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 33.86,
    "out:Etvv": 0,
    "out:Ef": 39.83,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.37,
    "out:Electricity (inc PV)": 39.68,
    "out:Total Energy Use Pre PV": 79.37,
    "out:Total Energy Use Post PV": 77.68,
    "out:Primary Energy": 92.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.76,
    "out:CO2 Operational/m2": 19.14,
    "out:Total CO2/m2": 43.9,
    "out:Total CO2 (tons)": 149.67,
    "out:Klimatpaverkan": -67.33,
    "out:Initial Cost/MSEK": 8.2445,
    "out:Running cost/(Apt SEK y)": 15681,
    "out:Running Cost over RSP/MSEK": 4.518,
    "out:LCP/MSEK": -0.06,
    "out:ROI% old": 14.18,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 139.7,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2895.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1044,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 453397,
    "out:EL kWh savings": -81071,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 274771,
    "out:DH kr savings": 412591,
    "out:El kr savings": -137821,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 128255,
    "out:% savings (space heating)": 70.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 30.62,
    "out:Etvv": 0,
    "out:Ef": 39.68,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.12,
    "out:Electricity (inc PV)": 31.03,
    "out:Total Energy Use Pre PV": 86.12,
    "out:Total Energy Use Post PV": 75.03,
    "out:Primary Energy": 80.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.62,
    "out:CO2 Operational/m2": 11.52,
    "out:Total CO2/m2": 48.14,
    "out:Total CO2 (tons)": 164.11,
    "out:Klimatpaverkan": -52.89,
    "out:Initial Cost/MSEK": 8.663375,
    "out:Running cost/(Apt SEK y)": 15570,
    "out:Running Cost over RSP/MSEK": 4.499,
    "out:LCP/MSEK": -0.46,
    "out:ROI% old": 13.53,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 149.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2313.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1048,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6022.48,
    "out:PV (% sold (El))": 15.81,
    "out:PV (kWh self-consumed)": 32080.41,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -51583,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 313965,
    "out:DH kr savings": 393978,
    "out:El kr savings": -87692,
    "out:El kr sold": 7679,
    "out:El kr saved": 54537,
    "out:El kr return": 62215,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 150369,
    "out:% savings (space heating)": 65.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.51,
    "out:Etvv": 0,
    "out:Ef": 31.03,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.02,
    "out:Electricity (inc PV)": 30.91,
    "out:Total Energy Use Pre PV": 83.02,
    "out:Total Energy Use Post PV": 71.91,
    "out:Primary Energy": 78.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.62,
    "out:CO2 Operational/m2": 10.38,
    "out:Total CO2/m2": 47,
    "out:Total CO2 (tons)": 160.22,
    "out:Klimatpaverkan": -56.78,
    "out:Initial Cost/MSEK": 8.748625,
    "out:Running cost/(Apt SEK y)": 14829,
    "out:Running Cost over RSP/MSEK": 4.284,
    "out:LCP/MSEK": -0.33,
    "out:ROI% old": 13.75,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2307.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1076,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6057.05,
    "out:PV (% sold (El))": 15.9,
    "out:PV (kWh self-consumed)": 32045.84,
    "out:PV (ratio sold/self-consumed)": 0.19,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -51159,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324037,
    "out:DH kr savings": 403285,
    "out:El kr savings": -86971,
    "out:El kr sold": 7723,
    "out:El kr saved": 54478,
    "out:El kr return": 62201,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 138720,
    "out:% savings (space heating)": 68.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 32.24,
    "out:Etvv": 0,
    "out:Ef": 30.91,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 30.67,
    "out:Total Energy Use Pre PV": 82.48,
    "out:Total Energy Use Post PV": 71.67,
    "out:Primary Energy": 78.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.12,
    "out:CO2 Operational/m2": 10.03,
    "out:Total CO2/m2": 47.14,
    "out:Total CO2 (tons)": 160.71,
    "out:Klimatpaverkan": -56.29,
    "out:Initial Cost/MSEK": 8.868375,
    "out:Running cost/(Apt SEK y)": 14746,
    "out:Running Cost over RSP/MSEK": 4.26,
    "out:LCP/MSEK": -0.427,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2273.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1079,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6251.33,
    "out:PV (% sold (El))": 16.41,
    "out:PV (kWh self-consumed)": 31851.56,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -50346,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325667,
    "out:DH kr savings": 403285,
    "out:El kr savings": -85588,
    "out:El kr sold": 7970,
    "out:El kr saved": 54148,
    "out:El kr return": 62118,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 139752,
    "out:% savings (space heating)": 67.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 33.86,
    "out:Etvv": 0,
    "out:Ef": 30.67,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.37,
    "out:Electricity (inc PV)": 30.54,
    "out:Total Energy Use Pre PV": 79.37,
    "out:Total Energy Use Post PV": 68.54,
    "out:Primary Energy": 76.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.12,
    "out:CO2 Operational/m2": 8.87,
    "out:Total CO2/m2": 45.99,
    "out:Total CO2 (tons)": 156.78,
    "out:Klimatpaverkan": -60.22,
    "out:Initial Cost/MSEK": 8.953625,
    "out:Running cost/(Apt SEK y)": 14003,
    "out:Running Cost over RSP/MSEK": 4.045,
    "out:LCP/MSEK": -0.297,
    "out:ROI% old": 13.81,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 171,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2266.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1106,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 6291.7,
    "out:PV (% sold (El))": 16.51,
    "out:PV (kWh self-consumed)": 31811.2,
    "out:PV (ratio sold/self-consumed)": 0.2,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 453397,
    "out:EL kWh savings": -49895,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 335791,
    "out:DH kr savings": 412591,
    "out:El kr savings": -84822,
    "out:El kr sold": 8022,
    "out:El kr saved": 54079,
    "out:El kr return": 62101,
    "out:% solar/total": 59,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 128255,
    "out:% savings (space heating)": 70.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 30.62,
    "out:Etvv": 0,
    "out:Ef": 30.54,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.11,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.12,
    "out:Electricity (inc PV)": 28.06,
    "out:Total Energy Use Pre PV": 86.12,
    "out:Total Energy Use Post PV": 72.06,
    "out:Primary Energy": 75.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.97,
    "out:CO2 Operational/m2": -29.52,
    "out:Total CO2/m2": 19.46,
    "out:Total CO2 (tons)": 66.33,
    "out:Klimatpaverkan": -150.67,
    "out:Initial Cost/MSEK": 9.3725,
    "out:Running cost/(Apt SEK y)": 14134,
    "out:Running Cost over RSP/MSEK": 4.094,
    "out:LCP/MSEK": -0.764,
    "out:ROI% old": 13.12,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 159.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2089.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33395.89,
    "out:PV (% sold (El))": 43.82,
    "out:PV (kWh self-consumed)": 42809.9,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 432943,
    "out:EL kWh savings": -41442,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 366106,
    "out:DH kr savings": 393978,
    "out:El kr savings": -70452,
    "out:El kr sold": 42580,
    "out:El kr saved": 72777,
    "out:El kr return": 115357,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 150369,
    "out:% savings (space heating)": 65.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 35.51,
    "out:Etvv": 0,
    "out:Ef": 28.06,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.02,
    "out:Electricity (inc PV)": 27.95,
    "out:Total Energy Use Pre PV": 83.02,
    "out:Total Energy Use Post PV": 68.95,
    "out:Primary Energy": 72.87,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.97,
    "out:CO2 Operational/m2": -30.7,
    "out:Total CO2/m2": 18.27,
    "out:Total CO2 (tons)": 62.28,
    "out:Klimatpaverkan": -154.72,
    "out:Initial Cost/MSEK": 9.45775,
    "out:Running cost/(Apt SEK y)": 13393,
    "out:Running Cost over RSP/MSEK": 3.88,
    "out:LCP/MSEK": -0.635,
    "out:ROI% old": 13.33,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 171,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2084.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1129,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 23.82,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33461.34,
    "out:PV (% sold (El))": 43.91,
    "out:PV (kWh self-consumed)": 42744.44,
    "out:PV (ratio sold/self-consumed)": 0.78,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -41062,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376143,
    "out:DH kr savings": 403285,
    "out:El kr savings": -69805,
    "out:El kr sold": 42663,
    "out:El kr saved": 72666,
    "out:El kr return": 115329,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 138720,
    "out:% savings (space heating)": 68.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 32.24,
    "out:Etvv": 0,
    "out:Ef": 27.95,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.48,
    "out:Electricity (inc PV)": 27.73,
    "out:Total Energy Use Pre PV": 82.48,
    "out:Total Energy Use Post PV": 68.73,
    "out:Primary Energy": 73.62,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.47,
    "out:CO2 Operational/m2": -31.31,
    "out:Total CO2/m2": 18.16,
    "out:Total CO2 (tons)": 61.9,
    "out:Klimatpaverkan": -155.1,
    "out:Initial Cost/MSEK": 9.5775,
    "out:Running cost/(Apt SEK y)": 13312,
    "out:Running Cost over RSP/MSEK": 3.857,
    "out:LCP/MSEK": -0.732,
    "out:ROI% old": 13.19,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 171,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2053.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1132,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.28,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33826.54,
    "out:PV (% sold (El))": 44.39,
    "out:PV (kWh self-consumed)": 42379.25,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 443170,
    "out:EL kWh savings": -40331,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 377852,
    "out:DH kr savings": 403285,
    "out:El kr savings": -68562,
    "out:El kr sold": 43129,
    "out:El kr saved": 72045,
    "out:El kr return": 115174,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 139752,
    "out:% savings (space heating)": 67.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 33.86,
    "out:Etvv": 0,
    "out:Ef": 27.73,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000201120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.37,
    "out:Electricity (inc PV)": 27.61,
    "out:Total Energy Use Pre PV": 79.37,
    "out:Total Energy Use Post PV": 65.61,
    "out:Primary Energy": 71.13,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.47,
    "out:CO2 Operational/m2": -32.52,
    "out:Total CO2/m2": 16.95,
    "out:Total CO2 (tons)": 57.79,
    "out:Klimatpaverkan": -159.21,
    "out:Initial Cost/MSEK": 9.66275,
    "out:Running cost/(Apt SEK y)": 12570,
    "out:Running Cost over RSP/MSEK": 3.641,
    "out:LCP/MSEK": -0.602,
    "out:ROI% old": 13.4,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 183.3,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 38,
    "out:El bought/kWh/m2": 2047.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1159,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 887400,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 44370,
    "out:EAHP (replacement cost)": 665852.650279,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 23.17,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 33901.64,
    "out:PV (% sold (El))": 44.49,
    "out:PV (kWh self-consumed)": 42304.14,
    "out:PV (ratio sold/self-consumed)": 0.8,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 453397,
    "out:EL kWh savings": -39926,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 387942,
    "out:DH kr savings": 412591,
    "out:El kr savings": -67874,
    "out:El kr sold": 43225,
    "out:El kr saved": 71917,
    "out:El kr return": 115142,
    "out:% solar/total": 78,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 128255,
    "out:% savings (space heating)": 70.5,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 30.62,
    "out:Etvv": 0,
    "out:Ef": 27.61,
    "out:heatPumpQuantity": 6,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.88,
    "out:Electricity (inc PV)": 51.91,
    "out:Total Energy Use Pre PV": 60.88,
    "out:Total Energy Use Post PV": 55.91,
    "out:Primary Energy": 94.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.86,
    "out:CO2 Operational/m2": 8.95,
    "out:Total CO2/m2": 33.81,
    "out:Total CO2 (tons)": 115.25,
    "out:Klimatpaverkan": -101.75,
    "out:Initial Cost/MSEK": 9.712875,
    "out:Running cost/(Apt SEK y)": 9878,
    "out:Running Cost over RSP/MSEK": 2.829,
    "out:LCP/MSEK": 0.16,
    "out:ROI% old": 14.52,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 233.9,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3981.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1259,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -122769,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309359,
    "out:DH kr savings": 518066,
    "out:El kr savings": -208706,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13928,
    "out:% savings (space heating)": 96.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.99,
    "out:Etvv": 0,
    "out:Ef": 51.91,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.09,
    "out:Electricity (inc PV)": 50.98,
    "out:Total Energy Use Pre PV": 59.09,
    "out:Total Energy Use Post PV": 53.98,
    "out:Primary Energy": 92.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.86,
    "out:CO2 Operational/m2": 8.48,
    "out:Total CO2/m2": 33.34,
    "out:Total CO2 (tons)": 113.66,
    "out:Klimatpaverkan": -103.34,
    "out:Initial Cost/MSEK": 9.798125,
    "out:Running cost/(Apt SEK y)": 9511,
    "out:Running Cost over RSP/MSEK": 2.723,
    "out:LCP/MSEK": 0.181,
    "out:ROI% old": 14.55,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3926.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1272,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 37.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -119600,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317849,
    "out:DH kr savings": 521168,
    "out:El kr savings": -203319,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10154,
    "out:% savings (space heating)": 97.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.32,
    "out:Etvv": 0,
    "out:Ef": 50.98,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.17,
    "out:Electricity (inc PV)": 50.87,
    "out:Total Energy Use Pre PV": 58.17,
    "out:Total Energy Use Post PV": 53.87,
    "out:Primary Energy": 92.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.36,
    "out:CO2 Operational/m2": 8.36,
    "out:Total CO2/m2": 33.72,
    "out:Total CO2 (tons)": 114.95,
    "out:Klimatpaverkan": -102.05,
    "out:Initial Cost/MSEK": 9.917875,
    "out:Running cost/(Apt SEK y)": 9368,
    "out:Running Cost over RSP/MSEK": 2.682,
    "out:LCP/MSEK": 0.102,
    "out:ROI% old": 14.43,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3862.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1278,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -119204,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318521,
    "out:DH kr savings": 521168,
    "out:El kr savings": -202647,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10909,
    "out:% savings (space heating)": 97.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.69,
    "out:Etvv": 0,
    "out:Ef": 50.87,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.32,
    "out:Electricity (inc PV)": 49.92,
    "out:Total Energy Use Pre PV": 56.32,
    "out:Total Energy Use Post PV": 51.92,
    "out:Primary Energy": 90.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.36,
    "out:CO2 Operational/m2": 7.89,
    "out:Total CO2/m2": 33.25,
    "out:Total CO2 (tons)": 113.33,
    "out:Klimatpaverkan": -103.67,
    "out:Initial Cost/MSEK": 10.003,
    "out:Running cost/(Apt SEK y)": 8992,
    "out:Running Cost over RSP/MSEK": 2.574,
    "out:LCP/MSEK": 0.125,
    "out:ROI% old": 14.46,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3802.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1292,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -115964,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327132,
    "out:DH kr savings": 524270,
    "out:El kr savings": -197138,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7790,
    "out:% savings (space heating)": 98.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.09,
    "out:Etvv": 0,
    "out:Ef": 49.92,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.88,
    "out:Electricity (inc PV)": 41.66,
    "out:Total Energy Use Pre PV": 60.88,
    "out:Total Energy Use Post PV": 45.66,
    "out:Primary Energy": 76.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.21,
    "out:CO2 Operational/m2": 4.69,
    "out:Total CO2/m2": 41.9,
    "out:Total CO2 (tons)": 142.85,
    "out:Klimatpaverkan": -74.15,
    "out:Initial Cost/MSEK": 10.422,
    "out:Running cost/(Apt SEK y)": 8152,
    "out:Running Cost over RSP/MSEK": 2.343,
    "out:LCP/MSEK": -0.063,
    "out:ROI% old": 14.2,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 306.5,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3278.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1323,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2092.01,
    "out:PV (% sold (El))": 5.49,
    "out:PV (kWh self-consumed)": 36010.88,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -87806,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 371464,
    "out:DH kr savings": 518066,
    "out:El kr savings": -149269,
    "out:El kr sold": 2667,
    "out:El kr saved": 61219,
    "out:El kr return": 63886,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13928,
    "out:% savings (space heating)": 96.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.99,
    "out:Etvv": 0,
    "out:Ef": 41.66,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.09,
    "out:Electricity (inc PV)": 40.79,
    "out:Total Energy Use Pre PV": 59.09,
    "out:Total Energy Use Post PV": 43.79,
    "out:Primary Energy": 74.35,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.21,
    "out:CO2 Operational/m2": 4.01,
    "out:Total CO2/m2": 41.23,
    "out:Total CO2 (tons)": 140.54,
    "out:Klimatpaverkan": -76.46,
    "out:Initial Cost/MSEK": 10.50725,
    "out:Running cost/(Apt SEK y)": 7786,
    "out:Running Cost over RSP/MSEK": 2.238,
    "out:LCP/MSEK": -0.043,
    "out:ROI% old": 14.23,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3224.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1336,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 37.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2242.9,
    "out:PV (% sold (El))": 5.89,
    "out:PV (kWh self-consumed)": 35859.99,
    "out:PV (ratio sold/self-consumed)": 0.06,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -84861,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 379764,
    "out:DH kr savings": 521168,
    "out:El kr savings": -144264,
    "out:El kr sold": 2860,
    "out:El kr saved": 60962,
    "out:El kr return": 63822,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10154,
    "out:% savings (space heating)": 97.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.32,
    "out:Etvv": 0,
    "out:Ef": 40.79,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.17,
    "out:Electricity (inc PV)": 40.69,
    "out:Total Energy Use Pre PV": 58.17,
    "out:Total Energy Use Post PV": 43.69,
    "out:Primary Energy": 74.42,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.71,
    "out:CO2 Operational/m2": 3.64,
    "out:Total CO2/m2": 41.35,
    "out:Total CO2 (tons)": 140.95,
    "out:Klimatpaverkan": -76.05,
    "out:Initial Cost/MSEK": 10.626875,
    "out:Running cost/(Apt SEK y)": 7646,
    "out:Running Cost over RSP/MSEK": 2.197,
    "out:LCP/MSEK": -0.122,
    "out:ROI% old": 14.12,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 325,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3164.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1341,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2423.39,
    "out:PV (% sold (El))": 6.36,
    "out:PV (kWh self-consumed)": 35679.5,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -84495,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 380617,
    "out:DH kr savings": 521168,
    "out:El kr savings": -143641,
    "out:El kr sold": 3090,
    "out:El kr saved": 60655,
    "out:El kr return": 63745,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10909,
    "out:% savings (space heating)": 97.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.69,
    "out:Etvv": 0,
    "out:Ef": 40.69,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.32,
    "out:Electricity (inc PV)": 39.81,
    "out:Total Energy Use Pre PV": 56.32,
    "out:Total Energy Use Post PV": 41.81,
    "out:Primary Energy": 72.41,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.71,
    "out:CO2 Operational/m2": 2.92,
    "out:Total CO2/m2": 40.62,
    "out:Total CO2 (tons)": 138.49,
    "out:Klimatpaverkan": -78.51,
    "out:Initial Cost/MSEK": 10.712125,
    "out:Running cost/(Apt SEK y)": 7272,
    "out:Running Cost over RSP/MSEK": 2.089,
    "out:LCP/MSEK": -0.099,
    "out:ROI% old": 14.15,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3107.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1355,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2599.67,
    "out:PV (% sold (El))": 6.82,
    "out:PV (kWh self-consumed)": 35503.22,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -81494,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389046,
    "out:DH kr savings": 524270,
    "out:El kr savings": -138539,
    "out:El kr sold": 3315,
    "out:El kr saved": 60355,
    "out:El kr return": 63670,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7790,
    "out:% savings (space heating)": 98.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.09,
    "out:Etvv": 0,
    "out:Ef": 39.81,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.88,
    "out:Electricity (inc PV)": 37.55,
    "out:Total Energy Use Pre PV": 60.88,
    "out:Total Energy Use Post PV": 41.55,
    "out:Primary Energy": 68.98,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.57,
    "out:CO2 Operational/m2": -28.78,
    "out:Total CO2/m2": 20.78,
    "out:Total CO2 (tons)": 70.84,
    "out:Klimatpaverkan": -146.16,
    "out:Initial Cost/MSEK": 11.131125,
    "out:Running cost/(Apt SEK y)": 6657,
    "out:Running Cost over RSP/MSEK": 1.922,
    "out:LCP/MSEK": -0.351,
    "out:ROI% old": 13.84,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2946.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1378,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 38.68,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24407.11,
    "out:PV (% sold (El))": 32.03,
    "out:PV (kWh self-consumed)": 51798.68,
    "out:PV (ratio sold/self-consumed)": 0.47,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 569303,
    "out:EL kWh savings": -73796,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 423732,
    "out:DH kr savings": 518066,
    "out:El kr savings": -125453,
    "out:El kr sold": 31119,
    "out:El kr saved": 88058,
    "out:El kr return": 119177,
    "out:% solar/total": 96,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 13928,
    "out:% savings (space heating)": 96.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.99,
    "out:Etvv": 0,
    "out:Ef": 37.55,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.09,
    "out:Electricity (inc PV)": 36.78,
    "out:Total Energy Use Pre PV": 59.09,
    "out:Total Energy Use Post PV": 39.78,
    "out:Primary Energy": 67.12,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.57,
    "out:CO2 Operational/m2": -29.9,
    "out:Total CO2/m2": 19.66,
    "out:Total CO2 (tons)": 67.04,
    "out:Klimatpaverkan": -149.96,
    "out:Initial Cost/MSEK": 11.216375,
    "out:Running cost/(Apt SEK y)": 6295,
    "out:Running Cost over RSP/MSEK": 1.817,
    "out:LCP/MSEK": -0.331,
    "out:ROI% old": 13.86,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 367.5,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2899.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1391,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 37.89,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 24850.02,
    "out:PV (% sold (El))": 32.61,
    "out:PV (kWh self-consumed)": 51355.76,
    "out:PV (ratio sold/self-consumed)": 0.48,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -71175,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 431854,
    "out:DH kr savings": 521168,
    "out:El kr savings": -120998,
    "out:El kr sold": 31684,
    "out:El kr saved": 87305,
    "out:El kr return": 118989,
    "out:% solar/total": 95,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10154,
    "out:% savings (space heating)": 97.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.32,
    "out:Etvv": 0,
    "out:Ef": 36.78,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 3.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.17,
    "out:Electricity (inc PV)": 36.68,
    "out:Total Energy Use Pre PV": 58.17,
    "out:Total Energy Use Post PV": 39.68,
    "out:Primary Energy": 67.21,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.06,
    "out:CO2 Operational/m2": -30.77,
    "out:Total CO2/m2": 19.29,
    "out:Total CO2 (tons)": 65.76,
    "out:Klimatpaverkan": -151.24,
    "out:Initial Cost/MSEK": 11.336125,
    "out:Running cost/(Apt SEK y)": 6158,
    "out:Running Cost over RSP/MSEK": 1.778,
    "out:LCP/MSEK": -0.412,
    "out:ROI% old": 13.77,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 367.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2845.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1396,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25361.34,
    "out:PV (% sold (El))": 33.28,
    "out:PV (kWh self-consumed)": 50844.44,
    "out:PV (ratio sold/self-consumed)": 0.5,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -70849,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 433060,
    "out:DH kr savings": 521168,
    "out:El kr savings": -120443,
    "out:El kr sold": 32336,
    "out:El kr saved": 86436,
    "out:El kr return": 118771,
    "out:% solar/total": 94,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10909,
    "out:% savings (space heating)": 97.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.69,
    "out:Etvv": 0,
    "out:Ef": 36.68,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.32,
    "out:Electricity (inc PV)": 35.9,
    "out:Total Energy Use Pre PV": 56.32,
    "out:Total Energy Use Post PV": 37.9,
    "out:Primary Energy": 65.38,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.06,
    "out:CO2 Operational/m2": -31.95,
    "out:Total CO2/m2": 18.11,
    "out:Total CO2 (tons)": 61.73,
    "out:Klimatpaverkan": -155.27,
    "out:Initial Cost/MSEK": 11.42125,
    "out:Running cost/(Apt SEK y)": 5788,
    "out:Running Cost over RSP/MSEK": 1.671,
    "out:LCP/MSEK": -0.39,
    "out:ROI% old": 13.8,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 392.1,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2795.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1410,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 36.12,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25844.99,
    "out:PV (% sold (El))": 33.91,
    "out:PV (kWh self-consumed)": 50360.79,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -68178,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 441320,
    "out:DH kr savings": 524270,
    "out:El kr savings": -115902,
    "out:El kr sold": 32952,
    "out:El kr saved": 85613,
    "out:El kr return": 118566,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7790,
    "out:% savings (space heating)": 98.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.09,
    "out:Etvv": 0,
    "out:Ef": 35.9,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.59,
    "out:Electricity (inc PV)": 50.52,
    "out:Total Energy Use Pre PV": 57.59,
    "out:Total Energy Use Post PV": 53.52,
    "out:Primary Energy": 92.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.86,
    "out:CO2 Operational/m2": 8.29,
    "out:Total CO2/m2": 33.15,
    "out:Total CO2 (tons)": 112.99,
    "out:Klimatpaverkan": -104.01,
    "out:Initial Cost/MSEK": 9.810625,
    "out:Running cost/(Apt SEK y)": 9277,
    "out:Running Cost over RSP/MSEK": 2.656,
    "out:LCP/MSEK": 0.235,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 246.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3821.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1281,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -118009,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320552,
    "out:DH kr savings": 521168,
    "out:El kr savings": -200616,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10039,
    "out:% savings (space heating)": 97.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.6,
    "out:Etvv": 0,
    "out:Ef": 50.52,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.72,
    "out:Electricity (inc PV)": 49.56,
    "out:Total Energy Use Pre PV": 55.72,
    "out:Total Energy Use Post PV": 51.56,
    "out:Primary Energy": 89.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.86,
    "out:CO2 Operational/m2": 7.81,
    "out:Total CO2/m2": 32.67,
    "out:Total CO2 (tons)": 111.37,
    "out:Klimatpaverkan": -105.63,
    "out:Initial Cost/MSEK": 9.89575,
    "out:Running cost/(Apt SEK y)": 8899,
    "out:Running Cost over RSP/MSEK": 2.547,
    "out:LCP/MSEK": 0.259,
    "out:ROI% old": 14.66,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3760.5,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1295,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -114744,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329206,
    "out:DH kr savings": 524270,
    "out:El kr savings": -195064,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7097,
    "out:% savings (space heating)": 98.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.02,
    "out:Etvv": 0,
    "out:Ef": 49.56,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.18,
    "out:Electricity (inc PV)": 49.67,
    "out:Total Energy Use Pre PV": 55.18,
    "out:Total Energy Use Post PV": 51.67,
    "out:Primary Energy": 90.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.36,
    "out:CO2 Operational/m2": 7.74,
    "out:Total CO2/m2": 33.09,
    "out:Total CO2 (tons)": 112.82,
    "out:Klimatpaverkan": -104.18,
    "out:Initial Cost/MSEK": 10.0155,
    "out:Running cost/(Apt SEK y)": 8813,
    "out:Running Cost over RSP/MSEK": 2.523,
    "out:LCP/MSEK": 0.164,
    "out:ROI% old": 14.52,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 259.6,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3722.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1298,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -115128,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328553,
    "out:DH kr savings": 524270,
    "out:El kr savings": -195717,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 8195,
    "out:% savings (space heating)": 98.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.39,
    "out:Etvv": 0,
    "out:Ef": 49.67,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.27,
    "out:Electricity (inc PV)": 48.7,
    "out:Total Energy Use Pre PV": 54.27,
    "out:Total Energy Use Post PV": 50.7,
    "out:Primary Energy": 88.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.36,
    "out:CO2 Operational/m2": 7.62,
    "out:Total CO2/m2": 32.98,
    "out:Total CO2 (tons)": 112.41,
    "out:Klimatpaverkan": -104.59,
    "out:Initial Cost/MSEK": 10.10075,
    "out:Running cost/(Apt SEK y)": 8672,
    "out:Running Cost over RSP/MSEK": 2.482,
    "out:LCP/MSEK": 0.119,
    "out:ROI% old": 14.45,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 266.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3659,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1303,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -111799,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334211,
    "out:DH kr savings": 524270,
    "out:El kr savings": -190059,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 5617,
    "out:% savings (space heating)": 98.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 0.87,
    "out:Etvv": 0,
    "out:Ef": 48.7,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.59,
    "out:Electricity (inc PV)": 40.36,
    "out:Total Energy Use Pre PV": 57.59,
    "out:Total Energy Use Post PV": 43.36,
    "out:Primary Energy": 73.77,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.21,
    "out:CO2 Operational/m2": 3.39,
    "out:Total CO2/m2": 40.61,
    "out:Total CO2 (tons)": 138.42,
    "out:Klimatpaverkan": -78.58,
    "out:Initial Cost/MSEK": 10.519625,
    "out:Running cost/(Apt SEK y)": 7556,
    "out:Running Cost over RSP/MSEK": 2.172,
    "out:LCP/MSEK": 0.011,
    "out:ROI% old": 14.3,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 334.9,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 3125.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1345,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2542.22,
    "out:PV (% sold (El))": 6.67,
    "out:PV (kWh self-consumed)": 35560.67,
    "out:PV (ratio sold/self-consumed)": 0.07,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -83387,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 382651,
    "out:DH kr savings": 521168,
    "out:El kr savings": -141758,
    "out:El kr sold": 3241,
    "out:El kr saved": 60453,
    "out:El kr return": 63694,
    "out:% solar/total": 66,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10039,
    "out:% savings (space heating)": 97.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.6,
    "out:Etvv": 0,
    "out:Ef": 40.36,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.72,
    "out:Electricity (inc PV)": 39.47,
    "out:Total Energy Use Pre PV": 55.72,
    "out:Total Energy Use Post PV": 41.47,
    "out:Primary Energy": 71.77,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.21,
    "out:CO2 Operational/m2": 2.66,
    "out:Total CO2/m2": 39.87,
    "out:Total CO2 (tons)": 135.91,
    "out:Klimatpaverkan": -81.09,
    "out:Initial Cost/MSEK": 10.604875,
    "out:Running cost/(Apt SEK y)": 7179,
    "out:Running Cost over RSP/MSEK": 2.063,
    "out:LCP/MSEK": 0.034,
    "out:ROI% old": 14.33,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3067.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1359,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2728.22,
    "out:PV (% sold (El))": 7.16,
    "out:PV (kWh self-consumed)": 35374.67,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -80366,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 391126,
    "out:DH kr savings": 524270,
    "out:El kr savings": -136622,
    "out:El kr sold": 3478,
    "out:El kr saved": 60137,
    "out:El kr return": 63615,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7097,
    "out:% savings (space heating)": 98.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.02,
    "out:Etvv": 0,
    "out:Ef": 39.47,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.18,
    "out:Electricity (inc PV)": 39.58,
    "out:Total Energy Use Pre PV": 55.18,
    "out:Total Energy Use Post PV": 41.58,
    "out:Primary Energy": 72.21,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.71,
    "out:CO2 Operational/m2": 2.41,
    "out:Total CO2/m2": 40.12,
    "out:Total CO2 (tons)": 136.77,
    "out:Klimatpaverkan": -80.23,
    "out:Initial Cost/MSEK": 10.724625,
    "out:Running cost/(Apt SEK y)": 7095,
    "out:Running Cost over RSP/MSEK": 2.039,
    "out:LCP/MSEK": -0.061,
    "out:ROI% old": 14.2,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 345.2,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 3031.4,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1362,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 2848.88,
    "out:PV (% sold (El))": 7.48,
    "out:PV (kWh self-consumed)": 35254.02,
    "out:PV (ratio sold/self-consumed)": 0.08,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -80721,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 390677,
    "out:DH kr savings": 524270,
    "out:El kr savings": -137226,
    "out:El kr sold": 3632,
    "out:El kr saved": 59932,
    "out:El kr return": 63564,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 8195,
    "out:% savings (space heating)": 98.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.39,
    "out:Etvv": 0,
    "out:Ef": 39.58,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.27,
    "out:Electricity (inc PV)": 38.68,
    "out:Total Energy Use Pre PV": 54.27,
    "out:Total Energy Use Post PV": 40.68,
    "out:Primary Energy": 70.23,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.71,
    "out:CO2 Operational/m2": 2,
    "out:Total CO2/m2": 39.71,
    "out:Total CO2 (tons)": 135.36,
    "out:Klimatpaverkan": -81.64,
    "out:Initial Cost/MSEK": 10.809875,
    "out:Running cost/(Apt SEK y)": 6956,
    "out:Running Cost over RSP/MSEK": 1.999,
    "out:LCP/MSEK": -0.107,
    "out:ROI% old": 14.14,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 41,
    "out:Energy use kWh/m2": 41,
    "out:Energy savings %": 356.1,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2971.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 1367,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 3055.4,
    "out:PV (% sold (El))": 8.02,
    "out:PV (kWh self-consumed)": 35047.49,
    "out:PV (ratio sold/self-consumed)": 0.09,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -77652,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 396158,
    "out:DH kr savings": 524270,
    "out:El kr savings": -132008,
    "out:El kr sold": 3896,
    "out:El kr saved": 59581,
    "out:El kr return": 63476,
    "out:% solar/total": 65,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 5617,
    "out:% savings (space heating)": 98.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 0.87,
    "out:Etvv": 0,
    "out:Ef": 38.68,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.59,
    "out:Electricity (inc PV)": 36.39,
    "out:Total Energy Use Pre PV": 57.59,
    "out:Total Energy Use Post PV": 39.39,
    "out:Primary Energy": 66.63,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.57,
    "out:CO2 Operational/m2": -31.33,
    "out:Total CO2/m2": 18.24,
    "out:Total CO2 (tons)": 62.16,
    "out:Klimatpaverkan": -154.84,
    "out:Initial Cost/MSEK": 11.22875,
    "out:Running cost/(Apt SEK y)": 6071,
    "out:Running Cost over RSP/MSEK": 1.753,
    "out:LCP/MSEK": -0.28,
    "out:ROI% old": 13.93,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 39,
    "out:Energy use kWh/m2": 39,
    "out:Energy savings %": 379.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 3,
    "out:El bought/kWh/m2": 2811.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1400,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 36.39,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 25689.93,
    "out:PV (% sold (El))": 33.71,
    "out:PV (kWh self-consumed)": 50515.86,
    "out:PV (ratio sold/self-consumed)": 0.51,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 572712,
    "out:EL kWh savings": -69863,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 435155,
    "out:DH kr savings": 521168,
    "out:El kr savings": -118768,
    "out:El kr sold": 32755,
    "out:El kr saved": 85877,
    "out:El kr return": 118632,
    "out:% solar/total": 93,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 10039,
    "out:% savings (space heating)": 97.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.6,
    "out:Etvv": 0,
    "out:Ef": 36.39,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.72,
    "out:Electricity (inc PV)": 35.6,
    "out:Total Energy Use Pre PV": 55.72,
    "out:Total Energy Use Post PV": 37.6,
    "out:Primary Energy": 64.8,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.57,
    "out:CO2 Operational/m2": -32.53,
    "out:Total CO2/m2": 17.03,
    "out:Total CO2 (tons)": 58.06,
    "out:Klimatpaverkan": -158.94,
    "out:Initial Cost/MSEK": 11.314,
    "out:Running cost/(Apt SEK y)": 5698,
    "out:Running Cost over RSP/MSEK": 1.646,
    "out:LCP/MSEK": -0.257,
    "out:ROI% old": 13.96,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 392.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2760.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1413,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 35.52,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 26185.14,
    "out:PV (% sold (El))": 34.36,
    "out:PV (kWh self-consumed)": 50020.64,
    "out:PV (ratio sold/self-consumed)": 0.52,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -67174,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 443461,
    "out:DH kr savings": 524270,
    "out:El kr savings": -114195,
    "out:El kr sold": 33386,
    "out:El kr saved": 85035,
    "out:El kr return": 118421,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 7097,
    "out:% savings (space heating)": 98.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.02,
    "out:Etvv": 0,
    "out:Ef": 35.6,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 2.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.18,
    "out:Electricity (inc PV)": 35.7,
    "out:Total Energy Use Pre PV": 55.18,
    "out:Total Energy Use Post PV": 37.7,
    "out:Primary Energy": 65.23,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.06,
    "out:CO2 Operational/m2": -33.07,
    "out:Total CO2/m2": 17,
    "out:Total CO2 (tons)": 57.94,
    "out:Klimatpaverkan": -159.06,
    "out:Initial Cost/MSEK": 11.43375,
    "out:Running cost/(Apt SEK y)": 5616,
    "out:Running Cost over RSP/MSEK": 1.622,
    "out:LCP/MSEK": -0.354,
    "out:ROI% old": 13.84,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 392.1,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2728.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1416,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.98,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 26499.64,
    "out:PV (% sold (El))": 34.77,
    "out:PV (kWh self-consumed)": 49706.14,
    "out:PV (ratio sold/self-consumed)": 0.53,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -67490,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 443325,
    "out:DH kr savings": 524270,
    "out:El kr savings": -114732,
    "out:El kr sold": 33787,
    "out:El kr saved": 84500,
    "out:El kr return": 118287,
    "out:% solar/total": 92,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 8195,
    "out:% savings (space heating)": 98.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 1.39,
    "out:Etvv": 0,
    "out:Ef": 35.7,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000202120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 1.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.27,
    "out:Electricity (inc PV)": 34.9,
    "out:Total Energy Use Pre PV": 54.27,
    "out:Total Energy Use Post PV": 36.9,
    "out:Primary Energy": 63.42,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 50.06,
    "out:CO2 Operational/m2": -33.95,
    "out:Total CO2/m2": 16.11,
    "out:Total CO2 (tons)": 54.92,
    "out:Klimatpaverkan": -162.08,
    "out:Initial Cost/MSEK": 11.519,
    "out:Running cost/(Apt SEK y)": 5481,
    "out:Running Cost over RSP/MSEK": 1.583,
    "out:LCP/MSEK": -0.4,
    "out:ROI% old": 13.79,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 37,
    "out:Energy use kWh/m2": 37,
    "out:Energy savings %": 405.4,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 0,
    "out:DH+ DHW/kWh/m2": 2,
    "out:El bought/kWh/m2": 2675.4,
    "out:Return %": 12,
    "out:Return/kSEK/y": 1421,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 1922700,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 96135,
    "out:EAHP (replacement cost)": 1435100,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 34.07,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 27023.43,
    "out:PV (% sold (El))": 35.46,
    "out:PV (kWh self-consumed)": 49182.36,
    "out:PV (ratio sold/self-consumed)": 0.55,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 576121,
    "out:EL kWh savings": -64755,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 448642,
    "out:DH kr savings": 524270,
    "out:El kr savings": -110083,
    "out:El kr sold": 34455,
    "out:El kr saved": 83610,
    "out:El kr return": 118065,
    "out:% solar/total": 91,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 5617,
    "out:% savings (space heating)": 98.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 0.87,
    "out:Etvv": 0,
    "out:Ef": 34.9,
    "out:heatPumpQuantity": 13,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 161.5,
    "out:Total Energy Use Post PV": 161.5,
    "out:Primary Energy": 122.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.7,
    "out:CO2 Operational/m2": 53.46,
    "out:Total CO2/m2": 55.16,
    "out:Total CO2 (tons)": 188.05,
    "out:Klimatpaverkan": -28.95,
    "out:Initial Cost/MSEK": 2.375125,
    "out:Running cost/(Apt SEK y)": 37354,
    "out:Running Cost over RSP/MSEK": 10.805,
    "out:LCP/MSEK": -0.478,
    "out:ROI% old": 11.41,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 16.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 242,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66413,
    "out:DH kr savings": 93066,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 155.5,
    "out:Primary Energy": 118.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.7,
    "out:CO2 Operational/m2": 51.3,
    "out:Total CO2/m2": 53,
    "out:Total CO2 (tons)": 180.68,
    "out:Klimatpaverkan": -36.32,
    "out:Initial Cost/MSEK": 2.460375,
    "out:Running cost/(Apt SEK y)": 35901,
    "out:Running Cost over RSP/MSEK": 10.384,
    "out:LCP/MSEK": -0.142,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 296,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85026,
    "out:DH kr savings": 111679,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 154.5,
    "out:Total Energy Use Post PV": 154.5,
    "out:Primary Energy": 120.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.19,
    "out:CO2 Operational/m2": 50.94,
    "out:Total CO2/m2": 53.14,
    "out:Total CO2 (tons)": 181.15,
    "out:Klimatpaverkan": -35.85,
    "out:Initial Cost/MSEK": 2.580125,
    "out:Running cost/(Apt SEK y)": 35659,
    "out:Running Cost over RSP/MSEK": 10.314,
    "out:LCP/MSEK": -0.192,
    "out:ROI% old": 13.22,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 305,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88128,
    "out:DH kr savings": 114781,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 149.5,
    "out:Primary Energy": 116.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.19,
    "out:CO2 Operational/m2": 49.14,
    "out:Total CO2/m2": 51.34,
    "out:Total CO2 (tons)": 175.01,
    "out:Klimatpaverkan": -41.99,
    "out:Initial Cost/MSEK": 2.66525,
    "out:Running cost/(Apt SEK y)": 34448,
    "out:Running Cost over RSP/MSEK": 9.963,
    "out:LCP/MSEK": 0.074,
    "out:ROI% old": 14.68,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103639,
    "out:DH kr savings": 130292,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 161.5,
    "out:Total Energy Use Post PV": 155.33,
    "out:Primary Energy": 111.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.05,
    "out:CO2 Operational/m2": 27.45,
    "out:Total CO2/m2": 41.5,
    "out:Total CO2 (tons)": 141.48,
    "out:Klimatpaverkan": -75.52,
    "out:Initial Cost/MSEK": 3.08425,
    "out:Running cost/(Apt SEK y)": 35799,
    "out:Running Cost over RSP/MSEK": 10.367,
    "out:LCP/MSEK": -0.749,
    "out:ROI% old": 10.82,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 300,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 123930,
    "out:DH kr savings": 93066,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 149.33,
    "out:Primary Energy": 107.68,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.05,
    "out:CO2 Operational/m2": 25.29,
    "out:Total CO2/m2": 39.34,
    "out:Total CO2 (tons)": 134.11,
    "out:Klimatpaverkan": -82.89,
    "out:Initial Cost/MSEK": 3.1695,
    "out:Running cost/(Apt SEK y)": 34346,
    "out:Running Cost over RSP/MSEK": 9.946,
    "out:LCP/MSEK": -0.414,
    "out:ROI% old": 12.42,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 25.5,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 353,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 142543,
    "out:DH kr savings": 111679,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 154.5,
    "out:Total Energy Use Post PV": 148.33,
    "out:Primary Energy": 108.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.55,
    "out:CO2 Operational/m2": 24.93,
    "out:Total CO2/m2": 39.48,
    "out:Total CO2 (tons)": 134.58,
    "out:Klimatpaverkan": -82.42,
    "out:Initial Cost/MSEK": 3.289125,
    "out:Running cost/(Apt SEK y)": 34104,
    "out:Running Cost over RSP/MSEK": 9.876,
    "out:LCP/MSEK": -0.463,
    "out:ROI% old": 12.27,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145645,
    "out:DH kr savings": 114781,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 143.33,
    "out:Primary Energy": 105.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.55,
    "out:CO2 Operational/m2": 23.13,
    "out:Total CO2/m2": 37.68,
    "out:Total CO2 (tons)": 128.44,
    "out:Klimatpaverkan": -88.56,
    "out:Initial Cost/MSEK": 3.374375,
    "out:Running cost/(Apt SEK y)": 32894,
    "out:Running Cost over RSP/MSEK": 9.526,
    "out:LCP/MSEK": -0.198,
    "out:ROI% old": 13.45,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 407,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161156,
    "out:DH kr savings": 130292,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 161.5,
    "out:Total Energy Use Post PV": 154.09,
    "out:Primary Energy": 109.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.4,
    "out:CO2 Operational/m2": -23.23,
    "out:Total CO2/m2": 3.17,
    "out:Total CO2 (tons)": 10.82,
    "out:Klimatpaverkan": -206.18,
    "out:Initial Cost/MSEK": 3.793375,
    "out:Running cost/(Apt SEK y)": 34438,
    "out:Running Cost over RSP/MSEK": 9.984,
    "out:LCP/MSEK": -1.075,
    "out:ROI% old": 10.24,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 21.4,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 102270,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174310,
    "out:DH kr savings": 93066,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 155.5,
    "out:Total Energy Use Post PV": 148.09,
    "out:Primary Energy": 105.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.4,
    "out:CO2 Operational/m2": -25.39,
    "out:Total CO2/m2": 1.01,
    "out:Total CO2 (tons)": 3.45,
    "out:Klimatpaverkan": -213.55,
    "out:Initial Cost/MSEK": 3.878625,
    "out:Running cost/(Apt SEK y)": 32985,
    "out:Running Cost over RSP/MSEK": 9.563,
    "out:LCP/MSEK": -0.74,
    "out:ROI% old": 11.56,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 26.4,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 404,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122724,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192923,
    "out:DH kr savings": 111679,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 154.5,
    "out:Total Energy Use Post PV": 147.09,
    "out:Primary Energy": 106.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.9,
    "out:CO2 Operational/m2": -25.75,
    "out:Total CO2/m2": 1.15,
    "out:Total CO2 (tons)": 3.92,
    "out:Klimatpaverkan": -213.08,
    "out:Initial Cost/MSEK": 3.99825,
    "out:Running cost/(Apt SEK y)": 32743,
    "out:Running Cost over RSP/MSEK": 9.493,
    "out:LCP/MSEK": -0.789,
    "out:ROI% old": 11.47,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 126133,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196026,
    "out:DH kr savings": 114781,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 149.5,
    "out:Total Energy Use Post PV": 142.09,
    "out:Primary Energy": 102.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.9,
    "out:CO2 Operational/m2": -27.55,
    "out:Total CO2/m2": -0.65,
    "out:Total CO2 (tons)": -2.22,
    "out:Klimatpaverkan": -219.22,
    "out:Initial Cost/MSEK": 4.0835,
    "out:Running cost/(Apt SEK y)": 31532,
    "out:Running Cost over RSP/MSEK": 9.142,
    "out:LCP/MSEK": -0.524,
    "out:ROI% old": 12.45,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 143178,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211537,
    "out:DH kr savings": 130292,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 152.5,
    "out:Total Energy Use Post PV": 152.5,
    "out:Primary Energy": 119.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.7,
    "out:CO2 Operational/m2": 50.22,
    "out:Total CO2/m2": 51.92,
    "out:Total CO2 (tons)": 177,
    "out:Klimatpaverkan": -40,
    "out:Initial Cost/MSEK": 2.47275,
    "out:Running cost/(Apt SEK y)": 35174,
    "out:Running Cost over RSP/MSEK": 10.174,
    "out:LCP/MSEK": 0.056,
    "out:ROI% old": 14.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 23,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94333,
    "out:DH kr savings": 120985,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 115.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.7,
    "out:CO2 Operational/m2": 48.42,
    "out:Total CO2/m2": 50.12,
    "out:Total CO2 (tons)": 170.87,
    "out:Klimatpaverkan": -46.13,
    "out:Initial Cost/MSEK": 2.558,
    "out:Running cost/(Apt SEK y)": 33964,
    "out:Running Cost over RSP/MSEK": 9.823,
    "out:LCP/MSEK": 0.321,
    "out:ROI% old": 16.08,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109844,
    "out:DH kr savings": 136496,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 117.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.19,
    "out:CO2 Operational/m2": 48.42,
    "out:Total CO2/m2": 50.62,
    "out:Total CO2 (tons)": 172.56,
    "out:Klimatpaverkan": -44.44,
    "out:Initial Cost/MSEK": 2.67775,
    "out:Running cost/(Apt SEK y)": 33964,
    "out:Running Cost over RSP/MSEK": 9.823,
    "out:LCP/MSEK": 0.201,
    "out:ROI% old": 15.36,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109844,
    "out:DH kr savings": 136496,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 142.5,
    "out:Primary Energy": 113.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 2.19,
    "out:CO2 Operational/m2": 46.62,
    "out:Total CO2/m2": 48.82,
    "out:Total CO2 (tons)": 166.42,
    "out:Klimatpaverkan": -50.58,
    "out:Initial Cost/MSEK": 2.763,
    "out:Running cost/(Apt SEK y)": 32753,
    "out:Running Cost over RSP/MSEK": 9.473,
    "out:LCP/MSEK": 0.467,
    "out:ROI% old": 16.7,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 167041,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125355,
    "out:DH kr savings": 152007,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 152.5,
    "out:Total Energy Use Post PV": 146.33,
    "out:Primary Energy": 108.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.05,
    "out:CO2 Operational/m2": 24.21,
    "out:Total CO2/m2": 38.26,
    "out:Total CO2 (tons)": 130.43,
    "out:Klimatpaverkan": -86.57,
    "out:Initial Cost/MSEK": 3.181875,
    "out:Running cost/(Apt SEK y)": 33620,
    "out:Running Cost over RSP/MSEK": 9.736,
    "out:LCP/MSEK": -0.216,
    "out:ROI% old": 13.32,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 380,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151850,
    "out:DH kr savings": 120985,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 141.33,
    "out:Primary Energy": 104.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.05,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 36.46,
    "out:Total CO2 (tons)": 124.29,
    "out:Klimatpaverkan": -92.71,
    "out:Initial Cost/MSEK": 3.267125,
    "out:Running cost/(Apt SEK y)": 32409,
    "out:Running Cost over RSP/MSEK": 9.385,
    "out:LCP/MSEK": 0.05,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 425,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167361,
    "out:DH kr savings": 136496,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 141.33,
    "out:Primary Energy": 105.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.55,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 36.96,
    "out:Total CO2 (tons)": 125.99,
    "out:Klimatpaverkan": -91.01,
    "out:Initial Cost/MSEK": 3.386875,
    "out:Running cost/(Apt SEK y)": 32409,
    "out:Running Cost over RSP/MSEK": 9.385,
    "out:LCP/MSEK": -0.07,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 425,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167361,
    "out:DH kr savings": 136496,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 136.33,
    "out:Primary Energy": 102.08,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.55,
    "out:CO2 Operational/m2": 20.61,
    "out:Total CO2/m2": 35.16,
    "out:Total CO2 (tons)": 119.85,
    "out:Klimatpaverkan": -97.15,
    "out:Initial Cost/MSEK": 3.472,
    "out:Running cost/(Apt SEK y)": 31198,
    "out:Running Cost over RSP/MSEK": 9.035,
    "out:LCP/MSEK": 0.195,
    "out:ROI% old": 15.09,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 167041,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182872,
    "out:DH kr savings": 152007,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 152.5,
    "out:Total Energy Use Post PV": 145.09,
    "out:Primary Energy": 105.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.4,
    "out:CO2 Operational/m2": -26.47,
    "out:Total CO2/m2": -0.07,
    "out:Total CO2 (tons)": -0.23,
    "out:Klimatpaverkan": -217.23,
    "out:Initial Cost/MSEK": 3.891,
    "out:Running cost/(Apt SEK y)": 32258,
    "out:Running Cost over RSP/MSEK": 9.353,
    "out:LCP/MSEK": -0.542,
    "out:ROI% old": 12.3,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 431,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 132951,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202230,
    "out:DH kr savings": 120985,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 140.09,
    "out:Primary Energy": 101.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.4,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": -1.87,
    "out:Total CO2 (tons)": -6.37,
    "out:Klimatpaverkan": -223.37,
    "out:Initial Cost/MSEK": 3.97625,
    "out:Running cost/(Apt SEK y)": 31048,
    "out:Running Cost over RSP/MSEK": 9.002,
    "out:LCP/MSEK": -0.276,
    "out:ROI% old": 13.29,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 217741,
    "out:DH kr savings": 136496,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.51,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 140.09,
    "out:Primary Energy": 103.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.9,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": -1.37,
    "out:Total CO2 (tons)": -4.67,
    "out:Klimatpaverkan": -221.67,
    "out:Initial Cost/MSEK": 4.096,
    "out:Running cost/(Apt SEK y)": 31048,
    "out:Running Cost over RSP/MSEK": 9.002,
    "out:LCP/MSEK": -0.396,
    "out:ROI% old": 12.9,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217741,
    "out:DH kr savings": 136496,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 332395,
    "out:% savings (space heating)": 23.54,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 127.53,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.98,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 135.09,
    "out:Primary Energy": 99.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.9,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": -3.17,
    "out:Total CO2 (tons)": -10.81,
    "out:Klimatpaverkan": -227.81,
    "out:Initial Cost/MSEK": 4.181125,
    "out:Running cost/(Apt SEK y)": 29837,
    "out:Running Cost over RSP/MSEK": 8.652,
    "out:LCP/MSEK": -0.131,
    "out:ROI% old": 13.84,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.83,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 167041,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233252,
    "out:DH kr savings": 152007,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 313563,
    "out:% savings (space heating)": 27.88,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 122.01,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 150.5,
    "out:Primary Energy": 115.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.88,
    "out:CO2 Operational/m2": 49.5,
    "out:Total CO2/m2": 53.38,
    "out:Total CO2 (tons)": 181.98,
    "out:Klimatpaverkan": -35.02,
    "out:Initial Cost/MSEK": 2.91875,
    "out:Running cost/(Apt SEK y)": 34690,
    "out:Running Cost over RSP/MSEK": 10.034,
    "out:LCP/MSEK": -0.25,
    "out:ROI% old": 13.06,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 24.7,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 341,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 139769,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100537,
    "out:DH kr savings": 127190,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 145.5,
    "out:Total Energy Use Post PV": 145.5,
    "out:Primary Energy": 111.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.88,
    "out:CO2 Operational/m2": 47.7,
    "out:Total CO2/m2": 51.58,
    "out:Total CO2 (tons)": 175.85,
    "out:Klimatpaverkan": -41.15,
    "out:Initial Cost/MSEK": 3.004,
    "out:Running cost/(Apt SEK y)": 33479,
    "out:Running Cost over RSP/MSEK": 9.683,
    "out:LCP/MSEK": 0.015,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 29,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 385,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116048,
    "out:DH kr savings": 142701,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 112.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 47.34,
    "out:Total CO2/m2": 51.72,
    "out:Total CO2 (tons)": 176.31,
    "out:Klimatpaverkan": -40.69,
    "out:Initial Cost/MSEK": 3.123625,
    "out:Running cost/(Apt SEK y)": 33237,
    "out:Running Cost over RSP/MSEK": 9.613,
    "out:LCP/MSEK": -0.034,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 394,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 160223,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 119150,
    "out:DH kr savings": 145803,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 139.5,
    "out:Primary Energy": 108.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 45.54,
    "out:Total CO2/m2": 49.92,
    "out:Total CO2 (tons)": 170.17,
    "out:Klimatpaverkan": -46.83,
    "out:Initial Cost/MSEK": 3.208875,
    "out:Running cost/(Apt SEK y)": 32027,
    "out:Running Cost over RSP/MSEK": 9.262,
    "out:LCP/MSEK": 0.231,
    "out:ROI% old": 15.31,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 177268,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 134661,
    "out:DH kr savings": 161314,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 144.33,
    "out:Primary Energy": 103.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.23,
    "out:CO2 Operational/m2": 23.49,
    "out:Total CO2/m2": 39.72,
    "out:Total CO2 (tons)": 135.41,
    "out:Klimatpaverkan": -81.59,
    "out:Initial Cost/MSEK": 3.627875,
    "out:Running cost/(Apt SEK y)": 33136,
    "out:Running Cost over RSP/MSEK": 9.596,
    "out:LCP/MSEK": -0.521,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 29.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 398,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 139769,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158054,
    "out:DH kr savings": 127190,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 145.5,
    "out:Total Energy Use Post PV": 139.33,
    "out:Primary Energy": 100.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.23,
    "out:CO2 Operational/m2": 21.69,
    "out:Total CO2/m2": 37.92,
    "out:Total CO2 (tons)": 129.27,
    "out:Klimatpaverkan": -87.73,
    "out:Initial Cost/MSEK": 3.713125,
    "out:Running cost/(Apt SEK y)": 31925,
    "out:Running Cost over RSP/MSEK": 9.245,
    "out:LCP/MSEK": -0.256,
    "out:ROI% old": 13.3,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 34.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 443,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 173565,
    "out:DH kr savings": 142701,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 138.33,
    "out:Primary Energy": 101.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.73,
    "out:CO2 Operational/m2": 21.33,
    "out:Total CO2/m2": 38.06,
    "out:Total CO2 (tons)": 129.74,
    "out:Klimatpaverkan": -87.26,
    "out:Initial Cost/MSEK": 3.83275,
    "out:Running cost/(Apt SEK y)": 31683,
    "out:Running Cost over RSP/MSEK": 9.175,
    "out:LCP/MSEK": -0.306,
    "out:ROI% old": 13.15,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 12,
    "out:Return/kSEK/y": 452,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 160223,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176667,
    "out:DH kr savings": 145803,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 133.33,
    "out:Primary Energy": 97.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.73,
    "out:CO2 Operational/m2": 19.53,
    "out:Total CO2/m2": 36.26,
    "out:Total CO2 (tons)": 123.6,
    "out:Klimatpaverkan": -93.4,
    "out:Initial Cost/MSEK": 3.918,
    "out:Running cost/(Apt SEK y)": 30472,
    "out:Running Cost over RSP/MSEK": 8.824,
    "out:LCP/MSEK": -0.04,
    "out:ROI% old": 14.14,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 177268,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192178,
    "out:DH kr savings": 161314,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 150.5,
    "out:Total Energy Use Post PV": 143.09,
    "out:Primary Energy": 101.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.59,
    "out:CO2 Operational/m2": -27.19,
    "out:Total CO2/m2": 1.39,
    "out:Total CO2 (tons)": 4.75,
    "out:Klimatpaverkan": -212.25,
    "out:Initial Cost/MSEK": 4.337,
    "out:Running cost/(Apt SEK y)": 31774,
    "out:Running Cost over RSP/MSEK": 9.213,
    "out:LCP/MSEK": -0.847,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 139769,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 208434,
    "out:DH kr savings": 127190,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 145.5,
    "out:Total Energy Use Post PV": 138.09,
    "out:Primary Energy": 98.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.59,
    "out:CO2 Operational/m2": -28.99,
    "out:Total CO2/m2": -0.41,
    "out:Total CO2 (tons)": -1.39,
    "out:Klimatpaverkan": -218.39,
    "out:Initial Cost/MSEK": 4.42225,
    "out:Running cost/(Apt SEK y)": 30563,
    "out:Running Cost over RSP/MSEK": 8.862,
    "out:LCP/MSEK": -0.582,
    "out:ROI% old": 12.41,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 156814,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223945,
    "out:DH kr savings": 142701,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 144.5,
    "out:Total Energy Use Post PV": 137.09,
    "out:Primary Energy": 99.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.08,
    "out:CO2 Operational/m2": -29.35,
    "out:Total CO2/m2": -0.27,
    "out:Total CO2 (tons)": -0.92,
    "out:Klimatpaverkan": -217.92,
    "out:Initial Cost/MSEK": 4.541875,
    "out:Running cost/(Apt SEK y)": 30321,
    "out:Running Cost over RSP/MSEK": 8.792,
    "out:LCP/MSEK": -0.631,
    "out:ROI% old": 12.3,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 502,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 160223,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227047,
    "out:DH kr savings": 145803,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 139.5,
    "out:Total Energy Use Post PV": 132.09,
    "out:Primary Energy": 95.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.08,
    "out:CO2 Operational/m2": -31.15,
    "out:Total CO2/m2": -2.07,
    "out:Total CO2 (tons)": -7.06,
    "out:Klimatpaverkan": -224.06,
    "out:Initial Cost/MSEK": 4.627125,
    "out:Running cost/(Apt SEK y)": 29110,
    "out:Running Cost over RSP/MSEK": 8.441,
    "out:LCP/MSEK": -0.366,
    "out:ROI% old": 13.16,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 177268,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 242558,
    "out:DH kr savings": 161314,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 141.5,
    "out:Primary Energy": 111.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.88,
    "out:CO2 Operational/m2": 46.26,
    "out:Total CO2/m2": 50.14,
    "out:Total CO2 (tons)": 170.94,
    "out:Klimatpaverkan": -46.06,
    "out:Initial Cost/MSEK": 3.016375,
    "out:Running cost/(Apt SEK y)": 32511,
    "out:Running Cost over RSP/MSEK": 9.402,
    "out:LCP/MSEK": 0.283,
    "out:ROI% old": 15.63,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 421,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128457,
    "out:DH kr savings": 155110,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 108.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 3.88,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 48.34,
    "out:Total CO2 (tons)": 164.8,
    "out:Klimatpaverkan": -52.2,
    "out:Initial Cost/MSEK": 3.101625,
    "out:Running cost/(Apt SEK y)": 31300,
    "out:Running Cost over RSP/MSEK": 9.052,
    "out:LCP/MSEK": 0.549,
    "out:ROI% old": 16.81,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143968,
    "out:DH kr savings": 170620,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 109.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 48.84,
    "out:Total CO2 (tons)": 166.49,
    "out:Klimatpaverkan": -50.51,
    "out:Initial Cost/MSEK": 3.221375,
    "out:Running cost/(Apt SEK y)": 31300,
    "out:Running Cost over RSP/MSEK": 9.052,
    "out:LCP/MSEK": 0.429,
    "out:ROI% old": 16.19,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143968,
    "out:DH kr savings": 170620,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 105.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.37,
    "out:CO2 Operational/m2": 42.66,
    "out:Total CO2/m2": 47.04,
    "out:Total CO2 (tons)": 160.36,
    "out:Klimatpaverkan": -56.64,
    "out:Initial Cost/MSEK": 3.3065,
    "out:Running cost/(Apt SEK y)": 30089,
    "out:Running Cost over RSP/MSEK": 8.701,
    "out:LCP/MSEK": 0.694,
    "out:ROI% old": 17.29,
    "out:Payback discounted": 7,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159479,
    "out:DH kr savings": 186131,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 135.33,
    "out:Primary Energy": 100.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.23,
    "out:CO2 Operational/m2": 20.25,
    "out:Total CO2/m2": 36.48,
    "out:Total CO2 (tons)": 124.36,
    "out:Klimatpaverkan": -92.64,
    "out:Initial Cost/MSEK": 3.7255,
    "out:Running cost/(Apt SEK y)": 30956,
    "out:Running Cost over RSP/MSEK": 8.965,
    "out:LCP/MSEK": 0.012,
    "out:ROI% old": 14.33,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 479,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185974,
    "out:DH kr savings": 155110,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 96.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.23,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 34.68,
    "out:Total CO2 (tons)": 118.23,
    "out:Klimatpaverkan": -98.77,
    "out:Initial Cost/MSEK": 3.81075,
    "out:Running cost/(Apt SEK y)": 29746,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": 0.277,
    "out:ROI% old": 15.33,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201485,
    "out:DH kr savings": 170620,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 98.37,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.73,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 35.18,
    "out:Total CO2 (tons)": 119.92,
    "out:Klimatpaverkan": -97.08,
    "out:Initial Cost/MSEK": 3.930375,
    "out:Running cost/(Apt SEK y)": 29746,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": 0.158,
    "out:ROI% old": 14.86,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201485,
    "out:DH kr savings": 170620,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 125.33,
    "out:Primary Energy": 94.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.73,
    "out:CO2 Operational/m2": 16.65,
    "out:Total CO2/m2": 33.38,
    "out:Total CO2 (tons)": 113.78,
    "out:Klimatpaverkan": -103.22,
    "out:Initial Cost/MSEK": 4.015625,
    "out:Running cost/(Apt SEK y)": 28535,
    "out:Running Cost over RSP/MSEK": 8.263,
    "out:LCP/MSEK": 0.423,
    "out:ROI% old": 15.79,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 14,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216996,
    "out:DH kr savings": 186131,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 141.5,
    "out:Total Energy Use Post PV": 134.09,
    "out:Primary Energy": 98.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.59,
    "out:CO2 Operational/m2": -30.43,
    "out:Total CO2/m2": -1.85,
    "out:Total CO2 (tons)": -6.3,
    "out:Klimatpaverkan": -223.3,
    "out:Initial Cost/MSEK": 4.434625,
    "out:Running cost/(Apt SEK y)": 29595,
    "out:Running Cost over RSP/MSEK": 8.581,
    "out:LCP/MSEK": -0.314,
    "out:ROI% old": 13.27,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170450,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236354,
    "out:DH kr savings": 155110,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 94.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.59,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": -3.65,
    "out:Total CO2 (tons)": -12.43,
    "out:Klimatpaverkan": -229.43,
    "out:Initial Cost/MSEK": 4.519875,
    "out:Running cost/(Apt SEK y)": 28384,
    "out:Running Cost over RSP/MSEK": 8.231,
    "out:LCP/MSEK": -0.049,
    "out:ROI% old": 14.13,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 251865,
    "out:DH kr savings": 170620,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.67,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 96.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.08,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": -3.15,
    "out:Total CO2 (tons)": -10.74,
    "out:Klimatpaverkan": -227.74,
    "out:Initial Cost/MSEK": 4.6395,
    "out:Running cost/(Apt SEK y)": 28384,
    "out:Running Cost over RSP/MSEK": 8.231,
    "out:LCP/MSEK": -0.168,
    "out:ROI% old": 13.77,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 12,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251865,
    "out:DH kr savings": 170620,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 295469,
    "out:% savings (space heating)": 32.04,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 116.7,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.64,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 124.09,
    "out:Primary Energy": 92.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.08,
    "out:CO2 Operational/m2": -34.03,
    "out:Total CO2/m2": -4.95,
    "out:Total CO2 (tons)": -16.88,
    "out:Klimatpaverkan": -233.88,
    "out:Initial Cost/MSEK": 4.72475,
    "out:Running cost/(Apt SEK y)": 27173,
    "out:Running Cost over RSP/MSEK": 7.88,
    "out:LCP/MSEK": 0.097,
    "out:ROI% old": 14.58,
    "out:Payback discounted": 8,
    "out:Atemp": 3409,
    "out:Umean": 0.69,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 267376,
    "out:DH kr savings": 186131,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 278316,
    "out:% savings (space heating)": 35.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 111.67,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 112.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.67,
    "out:CO2 Operational/m2": 48.42,
    "out:Total CO2/m2": 58.09,
    "out:Total CO2 (tons)": 198.03,
    "out:Klimatpaverkan": -18.97,
    "out:Initial Cost/MSEK": 4.24975,
    "out:Running cost/(Apt SEK y)": 33964,
    "out:Running Cost over RSP/MSEK": 9.823,
    "out:LCP/MSEK": -1.371,
    "out:ROI% old": 9.68,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 27.2,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 368,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109844,
    "out:DH kr savings": 136496,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 142.5,
    "out:Primary Energy": 109.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.67,
    "out:CO2 Operational/m2": 46.62,
    "out:Total CO2/m2": 56.29,
    "out:Total CO2 (tons)": 191.9,
    "out:Klimatpaverkan": -25.1,
    "out:Initial Cost/MSEK": 4.335,
    "out:Running cost/(Apt SEK y)": 32753,
    "out:Running Cost over RSP/MSEK": 9.473,
    "out:LCP/MSEK": -1.105,
    "out:ROI% old": 10.64,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 31.7,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 167041,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 125355,
    "out:DH kr savings": 152007,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 140.5,
    "out:Primary Energy": 110.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.16,
    "out:CO2 Operational/m2": 45.9,
    "out:Total CO2/m2": 56.07,
    "out:Total CO2 (tons)": 191.13,
    "out:Klimatpaverkan": -25.87,
    "out:Initial Cost/MSEK": 4.45475,
    "out:Running cost/(Apt SEK y)": 32269,
    "out:Running Cost over RSP/MSEK": 9.332,
    "out:LCP/MSEK": -1.085,
    "out:ROI% old": 10.81,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173859,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131559,
    "out:DH kr savings": 158212,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 106.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.16,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 54.63,
    "out:Total CO2 (tons)": 186.23,
    "out:Klimatpaverkan": -30.77,
    "out:Initial Cost/MSEK": 4.539875,
    "out:Running cost/(Apt SEK y)": 31300,
    "out:Running Cost over RSP/MSEK": 9.052,
    "out:LCP/MSEK": -0.89,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 143968,
    "out:DH kr savings": 170620,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 141.33,
    "out:Primary Energy": 101.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.02,
    "out:CO2 Operational/m2": 22.41,
    "out:Total CO2/m2": 44.43,
    "out:Total CO2 (tons)": 151.46,
    "out:Klimatpaverkan": -65.54,
    "out:Initial Cost/MSEK": 4.958875,
    "out:Running cost/(Apt SEK y)": 32409,
    "out:Running Cost over RSP/MSEK": 9.385,
    "out:LCP/MSEK": -1.642,
    "out:ROI% old": 9.56,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 32.6,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 425,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167361,
    "out:DH kr savings": 136496,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 136.33,
    "out:Primary Energy": 98.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.02,
    "out:CO2 Operational/m2": 20.61,
    "out:Total CO2/m2": 42.63,
    "out:Total CO2 (tons)": 145.32,
    "out:Klimatpaverkan": -71.68,
    "out:Initial Cost/MSEK": 5.044125,
    "out:Running cost/(Apt SEK y)": 31198,
    "out:Running Cost over RSP/MSEK": 9.035,
    "out:LCP/MSEK": -1.377,
    "out:ROI% old": 10.39,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 470,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 167041,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 182872,
    "out:DH kr savings": 152007,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 134.33,
    "out:Primary Energy": 99.12,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.52,
    "out:CO2 Operational/m2": 19.89,
    "out:Total CO2/m2": 42.41,
    "out:Total CO2 (tons)": 144.56,
    "out:Klimatpaverkan": -72.44,
    "out:Initial Cost/MSEK": 5.16375,
    "out:Running cost/(Apt SEK y)": 30714,
    "out:Running Cost over RSP/MSEK": 8.895,
    "out:LCP/MSEK": -1.356,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 173859,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189076,
    "out:DH kr savings": 158212,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 95.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.52,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 40.97,
    "out:Total CO2 (tons)": 139.65,
    "out:Klimatpaverkan": -77.35,
    "out:Initial Cost/MSEK": 5.249,
    "out:Running cost/(Apt SEK y)": 29746,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": -1.161,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201485,
    "out:DH kr savings": 170620,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 147.5,
    "out:Total Energy Use Post PV": 140.09,
    "out:Primary Energy": 99.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.37,
    "out:CO2 Operational/m2": -28.27,
    "out:Total CO2/m2": 6.1,
    "out:Total CO2 (tons)": 20.8,
    "out:Klimatpaverkan": -196.2,
    "out:Initial Cost/MSEK": 5.668,
    "out:Running cost/(Apt SEK y)": 31048,
    "out:Running Cost over RSP/MSEK": 9.002,
    "out:LCP/MSEK": -1.968,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 33.6,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 475,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 149996,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217741,
    "out:DH kr savings": 136496,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 142.5,
    "out:Total Energy Use Post PV": 135.09,
    "out:Primary Energy": 96.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.37,
    "out:CO2 Operational/m2": -30.07,
    "out:Total CO2/m2": 4.3,
    "out:Total CO2 (tons)": 14.67,
    "out:Klimatpaverkan": -202.33,
    "out:Initial Cost/MSEK": 5.75325,
    "out:Running cost/(Apt SEK y)": 29837,
    "out:Running Cost over RSP/MSEK": 8.652,
    "out:LCP/MSEK": -1.703,
    "out:ROI% old": 10.06,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 38.5,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 167041,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233252,
    "out:DH kr savings": 152007,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 140.5,
    "out:Total Energy Use Post PV": 133.09,
    "out:Primary Energy": 96.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.87,
    "out:CO2 Operational/m2": -30.79,
    "out:Total CO2/m2": 4.08,
    "out:Total CO2 (tons)": 13.9,
    "out:Klimatpaverkan": -203.1,
    "out:Initial Cost/MSEK": 5.873,
    "out:Running cost/(Apt SEK y)": 29353,
    "out:Running Cost over RSP/MSEK": 8.511,
    "out:LCP/MSEK": -1.682,
    "out:ROI% old": 10.19,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 173859,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 239456,
    "out:DH kr savings": 158212,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 93.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.87,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": 2.64,
    "out:Total CO2 (tons)": 8.99,
    "out:Klimatpaverkan": -208.01,
    "out:Initial Cost/MSEK": 5.958125,
    "out:Running cost/(Apt SEK y)": 28384,
    "out:Running Cost over RSP/MSEK": 8.231,
    "out:LCP/MSEK": -1.487,
    "out:ROI% old": 10.72,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 251865,
    "out:DH kr savings": 170620,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 109.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.67,
    "out:CO2 Operational/m2": 45.18,
    "out:Total CO2/m2": 54.85,
    "out:Total CO2 (tons)": 186.99,
    "out:Klimatpaverkan": -30.01,
    "out:Initial Cost/MSEK": 4.3475,
    "out:Running cost/(Apt SEK y)": 31784,
    "out:Running Cost over RSP/MSEK": 9.192,
    "out:LCP/MSEK": -0.837,
    "out:ROI% old": 11.53,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 137764,
    "out:DH kr savings": 164416,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 105.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.67,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 53.05,
    "out:Total CO2 (tons)": 180.85,
    "out:Klimatpaverkan": -36.15,
    "out:Initial Cost/MSEK": 4.432625,
    "out:Running cost/(Apt SEK y)": 30574,
    "out:Running Cost over RSP/MSEK": 8.842,
    "out:LCP/MSEK": -0.572,
    "out:ROI% old": 12.44,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 197722,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153274,
    "out:DH kr savings": 179927,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 107.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.16,
    "out:CO2 Operational/m2": 43.38,
    "out:Total CO2/m2": 53.55,
    "out:Total CO2 (tons)": 182.54,
    "out:Klimatpaverkan": -34.46,
    "out:Initial Cost/MSEK": 4.552375,
    "out:Running cost/(Apt SEK y)": 30574,
    "out:Running Cost over RSP/MSEK": 8.842,
    "out:LCP/MSEK": -0.692,
    "out:ROI% old": 12.12,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 493,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 197722,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153274,
    "out:DH kr savings": 179927,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 103.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.16,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 51.75,
    "out:Total CO2 (tons)": 176.41,
    "out:Klimatpaverkan": -40.59,
    "out:Initial Cost/MSEK": 4.637625,
    "out:Running cost/(Apt SEK y)": 29363,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -0.426,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168785,
    "out:DH kr savings": 195438,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 132.33,
    "out:Primary Energy": 98.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.02,
    "out:CO2 Operational/m2": 19.17,
    "out:Total CO2/m2": 41.19,
    "out:Total CO2 (tons)": 140.42,
    "out:Klimatpaverkan": -76.58,
    "out:Initial Cost/MSEK": 5.0565,
    "out:Running cost/(Apt SEK y)": 30230,
    "out:Running Cost over RSP/MSEK": 8.754,
    "out:LCP/MSEK": -1.109,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195280,
    "out:DH kr savings": 164416,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 94.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.02,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 39.39,
    "out:Total CO2 (tons)": 134.28,
    "out:Klimatpaverkan": -82.72,
    "out:Initial Cost/MSEK": 5.14175,
    "out:Running cost/(Apt SEK y)": 29019,
    "out:Running Cost over RSP/MSEK": 8.404,
    "out:LCP/MSEK": -0.843,
    "out:ROI% old": 11.94,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 197722,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210791,
    "out:DH kr savings": 179927,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 127.33,
    "out:Primary Energy": 96.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.52,
    "out:CO2 Operational/m2": 17.37,
    "out:Total CO2/m2": 39.89,
    "out:Total CO2 (tons)": 135.97,
    "out:Klimatpaverkan": -81.03,
    "out:Initial Cost/MSEK": 5.2615,
    "out:Running cost/(Apt SEK y)": 29019,
    "out:Running Cost over RSP/MSEK": 8.404,
    "out:LCP/MSEK": -0.963,
    "out:ROI% old": 11.67,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 197722,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210791,
    "out:DH kr savings": 179927,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 92.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.52,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 38.09,
    "out:Total CO2 (tons)": 129.84,
    "out:Klimatpaverkan": -87.16,
    "out:Initial Cost/MSEK": 5.34675,
    "out:Running cost/(Apt SEK y)": 27808,
    "out:Running Cost over RSP/MSEK": 8.053,
    "out:LCP/MSEK": -0.698,
    "out:ROI% old": 12.42,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226302,
    "out:DH kr savings": 195438,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 131.09,
    "out:Primary Energy": 96.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.37,
    "out:CO2 Operational/m2": -31.51,
    "out:Total CO2/m2": 2.86,
    "out:Total CO2 (tons)": 9.76,
    "out:Klimatpaverkan": -207.24,
    "out:Initial Cost/MSEK": 5.765625,
    "out:Running cost/(Apt SEK y)": 28868,
    "out:Running Cost over RSP/MSEK": 8.371,
    "out:LCP/MSEK": -1.435,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245661,
    "out:DH kr savings": 164416,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 92.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.37,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 1.06,
    "out:Total CO2 (tons)": 3.62,
    "out:Klimatpaverkan": -213.38,
    "out:Initial Cost/MSEK": 5.850875,
    "out:Running cost/(Apt SEK y)": 27658,
    "out:Running Cost over RSP/MSEK": 8.021,
    "out:LCP/MSEK": -1.169,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 197722,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 261172,
    "out:DH kr savings": 179927,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.5,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 133.5,
    "out:Total Energy Use Post PV": 126.09,
    "out:Primary Energy": 93.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.87,
    "out:CO2 Operational/m2": -33.31,
    "out:Total CO2/m2": 1.56,
    "out:Total CO2 (tons)": 5.31,
    "out:Klimatpaverkan": -211.69,
    "out:Initial Cost/MSEK": 5.970625,
    "out:Running cost/(Apt SEK y)": 27658,
    "out:Running Cost over RSP/MSEK": 8.021,
    "out:LCP/MSEK": -1.289,
    "out:ROI% old": 11.2,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 197722,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 261172,
    "out:DH kr savings": 179927,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 284656,
    "out:% savings (space heating)": 34.53,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 113.53,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.61,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 90.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.87,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": -0.24,
    "out:Total CO2 (tons)": -0.82,
    "out:Klimatpaverkan": -217.82,
    "out:Initial Cost/MSEK": 6.055875,
    "out:Running cost/(Apt SEK y)": 26447,
    "out:Running Cost over RSP/MSEK": 7.67,
    "out:LCP/MSEK": -1.024,
    "out:ROI% old": 11.87,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.65,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 1.12,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276683,
    "out:DH kr savings": 195438,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 267994,
    "out:% savings (space heating)": 38.36,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 108.64,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 143.5,
    "out:Primary Energy": 109.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.13,
    "out:CO2 Operational/m2": 46.98,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 201.52,
    "out:Klimatpaverkan": -15.48,
    "out:Initial Cost/MSEK": 4.92825,
    "out:Running cost/(Apt SEK y)": 32995,
    "out:Running Cost over RSP/MSEK": 9.543,
    "out:LCP/MSEK": -1.769,
    "out:ROI% old": 9.16,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 30.8,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 403,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 122253,
    "out:DH kr savings": 148905,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 106.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.13,
    "out:CO2 Operational/m2": 45.18,
    "out:Total CO2/m2": 57.31,
    "out:Total CO2 (tons)": 195.39,
    "out:Klimatpaverkan": -21.61,
    "out:Initial Cost/MSEK": 5.0135,
    "out:Running cost/(Apt SEK y)": 31784,
    "out:Running Cost over RSP/MSEK": 9.192,
    "out:LCP/MSEK": -1.503,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 448,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137764,
    "out:DH kr savings": 164416,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 107.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.63,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 57.09,
    "out:Total CO2 (tons)": 194.62,
    "out:Klimatpaverkan": -22.38,
    "out:Initial Cost/MSEK": 5.13325,
    "out:Running cost/(Apt SEK y)": 31300,
    "out:Running Cost over RSP/MSEK": 9.052,
    "out:LCP/MSEK": -1.483,
    "out:ROI% old": 10.16,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143968,
    "out:DH kr savings": 170620,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 103.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.63,
    "out:CO2 Operational/m2": 42.66,
    "out:Total CO2/m2": 55.29,
    "out:Total CO2 (tons)": 188.49,
    "out:Klimatpaverkan": -28.51,
    "out:Initial Cost/MSEK": 5.218375,
    "out:Running cost/(Apt SEK y)": 30089,
    "out:Running Cost over RSP/MSEK": 8.701,
    "out:LCP/MSEK": -1.217,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159479,
    "out:DH kr savings": 186131,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 137.33,
    "out:Primary Energy": 98.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.48,
    "out:CO2 Operational/m2": 20.97,
    "out:Total CO2/m2": 45.45,
    "out:Total CO2 (tons)": 154.95,
    "out:Klimatpaverkan": -62.05,
    "out:Initial Cost/MSEK": 5.637375,
    "out:Running cost/(Apt SEK y)": 31441,
    "out:Running Cost over RSP/MSEK": 9.105,
    "out:LCP/MSEK": -2.04,
    "out:ROI% old": 9.12,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 36.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 461,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179770,
    "out:DH kr savings": 148905,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 132.33,
    "out:Primary Energy": 95.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.48,
    "out:CO2 Operational/m2": 19.17,
    "out:Total CO2/m2": 43.65,
    "out:Total CO2 (tons)": 148.81,
    "out:Klimatpaverkan": -68.19,
    "out:Initial Cost/MSEK": 5.722625,
    "out:Running cost/(Apt SEK y)": 30230,
    "out:Running Cost over RSP/MSEK": 8.754,
    "out:LCP/MSEK": -1.775,
    "out:ROI% old": 9.86,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195280,
    "out:DH kr savings": 164416,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 96.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.98,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 43.43,
    "out:Total CO2 (tons)": 148.05,
    "out:Klimatpaverkan": -68.95,
    "out:Initial Cost/MSEK": 5.84225,
    "out:Running cost/(Apt SEK y)": 29746,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": -1.754,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201485,
    "out:DH kr savings": 170620,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 125.33,
    "out:Primary Energy": 92.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.98,
    "out:CO2 Operational/m2": 16.65,
    "out:Total CO2/m2": 41.63,
    "out:Total CO2 (tons)": 141.92,
    "out:Klimatpaverkan": -75.08,
    "out:Initial Cost/MSEK": 5.9275,
    "out:Running cost/(Apt SEK y)": 28535,
    "out:Running Cost over RSP/MSEK": 8.263,
    "out:LCP/MSEK": -1.489,
    "out:ROI% old": 10.7,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 216996,
    "out:DH kr savings": 186131,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 143.5,
    "out:Total Energy Use Post PV": 136.09,
    "out:Primary Energy": 96.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.84,
    "out:CO2 Operational/m2": -29.71,
    "out:Total CO2/m2": 7.13,
    "out:Total CO2 (tons)": 24.29,
    "out:Klimatpaverkan": -192.71,
    "out:Initial Cost/MSEK": 6.3465,
    "out:Running cost/(Apt SEK y)": 30079,
    "out:Running Cost over RSP/MSEK": 8.722,
    "out:LCP/MSEK": -2.366,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163632,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 230150,
    "out:DH kr savings": 148905,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 138.5,
    "out:Total Energy Use Post PV": 131.09,
    "out:Primary Energy": 93.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.84,
    "out:CO2 Operational/m2": -31.51,
    "out:Total CO2/m2": 5.33,
    "out:Total CO2 (tons)": 18.15,
    "out:Klimatpaverkan": -198.85,
    "out:Initial Cost/MSEK": 6.43175,
    "out:Running cost/(Apt SEK y)": 28868,
    "out:Running Cost over RSP/MSEK": 8.371,
    "out:LCP/MSEK": -2.101,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 180677,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 245661,
    "out:DH kr savings": 164416,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 93.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.33,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": 5.1,
    "out:Total CO2 (tons)": 17.39,
    "out:Klimatpaverkan": -199.61,
    "out:Initial Cost/MSEK": 6.551375,
    "out:Running cost/(Apt SEK y)": 28384,
    "out:Running Cost over RSP/MSEK": 8.231,
    "out:LCP/MSEK": -2.08,
    "out:ROI% old": 9.75,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251865,
    "out:DH kr savings": 170620,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 124.09,
    "out:Primary Energy": 90.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.33,
    "out:CO2 Operational/m2": -34.03,
    "out:Total CO2/m2": 3.3,
    "out:Total CO2 (tons)": 11.26,
    "out:Klimatpaverkan": -205.74,
    "out:Initial Cost/MSEK": 6.636625,
    "out:Running cost/(Apt SEK y)": 27173,
    "out:Running Cost over RSP/MSEK": 7.88,
    "out:LCP/MSEK": -1.815,
    "out:ROI% old": 10.38,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 267376,
    "out:DH kr savings": 186131,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 106.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.13,
    "out:CO2 Operational/m2": 43.74,
    "out:Total CO2/m2": 55.87,
    "out:Total CO2 (tons)": 190.48,
    "out:Klimatpaverkan": -26.52,
    "out:Initial Cost/MSEK": 5.025875,
    "out:Running cost/(Apt SEK y)": 30816,
    "out:Running Cost over RSP/MSEK": 8.912,
    "out:LCP/MSEK": -1.235,
    "out:ROI% old": 10.77,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 484,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 194313,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 150172,
    "out:DH kr savings": 176825,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 102.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.13,
    "out:CO2 Operational/m2": 41.94,
    "out:Total CO2/m2": 54.07,
    "out:Total CO2 (tons)": 184.34,
    "out:Klimatpaverkan": -32.66,
    "out:Initial Cost/MSEK": 5.111125,
    "out:Running cost/(Apt SEK y)": 29605,
    "out:Running Cost over RSP/MSEK": 8.561,
    "out:LCP/MSEK": -0.97,
    "out:ROI% old": 11.57,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211358,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165683,
    "out:DH kr savings": 192336,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 104.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.63,
    "out:CO2 Operational/m2": 41.94,
    "out:Total CO2/m2": 54.57,
    "out:Total CO2 (tons)": 186.03,
    "out:Klimatpaverkan": -30.97,
    "out:Initial Cost/MSEK": 5.230875,
    "out:Running cost/(Apt SEK y)": 29605,
    "out:Running Cost over RSP/MSEK": 8.561,
    "out:LCP/MSEK": -1.09,
    "out:ROI% old": 11.31,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 529,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 211358,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165683,
    "out:DH kr savings": 192336,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 124.5,
    "out:Primary Energy": 100.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.63,
    "out:CO2 Operational/m2": 40.14,
    "out:Total CO2/m2": 52.77,
    "out:Total CO2 (tons)": 179.9,
    "out:Klimatpaverkan": -37.1,
    "out:Initial Cost/MSEK": 5.316125,
    "out:Running cost/(Apt SEK y)": 28394,
    "out:Running Cost over RSP/MSEK": 8.21,
    "out:LCP/MSEK": -0.824,
    "out:ROI% old": 12.07,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 228403,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181194,
    "out:DH kr savings": 207847,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 128.33,
    "out:Primary Energy": 95.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.48,
    "out:CO2 Operational/m2": 17.73,
    "out:Total CO2/m2": 42.21,
    "out:Total CO2 (tons)": 143.91,
    "out:Klimatpaverkan": -73.09,
    "out:Initial Cost/MSEK": 5.735,
    "out:Running cost/(Apt SEK y)": 29261,
    "out:Running Cost over RSP/MSEK": 8.474,
    "out:LCP/MSEK": -1.507,
    "out:ROI% old": 10.53,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 542,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 194313,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207689,
    "out:DH kr savings": 176825,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 123.33,
    "out:Primary Energy": 91.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.48,
    "out:CO2 Operational/m2": 15.93,
    "out:Total CO2/m2": 40.41,
    "out:Total CO2 (tons)": 137.77,
    "out:Klimatpaverkan": -79.23,
    "out:Initial Cost/MSEK": 5.82025,
    "out:Running cost/(Apt SEK y)": 28051,
    "out:Running Cost over RSP/MSEK": 8.123,
    "out:LCP/MSEK": -1.241,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211358,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223200,
    "out:DH kr savings": 192336,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 123.33,
    "out:Primary Energy": 93.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.98,
    "out:CO2 Operational/m2": 15.93,
    "out:Total CO2/m2": 40.91,
    "out:Total CO2 (tons)": 139.46,
    "out:Klimatpaverkan": -77.54,
    "out:Initial Cost/MSEK": 5.94,
    "out:Running cost/(Apt SEK y)": 28051,
    "out:Running Cost over RSP/MSEK": 8.123,
    "out:LCP/MSEK": -1.361,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211358,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223200,
    "out:DH kr savings": 192336,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 118.33,
    "out:Primary Energy": 89.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.98,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 39.11,
    "out:Total CO2 (tons)": 133.33,
    "out:Klimatpaverkan": -83.67,
    "out:Initial Cost/MSEK": 6.025125,
    "out:Running cost/(Apt SEK y)": 26840,
    "out:Running Cost over RSP/MSEK": 7.773,
    "out:LCP/MSEK": -1.096,
    "out:ROI% old": 11.69,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 631,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228403,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 238711,
    "out:DH kr savings": 207847,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 134.5,
    "out:Total Energy Use Post PV": 127.09,
    "out:Primary Energy": 92.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.84,
    "out:CO2 Operational/m2": -32.95,
    "out:Total CO2/m2": 3.89,
    "out:Total CO2 (tons)": 13.25,
    "out:Klimatpaverkan": -203.75,
    "out:Initial Cost/MSEK": 6.444125,
    "out:Running cost/(Apt SEK y)": 27900,
    "out:Running Cost over RSP/MSEK": 8.091,
    "out:LCP/MSEK": -1.833,
    "out:ROI% old": 10.22,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 194313,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 258069,
    "out:DH kr savings": 176825,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 122.09,
    "out:Primary Energy": 89.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.84,
    "out:CO2 Operational/m2": -34.75,
    "out:Total CO2/m2": 2.09,
    "out:Total CO2 (tons)": 7.11,
    "out:Klimatpaverkan": -209.89,
    "out:Initial Cost/MSEK": 6.529375,
    "out:Running cost/(Apt SEK y)": 26689,
    "out:Running Cost over RSP/MSEK": 7.74,
    "out:LCP/MSEK": -1.567,
    "out:ROI% old": 10.86,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 637,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211358,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273580,
    "out:DH kr savings": 192336,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.1,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 129.5,
    "out:Total Energy Use Post PV": 122.09,
    "out:Primary Energy": 90.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.33,
    "out:CO2 Operational/m2": -34.75,
    "out:Total CO2/m2": 2.58,
    "out:Total CO2 (tons)": 8.8,
    "out:Klimatpaverkan": -208.2,
    "out:Initial Cost/MSEK": 6.649125,
    "out:Running cost/(Apt SEK y)": 26689,
    "out:Running Cost over RSP/MSEK": 7.74,
    "out:LCP/MSEK": -1.687,
    "out:ROI% old": 10.66,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 637,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 211358,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273580,
    "out:DH kr savings": 192336,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 269651,
    "out:% savings (space heating)": 37.98,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 109.13,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 117.09,
    "out:Primary Energy": 87.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.33,
    "out:CO2 Operational/m2": -36.55,
    "out:Total CO2/m2": 0.78,
    "out:Total CO2 (tons)": 2.67,
    "out:Klimatpaverkan": -214.33,
    "out:Initial Cost/MSEK": 6.73425,
    "out:Running cost/(Apt SEK y)": 25478,
    "out:Running Cost over RSP/MSEK": 7.39,
    "out:LCP/MSEK": -1.422,
    "out:ROI% old": 11.27,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.56,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228403,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289091,
    "out:DH kr savings": 207847,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 253671,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 104.44,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 101.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.31,
    "out:CO2 Operational/m2": 42.66,
    "out:Total CO2/m2": 56.98,
    "out:Total CO2 (tons)": 194.23,
    "out:Klimatpaverkan": -22.77,
    "out:Initial Cost/MSEK": 5.471875,
    "out:Running cost/(Apt SEK y)": 30089,
    "out:Running Cost over RSP/MSEK": 8.701,
    "out:LCP/MSEK": -1.471,
    "out:ROI% old": 10.45,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 42.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 511,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 159479,
    "out:DH kr savings": 186131,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 99.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.31,
    "out:CO2 Operational/m2": 41.22,
    "out:Total CO2/m2": 55.54,
    "out:Total CO2 (tons)": 189.32,
    "out:Klimatpaverkan": -27.68,
    "out:Initial Cost/MSEK": 5.557125,
    "out:Running cost/(Apt SEK y)": 29121,
    "out:Running Cost over RSP/MSEK": 8.421,
    "out:LCP/MSEK": -1.276,
    "out:ROI% old": 11.01,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 47.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 218176,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171888,
    "out:DH kr savings": 198540,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 99.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.81,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 55.31,
    "out:Total CO2 (tons)": 188.56,
    "out:Klimatpaverkan": -28.44,
    "out:Initial Cost/MSEK": 5.67675,
    "out:Running cost/(Apt SEK y)": 28636,
    "out:Running Cost over RSP/MSEK": 8.281,
    "out:LCP/MSEK": -1.255,
    "out:ROI% old": 11.13,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 224994,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178092,
    "out:DH kr savings": 204745,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 96.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.81,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 53.87,
    "out:Total CO2 (tons)": 183.65,
    "out:Klimatpaverkan": -33.35,
    "out:Initial Cost/MSEK": 5.762,
    "out:Running cost/(Apt SEK y)": 27668,
    "out:Running Cost over RSP/MSEK": 8,
    "out:LCP/MSEK": -1.06,
    "out:ROI% old": 11.66,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190501,
    "out:DH kr savings": 217153,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 125.33,
    "out:Primary Energy": 90.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.66,
    "out:CO2 Operational/m2": 16.65,
    "out:Total CO2/m2": 43.31,
    "out:Total CO2 (tons)": 147.66,
    "out:Klimatpaverkan": -69.34,
    "out:Initial Cost/MSEK": 6.181,
    "out:Running cost/(Apt SEK y)": 28535,
    "out:Running Cost over RSP/MSEK": 8.263,
    "out:LCP/MSEK": -1.742,
    "out:ROI% old": 10.26,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 568,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 216996,
    "out:DH kr savings": 186131,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 121.33,
    "out:Primary Energy": 87.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.66,
    "out:CO2 Operational/m2": 15.21,
    "out:Total CO2/m2": 41.87,
    "out:Total CO2 (tons)": 142.75,
    "out:Klimatpaverkan": -74.25,
    "out:Initial Cost/MSEK": 6.26625,
    "out:Running cost/(Apt SEK y)": 27566,
    "out:Running Cost over RSP/MSEK": 7.983,
    "out:LCP/MSEK": -1.547,
    "out:ROI% old": 10.76,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218176,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 229405,
    "out:DH kr savings": 198540,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 88.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.16,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 41.65,
    "out:Total CO2 (tons)": 141.99,
    "out:Klimatpaverkan": -75.01,
    "out:Initial Cost/MSEK": 6.385875,
    "out:Running cost/(Apt SEK y)": 27082,
    "out:Running Cost over RSP/MSEK": 7.843,
    "out:LCP/MSEK": -1.526,
    "out:ROI% old": 10.87,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 224994,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235609,
    "out:DH kr savings": 204745,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 85.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.16,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 40.21,
    "out:Total CO2 (tons)": 137.08,
    "out:Klimatpaverkan": -79.92,
    "out:Initial Cost/MSEK": 6.471125,
    "out:Running cost/(Apt SEK y)": 26113,
    "out:Running Cost over RSP/MSEK": 7.562,
    "out:LCP/MSEK": -1.331,
    "out:ROI% old": 11.35,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248018,
    "out:DH kr savings": 217153,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 131.5,
    "out:Total Energy Use Post PV": 124.09,
    "out:Primary Energy": 88.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.02,
    "out:CO2 Operational/m2": -34.03,
    "out:Total CO2/m2": 4.99,
    "out:Total CO2 (tons)": 17,
    "out:Klimatpaverkan": -200,
    "out:Initial Cost/MSEK": 6.890125,
    "out:Running cost/(Apt SEK y)": 27173,
    "out:Running Cost over RSP/MSEK": 7.88,
    "out:LCP/MSEK": -2.068,
    "out:ROI% old": 10,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 204540,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267376,
    "out:DH kr savings": 186131,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 127.5,
    "out:Total Energy Use Post PV": 120.09,
    "out:Primary Energy": 85.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.02,
    "out:CO2 Operational/m2": -35.47,
    "out:Total CO2/m2": 3.55,
    "out:Total CO2 (tons)": 12.09,
    "out:Klimatpaverkan": -204.91,
    "out:Initial Cost/MSEK": 6.975375,
    "out:Running cost/(Apt SEK y)": 26205,
    "out:Running Cost over RSP/MSEK": 7.6,
    "out:LCP/MSEK": -1.873,
    "out:ROI% old": 10.45,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 107,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 655,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218176,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279785,
    "out:DH kr savings": 198540,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 85.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.51,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 3.32,
    "out:Total CO2 (tons)": 11.33,
    "out:Klimatpaverkan": -205.67,
    "out:Initial Cost/MSEK": 7.095,
    "out:Running cost/(Apt SEK y)": 25720,
    "out:Running Cost over RSP/MSEK": 7.46,
    "out:LCP/MSEK": -1.852,
    "out:ROI% old": 10.56,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 224994,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285989,
    "out:DH kr savings": 204745,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 83.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.51,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 1.88,
    "out:Total CO2 (tons)": 6.42,
    "out:Klimatpaverkan": -210.58,
    "out:Initial Cost/MSEK": 7.18025,
    "out:Running cost/(Apt SEK y)": 24752,
    "out:Running Cost over RSP/MSEK": 7.179,
    "out:LCP/MSEK": -1.657,
    "out:ROI% old": 10.99,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298398,
    "out:DH kr savings": 217153,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 98.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.31,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 54.1,
    "out:Total CO2 (tons)": 184.41,
    "out:Klimatpaverkan": -32.59,
    "out:Initial Cost/MSEK": 5.5695,
    "out:Running cost/(Apt SEK y)": 28152,
    "out:Running Cost over RSP/MSEK": 8.14,
    "out:LCP/MSEK": -1.008,
    "out:ROI% old": 11.7,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184296,
    "out:DH kr savings": 210949,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 95.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.31,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 52.66,
    "out:Total CO2 (tons)": 179.5,
    "out:Klimatpaverkan": -37.5,
    "out:Initial Cost/MSEK": 5.65475,
    "out:Running cost/(Apt SEK y)": 27184,
    "out:Running Cost over RSP/MSEK": 7.86,
    "out:LCP/MSEK": -0.812,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196705,
    "out:DH kr savings": 223358,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 96.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.81,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 52.43,
    "out:Total CO2 (tons)": 178.74,
    "out:Klimatpaverkan": -38.26,
    "out:Initial Cost/MSEK": 5.7745,
    "out:Running cost/(Apt SEK y)": 26699,
    "out:Running Cost over RSP/MSEK": 7.72,
    "out:LCP/MSEK": -0.792,
    "out:ROI% old": 12.33,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202909,
    "out:DH kr savings": 229562,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 93.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.81,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 50.99,
    "out:Total CO2 (tons)": 173.83,
    "out:Klimatpaverkan": -43.17,
    "out:Initial Cost/MSEK": 5.859625,
    "out:Running cost/(Apt SEK y)": 25731,
    "out:Running Cost over RSP/MSEK": 7.439,
    "out:LCP/MSEK": -0.597,
    "out:ROI% old": 12.83,
    "out:Payback discounted": 9,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215318,
    "out:DH kr savings": 241971,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 87.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.66,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 40.43,
    "out:Total CO2 (tons)": 137.84,
    "out:Klimatpaverkan": -79.16,
    "out:Initial Cost/MSEK": 6.278625,
    "out:Running cost/(Apt SEK y)": 26598,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": -1.279,
    "out:ROI% old": 11.38,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241813,
    "out:DH kr savings": 210949,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 84.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.66,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 38.99,
    "out:Total CO2 (tons)": 132.93,
    "out:Klimatpaverkan": -84.07,
    "out:Initial Cost/MSEK": 6.363875,
    "out:Running cost/(Apt SEK y)": 25629,
    "out:Running Cost over RSP/MSEK": 7.422,
    "out:LCP/MSEK": -1.084,
    "out:ROI% old": 11.85,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254222,
    "out:DH kr savings": 223358,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 85.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.16,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 38.77,
    "out:Total CO2 (tons)": 132.17,
    "out:Klimatpaverkan": -84.83,
    "out:Initial Cost/MSEK": 6.4835,
    "out:Running cost/(Apt SEK y)": 25145,
    "out:Running Cost over RSP/MSEK": 7.282,
    "out:LCP/MSEK": -1.063,
    "out:ROI% old": 11.94,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260426,
    "out:DH kr savings": 229562,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 82.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.16,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 37.33,
    "out:Total CO2 (tons)": 127.26,
    "out:Klimatpaverkan": -89.74,
    "out:Initial Cost/MSEK": 6.56875,
    "out:Running cost/(Apt SEK y)": 24176,
    "out:Running Cost over RSP/MSEK": 7.001,
    "out:LCP/MSEK": -0.868,
    "out:ROI% old": 12.4,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272835,
    "out:DH kr savings": 241971,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 85.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.02,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 2.11,
    "out:Total CO2 (tons)": 7.18,
    "out:Klimatpaverkan": -209.82,
    "out:Initial Cost/MSEK": 6.98775,
    "out:Running cost/(Apt SEK y)": 25236,
    "out:Running Cost over RSP/MSEK": 7.319,
    "out:LCP/MSEK": -1.605,
    "out:ROI% old": 11,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292193,
    "out:DH kr savings": 210949,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 82.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.02,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 0.67,
    "out:Total CO2 (tons)": 2.27,
    "out:Klimatpaverkan": -214.73,
    "out:Initial Cost/MSEK": 7.073,
    "out:Running cost/(Apt SEK y)": 24267,
    "out:Running Cost over RSP/MSEK": 7.039,
    "out:LCP/MSEK": -1.41,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304602,
    "out:DH kr savings": 223358,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.91,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.51,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 0.44,
    "out:Total CO2 (tons)": 1.51,
    "out:Klimatpaverkan": -215.49,
    "out:Initial Cost/MSEK": 7.192625,
    "out:Running cost/(Apt SEK y)": 23783,
    "out:Running Cost over RSP/MSEK": 6.899,
    "out:LCP/MSEK": -1.389,
    "out:ROI% old": 11.53,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310807,
    "out:DH kr savings": 229562,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 231492,
    "out:% savings (space heating)": 46.75,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 97.93,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 80.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.51,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": -1,
    "out:Total CO2 (tons)": -3.4,
    "out:Klimatpaverkan": -220.4,
    "out:Initial Cost/MSEK": 7.277875,
    "out:Running cost/(Apt SEK y)": 22815,
    "out:Running Cost over RSP/MSEK": 6.618,
    "out:LCP/MSEK": -1.194,
    "out:ROI% old": 11.94,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.42,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323215,
    "out:DH kr savings": 241971,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 217246,
    "out:% savings (space heating)": 50.03,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 93.75,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 99.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.1,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 61.68,
    "out:Total CO2 (tons)": 210.28,
    "out:Klimatpaverkan": -6.72,
    "out:Initial Cost/MSEK": 6.802875,
    "out:Running cost/(Apt SEK y)": 29363,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -2.592,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168785,
    "out:DH kr savings": 195438,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 124.5,
    "out:Primary Energy": 96.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.1,
    "out:CO2 Operational/m2": 40.14,
    "out:Total CO2/m2": 60.24,
    "out:Total CO2 (tons)": 205.37,
    "out:Klimatpaverkan": -11.63,
    "out:Initial Cost/MSEK": 6.888125,
    "out:Running cost/(Apt SEK y)": 28394,
    "out:Running Cost over RSP/MSEK": 8.21,
    "out:LCP/MSEK": -2.396,
    "out:ROI% old": 9.32,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 228403,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 181194,
    "out:DH kr savings": 207847,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 96.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.6,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 60.02,
    "out:Total CO2 (tons)": 204.61,
    "out:Klimatpaverkan": -12.39,
    "out:Initial Cost/MSEK": 7.007875,
    "out:Running cost/(Apt SEK y)": 27910,
    "out:Running Cost over RSP/MSEK": 8.07,
    "out:LCP/MSEK": -2.376,
    "out:ROI% old": 9.44,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235221,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187399,
    "out:DH kr savings": 214051,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 94.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.6,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 58.58,
    "out:Total CO2 (tons)": 199.7,
    "out:Klimatpaverkan": -17.3,
    "out:Initial Cost/MSEK": 7.093,
    "out:Running cost/(Apt SEK y)": 26941,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": -2.181,
    "out:ROI% old": 9.89,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199807,
    "out:DH kr savings": 226460,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 88.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.45,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 48.02,
    "out:Total CO2 (tons)": 163.71,
    "out:Klimatpaverkan": -53.29,
    "out:Initial Cost/MSEK": 7.512,
    "out:Running cost/(Apt SEK y)": 27808,
    "out:Running Cost over RSP/MSEK": 8.053,
    "out:LCP/MSEK": -2.863,
    "out:ROI% old": 8.84,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226302,
    "out:DH kr savings": 195438,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 118.33,
    "out:Primary Energy": 85.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.45,
    "out:CO2 Operational/m2": 14.13,
    "out:Total CO2/m2": 46.58,
    "out:Total CO2 (tons)": 158.8,
    "out:Klimatpaverkan": -58.2,
    "out:Initial Cost/MSEK": 7.59725,
    "out:Running cost/(Apt SEK y)": 26840,
    "out:Running Cost over RSP/MSEK": 7.773,
    "out:LCP/MSEK": -2.668,
    "out:ROI% old": 9.27,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 631,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 228403,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 238711,
    "out:DH kr savings": 207847,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 85.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.95,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 46.36,
    "out:Total CO2 (tons)": 158.04,
    "out:Klimatpaverkan": -58.96,
    "out:Initial Cost/MSEK": 7.716875,
    "out:Running cost/(Apt SEK y)": 26355,
    "out:Running Cost over RSP/MSEK": 7.632,
    "out:LCP/MSEK": -2.647,
    "out:ROI% old": 9.39,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235221,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 244916,
    "out:DH kr savings": 214051,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 83.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.95,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 44.92,
    "out:Total CO2 (tons)": 153.13,
    "out:Klimatpaverkan": -63.87,
    "out:Initial Cost/MSEK": 7.802125,
    "out:Running cost/(Apt SEK y)": 25387,
    "out:Running Cost over RSP/MSEK": 7.352,
    "out:LCP/MSEK": -2.452,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257324,
    "out:DH kr savings": 226460,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 86.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.81,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 9.69,
    "out:Total CO2 (tons)": 33.05,
    "out:Klimatpaverkan": -183.95,
    "out:Initial Cost/MSEK": 8.221125,
    "out:Running cost/(Apt SEK y)": 26447,
    "out:Running Cost over RSP/MSEK": 7.67,
    "out:LCP/MSEK": -3.189,
    "out:ROI% old": 8.74,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276683,
    "out:DH kr savings": 195438,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 124.5,
    "out:Total Energy Use Post PV": 117.09,
    "out:Primary Energy": 83.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.81,
    "out:CO2 Operational/m2": -36.55,
    "out:Total CO2/m2": 8.25,
    "out:Total CO2 (tons)": 28.14,
    "out:Klimatpaverkan": -188.86,
    "out:Initial Cost/MSEK": 8.306375,
    "out:Running cost/(Apt SEK y)": 25478,
    "out:Running Cost over RSP/MSEK": 7.39,
    "out:LCP/MSEK": -2.994,
    "out:ROI% old": 9.14,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 682,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 228403,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289091,
    "out:DH kr savings": 207847,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 83.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.3,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": 8.03,
    "out:Total CO2 (tons)": 27.38,
    "out:Klimatpaverkan": -189.62,
    "out:Initial Cost/MSEK": 8.426125,
    "out:Running cost/(Apt SEK y)": 24994,
    "out:Running Cost over RSP/MSEK": 7.249,
    "out:LCP/MSEK": -2.973,
    "out:ROI% old": 9.24,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 699,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 235221,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295296,
    "out:DH kr savings": 214051,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 80.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.3,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 6.59,
    "out:Total CO2 (tons)": 22.47,
    "out:Klimatpaverkan": -194.53,
    "out:Initial Cost/MSEK": 8.51125,
    "out:Running cost/(Apt SEK y)": 24025,
    "out:Running Cost over RSP/MSEK": 6.969,
    "out:LCP/MSEK": -2.778,
    "out:ROI% old": 9.62,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 307704,
    "out:DH kr savings": 226460,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 96.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.1,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 58.44,
    "out:Total CO2 (tons)": 199.24,
    "out:Klimatpaverkan": -17.76,
    "out:Initial Cost/MSEK": 6.900625,
    "out:Running cost/(Apt SEK y)": 27184,
    "out:Running Cost over RSP/MSEK": 7.86,
    "out:LCP/MSEK": -2.058,
    "out:ROI% old": 10.02,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196705,
    "out:DH kr savings": 223358,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 93.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.1,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 57,
    "out:Total CO2 (tons)": 194.33,
    "out:Klimatpaverkan": -22.67,
    "out:Initial Cost/MSEK": 6.98575,
    "out:Running cost/(Apt SEK y)": 26215,
    "out:Running Cost over RSP/MSEK": 7.579,
    "out:LCP/MSEK": -1.863,
    "out:ROI% old": 10.48,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209114,
    "out:DH kr savings": 235766,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 94.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.6,
    "out:CO2 Operational/m2": 36.54,
    "out:Total CO2/m2": 57.14,
    "out:Total CO2 (tons)": 194.79,
    "out:Klimatpaverkan": -22.21,
    "out:Initial Cost/MSEK": 7.1055,
    "out:Running cost/(Apt SEK y)": 25973,
    "out:Running Cost over RSP/MSEK": 7.509,
    "out:LCP/MSEK": -1.913,
    "out:ROI% old": 10.44,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212216,
    "out:DH kr savings": 238869,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 110.5,
    "out:Primary Energy": 91.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.6,
    "out:CO2 Operational/m2": 35.1,
    "out:Total CO2/m2": 55.7,
    "out:Total CO2 (tons)": 189.88,
    "out:Klimatpaverkan": -27.12,
    "out:Initial Cost/MSEK": 7.19075,
    "out:Running cost/(Apt SEK y)": 25004,
    "out:Running Cost over RSP/MSEK": 7.229,
    "out:LCP/MSEK": -1.717,
    "out:ROI% old": 10.87,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 699,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 276129,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 224625,
    "out:DH kr savings": 251277,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 85.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.45,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 44.78,
    "out:Total CO2 (tons)": 152.66,
    "out:Klimatpaverkan": -64.34,
    "out:Initial Cost/MSEK": 7.609625,
    "out:Running cost/(Apt SEK y)": 25629,
    "out:Running Cost over RSP/MSEK": 7.422,
    "out:LCP/MSEK": -2.329,
    "out:ROI% old": 9.91,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254222,
    "out:DH kr savings": 223358,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 82.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.45,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 43.34,
    "out:Total CO2 (tons)": 147.76,
    "out:Klimatpaverkan": -69.24,
    "out:Initial Cost/MSEK": 7.694875,
    "out:Running cost/(Apt SEK y)": 24660,
    "out:Running Cost over RSP/MSEK": 7.142,
    "out:LCP/MSEK": -2.134,
    "out:ROI% old": 10.32,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 266631,
    "out:DH kr savings": 235766,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 108.33,
    "out:Primary Energy": 82.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.95,
    "out:CO2 Operational/m2": 10.53,
    "out:Total CO2/m2": 43.48,
    "out:Total CO2 (tons)": 148.22,
    "out:Klimatpaverkan": -68.78,
    "out:Initial Cost/MSEK": 7.814625,
    "out:Running cost/(Apt SEK y)": 24418,
    "out:Running Cost over RSP/MSEK": 7.072,
    "out:LCP/MSEK": -2.184,
    "out:ROI% old": 10.29,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 269733,
    "out:DH kr savings": 238869,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 104.33,
    "out:Primary Energy": 80.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.95,
    "out:CO2 Operational/m2": 9.09,
    "out:Total CO2/m2": 42.04,
    "out:Total CO2 (tons)": 143.31,
    "out:Klimatpaverkan": -73.69,
    "out:Initial Cost/MSEK": 7.899875,
    "out:Running cost/(Apt SEK y)": 23450,
    "out:Running Cost over RSP/MSEK": 6.791,
    "out:LCP/MSEK": -1.989,
    "out:ROI% old": 10.69,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 757,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 276129,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282142,
    "out:DH kr savings": 251277,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 82.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.81,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 6.45,
    "out:Total CO2 (tons)": 22,
    "out:Klimatpaverkan": -195,
    "out:Initial Cost/MSEK": 8.31875,
    "out:Running cost/(Apt SEK y)": 24267,
    "out:Running Cost over RSP/MSEK": 7.039,
    "out:LCP/MSEK": -2.655,
    "out:ROI% old": 9.73,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304602,
    "out:DH kr savings": 223358,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 79.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 44.81,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 5.01,
    "out:Total CO2 (tons)": 17.1,
    "out:Klimatpaverkan": -199.9,
    "out:Initial Cost/MSEK": 8.404,
    "out:Running cost/(Apt SEK y)": 23299,
    "out:Running Cost over RSP/MSEK": 6.758,
    "out:LCP/MSEK": -2.46,
    "out:ROI% old": 10.1,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317011,
    "out:DH kr savings": 235766,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.59,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 107.09,
    "out:Primary Energy": 80.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.3,
    "out:CO2 Operational/m2": -40.15,
    "out:Total CO2/m2": 5.15,
    "out:Total CO2 (tons)": 17.56,
    "out:Klimatpaverkan": -199.44,
    "out:Initial Cost/MSEK": 8.52375,
    "out:Running cost/(Apt SEK y)": 23057,
    "out:Running Cost over RSP/MSEK": 6.688,
    "out:LCP/MSEK": -2.51,
    "out:ROI% old": 10.08,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320113,
    "out:DH kr savings": 238869,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 220192,
    "out:% savings (space heating)": 49.35,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.62,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z01000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.56,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 110.5,
    "out:Total Energy Use Post PV": 103.09,
    "out:Primary Energy": 77.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 45.3,
    "out:CO2 Operational/m2": -41.59,
    "out:Total CO2/m2": 3.71,
    "out:Total CO2 (tons)": 12.65,
    "out:Klimatpaverkan": -204.35,
    "out:Initial Cost/MSEK": 8.609,
    "out:Running cost/(Apt SEK y)": 22088,
    "out:Running Cost over RSP/MSEK": 6.408,
    "out:LCP/MSEK": -2.315,
    "out:ROI% old": 10.44,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.41,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 276129,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 332522,
    "out:DH kr savings": 251277,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 206461,
    "out:% savings (space heating)": 52.51,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 90.59,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 105.49,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 44.46,
    "out:Total CO2/m2": 58.95,
    "out:Total CO2 (tons)": 200.98,
    "out:Klimatpaverkan": -16.02,
    "out:Initial Cost/MSEK": 5.511125,
    "out:Running cost/(Apt SEK y)": 31300,
    "out:Running Cost over RSP/MSEK": 9.052,
    "out:LCP/MSEK": -1.861,
    "out:ROI% old": 9.46,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 37.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143968,
    "out:DH kr savings": 170620,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 102.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 57.51,
    "out:Total CO2 (tons)": 196.07,
    "out:Klimatpaverkan": -20.93,
    "out:Initial Cost/MSEK": 5.596375,
    "out:Running cost/(Apt SEK y)": 30332,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": -1.666,
    "out:ROI% old": 10.03,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 502,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201131,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156377,
    "out:DH kr savings": 183029,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 102.86,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.99,
    "out:CO2 Operational/m2": 42.3,
    "out:Total CO2/m2": 57.29,
    "out:Total CO2 (tons)": 195.3,
    "out:Klimatpaverkan": -21.7,
    "out:Initial Cost/MSEK": 5.716125,
    "out:Running cost/(Apt SEK y)": 29847,
    "out:Running Cost over RSP/MSEK": 8.631,
    "out:LCP/MSEK": -1.645,
    "out:ROI% old": 10.17,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 520,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207949,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162581,
    "out:DH kr savings": 189234,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 99.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.99,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 55.85,
    "out:Total CO2 (tons)": 190.4,
    "out:Klimatpaverkan": -26.6,
    "out:Initial Cost/MSEK": 5.80125,
    "out:Running cost/(Apt SEK y)": 28879,
    "out:Running Cost over RSP/MSEK": 8.351,
    "out:LCP/MSEK": -1.45,
    "out:ROI% old": 10.72,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221585,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174990,
    "out:DH kr savings": 201642,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 130.33,
    "out:Primary Energy": 94.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.84,
    "out:CO2 Operational/m2": 18.45,
    "out:Total CO2/m2": 45.29,
    "out:Total CO2 (tons)": 154.4,
    "out:Klimatpaverkan": -62.6,
    "out:Initial Cost/MSEK": 6.22025,
    "out:Running cost/(Apt SEK y)": 29746,
    "out:Running Cost over RSP/MSEK": 8.614,
    "out:LCP/MSEK": -2.132,
    "out:ROI% old": 9.39,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 43.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 524,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 201485,
    "out:DH kr savings": 170620,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 91.3,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.84,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 43.85,
    "out:Total CO2 (tons)": 149.49,
    "out:Klimatpaverkan": -67.51,
    "out:Initial Cost/MSEK": 6.3055,
    "out:Running cost/(Apt SEK y)": 28777,
    "out:Running Cost over RSP/MSEK": 8.334,
    "out:LCP/MSEK": -1.937,
    "out:ROI% old": 9.9,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201131,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 213894,
    "out:DH kr savings": 183029,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 124.33,
    "out:Primary Energy": 91.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 16.29,
    "out:Total CO2/m2": 43.63,
    "out:Total CO2 (tons)": 148.73,
    "out:Klimatpaverkan": -68.27,
    "out:Initial Cost/MSEK": 6.425125,
    "out:Running cost/(Apt SEK y)": 28293,
    "out:Running Cost over RSP/MSEK": 8.193,
    "out:LCP/MSEK": -1.916,
    "out:ROI% old": 10.03,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 50.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 207949,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220098,
    "out:DH kr savings": 189234,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 88.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 42.19,
    "out:Total CO2 (tons)": 143.82,
    "out:Klimatpaverkan": -73.18,
    "out:Initial Cost/MSEK": 6.510375,
    "out:Running cost/(Apt SEK y)": 27324,
    "out:Running Cost over RSP/MSEK": 7.913,
    "out:LCP/MSEK": -1.721,
    "out:ROI% old": 10.51,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221585,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 232507,
    "out:DH kr savings": 201642,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 136.5,
    "out:Total Energy Use Post PV": 129.09,
    "out:Primary Energy": 92.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.2,
    "out:CO2 Operational/m2": -32.23,
    "out:Total CO2/m2": 6.97,
    "out:Total CO2 (tons)": 23.74,
    "out:Klimatpaverkan": -193.26,
    "out:Initial Cost/MSEK": 6.929375,
    "out:Running cost/(Apt SEK y)": 28384,
    "out:Running Cost over RSP/MSEK": 8.231,
    "out:LCP/MSEK": -2.458,
    "out:ROI% old": 9.22,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 45,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 187495,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 251865,
    "out:DH kr savings": 170620,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 89.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.2,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 5.53,
    "out:Total CO2 (tons)": 18.84,
    "out:Klimatpaverkan": -198.16,
    "out:Initial Cost/MSEK": 7.014625,
    "out:Running cost/(Apt SEK y)": 27415,
    "out:Running Cost over RSP/MSEK": 7.95,
    "out:LCP/MSEK": -2.263,
    "out:ROI% old": 9.68,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 201131,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 264274,
    "out:DH kr savings": 183029,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 130.5,
    "out:Total Energy Use Post PV": 123.09,
    "out:Primary Energy": 89.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.69,
    "out:CO2 Operational/m2": -34.39,
    "out:Total CO2/m2": 5.3,
    "out:Total CO2 (tons)": 18.07,
    "out:Klimatpaverkan": -198.93,
    "out:Initial Cost/MSEK": 7.13425,
    "out:Running cost/(Apt SEK y)": 26931,
    "out:Running Cost over RSP/MSEK": 7.81,
    "out:LCP/MSEK": -2.242,
    "out:ROI% old": 9.8,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 207949,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270478,
    "out:DH kr savings": 189234,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 86.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.69,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 3.86,
    "out:Total CO2 (tons)": 13.16,
    "out:Klimatpaverkan": -203.84,
    "out:Initial Cost/MSEK": 7.2195,
    "out:Running cost/(Apt SEK y)": 25962,
    "out:Running Cost over RSP/MSEK": 7.53,
    "out:LCP/MSEK": -2.047,
    "out:ROI% old": 10.24,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 664,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 221585,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 282887,
    "out:DH kr savings": 201642,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 101.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 56.07,
    "out:Total CO2 (tons)": 191.16,
    "out:Klimatpaverkan": -25.84,
    "out:Initial Cost/MSEK": 5.60875,
    "out:Running cost/(Apt SEK y)": 29363,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -1.397,
    "out:ROI% old": 10.73,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168785,
    "out:DH kr savings": 195438,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 98.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.49,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 54.27,
    "out:Total CO2 (tons)": 185.02,
    "out:Klimatpaverkan": -31.98,
    "out:Initial Cost/MSEK": 5.694,
    "out:Running cost/(Apt SEK y)": 28152,
    "out:Running Cost over RSP/MSEK": 8.14,
    "out:LCP/MSEK": -1.132,
    "out:ROI% old": 11.45,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 184296,
    "out:DH kr savings": 210949,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 99.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.99,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 54.77,
    "out:Total CO2 (tons)": 186.71,
    "out:Klimatpaverkan": -30.29,
    "out:Initial Cost/MSEK": 5.81375,
    "out:Running cost/(Apt SEK y)": 28152,
    "out:Running Cost over RSP/MSEK": 8.14,
    "out:LCP/MSEK": -1.252,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184296,
    "out:DH kr savings": 210949,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 96.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.99,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 52.97,
    "out:Total CO2 (tons)": 180.58,
    "out:Klimatpaverkan": -36.42,
    "out:Initial Cost/MSEK": 5.899,
    "out:Running cost/(Apt SEK y)": 26941,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": -0.987,
    "out:ROI% old": 11.9,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199807,
    "out:DH kr savings": 226460,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 90.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.84,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 42.41,
    "out:Total CO2 (tons)": 144.59,
    "out:Klimatpaverkan": -72.41,
    "out:Initial Cost/MSEK": 6.317875,
    "out:Running cost/(Apt SEK y)": 27808,
    "out:Running Cost over RSP/MSEK": 8.053,
    "out:LCP/MSEK": -1.669,
    "out:ROI% old": 10.51,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226302,
    "out:DH kr savings": 195438,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 87.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.84,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 40.61,
    "out:Total CO2 (tons)": 138.45,
    "out:Klimatpaverkan": -78.55,
    "out:Initial Cost/MSEK": 6.403125,
    "out:Running cost/(Apt SEK y)": 26598,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": -1.403,
    "out:ROI% old": 11.15,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241813,
    "out:DH kr savings": 210949,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 88.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 41.11,
    "out:Total CO2 (tons)": 140.14,
    "out:Klimatpaverkan": -76.86,
    "out:Initial Cost/MSEK": 6.522875,
    "out:Running cost/(Apt SEK y)": 26598,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": -1.523,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241813,
    "out:DH kr savings": 210949,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 85.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 39.31,
    "out:Total CO2 (tons)": 134.01,
    "out:Klimatpaverkan": -82.99,
    "out:Initial Cost/MSEK": 6.608,
    "out:Running cost/(Apt SEK y)": 25387,
    "out:Running Cost over RSP/MSEK": 7.352,
    "out:LCP/MSEK": -1.258,
    "out:ROI% old": 11.57,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257324,
    "out:DH kr savings": 226460,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 88.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.2,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 4.09,
    "out:Total CO2 (tons)": 13.93,
    "out:Klimatpaverkan": -203.07,
    "out:Initial Cost/MSEK": 7.027,
    "out:Running cost/(Apt SEK y)": 26447,
    "out:Running Cost over RSP/MSEK": 7.67,
    "out:LCP/MSEK": -1.995,
    "out:ROI% old": 10.23,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276683,
    "out:DH kr savings": 195438,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 85.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.2,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 2.29,
    "out:Total CO2 (tons)": 7.79,
    "out:Klimatpaverkan": -209.21,
    "out:Initial Cost/MSEK": 7.11225,
    "out:Running cost/(Apt SEK y)": 25236,
    "out:Running Cost over RSP/MSEK": 7.319,
    "out:LCP/MSEK": -1.729,
    "out:ROI% old": 10.81,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292193,
    "out:DH kr savings": 210949,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.99,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 86.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.69,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 2.78,
    "out:Total CO2 (tons)": 9.48,
    "out:Klimatpaverkan": -207.52,
    "out:Initial Cost/MSEK": 7.232,
    "out:Running cost/(Apt SEK y)": 25236,
    "out:Running Cost over RSP/MSEK": 7.319,
    "out:LCP/MSEK": -1.849,
    "out:ROI% old": 10.63,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292193,
    "out:DH kr savings": 210949,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 248809,
    "out:% savings (space heating)": 42.77,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 103.01,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.58,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 83.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 39.69,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 0.98,
    "out:Total CO2 (tons)": 3.35,
    "out:Klimatpaverkan": -213.65,
    "out:Initial Cost/MSEK": 7.317125,
    "out:Running cost/(Apt SEK y)": 24025,
    "out:Running Cost over RSP/MSEK": 6.969,
    "out:LCP/MSEK": -1.584,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.47,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 307704,
    "out:DH kr savings": 226460,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233777,
    "out:% savings (space heating)": 46.23,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.6,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 97.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.67,
    "out:CO2 Operational/m2": 40.5,
    "out:Total CO2/m2": 57.17,
    "out:Total CO2 (tons)": 194.91,
    "out:Klimatpaverkan": -22.09,
    "out:Initial Cost/MSEK": 6.05475,
    "out:Running cost/(Apt SEK y)": 28636,
    "out:Running Cost over RSP/MSEK": 8.281,
    "out:LCP/MSEK": -1.633,
    "out:ROI% old": 10.43,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 224994,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178092,
    "out:DH kr savings": 204745,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 94.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.67,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 55.74,
    "out:Total CO2 (tons)": 190,
    "out:Klimatpaverkan": -27,
    "out:Initial Cost/MSEK": 6.14,
    "out:Running cost/(Apt SEK y)": 27668,
    "out:Running Cost over RSP/MSEK": 8,
    "out:LCP/MSEK": -1.438,
    "out:ROI% old": 10.94,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190501,
    "out:DH kr savings": 217153,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 94.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 55.51,
    "out:Total CO2 (tons)": 189.24,
    "out:Klimatpaverkan": -27.76,
    "out:Initial Cost/MSEK": 6.259625,
    "out:Running cost/(Apt SEK y)": 27184,
    "out:Running Cost over RSP/MSEK": 7.86,
    "out:LCP/MSEK": -1.417,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 196705,
    "out:DH kr savings": 223358,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 92.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 54.07,
    "out:Total CO2 (tons)": 184.33,
    "out:Klimatpaverkan": -32.67,
    "out:Initial Cost/MSEK": 6.344875,
    "out:Running cost/(Apt SEK y)": 26215,
    "out:Running Cost over RSP/MSEK": 7.579,
    "out:LCP/MSEK": -1.222,
    "out:ROI% old": 11.53,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209114,
    "out:DH kr savings": 235766,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 119.33,
    "out:Primary Energy": 86.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 14.49,
    "out:Total CO2/m2": 43.51,
    "out:Total CO2 (tons)": 148.34,
    "out:Klimatpaverkan": -68.66,
    "out:Initial Cost/MSEK": 6.763875,
    "out:Running cost/(Apt SEK y)": 27082,
    "out:Running Cost over RSP/MSEK": 7.843,
    "out:LCP/MSEK": -1.904,
    "out:ROI% old": 10.26,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 622,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 224994,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235609,
    "out:DH kr savings": 204745,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 83.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 42.07,
    "out:Total CO2 (tons)": 143.43,
    "out:Klimatpaverkan": -73.57,
    "out:Initial Cost/MSEK": 6.849125,
    "out:Running cost/(Apt SEK y)": 26113,
    "out:Running Cost over RSP/MSEK": 7.562,
    "out:LCP/MSEK": -1.709,
    "out:ROI% old": 10.72,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248018,
    "out:DH kr savings": 217153,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 83.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.52,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 41.85,
    "out:Total CO2 (tons)": 142.67,
    "out:Klimatpaverkan": -74.33,
    "out:Initial Cost/MSEK": 6.96875,
    "out:Running cost/(Apt SEK y)": 25629,
    "out:Running Cost over RSP/MSEK": 7.422,
    "out:LCP/MSEK": -1.689,
    "out:ROI% old": 10.82,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 254222,
    "out:DH kr savings": 223358,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 81.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.52,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 40.41,
    "out:Total CO2 (tons)": 137.76,
    "out:Klimatpaverkan": -79.24,
    "out:Initial Cost/MSEK": 7.054,
    "out:Running cost/(Apt SEK y)": 24660,
    "out:Running Cost over RSP/MSEK": 7.142,
    "out:LCP/MSEK": -1.493,
    "out:ROI% old": 11.26,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 266631,
    "out:DH kr savings": 235766,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 125.5,
    "out:Total Energy Use Post PV": 118.09,
    "out:Primary Energy": 84.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.38,
    "out:CO2 Operational/m2": -36.19,
    "out:Total CO2/m2": 5.19,
    "out:Total CO2 (tons)": 17.68,
    "out:Klimatpaverkan": -199.32,
    "out:Initial Cost/MSEK": 7.473,
    "out:Running cost/(Apt SEK y)": 25720,
    "out:Running Cost over RSP/MSEK": 7.46,
    "out:LCP/MSEK": -2.23,
    "out:ROI% old": 10.02,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 224994,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285989,
    "out:DH kr savings": 204745,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 81.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.38,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 3.75,
    "out:Total CO2 (tons)": 12.77,
    "out:Klimatpaverkan": -204.23,
    "out:Initial Cost/MSEK": 7.55825,
    "out:Running cost/(Apt SEK y)": 24752,
    "out:Running Cost over RSP/MSEK": 7.179,
    "out:LCP/MSEK": -2.035,
    "out:ROI% old": 10.44,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298398,
    "out:DH kr savings": 217153,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 81.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.87,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 3.52,
    "out:Total CO2 (tons)": 12.01,
    "out:Klimatpaverkan": -204.99,
    "out:Initial Cost/MSEK": 7.677875,
    "out:Running cost/(Apt SEK y)": 24267,
    "out:Running Cost over RSP/MSEK": 7.039,
    "out:LCP/MSEK": -2.015,
    "out:ROI% old": 10.54,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304602,
    "out:DH kr savings": 223358,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 78.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.87,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 2.08,
    "out:Total CO2 (tons)": 7.1,
    "out:Klimatpaverkan": -209.9,
    "out:Initial Cost/MSEK": 7.763125,
    "out:Running cost/(Apt SEK y)": 23299,
    "out:Running Cost over RSP/MSEK": 6.758,
    "out:LCP/MSEK": -1.819,
    "out:ROI% old": 10.94,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317011,
    "out:DH kr savings": 235766,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 116.5,
    "out:Primary Energy": 94.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.67,
    "out:CO2 Operational/m2": 37.26,
    "out:Total CO2/m2": 53.94,
    "out:Total CO2 (tons)": 183.86,
    "out:Klimatpaverkan": -33.14,
    "out:Initial Cost/MSEK": 6.152375,
    "out:Running cost/(Apt SEK y)": 26457,
    "out:Running Cost over RSP/MSEK": 7.65,
    "out:LCP/MSEK": -1.1,
    "out:ROI% old": 11.73,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 645,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 255675,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 206012,
    "out:DH kr savings": 232664,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 91.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.67,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 52.86,
    "out:Total CO2 (tons)": 180.18,
    "out:Klimatpaverkan": -36.82,
    "out:Initial Cost/MSEK": 6.237625,
    "out:Running cost/(Apt SEK y)": 25731,
    "out:Running Cost over RSP/MSEK": 7.439,
    "out:LCP/MSEK": -0.975,
    "out:ROI% old": 12.05,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215318,
    "out:DH kr savings": 241971,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 91.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 52.63,
    "out:Total CO2 (tons)": 179.42,
    "out:Klimatpaverkan": -37.58,
    "out:Initial Cost/MSEK": 6.357375,
    "out:Running cost/(Apt SEK y)": 25246,
    "out:Running Cost over RSP/MSEK": 7.299,
    "out:LCP/MSEK": -0.954,
    "out:ROI% old": 12.14,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221523,
    "out:DH kr savings": 248175,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 107.5,
    "out:Primary Energy": 89.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 34.02,
    "out:Total CO2/m2": 51.19,
    "out:Total CO2 (tons)": 174.51,
    "out:Klimatpaverkan": -42.49,
    "out:Initial Cost/MSEK": 6.4425,
    "out:Running cost/(Apt SEK y)": 24278,
    "out:Running Cost over RSP/MSEK": 7.019,
    "out:LCP/MSEK": -0.759,
    "out:ROI% old": 12.6,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233931,
    "out:DH kr savings": 260584,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 110.33,
    "out:Primary Energy": 82.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 11.25,
    "out:Total CO2/m2": 40.27,
    "out:Total CO2 (tons)": 137.29,
    "out:Klimatpaverkan": -79.71,
    "out:Initial Cost/MSEK": 6.8615,
    "out:Running cost/(Apt SEK y)": 24903,
    "out:Running Cost over RSP/MSEK": 7.212,
    "out:LCP/MSEK": -1.371,
    "out:ROI% old": 11.43,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 703,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255675,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263529,
    "out:DH kr savings": 232664,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 80.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 39.19,
    "out:Total CO2 (tons)": 133.61,
    "out:Klimatpaverkan": -83.39,
    "out:Initial Cost/MSEK": 6.94675,
    "out:Running cost/(Apt SEK y)": 24176,
    "out:Running Cost over RSP/MSEK": 7.001,
    "out:LCP/MSEK": -1.246,
    "out:ROI% old": 11.72,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272835,
    "out:DH kr savings": 241971,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 80.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.52,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 38.97,
    "out:Total CO2 (tons)": 132.85,
    "out:Klimatpaverkan": -84.15,
    "out:Initial Cost/MSEK": 7.066375,
    "out:Running cost/(Apt SEK y)": 23692,
    "out:Running Cost over RSP/MSEK": 6.861,
    "out:LCP/MSEK": -1.225,
    "out:ROI% old": 11.81,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279040,
    "out:DH kr savings": 248175,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 101.33,
    "out:Primary Energy": 78.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.52,
    "out:CO2 Operational/m2": 8.01,
    "out:Total CO2/m2": 37.53,
    "out:Total CO2 (tons)": 127.94,
    "out:Klimatpaverkan": -89.06,
    "out:Initial Cost/MSEK": 7.151625,
    "out:Running cost/(Apt SEK y)": 22723,
    "out:Running Cost over RSP/MSEK": 6.581,
    "out:LCP/MSEK": -1.03,
    "out:ROI% old": 12.23,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 11,
    "out:Return/kSEK/y": 783,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291448,
    "out:DH kr savings": 260584,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 116.5,
    "out:Total Energy Use Post PV": 109.09,
    "out:Primary Energy": 80.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.38,
    "out:CO2 Operational/m2": -39.43,
    "out:Total CO2/m2": 1.95,
    "out:Total CO2 (tons)": 6.63,
    "out:Klimatpaverkan": -210.37,
    "out:Initial Cost/MSEK": 7.570625,
    "out:Running cost/(Apt SEK y)": 23541,
    "out:Running Cost over RSP/MSEK": 6.829,
    "out:LCP/MSEK": -1.697,
    "out:ROI% old": 11.08,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 255675,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 313909,
    "out:DH kr savings": 232664,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 77.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.38,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 0.87,
    "out:Total CO2 (tons)": 2.95,
    "out:Klimatpaverkan": -214.05,
    "out:Initial Cost/MSEK": 7.655875,
    "out:Running cost/(Apt SEK y)": 22815,
    "out:Running Cost over RSP/MSEK": 6.618,
    "out:LCP/MSEK": -1.572,
    "out:ROI% old": 11.35,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323215,
    "out:DH kr savings": 241971,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 78.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.87,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 0.64,
    "out:Total CO2 (tons)": 2.19,
    "out:Klimatpaverkan": -214.81,
    "out:Initial Cost/MSEK": 7.7755,
    "out:Running cost/(Apt SEK y)": 22330,
    "out:Running Cost over RSP/MSEK": 6.478,
    "out:LCP/MSEK": -1.551,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 329420,
    "out:DH kr savings": 248175,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 210071,
    "out:% savings (space heating)": 51.68,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 91.65,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000100120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 57.73,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 100.09,
    "out:Primary Energy": 75.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 41.87,
    "out:CO2 Operational/m2": -42.67,
    "out:Total CO2/m2": -0.8,
    "out:Total CO2 (tons)": -2.72,
    "out:Klimatpaverkan": -219.72,
    "out:Initial Cost/MSEK": 7.86075,
    "out:Running cost/(Apt SEK y)": 21362,
    "out:Running Cost over RSP/MSEK": 6.198,
    "out:LCP/MSEK": -1.356,
    "out:ROI% old": 11.82,
    "out:Payback discounted": 10,
    "out:Atemp": 3409,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 11,
    "out:Return/kSEK/y": 834,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 341829,
    "out:DH kr savings": 260584,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 196800,
    "out:% savings (space heating)": 54.73,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.76,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 95.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.46,
    "out:CO2 Operational/m2": 39.42,
    "out:Total CO2/m2": 61.88,
    "out:Total CO2 (tons)": 210.96,
    "out:Klimatpaverkan": -6.04,
    "out:Initial Cost/MSEK": 7.38575,
    "out:Running cost/(Apt SEK y)": 27910,
    "out:Running Cost over RSP/MSEK": 8.07,
    "out:LCP/MSEK": -2.754,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 592,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235221,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 187399,
    "out:DH kr savings": 214051,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 92.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.46,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 60.44,
    "out:Total CO2 (tons)": 206.05,
    "out:Klimatpaverkan": -10.95,
    "out:Initial Cost/MSEK": 7.471,
    "out:Running cost/(Apt SEK y)": 26941,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": -2.559,
    "out:ROI% old": 9.39,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199807,
    "out:DH kr savings": 226460,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 115.5,
    "out:Primary Energy": 92.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.96,
    "out:CO2 Operational/m2": 36.9,
    "out:Total CO2/m2": 59.86,
    "out:Total CO2 (tons)": 204.06,
    "out:Klimatpaverkan": -12.94,
    "out:Initial Cost/MSEK": 7.59075,
    "out:Running cost/(Apt SEK y)": 26215,
    "out:Running Cost over RSP/MSEK": 7.579,
    "out:LCP/MSEK": -2.468,
    "out:ROI% old": 9.64,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 654,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209114,
    "out:DH kr savings": 235766,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 89.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.96,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 58.42,
    "out:Total CO2 (tons)": 199.15,
    "out:Klimatpaverkan": -17.85,
    "out:Initial Cost/MSEK": 7.675875,
    "out:Running cost/(Apt SEK y)": 25246,
    "out:Running Cost over RSP/MSEK": 7.299,
    "out:LCP/MSEK": -2.273,
    "out:ROI% old": 10.06,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221523,
    "out:DH kr savings": 248175,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 116.33,
    "out:Primary Energy": 84.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.81,
    "out:CO2 Operational/m2": 13.41,
    "out:Total CO2/m2": 48.22,
    "out:Total CO2 (tons)": 164.39,
    "out:Klimatpaverkan": -52.61,
    "out:Initial Cost/MSEK": 8.094875,
    "out:Running cost/(Apt SEK y)": 26355,
    "out:Running Cost over RSP/MSEK": 7.632,
    "out:LCP/MSEK": -3.025,
    "out:ROI% old": 8.95,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235221,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 244916,
    "out:DH kr savings": 214051,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 81.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.81,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 46.78,
    "out:Total CO2 (tons)": 159.48,
    "out:Klimatpaverkan": -57.52,
    "out:Initial Cost/MSEK": 8.180125,
    "out:Running cost/(Apt SEK y)": 25387,
    "out:Running Cost over RSP/MSEK": 7.352,
    "out:LCP/MSEK": -2.83,
    "out:ROI% old": 9.34,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 257324,
    "out:DH kr savings": 226460,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 109.33,
    "out:Primary Energy": 81.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.31,
    "out:CO2 Operational/m2": 10.89,
    "out:Total CO2/m2": 46.2,
    "out:Total CO2 (tons)": 157.49,
    "out:Klimatpaverkan": -59.51,
    "out:Initial Cost/MSEK": 8.29975,
    "out:Running cost/(Apt SEK y)": 24660,
    "out:Running Cost over RSP/MSEK": 7.142,
    "out:LCP/MSEK": -2.739,
    "out:ROI% old": 9.57,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 712,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266631,
    "out:DH kr savings": 235766,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 78.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.31,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 44.76,
    "out:Total CO2 (tons)": 152.58,
    "out:Klimatpaverkan": -64.42,
    "out:Initial Cost/MSEK": 8.385,
    "out:Running cost/(Apt SEK y)": 23692,
    "out:Running Cost over RSP/MSEK": 6.861,
    "out:LCP/MSEK": -2.544,
    "out:ROI% old": 9.95,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279040,
    "out:DH kr savings": 248175,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 122.5,
    "out:Total Energy Use Post PV": 115.09,
    "out:Primary Energy": 81.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.17,
    "out:CO2 Operational/m2": -37.27,
    "out:Total CO2/m2": 9.89,
    "out:Total CO2 (tons)": 33.73,
    "out:Klimatpaverkan": -183.27,
    "out:Initial Cost/MSEK": 8.804,
    "out:Running cost/(Apt SEK y)": 24994,
    "out:Running Cost over RSP/MSEK": 7.249,
    "out:LCP/MSEK": -3.351,
    "out:ROI% old": 8.85,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 699,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 235221,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295296,
    "out:DH kr savings": 214051,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 79.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.17,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 8.45,
    "out:Total CO2 (tons)": 28.82,
    "out:Klimatpaverkan": -188.18,
    "out:Initial Cost/MSEK": 8.88925,
    "out:Running cost/(Apt SEK y)": 24025,
    "out:Running Cost over RSP/MSEK": 6.969,
    "out:LCP/MSEK": -3.156,
    "out:ROI% old": 9.21,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 307704,
    "out:DH kr savings": 226460,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 115.5,
    "out:Total Energy Use Post PV": 108.09,
    "out:Primary Energy": 79.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.66,
    "out:CO2 Operational/m2": -39.79,
    "out:Total CO2/m2": 7.87,
    "out:Total CO2 (tons)": 26.83,
    "out:Klimatpaverkan": -190.17,
    "out:Initial Cost/MSEK": 9.009,
    "out:Running cost/(Apt SEK y)": 23299,
    "out:Running Cost over RSP/MSEK": 6.758,
    "out:LCP/MSEK": -3.065,
    "out:ROI% old": 9.43,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 259084,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 317011,
    "out:DH kr savings": 235766,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 76.57,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.66,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 6.43,
    "out:Total CO2 (tons)": 21.92,
    "out:Klimatpaverkan": -195.08,
    "out:Initial Cost/MSEK": 9.094125,
    "out:Running cost/(Apt SEK y)": 22330,
    "out:Running Cost over RSP/MSEK": 6.478,
    "out:LCP/MSEK": -2.87,
    "out:ROI% old": 9.78,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329420,
    "out:DH kr savings": 248175,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 91.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.46,
    "out:CO2 Operational/m2": 36.18,
    "out:Total CO2/m2": 58.64,
    "out:Total CO2 (tons)": 199.92,
    "out:Klimatpaverkan": -17.08,
    "out:Initial Cost/MSEK": 7.4835,
    "out:Running cost/(Apt SEK y)": 25731,
    "out:Running Cost over RSP/MSEK": 7.439,
    "out:LCP/MSEK": -2.22,
    "out:ROI% old": 10.05,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215318,
    "out:DH kr savings": 241971,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 89.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.46,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 57.2,
    "out:Total CO2 (tons)": 195.01,
    "out:Klimatpaverkan": -21.99,
    "out:Initial Cost/MSEK": 7.568625,
    "out:Running cost/(Apt SEK y)": 24762,
    "out:Running Cost over RSP/MSEK": 7.159,
    "out:LCP/MSEK": -2.025,
    "out:ROI% old": 10.46,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 279538,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227727,
    "out:DH kr savings": 254380,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 89.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.96,
    "out:CO2 Operational/m2": 34.38,
    "out:Total CO2/m2": 57.34,
    "out:Total CO2 (tons)": 195.47,
    "out:Klimatpaverkan": -21.53,
    "out:Initial Cost/MSEK": 7.688375,
    "out:Running cost/(Apt SEK y)": 24520,
    "out:Running Cost over RSP/MSEK": 7.089,
    "out:LCP/MSEK": -2.075,
    "out:ROI% old": 10.43,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 282947,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 230829,
    "out:DH kr savings": 257482,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 104.5,
    "out:Total Energy Use Post PV": 104.5,
    "out:Primary Energy": 86.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.96,
    "out:CO2 Operational/m2": 32.94,
    "out:Total CO2/m2": 55.9,
    "out:Total CO2 (tons)": 190.56,
    "out:Klimatpaverkan": -26.44,
    "out:Initial Cost/MSEK": 7.773625,
    "out:Running cost/(Apt SEK y)": 23551,
    "out:Running Cost over RSP/MSEK": 6.808,
    "out:LCP/MSEK": -1.88,
    "out:ROI% old": 10.83,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 753,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 296583,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243238,
    "out:DH kr savings": 269891,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 107.33,
    "out:Primary Energy": 80.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.81,
    "out:CO2 Operational/m2": 10.17,
    "out:Total CO2/m2": 44.98,
    "out:Total CO2 (tons)": 153.34,
    "out:Klimatpaverkan": -63.66,
    "out:Initial Cost/MSEK": 8.1925,
    "out:Running cost/(Apt SEK y)": 24176,
    "out:Running Cost over RSP/MSEK": 7.001,
    "out:LCP/MSEK": -2.492,
    "out:ROI% old": 9.94,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272835,
    "out:DH kr savings": 241971,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 77.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.81,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 43.54,
    "out:Total CO2 (tons)": 148.44,
    "out:Klimatpaverkan": -68.56,
    "out:Initial Cost/MSEK": 8.27775,
    "out:Running cost/(Apt SEK y)": 23208,
    "out:Running Cost over RSP/MSEK": 6.721,
    "out:LCP/MSEK": -2.296,
    "out:ROI% old": 10.32,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 766,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 279538,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285244,
    "out:DH kr savings": 254380,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 102.33,
    "out:Primary Energy": 78.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.31,
    "out:CO2 Operational/m2": 8.37,
    "out:Total CO2/m2": 43.68,
    "out:Total CO2 (tons)": 148.9,
    "out:Klimatpaverkan": -68.1,
    "out:Initial Cost/MSEK": 8.3975,
    "out:Running cost/(Apt SEK y)": 22965,
    "out:Running Cost over RSP/MSEK": 6.651,
    "out:LCP/MSEK": -2.346,
    "out:ROI% old": 10.29,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 282947,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288346,
    "out:DH kr savings": 257482,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 104.5,
    "out:Total Energy Use Post PV": 98.33,
    "out:Primary Energy": 75.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.31,
    "out:CO2 Operational/m2": 6.93,
    "out:Total CO2/m2": 42.24,
    "out:Total CO2 (tons)": 143.99,
    "out:Klimatpaverkan": -73.01,
    "out:Initial Cost/MSEK": 8.48275,
    "out:Running cost/(Apt SEK y)": 21997,
    "out:Running Cost over RSP/MSEK": 6.37,
    "out:LCP/MSEK": -2.151,
    "out:ROI% old": 10.66,
    "out:Payback discounted": 11,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 10,
    "out:Return/kSEK/y": 810,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296583,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 300755,
    "out:DH kr savings": 269891,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 113.5,
    "out:Total Energy Use Post PV": 106.09,
    "out:Primary Energy": 78.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.17,
    "out:CO2 Operational/m2": -40.51,
    "out:Total CO2/m2": 6.65,
    "out:Total CO2 (tons)": 22.68,
    "out:Klimatpaverkan": -194.32,
    "out:Initial Cost/MSEK": 8.901625,
    "out:Running cost/(Apt SEK y)": 22815,
    "out:Running Cost over RSP/MSEK": 6.618,
    "out:LCP/MSEK": -2.818,
    "out:ROI% old": 9.76,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265902,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323215,
    "out:DH kr savings": 241971,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 75.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.17,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 5.21,
    "out:Total CO2 (tons)": 17.78,
    "out:Klimatpaverkan": -199.22,
    "out:Initial Cost/MSEK": 8.986875,
    "out:Running cost/(Apt SEK y)": 21846,
    "out:Running Cost over RSP/MSEK": 6.338,
    "out:LCP/MSEK": -2.622,
    "out:ROI% old": 10.12,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 816,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 279538,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 335624,
    "out:DH kr savings": 254380,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 58.22,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 101.09,
    "out:Primary Energy": 76.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.66,
    "out:CO2 Operational/m2": -42.31,
    "out:Total CO2/m2": 5.35,
    "out:Total CO2 (tons)": 18.24,
    "out:Klimatpaverkan": -198.76,
    "out:Initial Cost/MSEK": 9.106625,
    "out:Running cost/(Apt SEK y)": 21604,
    "out:Running Cost over RSP/MSEK": 6.268,
    "out:LCP/MSEK": -2.672,
    "out:ROI% old": 10.09,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 282947,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 338726,
    "out:DH kr savings": 257482,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 198463,
    "out:% savings (space heating)": 54.35,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 88.24,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z02000200120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.48,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 104.5,
    "out:Total Energy Use Post PV": 97.09,
    "out:Primary Energy": 73.6,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.66,
    "out:CO2 Operational/m2": -43.75,
    "out:Total CO2/m2": 3.91,
    "out:Total CO2 (tons)": 13.33,
    "out:Klimatpaverkan": -203.67,
    "out:Initial Cost/MSEK": 9.191875,
    "out:Running cost/(Apt SEK y)": 20635,
    "out:Running Cost over RSP/MSEK": 5.987,
    "out:LCP/MSEK": -2.477,
    "out:ROI% old": 10.44,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.28,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 861,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 773142.866751,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.19,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.12,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296583,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 351135,
    "out:DH kr savings": 269891,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 185719,
    "out:% savings (space heating)": 57.28,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 84.51,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 102.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 34.89,
    "out:CO2 Operational/m2": 43.02,
    "out:Total CO2/m2": 77.92,
    "out:Total CO2 (tons)": 265.62,
    "out:Klimatpaverkan": 48.62,
    "out:Initial Cost/MSEK": 7.52425,
    "out:Running cost/(Apt SEK y)": 30332,
    "out:Running Cost over RSP/MSEK": 8.771,
    "out:LCP/MSEK": -3.593,
    "out:ROI% old": 7.46,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 41.7,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 502,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 201131,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156377,
    "out:DH kr savings": 183029,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 99.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.89,
    "out:CO2 Operational/m2": 41.58,
    "out:Total CO2/m2": 76.48,
    "out:Total CO2 (tons)": 260.71,
    "out:Klimatpaverkan": 43.71,
    "out:Initial Cost/MSEK": 7.6095,
    "out:Running cost/(Apt SEK y)": 29363,
    "out:Running Cost over RSP/MSEK": 8.491,
    "out:LCP/MSEK": -3.398,
    "out:ROI% old": 7.91,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 46.1,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 538,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168785,
    "out:DH kr savings": 195438,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 126.5,
    "out:Primary Energy": 99.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.39,
    "out:CO2 Operational/m2": 40.86,
    "out:Total CO2/m2": 76.25,
    "out:Total CO2 (tons)": 259.94,
    "out:Klimatpaverkan": 42.94,
    "out:Initial Cost/MSEK": 7.72925,
    "out:Running cost/(Apt SEK y)": 28879,
    "out:Running Cost over RSP/MSEK": 8.351,
    "out:LCP/MSEK": -3.378,
    "out:ROI% old": 8.04,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 221585,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 174990,
    "out:DH kr savings": 201642,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 96.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.39,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 74.45,
    "out:Total CO2 (tons)": 253.81,
    "out:Klimatpaverkan": 36.81,
    "out:Initial Cost/MSEK": 7.814375,
    "out:Running cost/(Apt SEK y)": 27668,
    "out:Running Cost over RSP/MSEK": 8,
    "out:LCP/MSEK": -3.112,
    "out:ROI% old": 8.6,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190501,
    "out:DH kr savings": 217153,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 126.33,
    "out:Primary Energy": 91.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.24,
    "out:CO2 Operational/m2": 17.01,
    "out:Total CO2/m2": 64.25,
    "out:Total CO2 (tons)": 219.04,
    "out:Klimatpaverkan": 2.04,
    "out:Initial Cost/MSEK": 8.233375,
    "out:Running cost/(Apt SEK y)": 28777,
    "out:Running Cost over RSP/MSEK": 8.334,
    "out:LCP/MSEK": -3.865,
    "out:ROI% old": 7.58,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 48.4,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 559,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201131,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 213894,
    "out:DH kr savings": 183029,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 122.33,
    "out:Primary Energy": 88.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.24,
    "out:CO2 Operational/m2": 15.57,
    "out:Total CO2/m2": 62.81,
    "out:Total CO2 (tons)": 214.13,
    "out:Klimatpaverkan": -2.87,
    "out:Initial Cost/MSEK": 8.318625,
    "out:Running cost/(Apt SEK y)": 27808,
    "out:Running Cost over RSP/MSEK": 8.053,
    "out:LCP/MSEK": -3.67,
    "out:ROI% old": 7.98,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 226302,
    "out:DH kr savings": 195438,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 120.33,
    "out:Primary Energy": 88.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.74,
    "out:CO2 Operational/m2": 14.85,
    "out:Total CO2/m2": 62.59,
    "out:Total CO2 (tons)": 213.37,
    "out:Klimatpaverkan": -3.63,
    "out:Initial Cost/MSEK": 8.43825,
    "out:Running cost/(Apt SEK y)": 27324,
    "out:Running Cost over RSP/MSEK": 7.913,
    "out:LCP/MSEK": -3.649,
    "out:ROI% old": 8.11,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 55.8,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 613,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221585,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 232507,
    "out:DH kr savings": 201642,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 85.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.74,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 60.79,
    "out:Total CO2 (tons)": 207.24,
    "out:Klimatpaverkan": -9.76,
    "out:Initial Cost/MSEK": 8.5235,
    "out:Running cost/(Apt SEK y)": 26113,
    "out:Running Cost over RSP/MSEK": 7.562,
    "out:LCP/MSEK": -3.384,
    "out:ROI% old": 8.61,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 248018,
    "out:DH kr savings": 217153,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 132.5,
    "out:Total Energy Use Post PV": 125.09,
    "out:Primary Energy": 89.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 59.6,
    "out:CO2 Operational/m2": -33.67,
    "out:Total CO2/m2": 25.93,
    "out:Total CO2 (tons)": 88.38,
    "out:Klimatpaverkan": -128.62,
    "out:Initial Cost/MSEK": 8.9425,
    "out:Running cost/(Apt SEK y)": 27415,
    "out:Running Cost over RSP/MSEK": 7.95,
    "out:LCP/MSEK": -4.191,
    "out:ROI% old": 7.59,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 49.6,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 201131,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264274,
    "out:DH kr savings": 183029,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 128.5,
    "out:Total Energy Use Post PV": 121.09,
    "out:Primary Energy": 86.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 59.6,
    "out:CO2 Operational/m2": -35.11,
    "out:Total CO2/m2": 24.49,
    "out:Total CO2 (tons)": 83.47,
    "out:Klimatpaverkan": -133.53,
    "out:Initial Cost/MSEK": 9.02775,
    "out:Running cost/(Apt SEK y)": 26447,
    "out:Running Cost over RSP/MSEK": 7.67,
    "out:LCP/MSEK": -3.996,
    "out:ROI% old": 7.96,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 214767,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276683,
    "out:DH kr savings": 195438,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 126.5,
    "out:Total Energy Use Post PV": 119.09,
    "out:Primary Energy": 86.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.09,
    "out:CO2 Operational/m2": -35.83,
    "out:Total CO2/m2": 24.26,
    "out:Total CO2 (tons)": 82.71,
    "out:Klimatpaverkan": -134.29,
    "out:Initial Cost/MSEK": 9.147375,
    "out:Running cost/(Apt SEK y)": 25962,
    "out:Running Cost over RSP/MSEK": 7.53,
    "out:LCP/MSEK": -3.975,
    "out:ROI% old": 8.08,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 664,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 221585,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 282887,
    "out:DH kr savings": 201642,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 83.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.09,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 22.46,
    "out:Total CO2 (tons)": 76.58,
    "out:Klimatpaverkan": -140.42,
    "out:Initial Cost/MSEK": 9.232625,
    "out:Running cost/(Apt SEK y)": 24752,
    "out:Running Cost over RSP/MSEK": 7.179,
    "out:LCP/MSEK": -3.71,
    "out:ROI% old": 8.55,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 298398,
    "out:DH kr savings": 217153,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 98.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.89,
    "out:CO2 Operational/m2": 39.78,
    "out:Total CO2/m2": 74.68,
    "out:Total CO2 (tons)": 254.57,
    "out:Klimatpaverkan": 37.57,
    "out:Initial Cost/MSEK": 7.621875,
    "out:Running cost/(Apt SEK y)": 28152,
    "out:Running Cost over RSP/MSEK": 8.14,
    "out:LCP/MSEK": -3.06,
    "out:ROI% old": 8.55,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 52,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184296,
    "out:DH kr savings": 210949,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 119.5,
    "out:Primary Energy": 95.98,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.89,
    "out:CO2 Operational/m2": 38.34,
    "out:Total CO2/m2": 73.24,
    "out:Total CO2 (tons)": 249.66,
    "out:Klimatpaverkan": 32.66,
    "out:Initial Cost/MSEK": 7.707125,
    "out:Running cost/(Apt SEK y)": 27184,
    "out:Running Cost over RSP/MSEK": 7.86,
    "out:LCP/MSEK": -2.865,
    "out:ROI% old": 8.98,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 57.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 618,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 196705,
    "out:DH kr savings": 223358,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 118.5,
    "out:Primary Energy": 96.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.39,
    "out:CO2 Operational/m2": 37.98,
    "out:Total CO2/m2": 73.37,
    "out:Total CO2 (tons)": 250.13,
    "out:Klimatpaverkan": 33.13,
    "out:Initial Cost/MSEK": 7.826875,
    "out:Running cost/(Apt SEK y)": 26941,
    "out:Running Cost over RSP/MSEK": 7.79,
    "out:LCP/MSEK": -2.914,
    "out:ROI% old": 8.97,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 58.5,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 627,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199807,
    "out:DH kr savings": 226460,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 93.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.39,
    "out:CO2 Operational/m2": 36.54,
    "out:Total CO2/m2": 71.93,
    "out:Total CO2 (tons)": 245.22,
    "out:Klimatpaverkan": 28.22,
    "out:Initial Cost/MSEK": 7.912125,
    "out:Running cost/(Apt SEK y)": 25973,
    "out:Running Cost over RSP/MSEK": 7.509,
    "out:LCP/MSEK": -2.719,
    "out:ROI% old": 9.38,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212216,
    "out:DH kr savings": 238869,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 117.33,
    "out:Primary Energy": 87.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.24,
    "out:CO2 Operational/m2": 13.77,
    "out:Total CO2/m2": 61.01,
    "out:Total CO2 (tons)": 208,
    "out:Klimatpaverkan": -9,
    "out:Initial Cost/MSEK": 8.331,
    "out:Running cost/(Apt SEK y)": 26598,
    "out:Running Cost over RSP/MSEK": 7.703,
    "out:LCP/MSEK": -3.331,
    "out:ROI% old": 8.57,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 640,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241813,
    "out:DH kr savings": 210949,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 113.33,
    "out:Primary Energy": 84.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.24,
    "out:CO2 Operational/m2": 12.33,
    "out:Total CO2/m2": 59.57,
    "out:Total CO2 (tons)": 203.09,
    "out:Klimatpaverkan": -13.91,
    "out:Initial Cost/MSEK": 8.41625,
    "out:Running cost/(Apt SEK y)": 25629,
    "out:Running Cost over RSP/MSEK": 7.422,
    "out:LCP/MSEK": -3.136,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 65.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 676,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 254222,
    "out:DH kr savings": 223358,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 112.33,
    "out:Primary Energy": 85.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.74,
    "out:CO2 Operational/m2": 11.97,
    "out:Total CO2/m2": 59.71,
    "out:Total CO2 (tons)": 203.55,
    "out:Klimatpaverkan": -13.45,
    "out:Initial Cost/MSEK": 8.536,
    "out:Running cost/(Apt SEK y)": 25387,
    "out:Running Cost over RSP/MSEK": 7.352,
    "out:LCP/MSEK": -3.186,
    "out:ROI% old": 8.95,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257324,
    "out:DH kr savings": 226460,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 108.33,
    "out:Primary Energy": 82.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.74,
    "out:CO2 Operational/m2": 10.53,
    "out:Total CO2/m2": 58.27,
    "out:Total CO2 (tons)": 198.65,
    "out:Klimatpaverkan": -18.35,
    "out:Initial Cost/MSEK": 8.621125,
    "out:Running cost/(Apt SEK y)": 24418,
    "out:Running Cost over RSP/MSEK": 7.072,
    "out:LCP/MSEK": -2.99,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 269733,
    "out:DH kr savings": 238869,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 123.5,
    "out:Total Energy Use Post PV": 116.09,
    "out:Primary Energy": 85.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 59.6,
    "out:CO2 Operational/m2": -36.91,
    "out:Total CO2/m2": 22.69,
    "out:Total CO2 (tons)": 77.34,
    "out:Klimatpaverkan": -139.66,
    "out:Initial Cost/MSEK": 9.040125,
    "out:Running cost/(Apt SEK y)": 25236,
    "out:Running Cost over RSP/MSEK": 7.319,
    "out:LCP/MSEK": -3.657,
    "out:ROI% old": 8.51,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 61.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 231812,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 292193,
    "out:DH kr savings": 210949,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 119.5,
    "out:Total Energy Use Post PV": 112.09,
    "out:Primary Energy": 82.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 59.6,
    "out:CO2 Operational/m2": -38.35,
    "out:Total CO2/m2": 21.25,
    "out:Total CO2 (tons)": 72.43,
    "out:Klimatpaverkan": -144.57,
    "out:Initial Cost/MSEK": 9.125375,
    "out:Running cost/(Apt SEK y)": 24267,
    "out:Running Cost over RSP/MSEK": 7.039,
    "out:LCP/MSEK": -3.462,
    "out:ROI% old": 8.87,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 245448,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 304602,
    "out:DH kr savings": 223358,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 68.62,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 118.5,
    "out:Total Energy Use Post PV": 111.09,
    "out:Primary Energy": 83.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.09,
    "out:CO2 Operational/m2": -38.71,
    "out:Total CO2/m2": 21.38,
    "out:Total CO2 (tons)": 72.9,
    "out:Klimatpaverkan": -144.1,
    "out:Initial Cost/MSEK": 9.245125,
    "out:Running cost/(Apt SEK y)": 24025,
    "out:Running Cost over RSP/MSEK": 6.969,
    "out:LCP/MSEK": -3.512,
    "out:ROI% old": 8.86,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 248857,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307704,
    "out:DH kr savings": 226460,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 233929,
    "out:% savings (space heating)": 46.19,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 98.65,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 64.41,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 107.09,
    "out:Primary Energy": 80.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 60.09,
    "out:CO2 Operational/m2": -40.15,
    "out:Total CO2/m2": 19.94,
    "out:Total CO2 (tons)": 67.99,
    "out:Klimatpaverkan": -149.01,
    "out:Initial Cost/MSEK": 9.33025,
    "out:Running cost/(Apt SEK y)": 23057,
    "out:Running Cost over RSP/MSEK": 6.688,
    "out:LCP/MSEK": -3.316,
    "out:ROI% old": 9.21,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.44,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320113,
    "out:DH kr savings": 238869,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 219573,
    "out:% savings (space heating)": 49.5,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 94.44,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 94.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.07,
    "out:CO2 Operational/m2": 39.06,
    "out:Total CO2/m2": 76.14,
    "out:Total CO2 (tons)": 259.55,
    "out:Klimatpaverkan": 42.55,
    "out:Initial Cost/MSEK": 8.067875,
    "out:Running cost/(Apt SEK y)": 27668,
    "out:Running Cost over RSP/MSEK": 8,
    "out:LCP/MSEK": -3.366,
    "out:ROI% old": 8.33,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 54.5,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 601,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190501,
    "out:DH kr savings": 217153,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 91.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.07,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 74.7,
    "out:Total CO2 (tons)": 254.64,
    "out:Klimatpaverkan": 37.64,
    "out:Initial Cost/MSEK": 8.153125,
    "out:Running cost/(Apt SEK y)": 26699,
    "out:Running Cost over RSP/MSEK": 7.72,
    "out:LCP/MSEK": -3.171,
    "out:ROI% old": 8.73,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202909,
    "out:DH kr savings": 229562,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 91.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.57,
    "out:CO2 Operational/m2": 36.54,
    "out:Total CO2/m2": 74.11,
    "out:Total CO2 (tons)": 252.65,
    "out:Klimatpaverkan": 35.65,
    "out:Initial Cost/MSEK": 8.27275,
    "out:Running cost/(Apt SEK y)": 25973,
    "out:Running Cost over RSP/MSEK": 7.509,
    "out:LCP/MSEK": -3.08,
    "out:ROI% old": 8.97,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212216,
    "out:DH kr savings": 238869,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 89.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.57,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 73.03,
    "out:Total CO2 (tons)": 248.97,
    "out:Klimatpaverkan": 31.97,
    "out:Initial Cost/MSEK": 8.358,
    "out:Running cost/(Apt SEK y)": 25246,
    "out:Running Cost over RSP/MSEK": 7.299,
    "out:LCP/MSEK": -2.955,
    "out:ROI% old": 9.24,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221523,
    "out:DH kr savings": 248175,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 115.33,
    "out:Primary Energy": 83.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.43,
    "out:CO2 Operational/m2": 13.05,
    "out:Total CO2/m2": 62.48,
    "out:Total CO2 (tons)": 212.98,
    "out:Klimatpaverkan": -4.02,
    "out:Initial Cost/MSEK": 8.777,
    "out:Running cost/(Apt SEK y)": 26113,
    "out:Running Cost over RSP/MSEK": 7.562,
    "out:LCP/MSEK": -3.637,
    "out:ROI% old": 8.37,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 62.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248018,
    "out:DH kr savings": 217153,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 80.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.43,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 61.04,
    "out:Total CO2 (tons)": 208.07,
    "out:Klimatpaverkan": -8.93,
    "out:Initial Cost/MSEK": 8.86225,
    "out:Running cost/(Apt SEK y)": 25145,
    "out:Running Cost over RSP/MSEK": 7.282,
    "out:LCP/MSEK": -3.442,
    "out:ROI% old": 8.74,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260426,
    "out:DH kr savings": 229562,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 108.33,
    "out:Primary Energy": 80.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.92,
    "out:CO2 Operational/m2": 10.53,
    "out:Total CO2/m2": 60.45,
    "out:Total CO2 (tons)": 206.08,
    "out:Klimatpaverkan": -10.92,
    "out:Initial Cost/MSEK": 8.981875,
    "out:Running cost/(Apt SEK y)": 24418,
    "out:Running Cost over RSP/MSEK": 7.072,
    "out:LCP/MSEK": -3.351,
    "out:ROI% old": 8.96,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 269733,
    "out:DH kr savings": 238869,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 78.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.92,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 59.37,
    "out:Total CO2 (tons)": 202.4,
    "out:Klimatpaverkan": -14.6,
    "out:Initial Cost/MSEK": 9.067125,
    "out:Running cost/(Apt SEK y)": 23692,
    "out:Running Cost over RSP/MSEK": 6.861,
    "out:LCP/MSEK": -3.226,
    "out:ROI% old": 9.2,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279040,
    "out:DH kr savings": 248175,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 121.5,
    "out:Total Energy Use Post PV": 114.09,
    "out:Primary Energy": 81.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 61.78,
    "out:CO2 Operational/m2": -37.63,
    "out:Total CO2/m2": 24.15,
    "out:Total CO2 (tons)": 82.32,
    "out:Klimatpaverkan": -134.68,
    "out:Initial Cost/MSEK": 9.486125,
    "out:Running cost/(Apt SEK y)": 24752,
    "out:Running Cost over RSP/MSEK": 7.179,
    "out:LCP/MSEK": -3.963,
    "out:ROI% old": 8.32,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 238630,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 298398,
    "out:DH kr savings": 217153,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 78.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 61.78,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 22.71,
    "out:Total CO2 (tons)": 77.41,
    "out:Klimatpaverkan": -139.59,
    "out:Initial Cost/MSEK": 9.571375,
    "out:Running cost/(Apt SEK y)": 23783,
    "out:Running Cost over RSP/MSEK": 6.899,
    "out:LCP/MSEK": -3.768,
    "out:ROI% old": 8.66,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310807,
    "out:DH kr savings": 229562,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 107.09,
    "out:Primary Energy": 78.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.27,
    "out:CO2 Operational/m2": -40.15,
    "out:Total CO2/m2": 22.12,
    "out:Total CO2 (tons)": 75.42,
    "out:Klimatpaverkan": -141.58,
    "out:Initial Cost/MSEK": 9.691,
    "out:Running cost/(Apt SEK y)": 23057,
    "out:Running Cost over RSP/MSEK": 6.688,
    "out:LCP/MSEK": -3.677,
    "out:ROI% old": 8.87,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320113,
    "out:DH kr savings": 238869,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 75.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.27,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 21.04,
    "out:Total CO2 (tons)": 71.74,
    "out:Klimatpaverkan": -145.26,
    "out:Initial Cost/MSEK": 9.77625,
    "out:Running cost/(Apt SEK y)": 22330,
    "out:Running Cost over RSP/MSEK": 6.478,
    "out:LCP/MSEK": -3.552,
    "out:ROI% old": 9.1,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 329420,
    "out:DH kr savings": 248175,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 112.5,
    "out:Primary Energy": 90.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.07,
    "out:CO2 Operational/m2": 35.82,
    "out:Total CO2/m2": 72.9,
    "out:Total CO2 (tons)": 248.5,
    "out:Klimatpaverkan": 31.5,
    "out:Initial Cost/MSEK": 8.1655,
    "out:Running cost/(Apt SEK y)": 25489,
    "out:Running Cost over RSP/MSEK": 7.369,
    "out:LCP/MSEK": -2.832,
    "out:ROI% old": 9.33,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 67,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269311,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218420,
    "out:DH kr savings": 245073,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 88.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.07,
    "out:CO2 Operational/m2": 34.38,
    "out:Total CO2/m2": 71.46,
    "out:Total CO2 (tons)": 243.6,
    "out:Klimatpaverkan": 26.6,
    "out:Initial Cost/MSEK": 8.25075,
    "out:Running cost/(Apt SEK y)": 24520,
    "out:Running Cost over RSP/MSEK": 7.089,
    "out:LCP/MSEK": -2.637,
    "out:ROI% old": 9.72,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 282947,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 230829,
    "out:DH kr savings": 257482,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 107.5,
    "out:Primary Energy": 88.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.57,
    "out:CO2 Operational/m2": 34.02,
    "out:Total CO2/m2": 71.59,
    "out:Total CO2 (tons)": 244.06,
    "out:Klimatpaverkan": 27.06,
    "out:Initial Cost/MSEK": 8.3705,
    "out:Running cost/(Apt SEK y)": 24278,
    "out:Running Cost over RSP/MSEK": 7.019,
    "out:LCP/MSEK": -2.687,
    "out:ROI% old": 9.7,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233931,
    "out:DH kr savings": 260584,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 103.5,
    "out:Total Energy Use Post PV": 103.5,
    "out:Primary Energy": 86.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.57,
    "out:CO2 Operational/m2": 32.58,
    "out:Total CO2/m2": 70.15,
    "out:Total CO2 (tons)": 239.15,
    "out:Klimatpaverkan": 22.15,
    "out:Initial Cost/MSEK": 8.455625,
    "out:Running cost/(Apt SEK y)": 23309,
    "out:Running Cost over RSP/MSEK": 6.738,
    "out:LCP/MSEK": -2.491,
    "out:ROI% old": 10.08,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 299992,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246340,
    "out:DH kr savings": 272993,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 106.33,
    "out:Primary Energy": 79.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.43,
    "out:CO2 Operational/m2": 9.81,
    "out:Total CO2/m2": 59.24,
    "out:Total CO2 (tons)": 201.93,
    "out:Klimatpaverkan": -15.07,
    "out:Initial Cost/MSEK": 8.874625,
    "out:Running cost/(Apt SEK y)": 23934,
    "out:Running Cost over RSP/MSEK": 6.931,
    "out:LCP/MSEK": -3.104,
    "out:ROI% old": 9.29,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 739,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269311,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 275937,
    "out:DH kr savings": 245073,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 102.33,
    "out:Primary Energy": 77.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.43,
    "out:CO2 Operational/m2": 8.37,
    "out:Total CO2/m2": 57.8,
    "out:Total CO2 (tons)": 197.02,
    "out:Klimatpaverkan": -19.98,
    "out:Initial Cost/MSEK": 8.959875,
    "out:Running cost/(Apt SEK y)": 22965,
    "out:Running Cost over RSP/MSEK": 6.651,
    "out:LCP/MSEK": -2.908,
    "out:ROI% old": 9.65,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 282947,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288346,
    "out:DH kr savings": 257482,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 101.33,
    "out:Primary Energy": 77.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.92,
    "out:CO2 Operational/m2": 8.01,
    "out:Total CO2/m2": 57.93,
    "out:Total CO2 (tons)": 197.49,
    "out:Klimatpaverkan": -19.51,
    "out:Initial Cost/MSEK": 9.0795,
    "out:Running cost/(Apt SEK y)": 22723,
    "out:Running Cost over RSP/MSEK": 6.581,
    "out:LCP/MSEK": -2.958,
    "out:ROI% old": 9.63,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 783,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 291448,
    "out:DH kr savings": 260584,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 103.5,
    "out:Total Energy Use Post PV": 97.33,
    "out:Primary Energy": 75.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.92,
    "out:CO2 Operational/m2": 6.57,
    "out:Total CO2/m2": 56.49,
    "out:Total CO2 (tons)": 192.58,
    "out:Klimatpaverkan": -24.42,
    "out:Initial Cost/MSEK": 9.16475,
    "out:Running cost/(Apt SEK y)": 21755,
    "out:Running Cost over RSP/MSEK": 6.3,
    "out:LCP/MSEK": -2.763,
    "out:ROI% old": 9.98,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 9,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 299992,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 303857,
    "out:DH kr savings": 272993,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 112.5,
    "out:Total Energy Use Post PV": 105.09,
    "out:Primary Energy": 77.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 61.78,
    "out:CO2 Operational/m2": -40.87,
    "out:Total CO2/m2": 20.91,
    "out:Total CO2 (tons)": 71.27,
    "out:Klimatpaverkan": -145.73,
    "out:Initial Cost/MSEK": 9.58375,
    "out:Running cost/(Apt SEK y)": 22572,
    "out:Running Cost over RSP/MSEK": 6.548,
    "out:LCP/MSEK": -3.43,
    "out:ROI% old": 9.17,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 92,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 789,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 269311,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 326318,
    "out:DH kr savings": 245073,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 108.5,
    "out:Total Energy Use Post PV": 101.09,
    "out:Primary Energy": 75.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 61.78,
    "out:CO2 Operational/m2": -42.31,
    "out:Total CO2/m2": 19.47,
    "out:Total CO2 (tons)": 66.36,
    "out:Klimatpaverkan": -150.64,
    "out:Initial Cost/MSEK": 9.669,
    "out:Running cost/(Apt SEK y)": 21604,
    "out:Running Cost over RSP/MSEK": 6.268,
    "out:LCP/MSEK": -3.234,
    "out:ROI% old": 9.51,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 282947,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338726,
    "out:DH kr savings": 257482,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 57.24,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 100.09,
    "out:Primary Energy": 75.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 62.27,
    "out:CO2 Operational/m2": -42.67,
    "out:Total CO2/m2": 19.6,
    "out:Total CO2 (tons)": 66.83,
    "out:Klimatpaverkan": -150.17,
    "out:Initial Cost/MSEK": 9.788625,
    "out:Running cost/(Apt SEK y)": 21362,
    "out:Running Cost over RSP/MSEK": 6.198,
    "out:LCP/MSEK": -3.284,
    "out:ROI% old": 9.49,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 834,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 341829,
    "out:DH kr savings": 260584,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 195129,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 87.27,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000100120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.55,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 103.5,
    "out:Total Energy Use Post PV": 96.09,
    "out:Primary Energy": 72.94,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 62.27,
    "out:CO2 Operational/m2": -44.11,
    "out:Total CO2/m2": 18.16,
    "out:Total CO2 (tons)": 61.92,
    "out:Klimatpaverkan": -155.08,
    "out:Initial Cost/MSEK": 9.873875,
    "out:Running cost/(Apt SEK y)": 20393,
    "out:Running Cost over RSP/MSEK": 5.917,
    "out:LCP/MSEK": -3.089,
    "out:ROI% old": 9.82,
    "out:Payback discounted": 12,
    "out:Atemp": 3409,
    "out:Umean": 0.3,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 9,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 1855.011108,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299992,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 354237,
    "out:DH kr savings": 272993,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 182536,
    "out:% savings (space heating)": 58.01,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.57,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 117.5,
    "out:Primary Energy": 92.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.86,
    "out:CO2 Operational/m2": 37.62,
    "out:Total CO2/m2": 80.49,
    "out:Total CO2 (tons)": 274.37,
    "out:Klimatpaverkan": 57.37,
    "out:Initial Cost/MSEK": 9.398875,
    "out:Running cost/(Apt SEK y)": 26699,
    "out:Running Cost over RSP/MSEK": 7.72,
    "out:LCP/MSEK": -4.416,
    "out:ROI% old": 7.57,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 59.8,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 636,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202909,
    "out:DH kr savings": 229562,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 89.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.86,
    "out:CO2 Operational/m2": 36.54,
    "out:Total CO2/m2": 79.41,
    "out:Total CO2 (tons)": 270.69,
    "out:Klimatpaverkan": 53.69,
    "out:Initial Cost/MSEK": 9.484125,
    "out:Running cost/(Apt SEK y)": 25973,
    "out:Running Cost over RSP/MSEK": 7.509,
    "out:LCP/MSEK": -4.291,
    "out:ROI% old": 7.82,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212216,
    "out:DH kr savings": 238869,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 89.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.36,
    "out:CO2 Operational/m2": 35.46,
    "out:Total CO2/m2": 78.82,
    "out:Total CO2 (tons)": 268.7,
    "out:Klimatpaverkan": 51.7,
    "out:Initial Cost/MSEK": 9.603875,
    "out:Running cost/(Apt SEK y)": 25246,
    "out:Running Cost over RSP/MSEK": 7.299,
    "out:LCP/MSEK": -4.201,
    "out:ROI% old": 8.04,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221523,
    "out:DH kr savings": 248175,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 107.5,
    "out:Primary Energy": 87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.36,
    "out:CO2 Operational/m2": 34.02,
    "out:Total CO2/m2": 77.38,
    "out:Total CO2 (tons)": 263.79,
    "out:Klimatpaverkan": 46.79,
    "out:Initial Cost/MSEK": 9.689,
    "out:Running cost/(Apt SEK y)": 24278,
    "out:Running Cost over RSP/MSEK": 7.019,
    "out:LCP/MSEK": -4.005,
    "out:ROI% old": 8.38,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233931,
    "out:DH kr savings": 260584,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 21.25,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 111.33,
    "out:Primary Energy": 81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.21,
    "out:CO2 Operational/m2": 11.61,
    "out:Total CO2/m2": 66.82,
    "out:Total CO2 (tons)": 227.8,
    "out:Klimatpaverkan": 10.8,
    "out:Initial Cost/MSEK": 10.108,
    "out:Running cost/(Apt SEK y)": 25145,
    "out:Running Cost over RSP/MSEK": 7.282,
    "out:LCP/MSEK": -4.688,
    "out:ROI% old": 7.66,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 68.5,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 694,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260426,
    "out:DH kr savings": 229562,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 108.33,
    "out:Primary Energy": 78.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.21,
    "out:CO2 Operational/m2": 10.53,
    "out:Total CO2/m2": 65.74,
    "out:Total CO2 (tons)": 224.12,
    "out:Klimatpaverkan": 7.12,
    "out:Initial Cost/MSEK": 10.19325,
    "out:Running cost/(Apt SEK y)": 24418,
    "out:Running Cost over RSP/MSEK": 7.072,
    "out:LCP/MSEK": -4.563,
    "out:ROI% old": 7.89,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 73.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 269733,
    "out:DH kr savings": 238869,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 105.33,
    "out:Primary Energy": 78.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.71,
    "out:CO2 Operational/m2": 9.45,
    "out:Total CO2/m2": 65.16,
    "out:Total CO2 (tons)": 222.13,
    "out:Klimatpaverkan": 5.13,
    "out:Initial Cost/MSEK": 10.312875,
    "out:Running cost/(Apt SEK y)": 23692,
    "out:Running Cost over RSP/MSEK": 6.861,
    "out:LCP/MSEK": -4.472,
    "out:ROI% old": 8.09,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 7,
    "out:Return/kSEK/y": 748,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279040,
    "out:DH kr savings": 248175,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 101.33,
    "out:Primary Energy": 75.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.71,
    "out:CO2 Operational/m2": 8.01,
    "out:Total CO2/m2": 63.72,
    "out:Total CO2 (tons)": 217.22,
    "out:Klimatpaverkan": 0.22,
    "out:Initial Cost/MSEK": 10.398125,
    "out:Running cost/(Apt SEK y)": 22723,
    "out:Running Cost over RSP/MSEK": 6.581,
    "out:LCP/MSEK": -4.277,
    "out:ROI% old": 8.41,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 85.1,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 783,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291448,
    "out:DH kr savings": 260584,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 21.25,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 117.5,
    "out:Total Energy Use Post PV": 110.09,
    "out:Primary Energy": 78.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 67.57,
    "out:CO2 Operational/m2": -39.07,
    "out:Total CO2/m2": 28.5,
    "out:Total CO2 (tons)": 97.14,
    "out:Klimatpaverkan": -119.86,
    "out:Initial Cost/MSEK": 10.817125,
    "out:Running cost/(Apt SEK y)": 23783,
    "out:Running Cost over RSP/MSEK": 6.899,
    "out:LCP/MSEK": -5.014,
    "out:ROI% old": 7.66,
    "out:Payback discounted": 16,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 70,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 252266,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310807,
    "out:DH kr savings": 229562,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 43.5,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 114.5,
    "out:Total Energy Use Post PV": 107.09,
    "out:Primary Energy": 76.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 67.57,
    "out:CO2 Operational/m2": -40.15,
    "out:Total CO2/m2": 27.42,
    "out:Total CO2 (tons)": 93.46,
    "out:Klimatpaverkan": -123.54,
    "out:Initial Cost/MSEK": 10.902375,
    "out:Running cost/(Apt SEK y)": 23057,
    "out:Running Cost over RSP/MSEK": 6.688,
    "out:LCP/MSEK": -4.888,
    "out:ROI% old": 7.88,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.5,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 262493,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320113,
    "out:DH kr savings": 238869,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 148292,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 111.5,
    "out:Total Energy Use Post PV": 104.09,
    "out:Primary Energy": 76.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 68.06,
    "out:CO2 Operational/m2": -41.23,
    "out:Total CO2/m2": 26.83,
    "out:Total CO2 (tons)": 91.47,
    "out:Klimatpaverkan": -125.53,
    "out:Initial Cost/MSEK": 11.022125,
    "out:Running cost/(Apt SEK y)": 22330,
    "out:Running Cost over RSP/MSEK": 6.478,
    "out:LCP/MSEK": -4.798,
    "out:ROI% old": 8.07,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 79.8,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 798,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 272720,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 329420,
    "out:DH kr savings": 248175,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 36.98,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 107.5,
    "out:Total Energy Use Post PV": 100.09,
    "out:Primary Energy": 73.67,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.06,
    "out:CO2 Operational/m2": -42.67,
    "out:Total CO2/m2": 25.39,
    "out:Total CO2 (tons)": 86.56,
    "out:Klimatpaverkan": -130.44,
    "out:Initial Cost/MSEK": 11.10725,
    "out:Running cost/(Apt SEK y)": 21362,
    "out:Running Cost over RSP/MSEK": 6.198,
    "out:LCP/MSEK": -4.603,
    "out:ROI% old": 8.37,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 834,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.975,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 286356,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 341829,
    "out:DH kr savings": 260584,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 126048,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 21.25,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 88.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.86,
    "out:CO2 Operational/m2": 34.74,
    "out:Total CO2/m2": 77.61,
    "out:Total CO2 (tons)": 264.56,
    "out:Klimatpaverkan": 47.56,
    "out:Initial Cost/MSEK": 9.496625,
    "out:Running cost/(Apt SEK y)": 24762,
    "out:Running Cost over RSP/MSEK": 7.159,
    "out:LCP/MSEK": -3.953,
    "out:ROI% old": 8.34,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 71.6,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 7,
    "out:Return/kSEK/y": 708,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 279538,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227727,
    "out:DH kr savings": 254380,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 105.5,
    "out:Primary Energy": 86.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 42.86,
    "out:CO2 Operational/m2": 33.3,
    "out:Total CO2/m2": 76.17,
    "out:Total CO2 (tons)": 259.65,
    "out:Klimatpaverkan": 42.65,
    "out:Initial Cost/MSEK": 9.58175,
    "out:Running cost/(Apt SEK y)": 23793,
    "out:Running Cost over RSP/MSEK": 6.878,
    "out:LCP/MSEK": -3.758,
    "out:ROI% old": 8.68,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 78.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 744,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293174,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240136,
    "out:DH kr savings": 266788,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 20,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 103.5,
    "out:Total Energy Use Post PV": 103.5,
    "out:Primary Energy": 86.51,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.36,
    "out:CO2 Operational/m2": 32.58,
    "out:Total CO2/m2": 75.94,
    "out:Total CO2 (tons)": 258.89,
    "out:Klimatpaverkan": 41.89,
    "out:Initial Cost/MSEK": 9.7015,
    "out:Running cost/(Apt SEK y)": 23309,
    "out:Running Cost over RSP/MSEK": 6.738,
    "out:LCP/MSEK": -3.737,
    "out:ROI% old": 8.78,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 299992,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 246340,
    "out:DH kr savings": 272993,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 20.5,
    "out:Total Energy Use Pre PV": 100.5,
    "out:Total Energy Use Post PV": 100.5,
    "out:Primary Energy": 84.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.36,
    "out:CO2 Operational/m2": 31.5,
    "out:Total CO2/m2": 74.86,
    "out:Total CO2 (tons)": 255.2,
    "out:Klimatpaverkan": 38.2,
    "out:Initial Cost/MSEK": 9.78675,
    "out:Running cost/(Apt SEK y)": 22583,
    "out:Running Cost over RSP/MSEK": 6.528,
    "out:LCP/MSEK": -3.612,
    "out:ROI% old": 9.01,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 87,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1434.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 789,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 310219,
    "out:EL kWh savings": -15678,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 255647,
    "out:DH kr savings": 282299,
    "out:El kr savings": -26653,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 17,
    "out:Ef": 20.5,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 103.33,
    "out:Primary Energy": 77.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.21,
    "out:CO2 Operational/m2": 8.73,
    "out:Total CO2/m2": 63.94,
    "out:Total CO2 (tons)": 217.98,
    "out:Klimatpaverkan": 0.98,
    "out:Initial Cost/MSEK": 10.205625,
    "out:Running cost/(Apt SEK y)": 23208,
    "out:Running Cost over RSP/MSEK": 6.721,
    "out:LCP/MSEK": -4.224,
    "out:ROI% old": 8.37,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 81.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 766,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 279538,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 285244,
    "out:DH kr savings": 254380,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 99.33,
    "out:Primary Energy": 75.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.21,
    "out:CO2 Operational/m2": 7.29,
    "out:Total CO2/m2": 62.5,
    "out:Total CO2 (tons)": 213.08,
    "out:Klimatpaverkan": -3.92,
    "out:Initial Cost/MSEK": 10.290875,
    "out:Running cost/(Apt SEK y)": 22239,
    "out:Running Cost over RSP/MSEK": 6.44,
    "out:LCP/MSEK": -4.029,
    "out:ROI% old": 8.69,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 88.9,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 801,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293174,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297653,
    "out:DH kr savings": 266788,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 20,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 103.5,
    "out:Total Energy Use Post PV": 97.33,
    "out:Primary Energy": 75.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.71,
    "out:CO2 Operational/m2": 6.57,
    "out:Total CO2/m2": 62.28,
    "out:Total CO2 (tons)": 212.31,
    "out:Klimatpaverkan": -4.69,
    "out:Initial Cost/MSEK": 10.410625,
    "out:Running cost/(Apt SEK y)": 21755,
    "out:Running Cost over RSP/MSEK": 6.3,
    "out:LCP/MSEK": -4.009,
    "out:ROI% old": 8.78,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 92.8,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 299992,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 303857,
    "out:DH kr savings": 272993,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 14.33,
    "out:Total Energy Use Pre PV": 100.5,
    "out:Total Energy Use Post PV": 94.33,
    "out:Primary Energy": 72.93,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 55.71,
    "out:CO2 Operational/m2": 5.49,
    "out:Total CO2/m2": 61.2,
    "out:Total CO2 (tons)": 208.63,
    "out:Klimatpaverkan": -8.37,
    "out:Initial Cost/MSEK": 10.495875,
    "out:Running cost/(Apt SEK y)": 21028,
    "out:Running Cost over RSP/MSEK": 6.09,
    "out:LCP/MSEK": -3.884,
    "out:ROI% old": 9,
    "out:Payback discounted": 13,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 98.9,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1013.1,
    "out:Return %": 8,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 40.609716,
    "out:PV (m2 panels)": 213,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 38102.89,
    "out:PV (kWh sold)": 17077.45,
    "out:PV (% sold (El))": 44.82,
    "out:PV (kWh self-consumed)": 21025.44,
    "out:PV (ratio sold/self-consumed)": 0.81,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 310219,
    "out:EL kWh savings": 5347,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 313164,
    "out:DH kr savings": 282299,
    "out:El kr savings": 9091,
    "out:El kr sold": 21774,
    "out:El kr saved": 35743,
    "out:El kr return": 57517,
    "out:% solar/total": 39,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 17,
    "out:Ef": 14.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 109.5,
    "out:Total Energy Use Post PV": 102.09,
    "out:Primary Energy": 75.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 67.57,
    "out:CO2 Operational/m2": -41.95,
    "out:Total CO2/m2": 25.62,
    "out:Total CO2 (tons)": 87.32,
    "out:Klimatpaverkan": -129.68,
    "out:Initial Cost/MSEK": 10.91475,
    "out:Running cost/(Apt SEK y)": 21846,
    "out:Running Cost over RSP/MSEK": 6.338,
    "out:LCP/MSEK": -4.55,
    "out:ROI% old": 8.33,
    "out:Payback discounted": 15,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 83.3,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 7,
    "out:Return/kSEK/y": 816,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 279538,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 335624,
    "out:DH kr savings": 254380,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 34.8,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 105.5,
    "out:Total Energy Use Post PV": 98.09,
    "out:Primary Energy": 72.79,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 67.57,
    "out:CO2 Operational/m2": -43.39,
    "out:Total CO2/m2": 24.18,
    "out:Total CO2 (tons)": 82.42,
    "out:Klimatpaverkan": -134.58,
    "out:Initial Cost/MSEK": 11,
    "out:Running cost/(Apt SEK y)": 20877,
    "out:Running Cost over RSP/MSEK": 6.057,
    "out:LCP/MSEK": -4.355,
    "out:ROI% old": 8.63,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 90.8,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.8,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 293174,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 348033,
    "out:DH kr savings": 266788,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 118633,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 20,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 53.87,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 103.5,
    "out:Total Energy Use Post PV": 96.09,
    "out:Primary Energy": 73.17,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.06,
    "out:CO2 Operational/m2": -44.11,
    "out:Total CO2/m2": 23.95,
    "out:Total CO2 (tons)": 81.65,
    "out:Klimatpaverkan": -135.35,
    "out:Initial Cost/MSEK": 11.11975,
    "out:Running cost/(Apt SEK y)": 20393,
    "out:Running Cost over RSP/MSEK": 5.917,
    "out:LCP/MSEK": -4.335,
    "out:ROI% old": 8.72,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 94.8,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 299992,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 354237,
    "out:DH kr savings": 272993,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 183634,
    "out:% savings (space heating)": 57.76,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 83.89,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  },
  {
    "ID": "Z00000200120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.33,
    "out:DHW (DH)": 29.58,
    "out:Property Electricity": 20.5,
    "out:Electricity (inc PV)": 13.09,
    "out:Total Energy Use Pre PV": 100.5,
    "out:Total Energy Use Post PV": 93.09,
    "out:Primary Energy": 70.69,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 68.06,
    "out:CO2 Operational/m2": -45.19,
    "out:Total CO2/m2": 22.87,
    "out:Total CO2 (tons)": 77.97,
    "out:Klimatpaverkan": -139.03,
    "out:Initial Cost/MSEK": 11.205,
    "out:Running cost/(Apt SEK y)": 19667,
    "out:Running Cost over RSP/MSEK": 5.707,
    "out:LCP/MSEK": -4.21,
    "out:ROI% old": 8.92,
    "out:Payback discounted": 14,
    "out:Atemp": 3409,
    "out:Umean": 0.26,
    "out:Roof surface/m2": 852.250068,
    "out:Facade surface/m2": 1428.228779,
    "out:Window surface/m2": 293.045635,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 101.1,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 923.7,
    "out:Return %": 8,
    "out:Return/kSEK/y": 897,
    "out:Windows(kr/m2)": 6397.091893,
    "out:Windows (U-value before)": 3,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 88863.057826,
    "out:EAHP (pipework)": 88591.394595,
    "out:EAHP (natural ventilation grills)": 176143.125,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.58,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.83,
    "out:PV (kWp installed)": 81.219431,
    "out:PV (m2 panels)": 426,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 76205.78,
    "out:PV (kWh sold)": 50947.52,
    "out:PV (% sold (El))": 66.86,
    "out:PV (kWh self-consumed)": 25258.27,
    "out:PV (ratio sold/self-consumed)": 2.02,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 1299100,
    "out:FTX (Diffusers)": 204546.875,
    "out:FTX (Dampers)": 39140.625,
    "out:FTX (Replacement Cost)": 153420.649829,
    "out:FTX (kWh heat recovery efficiency)": 30.026483,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 37,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.8,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 456950,
    "out:DH (pipework)": 508750,
    "out:Facade U-value (before)": 1.12,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.12,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 719533.426161,
    "out:FTX (Facade)": 3903600,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 310219,
    "out:EL kWh savings": 9580,
    "out:Sewage heat recovery cost": 204912,
    "out:Balancing hydronic system cost": 85225.01,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 363544,
    "out:DH kr savings": 282299,
    "out:El kr savings": 16286,
    "out:El kr sold": 64958,
    "out:El kr saved": 42939,
    "out:El kr return": 107897,
    "out:% solar/total": 47,
    "out:Total_El consumption (without PV)": 54203,
    "out:Total_Space Heating (DH)": 171564,
    "out:% savings (space heating)": 60.54,
    "out:Total_Water Heating (DH)": 100838,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 9,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 12500,
    "out:raised roof area m2": 20,
    "out:cost_raised roof": 894775.670275,
    "out:Eupp": 80.35,
    "out:Etvv": 17,
    "out:Ef": 13.09,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 1
  }
]