export const Address = {
  lönngatan40: "Lönngatan 40",
  fågelbacksgatan37: "Fågelbacksgatan 37",
  thomsonsVäg22: "Thomsons Väg 22",
  visbyringen4: "Visbyringen 4-16",
  visbyringen18: "Visbyringen 18-22",
  visbyringen24: "Visbyringen 24-32",
  visbyringen34: "Visbyringen 34-42",
  oslogatan15: "Oslogatan 15",
  oslogatan17: "Oslogatan 17",
  oslogatan19: "Oslogatan 19",
  oslogatan21: "Oslogatan 21",
  folkparksvägen3: "Folkparksvägen 3-9",
  plåtslagarevägen4: "Plåtslagarevägen 4A",
  vandürensväg45: "Van Dürens Väg 45A",
} as const;

export type Address = (typeof Address)[keyof typeof Address];
