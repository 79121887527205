import {
  formatCurrency as Currency,
  formatIntegers as Integer,
} from "packages/utils";
import type { BreakdownTableRow } from "./BreakdownTableRow";

export const breakdownTableRows: BreakdownTableRow[] = [
  {
    fieldName: "out:ROI %",
    label: "Avkastning",
    formatter: (value) => `${Integer(value)}%`,
  },
  {
    fieldName: "out:Initial Cost/MSEK",
    label: "Ackumulerad investering (kr)",
    formatter: Currency,
  },
  {
    fieldName: "out:Total besparing (kr)",
    label: "Ackumulerad besparing (kr)",
    formatter: Currency,
  },
  {
    fieldName: "out:Klimatpaverkan",
    label: "Klimatpåverkan (Ton CO2e)",
    formatter: Integer,
  },
  {
    fieldName: "out:Primary Energy",
    label: "Primärenergital",
    formatter: Integer,
  },
];
