import type { Address } from "./Address";

export const PostNumber: Record<Address, string> = {
  "Lönngatan 40": "21 449 Malmo",
  "Fågelbacksgatan 37": "21 744 Malmo",
  "Thomsons Väg 22": "213 73 Malmo",
  "Visbyringen 4-16": "163 73 Stockholm",
  "Visbyringen 18-22": "163 73 Stockholm",
  "Visbyringen 24-32": "163 73 Stockholm",
  "Visbyringen 34-42": "163 73 Stockholm",
  "Oslogatan 15": "164 31 Stockholm",
  "Oslogatan 17": "164 31 Stockholm",
  "Oslogatan 19": "164 31 Stockholm",
  "Oslogatan 21": "164 31 Stockholm",
  "Folkparksvägen 3-9": "227 30 Lund",
  "Plåtslagarevägen 4A": "227 30 Lund",
  "Van Dürens Väg 45A": "227 30 Lund",
} as const;
